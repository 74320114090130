import styles from "./overview.module.scss";

import React, { useEffect, useState } from "react";
import { useAjax, useRoles } from "../../../../utils/hooks";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../../../components/dream-button";
import Checkbox from "../../../../components/checkbox";
import UserSelectorModal from "../../../../components/modals/user-selector";
import { useOutletContext } from "react-router-dom";

export default function AdminOptions() {
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const { server } = useOutletContext();

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  const [ping, setPing] = useState(false);
  const [running, setRunning] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  useEffect(() => {
    setPing(server.ping);
    setRunning(server.running);
    setIsWorking(server.isWorking);
  }, [server]);

  async function toggleOption(field) {
    setLoading(true);

    await ajax("/servers/toggle", {
      field,
      serverID: server._id,
    });

    if (field === "ping") {
      setPing(!ping);
    }
    if (field === "running") {
      setRunning(!running);
    }
    if (field === "isWorking") {
      setIsWorking(!isWorking);
    }

    setLoading(false);
  }

  async function handleUserSelectorModalClosed(state) {
    if (state) {
      await ajax("/servers/changeServerOwner", {
        serversIDs: [server._id],
        userID: state.value,
      });
    }

    setIsUserSelectorModalOpen(false);
  }

  async function handleRemoveBackupMarkingClicked() {
    setLoading(true);

    await ajax("/servers/removeBackupMarking", {
      serverID: server._id,
    });

    setLoading(false);
  }

  async function handleLockServerClicked() {
    setLocking(true);

    await ajax("/servers/lock", { serverID: server._id });

    setLocking(false);
  }

  return (
    <>
      <div className={styles.title}>
        <div className={styles.bold}>
          <FormattedMessage id="server-admin-options.title" />
        </div>
      </div>

      <div className={styles.adminOptions}>
        {isAllowed(["admin.sales", "admin.support"]) && (
          <div className={styles.owner}>
            <div className={styles.email}>{server.user.email}</div>
            <DreamButton
              color="light-purple"
              disabled={loading}
              onClick={() => setIsUserSelectorModalOpen(true)}
            >
              <FormattedMessage id="server-admin-options.change-server-owner" />
            </DreamButton>
          </div>
        )}

        {isAllowed("super-admin.support") && (
          <>
            <div>
              <Checkbox
                disabled={loading}
                label="server-admin-options.has-agent"
                onChange={() => toggleOption("ping")}
                checked={ping}
              />
              <Checkbox
                disabled={loading}
                label="server-admin-options.running"
                onChange={() => toggleOption("running")}
                checked={running}
              />
              <Checkbox
                disabled={loading}
                label="server-admin-options.is-working"
                onChange={() => toggleOption("isWorking")}
                checked={isWorking}
              />
            </div>

            {server.created_from_backup &&
              server.created_from_backup.at &&
              server.created_from_backup.restored_to_date && (
                <div>
                  <div className={styles.serverFromBackup}>
                    <span>
                      <FormattedMessage
                        id="server-admin-options.server-from-backup"
                        values={{
                          created_at: format(
                            new Date(server.created_from_backup.at),
                            "dd/MM/yyyy HH:mm:ss"
                          ),
                          backup_date: format(
                            new Date(
                              server.created_from_backup.restored_to_date
                            ),
                            "dd/MM/yyyy HH:mm:ss"
                          ),
                        }}
                      />
                    </span>
                  </div>

                  <DreamButton
                    disabled={loading}
                    color="light-purple"
                    onClick={handleRemoveBackupMarkingClicked}
                  >
                    <FormattedMessage id="server-admin-options.remove-backup-marking" />
                  </DreamButton>
                </div>
              )}
          </>
        )}

        {isAllowed(["super-admin.support"]) && !server.configCurrent?.lock && (
          <div>
            <DreamButton
              loading={locking}
              disabled={locking}
              onClick={handleLockServerClicked}
            >
              <FormattedMessage id="servers.lock-server" />
            </DreamButton>
          </div>
        )}
      </div>

      <UserSelectorModal
        title={
          <FormattedMessage id="server-admin-options.change-server-owner-title" />
        }
        content={
          <FormattedMessage
            id="server-admin-options.change-server-owner-content"
            values={{
              oldEmail: server.user.email,
            }}
          />
        }
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
        withMe
      />
    </>
  );
}
