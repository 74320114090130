import styles from "./groups.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import HeaderWithNavs from "../../components/cloud-layout/headers-with-navs";
import ServersSelectorModal from "../../components/modals/servers-selector";
import { WithRole } from "../../components/with-role";
import { FormattedMessage } from "react-intl";
import Box from "../../components/box";
import DreamButton from "../../components/dream-button";
import Spinner from "../../components/spinner";
import ClickTable from "../../components/click-table";
import CustomMenu from "../../components/custom-menu";
import CustomMenuItem from "../../components/custom-menu/item";
import UserSelectorModal from "../../components/modals/user-selector";
import {
  useAjax,
  useAlert,
  useConfirm,
  usePrompt,
  useUser,
} from "../../utils/hooks";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";

function MyCloudGroups({ groupsInitial }) {
  const prompt = usePrompt();
  const confirm = useConfirm();
  const ajax = useAjax();
  const user = useUser();
  const alert = useAlert();

  const [groups, setGroups] = useState(groupsInitial);
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [selectedServers, setSelectedServers] = useState({});
  const [isServersSelectorModalOpen, setIsServersSelectorModalOpen] =
    useState(false);

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);
  const [userIDToWork, setUserIDToWork] = useState(null);

  const getGroups = useCallback(async () => {
    const data = await ajax("/groups/getAll", {
      userIDToWork: userIDToWork?.value,
    });

    if (data.result === "success") {
      setGroups(data.groups);
    }
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  function handleMenuClicked(item) {
    item.isMenuOpen = !item.isMenuOpen;
    setGroups([...groups]);
  }

  function handleAddGroupClicked() {
    prompt({
      title: <FormattedMessage id="groups.add.title" />,
      message: <FormattedMessage id="groups.add.content" />,
      acceptOnlyValue: (text) => ({
        status: !!text.trim(),
        reason: <FormattedMessage id="errors.name" />,
      }),
      beforeClose: async (name) => {
        name = name ? name.trim() : false;

        if (!name) {
          return;
        }

        const data = await ajax("/groups/create", {
          userIDToWork: userIDToWork?.value,
          name,
        });

        if (data.result === "error") {
          return await alert({
            title: <FormattedMessage id="groups.add.title" />,
            message: <FormattedMessage id="groups.add.title.too-much" />,
          });
        }

        await getGroups();
      },
    });
  }

  function handleRemoveGroupClicked(group) {
    confirm({
      title: <FormattedMessage id="groups.remove.title" />,
      message: (
        <FormattedMessage
          id="groups.remove.content"
          values={{ groupName: group.name }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/groups/remove", {
          userIDToWork: userIDToWork?.value,
          _id: group._id,
        });

        await getGroups();
      },
    });
  }

  async function handleEditGroupClicked(group) {
    let name = await prompt({
      title: <FormattedMessage id="groups.edit.title" />,
      message: (
        <FormattedMessage
          id="groups.edit.content"
          values={{ groupName: group.name }}
        />
      ),
    });

    name = name ? name.trim() : false;

    if (!name) {
      return;
    }

    await ajax("/groups/edit", {
      userIDToWork: userIDToWork?.value,
      _id: group._id,
      name,
    });

    await getGroups();
  }

  function handleServersSelectorModalOpen(group) {
    const selectedServers = {};

    group.assigned_servers.forEach((s) => {
      selectedServers[s] = true;
    });

    setSelectedServers(selectedServers);

    setSelectedGroup(group);

    setIsServersSelectorModalOpen(true);
  }

  async function handleServersSelectorModalClosed(state) {
    if (state) {
      await ajax("/groups/assignServers", {
        userIDToWork: userIDToWork?.value,
        _id: selectedGroup._id,
        servers: Object.keys(selectedServers).filter((k) => selectedServers[k]),
      });

      await getGroups();
    }

    setIsServersSelectorModalOpen(false);
  }

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  return (
    <WithRole permission="servers">
      <HeaderWithNavs title={<FormattedMessage id="groups.title" />}>
        <Box className={styles.descriptionBox}>
          <FormattedMessage id="servers.manage-groups.description" />
        </Box>

        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <DreamButton
              color="light-purple"
              onClick={handleUserSelectorModalOpen}
            >
              {userIDToWork?.label || user.email}
            </DreamButton>
          </Box>
        )}

        <Box>
          {!groups && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}

          {groups && (
            <ClickTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="groups.table.name" />
                  </th>
                  <th>
                    <FormattedMessage id="groups.table.assigned-servers" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {groups.map((group, idx) => (
                  <tr key={idx}>
                    <td>{group.name}</td>
                    <td>{group.assigned_servers.length}</td>
                    <td>
                      <CustomMenu
                        isOpen={group.isMenuOpen}
                        toggle={() => handleMenuClicked(group)}
                      >
                        <CustomMenuItem
                          onClick={() => handleServersSelectorModalOpen(group)}
                        >
                          <FormattedMessage id="groups.dropdown.assign-servers" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleEditGroupClicked(group)}
                        >
                          <FormattedMessage id="general.edit" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleRemoveGroupClicked(group)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ClickTable>
          )}

          <div>
            <br />

            <DreamButton color="light-purple" onClick={handleAddGroupClicked}>
              <FormattedMessage id="groups.add-group" />
            </DreamButton>
          </div>
        </Box>

        <ServersSelectorModal
          title={<FormattedMessage id="groups.servers-selector.title" />}
          selectedServers={selectedServers}
          setSelectedServers={setSelectedServers}
          isOpen={isServersSelectorModalOpen}
          onClose={handleServersSelectorModalClosed}
          onlyActive
        />

        <UserSelectorModal
          isOpen={isUserSelectorModalOpen}
          onClose={handleUserSelectorModalClosed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}

MyCloudGroups.propTypes = {
  groupsInitial: PropTypes.array,
};

export default MyCloudGroups;
