import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IpSubnetCalculator from "ip-subnet-calculator";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../../custom-react-select";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function WanSelectorModal({ location, isOpen, onClose }) {
  const ajax = useAjax();

  const [ip, setIp] = useState(null);
  const [ips, setIps] = useState(null);

  const getVLANs = useCallback(async () => {
    const data = await ajax("/network/getVLANs", {
      type: "wan",
    });

    const vlans = data.vlans.filter((vlan) => vlan.location === location);
    const ips = [];

    vlans.forEach((vlan) => {
      vlan.subnets.forEach((subnet) => {
        const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
          subnet.ipAddress,
          subnet.netmask
        );

        for (let i = ipSubnetCalc.ipLow + 1; i < ipSubnetCalc.ipHigh; i++) {
          const curIP = IpSubnetCalculator.toString(i);
          if (!subnet.ips.find((ip) => ip.ip === curIP)) {
            ips.push({ label: curIP, value: curIP, subnet });
          }
        }
      });
    });

    setIps(ips);
  }, [ajax, location]);

  useEffect(() => {
    if (isOpen) {
      getVLANs();
      setIp(null);
    }
  }, [isOpen, getVLANs]);

  function handleOkClicked() {
    onClose(ip.value, ip.subnet);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="wan-selector-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.description}>
          <FormattedMessage id="wan-selector-modal.description" />
        </div>
        <CustomReactSelect
          instanceId="wan-selector"
          options={ips}
          value={ip}
          onChange={(item) => setIp(item)}
        />
      </ModalBody>
      <ModalFooter>
        <DreamButton color="purple" onClick={handleOkClicked}>
          <FormattedMessage id="general.ok" />
        </DreamButton>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

WanSelectorModal.propTypes = {
  location: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default WanSelectorModal;
