import styles from "./index.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import Radio from "../../radio";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function ServerSelectorModal({
  title,
  selectedServer,
  setSelectedServer,
  isOpen,
  onClose,
  onlyActive,
  withoutIds = [],
  actionButtonTextID = "general.create",
}) {
  const ajax = useAjax();

  const [domains, setDomains] = useState([]);
  const [filter, setFilter] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDomains([]);
      setFilter("");
      setLoading(false);
    }
  }, [isOpen]);

  const getAllDomains = useCallback(async () => {
    const data = await ajax(`/servers/getAllDomains`, { onlyActive });

    if (data.result === "success") {
      data.domains = data.domains.filter(
        (domain) => !withoutIds.includes(domain._id)
      );

      setDomains(data.domains);
    }
  }, [ajax, withoutIds, onlyActive]);

  useEffect(() => {
    if (isOpen) {
      getAllDomains();
    }
  }, [isOpen, getAllDomains]);

  function handleActionClicked() {
    setLoading(true);
    onClose(true);
  }

  const filteredDomains = useMemo(
    () => domains.filter((domain) => domain.hostname.includes(filter)),
    [domains, filter]
  );

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        {domains.length === 0 && (
          <div>
            <FormattedMessage id="servers-selector-modal.no-servers" />
          </div>
        )}

        {domains.length > 0 && (
          <div className={styles.search}>
            <CustomText
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={<FormattedMessage id="general.filter" />}
            />
          </div>
        )}

        <div className={styles.domains}>
          {filteredDomains.map((domain, key) => (
            <div key={key} className={styles.checkboxWrapper}>
              <Radio
                label={
                  <span>
                    {domain.hostname} - {domain.location}
                  </span>
                }
                checked={selectedServer?._id === domain._id}
                onChange={() => setSelectedServer(domain)}
              />
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading || !selectedServer}
          loading={loading}
          color="purple"
          onClick={handleActionClicked}
        >
          <FormattedMessage id={actionButtonTextID} />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

ServerSelectorModal.propTypes = {
  title: PropTypes.object,
  selectedServer: PropTypes.object,
  setSelectedServer: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onlyActive: PropTypes.bool,
};

export default ServerSelectorModal;
