import styles from "./index.module.scss";
import TrashSvg from "../../svgs/trash-purple.svg?react";

import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useAlert } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";
import DreamButton from "../../dream-button";
import { proxmoxStringToObject } from "../../../utils";
import PasswordStrengthBar from "../../password-strength-bar";
import KeyDetector from "../../key-detector";

const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Terminated", value: "Terminated" },
];

const paymentTypeOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Hourly", value: "hourly" },
];

const locationOptions = [
  {
    label: "Israel",
    value: "IL",
  },
  {
    label: "Israel (Tamares)",
    value: "TA",
  },
  {
    label: "Netherlands",
    value: "NL",
  },
  {
    label: "United State",
    value: "US",
  },
];

const boolOptions = [
  {
    label: <FormattedMessage id="general.yes" />,
    value: true,
  },
  {
    label: <FormattedMessage id="general.no" />,
    value: false,
  },
];

export default function CreateNewServerModal({
  isOpen,
  onClose,
  user,
  editServer,
}) {
  const ajax = useAjax();
  const alert = useAlert();

  const [node, setNode] = useState("");
  const [vmid, setVmid] = useState("");
  const [vmInProxmox, setVmInProxmox] = useState(null);

  const [imageOptions, setImageOptions] = useState(null);
  const [cpuOptions, setCpuOptions] = useState(null);
  const [ramOptions, setRamOptions] = useState(null);
  const [ssdOptions, setSsdOptions] = useState(null);
  const [additionalSsdOptions, setAdditionalSsdOptions] = useState(null);

  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [status, setStatus] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [image, setImage] = useState(null);
  const [serverIP, setServerIP] = useState("");
  const [location, setLocation] = useState(null);
  const [cpu, setCpu] = useState(null);
  const [ram, setRam] = useState(null);
  const [disks_gb, setDisks_gb] = useState([{ name: "new-0", size: 10 }]);
  const [backup, setBackup] = useState(null);
  const [tag, setTag] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProductsAndPrices = useCallback(async () => {
    const data = await ajax(`/admin/getProductsAndPrices`, { forCloud: true });

    if (data.result === "error") {
      return;
    }

    const prices = data.productsAndPrices.prices;

    setImageOptions(
      prices.image.map((i) => ({
        label: i.value,
        value: i.value,
      }))
    );

    setCpuOptions(prices.cpu.map((c) => ({ label: c.value, value: c.value })));

    setRamOptions(
      prices.ram_mb.map((r) => ({
        label: r.value,
        value: r.value,
      }))
    );

    setSsdOptions(
      prices.ssd_gb.map((s) => ({
        label: s.value,
        value: s.value,
      }))
    );

    setAdditionalSsdOptions(
      [
        {
          label: <FormattedMessage id="general.none" />,
          value: false,
        },
      ].concat(
        prices.additional_ssd_gb.map((as, idx) => ({
          label: as.value,
          value: as.value,
          key: idx,
        }))
      )
    );
  }, [ajax]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    fetchProductsAndPrices();
  }, [fetchProductsAndPrices, isOpen]);

  useEffect(() => {
    if (
      !isOpen ||
      !imageOptions ||
      !cpuOptions ||
      !ramOptions ||
      !ssdOptions ||
      !additionalSsdOptions
    ) {
      return;
    }

    if (editServer) {
      setNode(editServer.node.toString());
      setVmid(editServer.vmid.toString());
      setVmInProxmox(editServer.hostname);
      setDomain(editServer.hostname);
      setPassword("");
      setStatus(statusOptions.find((s) => s.value === editServer.status));
      setPaymentType(
        paymentTypeOptions.find(
          (p) => p.value === editServer.payment.payment_type
        )
      );
      setImage(imageOptions.find((i) => i.value === editServer.image));
      setServerIP(editServer.dedicatedip);
      setLocation(locationOptions.find((l) => l.value === editServer.location));
      setCpu(cpuOptions.find((c) => c.value === editServer.cpu));
      setRam(ramOptions.find((r) => r.value === editServer.ram_mb));
      setDisks_gb(editServer.disks_gb);
      setBackup(boolOptions.find((b) => b.value === editServer.backup));
      setTag(editServer.tag);
    } else {
      setNode("");
      setVmid("");
      setVmInProxmox(null);
      setDomain("");
      setPassword("");
      setPasswordScore(0);
      setStatus(statusOptions[0]);
      setPaymentType(paymentTypeOptions[0]);
      setImage(null);
      setServerIP("");
      setLocation(null);
      setCpu(null);
      setRam(null);
      setDisks_gb([{ name: "new-0", size: 10 }]);
      setBackup(null);
      setTag("");
    }

    setError(false);
    setLoading(false);
  }, [
    additionalSsdOptions,
    cpuOptions,
    editServer,
    imageOptions,
    isOpen,
    ramOptions,
    ssdOptions,
  ]);

  async function handleCheckClicked() {
    if (!node || !vmid) {
      return alert({
        title: <FormattedMessage id="error" />,
        message: <FormattedMessage id="missing-node-vmid" />,
      });
    }
    setLoading(true);
    const data = await ajax(`/proxmox/nodes/qemu/status/getStatus`, {
      node,
      vmid,
    });
    setLoading(false);

    if (data.result === "success") {
      setVmInProxmox(data.config.name);
      setDomain(data.config.name);
      setLocation(locationOptions.find((l) => l.value === data.dataCenter));
      setCpu(cpuOptions.find((c) => c.value === data.status.cpus));
      setRam(
        ramOptions.find(
          (r) => r.value === data.status.maxmem / Math.pow(1024, 2)
        )
      );
      setDisks_gb(data.disksData);

      if (data.config.ipconfig0) {
        const ipObj = proxmoxStringToObject(data.config.ipconfig0);
        if (ipObj) {
          const ip = ipObj.ip.split("/")[0];
          setServerIP(ip);
        }
      }
    } else {
      if (data.message === "no-node") {
        alert({
          title: <FormattedMessage id="error" />,
          message: <FormattedMessage id={data.message} />,
        });
      } else {
        setVmInProxmox(false);
      }
    }
  }

  function handleRemoveDiskClicked(index) {
    setDisks_gb((prev) => prev.filter((_, i) => i !== index));
  }

  async function handleCreateClicked() {
    setError(false);

    const _domain = domain?.trim();
    const _password = password?.trim();
    const _node = node?.trim();
    const _vmid = vmid?.trim();
    const _serverIP = serverIP?.trim();
    const _tag = tag?.trim();

    if (!_domain) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-hostname" />
      );
    } else if (_password && passwordScore <= 1) {
      return setError(<FormattedMessage id="general.weak-password" />);
    } else if (!location) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-location" />
      );
    } else if (!cpu) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-cpu" />
      );
    } else if (!ram) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-ram" />
      );
    } else if (!disks_gb || disks_gb.lenght < 1) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-ssd" />
      );
    } else if (!image) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-image" />
      );
    } else if (!paymentType) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-payment-type" />
      );
    } else if (!backup) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-backup" />
      );
    } else if (!_node) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-node" />
      );
    } else if (!_vmid) {
      return setError(
        <FormattedMessage id="create-new-server-modal.create.wrong-vmid" />
      );
    }

    setLoading(true);
    const data = await ajax(
      editServer ? "/admin/editServer" : "/admin/createServer",
      {
        serverID: editServer?._id,
        userID: user._id,
        domain: _domain,
        password: _password,
        status: status.value,
        paymentType: paymentType.value,
        image: image?.value,
        node: _node,
        vmid: _vmid,
        serverIP: _serverIP,
        location: location.value,
        cpu: cpu.value,
        ram: ram.value,
        disks_gb,
        backup: backup.value,
        tag: _tag,
      }
    );
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      await alert({
        title: <FormattedMessage id="create-new-server-modal.title-edit" />,
        message: (
          <FormattedMessage id={`create-new-server-modal.${data.message}`} />
        ),
      });
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editServer
              ? "create-new-server-modal.title-edit"
              : "create-new-server-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <KeyDetector
          className={styles.nodeVMID}
          onKeyDetected={handleCheckClicked}
        >
          <div>
            <span>
              <FormattedMessage id="create-new-server-modal.node" />
            </span>
            <CustomText
              value={node}
              onChange={(e) => setNode(e.target.value)}
            />
          </div>
          <div>
            <span>
              <FormattedMessage id="create-new-server-modal.vmid" />
            </span>
            <CustomText
              value={vmid}
              onChange={(e) => setVmid(e.target.value)}
            />
          </div>
          <div>
            <DreamButton
              disabled={loading}
              color="light-purple"
              onClick={handleCheckClicked}
            >
              <FormattedMessage id="check" />
            </DreamButton>
          </div>
        </KeyDetector>
        <div className={styles.vmInProxmox}>
          {vmInProxmox === false && <FormattedMessage id="no-vm-in-proxmox" />}
          {typeof vmInProxmox === "string" && (
            <FormattedMessage
              id="found-vm-in-proxmox"
              values={{ hostname: vmInProxmox, b: (msg) => <b>{msg}</b> }}
            />
          )}
        </div>

        <hr />

        <div
          className={`${styles.layout} ${
            vmInProxmox === null ? "disabled" : ""
          }`}
        >
          <div className={styles.layoutContent}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="hostname" />
              </span>
              <CustomText
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.password" />
              </span>
              <CustomText
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={<FormattedMessage id="general.leave-blank" />}
              />
            </div>
            {password && (
              <div className={styles.row}>
                <span></span>
                <PasswordStrengthBar
                  password={password}
                  onChangeScore={setPasswordScore}
                />
              </div>
            )}
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.server-ip" />
              </span>
              <CustomText
                value={serverIP}
                onChange={(e) => setServerIP(e.target.value)}
                placeholder={
                  <FormattedMessage id="create-new-server-modal.server-ip-blank" />
                }
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.location" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-location"
                options={locationOptions}
                value={location}
                onChange={(item) => setLocation(item)}
              />
            </div>
          </div>
          <div className={styles.layoutContent}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.cpu" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-cpu"
                options={cpuOptions}
                value={cpu}
                onChange={(item) => setCpu(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.ram" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-ram"
                options={ramOptions}
                value={ram}
                onChange={(item) => setRam(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.disk" />
              </span>
              <div>
                {disks_gb?.map((disk, index) => (
                  <div key={disk.name || index}>
                    <div className={styles.diskRow}>
                      <CustomReactSelect
                        value={{
                          label: disk.size.toString(),
                          value: disk.size,
                        }}
                        onChange={(selectedItem) =>
                          setDisks_gb((prev) =>
                            prev.map((d, i) =>
                              i === index
                                ? { ...d, size: selectedItem.value }
                                : d
                            )
                          )
                        }
                        options={
                          index === 0 ? ssdOptions : additionalSsdOptions
                        }
                      />
                      {index > 0 && (
                        <TrashSvg
                          onClick={() => handleRemoveDiskClicked(index)}
                        />
                      )}
                      <div>{disk.name}</div>
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </div>
            <div className="drow f100">
              <DreamButton
                color="white"
                icon="plus"
                onClick={() =>
                  setDisks_gb((prev) => [
                    ...prev,
                    {
                      name: `new-${prev.length}`,
                      size: 10,
                    },
                  ])
                }
              >
                <FormattedMessage id="plan.hardware-box.add-additional-disk" />
              </DreamButton>
            </div>
          </div>
        </div>

        <hr />

        <div
          className={`${styles.layout} ${
            vmInProxmox === null ? "disabled" : ""
          }`}
        >
          <div className={styles.layoutContent}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.status" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-status"
                options={statusOptions}
                value={status}
                onChange={(item) => setStatus(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.payment-type" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-payment-type"
                options={paymentTypeOptions}
                value={paymentType}
                onChange={(item) => setPaymentType(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.tag" />
              </span>
              <CustomText
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.layoutContent}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.image" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-image"
                options={imageOptions}
                value={image}
                onChange={(item) => setImage(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.backup" />
              </span>
              <CustomReactSelect
                instanceId="create-new-server-modal-backup"
                options={boolOptions}
                value={backup}
                onChange={(item) => setBackup(item)}
              />
            </div>
          </div>
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="purple"
          onClick={handleCreateClicked}
        >
          <FormattedMessage
            id={editServer ? "general.save" : "general.create"}
          />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}
