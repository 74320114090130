import styles from "./notes.module.scss";

import React, { useEffect, useState, Fragment } from "react";
import useIsMounted from "ismounted";
import { useAjax, useUser, useWLDVPS } from "../../../../utils/hooks";
import { WithRole } from "../../../../components/with-role";
import { FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import CustomText from "../../../../components/custom-text";
import DreamButton from "../../../../components/dream-button";
import { SUPER_ADMIN, USER, WHITELABEL } from "../../../../utils/user";
import { useOutletContext } from "react-router-dom";

export default function ServerNotes() {
  const ajax = useAjax();
  const user = useUser();
  const wldvps = useWLDVPS();
  const isMounted = useIsMounted();
  const { server } = useOutletContext();

  const [adminNotes, setAdminNotes] = useState("");
  const [whitelabelNotes, setWhitelabelNotes] = useState("");
  const [clientNotes, setClientNotes] = useState("");

  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    setAdminNotes(server.notes);
    setWhitelabelNotes(server.whitelabel_notes);
    setClientNotes(server.client_notes);
  }, [server, wldvps, user.role, isMounted]);

  async function handleSaveClicked() {
    setLoading(true);
    await ajax("/servers/updateServerNotes", {
      adminNotes,
      whitelabelNotes,
      clientNotes,
      serverID: server._id,
    });
    setLoading(false);

    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 2000);
  }

  function renderBox(item) {
    if (item.type === SUPER_ADMIN && user.role !== SUPER_ADMIN) {
      return null;
    }
    if (
      item.type === WHITELABEL &&
      (wldvps === "dreamvps" ||
        user.role === USER ||
        (user.integrator && user.integrator !== "none"))
    ) {
      return null;
    }

    let title;
    switch (item.type) {
      case SUPER_ADMIN: {
        title = <FormattedMessage id="server-notes.admin-title" />;
        break;
      }
      case WHITELABEL: {
        title = <FormattedMessage id="server-notes.whitelabel-title" />;
        break;
      }
      default: {
        title = <FormattedMessage id="server-notes.client-title" />;
        break;
      }
    }

    return (
      <Box className={styles.boxWrapper}>
        <div className={styles.title}>{title}</div>

        <CustomText
          type="textarea"
          value={item.notes}
          onChange={(e) => item.setNotes(e.target.value)}
        />
      </Box>
    );
  }

  return (
    <WithRole permission="servers">
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="server-notes.description" />
      </Box>

      <div className={styles.wrapper}>
        {[
          { notes: adminNotes, setNotes: setAdminNotes, type: SUPER_ADMIN },
          {
            notes: whitelabelNotes,
            setNotes: setWhitelabelNotes,
            type: WHITELABEL,
          },
          { notes: clientNotes, setNotes: setClientNotes, type: USER },
        ].map((item, key) => (
          <Fragment key={key}>{renderBox(item)}</Fragment>
        ))}

        <Box>
          {updated && (
            <div className={`success ${styles.success}`}>
              <FormattedMessage id="server-notes.success" />
            </div>
          )}

          {!updated && (
            <DreamButton
              disabled={loading}
              color="light-purple"
              onClick={handleSaveClicked}
            >
              <FormattedMessage id="general.save" />
            </DreamButton>
          )}
        </Box>
      </div>
    </WithRole>
  );
}
