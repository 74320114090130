import styles from "./index.module.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DreamButton from "../../dream-button";
import sanitize from "sanitize-html";
import { FormattedMessage } from "react-intl";
import CheckSvg from "../../svgs/check-bold.svg?react";
import WarningSvg from "../../svgs/warning.svg?react";
import InfoSvg from "../../svgs/info2.svg?react";
import CancelSvg from "../../svgs/cancel-black.svg?react";

let timerID = null;
let interval = 0;
function AlertModal({
  isOpen,
  onClose,
  title,
  message,
  button = {},
  notification,
  size,
  alertStyle,
}) {
  const notificationRef = useRef();
  const onCloseRef = useRef(onClose);

  const [notificationStyle, setNotificationStyle] = useState({});
  const [, setProgressBar] = useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState();

  useEffect(() => {
    if (notificationRef.current) {
      if (isOpen) {
        setProgressBar(0);
        setProgressBarWidth();

        setNotificationStyle({
          top: "20px",
          //left: `calc(50% - ${notificationRef.current.offsetWidth / 2}px)`,
          insetInlineEnd: "20px",
        });

        interval = setInterval(() => {
          setProgressBar((prev) => {
            const newWidth = prev + 1;

            setProgressBarWidth({
              width: `${newWidth}%`,
            });

            if (newWidth >= 100) {
              clearInterval(interval);
              //onClose("modal");
            }

            return newWidth;
          });
        }, 20);

        if (!timerID) {
          timerID = setTimeout(() => {
            timerID = null;
            onCloseRef.current("modal");
          }, 2200);
        }
      } else {
        setNotificationStyle({
          top: "-20px",
        });
      }
    }

    return () => {
      clearInterval(interval);
      setNotificationStyle({});
    };
  }, [isOpen]);

  function renderNotificationMessage() {
    if (message && message.type === FormattedMessage) {
      return message;
    }

    return <div dangerouslySetInnerHTML={{ __html: message }}></div>;
  }

  const styleObj = useMemo(() => {
    if (alertStyle === "success") {
      return {
        icon: <CheckSvg fill="#5bb77a" />,
        backgroundColor: "#5bb77a",
      };
    }
    if (alertStyle === "warning") {
      return {
        icon: <WarningSvg fill="#FF903E" />,
        backgroundColor: "#FF903E",
      };
    }
    if (alertStyle === "danger") {
      return {
        icon: <WarningSvg fill="#ed5555" />,
        backgroundColor: "#ed5555",
      };
    } else {
      return {
        icon: <InfoSvg fill="#2f4881" />,
        backgroundColor: "#2f4881",
      };
    }
  }, [alertStyle]);

  if (notification) {
    return (
      <div
        ref={notificationRef}
        className={styles.notificationWrapper}
        style={notificationStyle}
      >
        <div className={styles.notificationWrapperContent}>
          {styleObj?.icon}
          <div>
            {title && <div className={styles.title}>{title}</div>}
            {renderNotificationMessage()}
          </div>
          <button onClick={() => onClose("modal")} className="cleanBtn">
            <CancelSvg />
          </button>
        </div>
        <div
          className={styles.progressBar}
          style={{
            ...progressBarWidth,
            backgroundColor: styleObj?.backgroundColor,
          }}
        ></div>
      </div>
    );
  }

  function renderBody() {
    if (message && message.type === FormattedMessage) {
      return <ModalBody className={styles.body}>{message}</ModalBody>;
    }

    return (
      <ModalBody className={styles.body}>
        {typeof message === "object" && message}
        {typeof message !== "object" && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(message),
            }}
          ></div>
        )}
      </ModalBody>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose("modal")}
      size={size}
    >
      <ModalHeader className={styles.header} toggle={() => onClose("modal")}>
        {title}
      </ModalHeader>
      {renderBody()}
      <ModalFooter>
        <DreamButton
          icon={button.icon || false}
          color={button.color || "gray"}
          onClick={() => onClose("button")}
          textColor={button.textColor || false}
        >
          {button.text || <FormattedMessage id="general.close" />}
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.any,
  message: PropTypes.any,
  button: PropTypes.object,
  notification: PropTypes.bool,
  size: PropTypes.string,
  alertStyle: PropTypes.string,
};

export default AlertModal;
