import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useLang } from "../../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import Checkbox from "../../checkbox";
import DreamButton from "../../dream-button";

function TakeSnapshotModal({ server, isOpen, onClose }) {
  const ajax = useAjax();
  const lang = useLang();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [withRAM, setWithRAM] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setDescription("");
      setWithRAM(false);
      setLoading(false);
      setError(false);
    }
  }, [isOpen]);

  async function handleTakeClicked() {
    const _name = name.trim();

    setError(false);

    if (!_name.match(/^[a-zA-Z]{1,}[a-zA-Z0-9_]{1,}[a-zA-Z0-9_]*$/g)) {
      return setError(<FormattedMessage id="take-snapshot-modal.wrong-name" />);
    } else if (
      _name.includes("scheduled_snapshot") ||
      _name.includes("system_snapshot") ||
      _name.includes("autodaily")
    ) {
      return setError(
        <FormattedMessage id="take-snapshot-modal.illegal-name" />
      );
    }

    setLoading(true);
    await ajax("/snapshots/create", {
      serverID: server._id,
      name,
      description,
      withRAM,
    });
    setLoading(false);

    onClose(true);
  }

  function handleWarningClicked(e) {
    if (e.target.tagName === "A") {
      navigate(`/${lang}/my-cloud/servers/${server._id}/backups`);
    }
  }

  if (!server) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="take-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className="drow f80">
          <span>
            <FormattedMessage id="name" />
          </span>
          <CustomText name={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.row}>
          <Checkbox
            label="take-snapshot-modal.include-ram"
            checked={withRAM}
            onChange={() => setWithRAM(!withRAM)}
          />
        </div>
        <div className="drow f80">
          <span>
            <FormattedMessage id="description" />
          </span>
          <CustomText
            description={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {Array.isArray(server.disks_gb) && server.disks_gb[0]?.size > 1000 && (
          <div className={`warning ${styles.warning}`}>
            <FormattedMessage id="take-snapshot-modal.big-server-warning" />
          </div>
        )}

        <div
          onClick={handleWarningClicked}
          className={`warning ${styles.warning}`}
        >
          <FormattedMessage
            id="take-snapshot-modal.auto-delete-warning"
            values={{
              link: (val) => <a href="#">{val}</a>,
            }}
          />
        </div>

        {error && <div className={`error ${styles.error}`}>{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="take-snapshot-modal.action" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}
TakeSnapshotModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TakeSnapshotModal;
