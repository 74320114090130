import styles from "./index.module.scss";

import React, { useCallback, useState, useEffect } from "react";
import { getDaysInMonth, differenceInDays, endOfMonth } from "date-fns";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";
import { currencySymbols } from "../../../utils/billing";

function CreateServerFromSnapshotModal({ server, snapshot, isOpen, onClose }) {
  const user = useUser();
  const ajax = useAjax();

  const [loading, setLoading] = useState(false);

  const [hourlyPriceOn, setHourlyPriceOn] = useState(false);
  const [hourlyPriceOff, setHourlyPriceOff] = useState(false);
  const [monthlyAddon, setMonthlyAddon] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(false);
  const [firstMonthPayment, setFirstMonthPayment] = useState(false);

  const fetchPrice = useCallback(async () => {
    const data = await ajax(`/billing/calculator/calculateNewServerPrice`, {
      userID: server.user_id,
      paymentPeriod: server.payment.payment_type,
      cpu: server.cpu,
      ram_mb: server.ram_mb,
      disks_gb: server.disks_gb,
      backup: server.backup,
      addonsIds: server.addons.map((addon) => addon.addon_id),
    });

    if (server.payment.payment_type === "monthly") {
      const totalPrice =
        data.monthlyServerPrice.priceForOne +
        data.monthlyAddonsPrices.totalPrice;

      setMonthlyPrice(totalPrice);

      const curDate = new Date();
      const daysInMonth = getDaysInMonth(curDate);
      const daysLeft = differenceInDays(endOfMonth(curDate), curDate) - 2;

      setFirstMonthPayment((totalPrice / daysInMonth) * daysLeft);
    }

    if (server.payment.payment_type === "hourly") {
      setHourlyPriceOn(data.hourlyOnServerPrice.priceForOne);
      setHourlyPriceOff(data.hourlyOffServerPrice.priceForOne);
      setMonthlyAddon(data.monthlyAddonsPrices.totalPrice);
    }
  }, [ajax, server]);

  useEffect(() => {
    if (isOpen) {
      fetchPrice();
    }
  }, [isOpen, fetchPrice]);

  async function handleTakeClicked() {
    setLoading(true);

    const data = await ajax(`/servers/createFromSnapshot`, {
      serverID: server._id,
      snapshotNameToClone: snapshot.name,
      location: server.location,
      image: server.image,
      cpu: server.cpu,
      ram_mb: server.ram_mb,
      disks_gb: server.disks_gb,
      backup: server.backup,
      hostnames: [`${server.hostname}.copy`],
      tag: server.tag,
      paymentType: server.payment.payment_type,
      publicNetworking: true,
      ipType: 4,
    });

    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    }
  }

  if (!snapshot) {
    return null;
  }

  return (
    <Modal
      size="lg"
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="create-server-from-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody className={styles.wrapper}>
        <div className={styles.description}>
          <FormattedMessage
            id="create-server-from-snapshot-modal.description1"
            values={{
              paymentType: <span>{server.payment.payment_type}</span>,
              name: <span>{snapshot.name}.</span>,
            }}
          />
        </div>
        <br />
        <div className={styles.description}>
          <FormattedMessage id="create-server-from-snapshot-modal.description2" />
        </div>

        {server.payment.payment_type === "hourly" &&
          hourlyPriceOn &&
          hourlyPriceOff && (
            <div className={styles.box}>
              <div>
                <FormattedMessage
                  id="create-server-from-snapshot-modal.info-box-hourly"
                  values={{
                    poweredOn: (
                      <span>
                        {currencySymbols[user.currency]}
                        {hourlyPriceOn.toFixed(2)}/hr
                      </span>
                    ),
                    poweredOff: (
                      <span>
                        {currencySymbols[user.currency]}
                        {hourlyPriceOff.toFixed(2)}/hr
                      </span>
                    ),
                    addons: (
                      <div>
                        Monthly addons price{" "}
                        <span>
                          {currencySymbols[user.currency]}
                          {monthlyAddon.toFixed(2)}
                        </span>
                      </div>
                    ),
                    bandwidth: (
                      <>
                        Bandwidth usage of VPS is charged at{" "}
                        <span>$0.01 per GB</span>
                      </>
                    ),
                  }}
                />
              </div>
            </div>
          )}

        {server.payment.payment_type === "monthly" &&
          firstMonthPayment &&
          monthlyPrice && (
            <div className={styles.box}>
              <div>
                <FormattedMessage
                  id="create-server-from-snapshot-modal.info-box-monthly"
                  values={{
                    firstMonthPayment: (
                      <b>
                        {currencySymbols[user.currency]}
                        {firstMonthPayment.toFixed(2)}
                      </b>
                    ),
                    monthlyPrice: (
                      <b>
                        {currencySymbols[user.currency]}
                        {monthlyPrice.toFixed(2)}
                      </b>
                    ),
                  }}
                />
              </div>
            </div>
          )}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="create-server-from-snapshot-modal.action" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}
CreateServerFromSnapshotModal.propTypes = {
  snapshot: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateServerFromSnapshotModal;
