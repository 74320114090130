import styles from "./index.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import IpSubnetCalculator from "ip-subnet-calculator";
import MinusSvg from "../../../components/svgs/minus.svg?react";
import PlusSvg from "../../../components/svgs/plus.svg?react";
import EditSvg from "../../../components/svgs/edit-purple.svg?react";
import TrashSvg from "../../../components/svgs/trash-purple.svg?react";
import HeaderWithNavs from "../../../components/cloud-layout/headers-with-navs";
import AddIpAddressScopeModal from "../../../components/modals/add-ip-address-scope";
import AssignIpsModal from "../../../components/modals/assign-ips";
import { WithRole } from "../../../components/with-role";
import { Link } from "react-router-dom";
import PrivateNetworking from "../../../components/create-new-server/private-networking";
import { FormattedMessage } from "react-intl";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import ClickTable from "../../../components/click-table";
import DreamButton from "../../../components/dream-button";
import Box from "../../../components/box";
import CustomReactSelect from "../../../components/custom-react-select";
import Spinner from "../../../components/spinner";
import CollapseTable from "../../../components/collapse-table";
import UserSelectorModal from "../../../components/modals/user-selector";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useRoles,
  useUser,
} from "../../../utils/hooks";
import { dataCenterOptions } from "../../../utils/networks";
import { getCountryIcon } from "../../../utils/countries";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";

const ipvOptions = [
  { label: "IPv4", value: 4 },
  { label: "IPv6", value: 6 },
];

function MyCloudNetworks({ type = "lan" }) {
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const isAllowedRef = useRef(isAllowed);
  isAllowedRef.current = isAllowed;
  const user = useUser();
  const lang = useLang("en");

  const confirm = useConfirm();
  const confirmRef = useRef(confirm);

  const alert = useAlert();

  const [gatewayOptions, setGatewayOptions] = useState([]);
  const [location, setLocation] = useState(null);
  const [vlanName, setVlanName] = useState("");
  const [ipScope, setIpScope] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);

  const [ipv, setIpv] = useState(ipvOptions[0]);

  const [loading, setLoading] = useState(false);

  const [vlansData, setVlansData] = useState([]);
  const [selectedVlan, setSelectedVlan] = useState(null);
  const [selectedSubnet, setSelectedSubnet] = useState(null);
  const [isVlanDropdownOpen, setIsVlanDropdownOpen] = useState({});

  const [dataCenterFilter, setDataCenterFilter] = useState(null);

  const [isAssignIpsModalOpen, setIsAssignIpsModalOpen] = useState(false);
  const [isAddIpAddressScopeModalOpen, setIsAddIpAddressScopeModalOpen] =
    useState(false);

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);
  const [isUserSelectorModal2Open, setIsUserSelectorModal2Open] =
    useState(false);
  const [userIDToWork, setUserIDToWork] = useState(null);

  const tableHeader = useMemo(() => {
    if (type === "wan") {
      return [
        { title: <FormattedMessage id="networks.name" /> },
        { title: <FormattedMessage id="networks.location" /> },
        { title: <FormattedMessage id="networks.subnets" /> },
        { title: <FormattedMessage id="networks.ip-address" /> },
        {},
      ];
    }

    return [
      { title: <FormattedMessage id="networks.name" /> },
      { title: <FormattedMessage id="networks.location" /> },
      { title: <FormattedMessage id="networks.subnets" /> },
      { title: <FormattedMessage id="networks.vlan-number" /> },
      {},
    ];
  }, [type]);

  const handleVlanDropDownToggle = useCallback(
    (vlan) => {
      isVlanDropdownOpen[vlan._id] = !isVlanDropdownOpen[vlan._id];
      setIsVlanDropdownOpen({ ...isVlanDropdownOpen });
    },
    [isVlanDropdownOpen]
  );

  function handleChangeOwnerClicked(vlan) {
    setSelectedVlan(vlan);
    setIsUserSelectorModal2Open(true);
  }

  async function handleUserSelectorModal2Closed(user) {
    if (user) {
      await ajax("/network/changeVlanOwner", {
        vlanID: selectedVlan._id,
        userID: user.value,
      });

      await getVLANs();
    }

    setIsUserSelectorModal2Open(false);
  }

  const getVLANs = useCallback(async () => {
    const data = await ajax("/network/getVLANs", {
      userIDToWork: userIDToWork?.value,
      ipv: ipv.value,
      type,
    });

    if (!data.vlans) {
      return;
    }

    setVlansData(data);
  }, [ajax, ipv.value, type, userIDToWork?.value]);

  useEffect(() => {
    getVLANs();
  }, [getVLANs]);

  useEffect(() => {
    if (!vlansData.vlans) {
      return;
    }

    const vlan = vlansData.vlans.find((v) => v._id === selectedVlan?._id);

    if (vlan) {
      const subnet = vlan.subnets.find((s) => s._id === selectedSubnet?._id);

      setSelectedSubnet(subnet);
    }
  }, [selectedVlan?._id, selectedSubnet?._id, vlansData]);

  const dataCenterFilterOptions = useMemo(() => {
    const options = [...dataCenterOptions];

    options.unshift({
      label: <FormattedMessage id="networks.all-datacenters" />,
      value: null,
    });

    return options;
  }, []);

  useEffect(() => {
    setDataCenterFilter(dataCenterFilterOptions[0]);
  }, [dataCenterFilterOptions]);

  function handleAddIpAddressScopeModalOpen(vlan) {
    setSelectedVlan(vlan);
    setIsAddIpAddressScopeModalOpen(true);
  }

  async function handleAddIpAddressScopeModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsAddIpAddressScopeModalOpen(false);
  }

  async function handleCreateVlan() {
    setLoading(true);
    const data = await ajax("/network/addPrivateNetworkCardForUser", {
      userIDToWork: userIDToWork?.value,
      location: location.value,
      vlanName,
      ip: ipScope.value,
      netmask: netmask.value,
      gateway: gateway.value,
    });

    if (data.result === "success") {
      await getVLANs();

      alert({
        title: <FormattedMessage id="networks.add-private" />,
        message: <FormattedMessage id="networks.add-private-created" />,
        notification: true,
      });

      setLocation(null);
      setVlanName("");
      setIpAddress(null);
      setNetmask(null);
      setGateway(null);
    } else {
      alert({
        title: (
          <FormattedMessage id="modals.add-ip-address-scope.errors.add.title" />
        ),
        message: (
          <FormattedMessage
            id={`modals.add-ip-address-scope.errors.${data.message}`}
            values={{ number: data.number }}
          />
        ),
      });
    }

    setLoading(false);
  }

  function handleAssignIpsModalOpen(vlan, subnet) {
    setSelectedVlan(vlan);
    setSelectedSubnet(subnet);
    setIsAssignIpsModalOpen(true);
  }

  function handleAssignIpsModalClosed() {
    setIsAssignIpsModalOpen(false);
  }

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  const handleDeleteClicked = useCallback(
    (vlan) => {
      confirmRef.current({
        title: <FormattedMessage id="networks.delete-network-title" />,
        message: (
          <FormattedMessage
            id="networks.delete-network-message"
            values={{ networkName: vlan.vlanName }}
          />
        ),
        beforeClose: async (state) => {
          if (state !== "button2") {
            return;
          }

          const data = await ajax("/network/deleteVLAN", {
            userIDToWork: userIDToWork?.value,
            vlanID: vlan._id,
          });

          if (data.result === "success") {
            await getVLANs();
          }
        },
      });
    },
    [ajax, getVLANs, userIDToWork?.value]
  );

  const handleDeleteSubnetClicked = useCallback(
    (vlan, subnet) => {
      confirmRef.current({
        title: <FormattedMessage id="networks.delete-subnet-title" />,
        message: (
          <FormattedMessage
            id="networks.delete-subnet-message"
            values={{ ip: subnet.ipAddress }}
          />
        ),
        beforeClose: async (state) => {
          if (state !== "button2") {
            return;
          }

          await ajax("/network/deleteSubnet", {
            userIDToWork: userIDToWork?.value,
            subnetID: subnet._id,
            vlanID: vlan._id,
          });

          await getVLANs();
        },
      });
    },
    [ajax, getVLANs, userIDToWork?.value]
  );

  const tableItems = useMemo(() => {
    if (!vlansData.vlans) {
      return null;
    }

    return vlansData.vlans
      .filter(
        (item) =>
          !dataCenterFilter ||
          !dataCenterFilter.value ||
          item.location === dataCenterFilter.value
      )
      .map((vlan) => {
        const CountryIcon = getCountryIcon(vlan.location);

        const cols = [
          { content: vlan.vlanName },
          {
            content: (
              <span className={styles.location}>
                {CountryIcon} {vlan.location}
              </span>
            ),
          },
          { content: vlan.subnets.length, className: styles.onlyOnDesktop },
          {
            content: isAllowedRef.current("networks") && (
              <div onClick={(e) => e.stopPropagation()}>
                <CustomMenu
                  className={styles.vlanMenu}
                  isOpen={isVlanDropdownOpen[vlan._id]}
                  toggle={() => handleVlanDropDownToggle(vlan)}
                >
                  {isAllowedRef.current("admin.support") && (
                    <CustomMenuItem
                      onClick={() => handleChangeOwnerClicked(vlan)}
                    >
                      <FormattedMessage id="networks.change-vlan-owner" />
                    </CustomMenuItem>
                  )}

                  <CustomMenuItem onClick={() => handleDeleteClicked(vlan)}>
                    <FormattedMessage id="general.delete" />
                  </CustomMenuItem>
                </CustomMenu>
              </div>
            ),
          },
        ];

        if (type === "wan") {
          cols.splice(3, 0, {
            content: vlan.subnets[0] ? vlan.subnets[0].ipAddress : "-",
          });
        } else {
          cols.splice(3, 0, {
            content: vlan.vlanNumber || vlan._id,
          });
        }

        return {
          cols,
          expanded: (
            <div className={styles.networksList}>
              <ClickTable layout="auto">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="networks.ip-address" />
                    </th>
                    <th>
                      <FormattedMessage id="networks.subnet-mask" />
                    </th>
                    <th>
                      <FormattedMessage id="networks.gateway" />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vlan.subnets.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        <FormattedMessage id="general.no-rows" />
                      </td>
                    </tr>
                  )}
                  {vlan.subnets.map((subnet, key) => {
                    const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
                      subnet.ipAddress,
                      subnet.netmask
                    );

                    return (
                      <tr key={key}>
                        <td>
                          {subnet.ipAddress}/{subnet.netmask}
                        </td>
                        <td>{ipSubnetCalc?.prefixMaskStr}</td>
                        <td>{subnet.gateway || "-"}</td>
                        <td className={styles.svgs}>
                          {isAllowedRef.current("networks") && (
                            <EditSvg
                              onClick={() =>
                                handleAssignIpsModalOpen(vlan, subnet)
                              }
                            />
                          )}
                          {isAllowedRef.current("networks") && (
                            <TrashSvg
                              onClick={() =>
                                handleDeleteSubnetClicked(vlan, subnet)
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ClickTable>

              {isAllowedRef.current("networks") && (
                <DreamButton
                  color="light-purple"
                  onClick={() => handleAddIpAddressScopeModalOpen(vlan)}
                >
                  <FormattedMessage id="general.ip-address-scope" />
                </DreamButton>
              )}
            </div>
          ),
        };
      });
  }, [
    handleDeleteClicked,
    handleDeleteSubnetClicked,
    handleVlanDropDownToggle,
    isVlanDropdownOpen,
    type,
    vlansData,
    dataCenterFilter,
  ]);

  if (!user.current_parent && user.registerStep !== -1) {
    return (
      <Box>
        <Link href="/[lang]/my-cloud" to={`/${lang}/my-cloud`}>
          <FormattedMessage id="general.complete-registration-first" />
        </Link>
      </Box>
    );
  }

  return (
    <WithRole permission="networks">
      <HeaderWithNavs title={<FormattedMessage id="networks.title" />}>
        {type === "lan" && [SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <DreamButton
              color="light-purple"
              onClick={handleUserSelectorModalOpen}
            >
              {userIDToWork?.label || user.email}
            </DreamButton>
          </Box>
        )}

        <div className={styles.wrapper}>
          {isAllowed("networks") && userIDToWork?.value !== "ALL" && (
            <Box
              className={styles.boxWrapper}
              expanded={
                <div className={styles.expandedWrapper}>
                  <PrivateNetworking
                    location={location}
                    setLocation={setLocation}
                    vlanName={vlanName}
                    setVlanName={setVlanName}
                    ipScope={ipScope}
                    setIpScope={setIpScope}
                    ipAddress={ipAddress}
                    setIpAddress={setIpAddress}
                    netmask={netmask}
                    setNetmask={setNetmask}
                    gateway={gateway}
                    setGateway={setGateway}
                    forUserID={userIDToWork?.value}
                    gatewayOptions={gatewayOptions}
                    setGatewayOptions={setGatewayOptions}
                    server={null}
                    hideIpAddressSelect
                  />

                  <div className={styles.buttonWrapper}>
                    <DreamButton
                      disabled={
                        loading || !vlanName || !ipScope || !netmask || !gateway
                      }
                      color="light-purple"
                      onClick={handleCreateVlan}
                    >
                      <FormattedMessage id="general.create" />
                    </DreamButton>
                  </div>
                </div>
              }
            >
              {(isOpen) => (
                <div className={styles.boxText}>
                  {isOpen ? <MinusSvg /> : <PlusSvg />}
                  <FormattedMessage
                    id={
                      type === "lan"
                        ? "networks.add-private"
                        : "networks.add-public"
                    }
                  />
                </div>
              )}
            </Box>
          )}

          {type === "lan" && (
            <>
              <Box
                className={styles.gap}
                expanded={
                  <div className={styles.howToDescription}>
                    <hr />

                    <div>
                      <FormattedMessage id="networks.what-is-description" />
                    </div>
                  </div>
                }
              >
                {(isOpen) => (
                  <div className={styles.boxText}>
                    {isOpen ? <MinusSvg /> : <PlusSvg />}
                    <FormattedMessage id="networks.what-is" />
                  </div>
                )}
              </Box>

              <Box
                expanded={
                  <div className={styles.howToDescription}>
                    <hr />

                    <div>
                      <FormattedMessage id="networks.how-to-description" />
                    </div>
                  </div>
                }
              >
                {(isOpen) => (
                  <div className={styles.boxText}>
                    {isOpen ? <MinusSvg /> : <PlusSvg />}
                    <FormattedMessage id="networks.how-to" />
                  </div>
                )}
              </Box>
            </>
          )}

          <div className={styles.activeNetworks}>
            <FormattedMessage id="networks.active-networks" />
            {type === "wan" && (
              <>
                <CustomReactSelect
                  className={styles.select}
                  value={ipv}
                  onChange={(item) => setIpv(item)}
                  options={ipvOptions}
                />

                <CustomReactSelect
                  className={styles.selectDatacenters}
                  value={dataCenterFilter}
                  onChange={(item) => setDataCenterFilter(item)}
                  options={dataCenterFilterOptions}
                />
              </>
            )}
          </div>

          {!tableItems && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}
          {tableItems && (
            <CollapseTable headers={tableHeader} items={tableItems} />
          )}
        </div>

        <AddIpAddressScopeModal
          vlan={selectedVlan}
          userIDToWork={userIDToWork}
          isOpen={isAddIpAddressScopeModalOpen}
          onClose={handleAddIpAddressScopeModalClosed}
        />

        <AssignIpsModal
          vlan={selectedVlan}
          subnet={selectedSubnet}
          isOpen={isAssignIpsModalOpen}
          onClose={handleAssignIpsModalClosed}
          getVLANs={getVLANs}
          vlans={vlansData.vlans}
          userIDToWork={userIDToWork}
          type={type}
        />

        <UserSelectorModal
          isOpen={isUserSelectorModalOpen}
          onClose={handleUserSelectorModalClosed}
          withMe
          withALL
        />

        <UserSelectorModal
          isOpen={isUserSelectorModal2Open}
          onClose={handleUserSelectorModal2Closed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}

MyCloudNetworks.propTypes = {
  type: PropTypes.oneOf(["lan", "wan"]),
};

export default MyCloudNetworks;
