import styles from "./console.module.scss";

import React, { useEffect, useCallback, useState, useRef } from "react";
import { Tooltip } from "reactstrap";
import useIsMounted from "ismounted";
import { useAjax, useAlert, useUrls } from "../../../../utils/hooks";
import { WithRole } from "../../../../components/with-role";
import { getSocket } from "../../../../utils/globals";
import HelpSvg from "../../../../components/svgs/help.svg?react";
import { inverseStringCase, proxmoxStringToObject } from "../../../../utils";
import { FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import DreamButton from "../../../../components/dream-button";
import Checkbox from "../../../../components/checkbox";
import { parseNets } from "../../../../utils/networks";
import { useOutletContext } from "react-router-dom";

let timerID;

export default function ServerConsole() {
  const ajax = useAjax();
  const urls = useUrls();
  const isMounted = useIsMounted();
  const socket = getSocket();
  const { server } = useOutletContext();

  const alert = useAlert();
  const alertRef = useRef(alert);

  const [url, setUrl] = useState(null);
  const [pasteToIframe, setPasteToIframe] = useState(false);
  const [pasting, setPasting] = useState(false);
  const [doInverseStringCase, setDoInverseStringCase] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState({});

  const [mainIP, setMainIP] = useState(null);
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);

  const [isWorking, setIsWorking] = useState(false);

  const getConsoleUrl = useCallback(async () => {
    if (url) {
      return;
    }

    const data = await ajax("/servers/getConsoleUrl", { serverID: server._id });

    if (!isMounted.current) {
      return;
    }

    setUrl(data.url);
  }, [ajax, server, url, isMounted]);

  useEffect(() => {
    if (server.qemuStatus.status === "running") {
      getConsoleUrl();
    }
  }, [getConsoleUrl, server]);

  const fetchConfig = useCallback(async () => {
    const data = await ajax("/proxmox/nodes/qemu/getConfig", {
      serverID: server._id,
    });

    if (!isMounted.current) {
      return;
    }

    if (data.result === "success" && data.data) {
      const { ipconfigs, gateway } = parseNets(data.data);

      if (Array.isArray(ipconfigs) && ipconfigs.length > 0) {
        const proxmoxObj = proxmoxStringToObject(ipconfigs[0]);

        if (proxmoxObj && proxmoxObj.ip) {
          const splt = proxmoxObj.ip.split("/");

          if (splt[0]) {
            setMainIP(splt[0]);
          }
        }
      }

      const cloudinitArr = data.cloudinit?.split("\n");

      if (!cloudinitArr) {
        return;
      }

      let eth;
      cloudinitArr.forEach((line) => {
        if (line.includes("eth")) {
          eth = line.match(/[0-9]/g);
        }

        if (eth && eth[0] === "0" && line.includes("netmask")) {
          const mtch = line.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g);

          if (Array.isArray(mtch) && mtch.length > 0) {
            setNetmask(mtch[0]);
          }
        }
      });

      setGateway(gateway);
    }
  }, [ajax, isMounted, server]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const handleDomFocusOut = useCallback(() => {
    if (!pasteToIframe) {
      return;
    }

    if (!typeof navigator.clipboard.readText === "function") {
      return alertRef.current({
        title: <FormattedMessage id="error" />,
        message: <FormattedMessage id="no-clipboard-object" />,
      });
    }

    setTimeout(function () {
      if (
        document.activeElement instanceof HTMLIFrameElement &&
        pasteToIframe
      ) {
        setPasteToIframe(false);
        clearTimeout(timerID);

        setPasting({ num: 1, total: 10 });

        navigator.clipboard
          .readText()
          .then((text) => {
            if (doInverseStringCase) {
              text = inverseStringCase(text);
            }

            setTimeout(() => {
              document
                .querySelector(".server-console-wrapper")
                .querySelector("iframe")
                .contentWindow.postMessage(
                  { type: "paste", text },
                  urls[`${server.location}_CONSOLE_URL`]
                );
            }, 500);
          })
          .catch(() => {
            alertRef.current({
              title: <FormattedMessage id="error" />,
              message: <FormattedMessage id="no-clipboard-object" />,
            });
          });
      }
    }, 0);
  }, [pasteToIframe, server, urls, doInverseStringCase]);

  useEffect(() => {
    document.addEventListener("focusout", handleDomFocusOut);

    return () => {
      document.removeEventListener("focusout", handleDomFocusOut);
    };
  }, [handleDomFocusOut]);

  useEffect(() => {
    window.addEventListener("message", onWindowMessage);

    return () => {
      window.removeEventListener("message", onWindowMessage);
    };
  }, []);

  const handleServerUpdated = useCallback(
    (data) => {
      if (data.serverObj.running) {
        getConsoleUrl();
      }
    },
    [getConsoleUrl]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("update-server", handleServerUpdated);

    return () => {
      socket.off("update-server", handleServerUpdated);
    };
  }, [socket, handleServerUpdated]);

  function onWindowMessage(data) {
    const { num, total, type } = data.data;

    if (type === "clipboard-progress") {
      setPasting({ num, total });

      if (num >= total) {
        setPasting(false);
      }
    }
  }

  function handlePasteFromClipboardClicked() {
    setPasteToIframe(true);

    timerID = setTimeout(() => {
      setPasteToIframe(false);
    }, 5000);
  }

  async function handlePasteServerPasswordClicked() {
    const data = await ajax(`/servers/getServerPassword`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      if (typeof navigator.clipboard.writeText === "function") {
        await navigator.clipboard.writeText(data.password);
      } else {
        await alert({
          title: <FormattedMessage id="error" />,
          message: <FormattedMessage id="no-clipboard-object" />,
        });
      }

      setPasteToIframe(true);

      timerID = setTimeout(() => {
        setPasteToIframe(false);
      }, 5000);
    }
  }

  async function handleRunServerClicked() {
    setIsWorking(true);

    await ajax("/servers/action", {
      serversIds: [server._id],
      actionType: "start",
    });
  }

  function handleTooltipToggle(id) {
    isTooltipOpen[id] = !isTooltipOpen[id];
    setIsTooltipOpen({ ...isTooltipOpen });
  }

  function renderContent() {
    if (server.qemuStatus.status !== "running") {
      return (
        <Box>
          {isWorking && (
            <div>
              <FormattedMessage id="server-console.starting-server" />
            </div>
          )}

          {!isWorking && (
            <div onClick={handleRunServerClicked}>
              <FormattedMessage
                id="server-console.no-running"
                values={{ action: (val) => <a href="#">{val}</a> }}
              />
            </div>
          )}
        </Box>
      );
    }

    return (
      <>
        <div className={styles.buttons}>
          <DreamButton
            className={styles.first}
            disabled={!url}
            color="white"
            href={url}
            target="_blank"
          >
            <FormattedMessage id="server-console.new-window" />
          </DreamButton>
          <DreamButton
            disabled={!url}
            color="white"
            onClick={handlePasteFromClipboardClicked}
          >
            <FormattedMessage id="server-console.paste-clipboard" />
          </DreamButton>
          <DreamButton
            disabled={!url}
            color="white"
            onClick={handlePasteServerPasswordClicked}
          >
            <FormattedMessage id="server-console.paste-server-password" />
          </DreamButton>

          <div className={styles.inverseStringCaseWrapper}>
            <Checkbox
              label="console.inverse-string-case.title"
              checked={doInverseStringCase}
              onChange={(e) => setDoInverseStringCase(e.target.checked)}
            />
            <HelpSvg id="inverse-string-case" className={styles.helpSvg} />
            <Tooltip
              placement="bottom"
              isOpen={isTooltipOpen["inverse-string-case"]}
              target="inverse-string-case"
              toggle={() => handleTooltipToggle("inverse-string-case")}
            >
              <FormattedMessage id="console.inverse-string-case.description" />
            </Tooltip>
          </div>
        </div>

        {(pasteToIframe || pasting) && (
          <div className={styles.progressWrapper}>
            <FormattedMessage id="server-console.click-on-console" />
            {pasting && (
              <div
                className={styles.progress}
                style={{
                  width: `${(pasting.num / pasting.total) * 100}%`,
                }}
              ></div>
            )}
          </div>
        )}

        {url && <iframe src={url} />}
        {!url && <div className={styles.blackBox}>Loading...</div>}
      </>
    );
  }

  return (
    <WithRole permission="servers">
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="servers.description" />
      </Box>

      {mainIP && gateway && netmask && (
        <Box className={styles.descriptionBox}>
          <div className={styles.row}>
            <b>Main IP:</b>
            {mainIP}
          </div>
          <div className={styles.row}>
            <b>Gateway:</b>
            {gateway}
          </div>
          <div className={styles.row}>
            <b>Netmask:</b>
            {netmask}
          </div>
        </Box>
      )}

      <div className={`${styles.wrapper} server-console-wrapper`}>
        {renderContent()}
      </div>
    </WithRole>
  );
}
