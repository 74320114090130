import styles from "./index.module.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomText from "../custom-text";
import { CircleFlag } from "react-circle-flags";
import { countriesPhoneCodes } from "../../utils/countries";
import CountryCodeSelector from "../country-code-selector";

function PhoneInput({
  value,
  onChange,
  countryCode = null,
  defaultCountry = null,
  setCountryCode = () => {},
  textClassName = "",
  affiliate = null,
  errorText,
  showSelector = false,
  name = "",
}) {
  const countryCodeRef = useRef();

  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const [countrySelectorItem, setCountrySelectorItem] = useState(null);
  const [paddingInlineStart, setPaddingInlineStart] = useState(0);

  useEffect(() => {
    if (!countryCode || showSelector) {
      setShowCountrySelector(true);
    }
  }, [countryCode, showSelector]);

  useEffect(() => {
    setTimeout(() => {
      if (countryCodeRef.current) {
        setPaddingInlineStart(countryCodeRef.current.clientWidth + 65);
      }
    }, 1);
  }, [countrySelectorItem]);

  function handleCountrySelectorChanged(item) {
    setCountrySelectorItem(item);
    setCountryCode(item.countryCode.toUpperCase());
  }

  if (affiliate) {
    return (
      <div className={styles.withCountrySelectorWrapper}>
        <div
          className={styles.countrySelectorWrapper}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <CountryCodeSelector
            value={countrySelectorItem}
            onChange={handleCountrySelectorChanged}
            defaultCountry={countryCode}
          />
          {countrySelectorItem && (
            <span ref={countryCodeRef} className={styles.countryCode}>
              ({countrySelectorItem.phoneCode})
            </span>
          )}
        </div>
        <CustomText
          autoFocus
          className={textClassName}
          style={{
            paddingInlineStart,
          }}
          inputClassName={styles.input}
          maxLength={15}
          value={value}
          onChange={onChange}
          errorText={errorText}
          name={name}
        />
      </div>
    );
  }

  if (showCountrySelector) {
    return (
      <div className={styles.withCountrySelectorWrapper}>
        <div
          className={styles.countrySelectorWrapper}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <CountryCodeSelector
            value={countrySelectorItem}
            onChange={handleCountrySelectorChanged}
            defaultCountry={defaultCountry}
          />
          {countrySelectorItem && (
            <span ref={countryCodeRef} className={styles.countryCode}>
              ({countrySelectorItem.phoneCode})
            </span>
          )}
        </div>
        <CustomText
          className={textClassName}
          style={{
            paddingInlineStart,
          }}
          inputClassName={styles.input}
          maxLength={15}
          value={value}
          onChange={onChange}
          errorText={errorText}
          name={name}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.abs}>
        <div className={styles.countryFlagWrapper}>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="25" />
        </div>

        <span className={styles.code}>
          (+{countriesPhoneCodes[countryCode]})
        </span>
      </div>
      <CustomText
        autoFocus
        className={`${styles.input} ${textClassName}`}
        maxLength={15}
        value={value}
        onChange={onChange}
        errorText={errorText}
        name={name}
      />
    </div>
  );
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  countryCode: PropTypes.string,
  defaultCountry: PropTypes.string,
  setCountryCode: PropTypes.func,
  textClassName: PropTypes.string,
  affiliate: PropTypes.bool,
  errorText: PropTypes.any,
  showSelector: PropTypes.bool,
  name: PropTypes.string,
};

export default PhoneInput;
