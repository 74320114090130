import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getDay } from "date-fns";
import DatePicker from "react-datepicker";
import { useAjax } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";

function ScheduledRemoveSnapshotModal({ server, isOpen, onClose, snapshot }) {
  const ajax = useAjax();

  const [datetime, setDatetime] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDatetime(null);

      setError(null);
      setLoading(false);
    }
  }, [isOpen]);

  async function handleAddClicked() {
    setError(null);

    if (!datetime) {
      return setError(
        <FormattedMessage
          id="general.is-required"
          values={{ field: "Date & Time" }}
        />
      );
    }

    setLoading(true);

    await ajax("/scheduler/scheduleTask", {
      serverID: server._id,
      action: "remove-snapshot",
      at: [getDay(datetime)],
      extraData: { snapshotName: snapshot.name },
      frequency: "weekly",
      once: true,
      time: datetime,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="scheduled-remove-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className="drow">
          <FormattedMessage id="scheduled-remove-snapshot-modal.description" />
        </div>
        <div className="drow f100">
          <span>
            <FormattedMessage id="general.datetime" />
          </span>
          <DatePicker
            wrapperClassName="select"
            selected={datetime}
            onChange={(date) => setDatetime(date)}
            showTimeSelect
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          onClick={handleAddClicked}
          disabled={loading}
          loading={loading}
          color="purple"
        >
          <FormattedMessage id="general.add" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

ScheduledRemoveSnapshotModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  snapshot: PropTypes.object,
};

export default ScheduledRemoveSnapshotModal;
