import styles from "./index.module.scss";

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AutoSizer, Table, Column } from "react-virtualized";
import IpSubnetCalculator from "ip-subnet-calculator";
import EditIpAddressModal from "../edit-ip-address";
import RemoveIpFromServerModal from "../remove-ip-from-server";
import IpHistoryModal from "../ip-history";
import { FormattedMessage, useIntl } from "react-intl";
import CustomText from "../../custom-text";
import CustomMenu from "../../custom-menu";
import CustomMenuItem from "../../custom-menu/item";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function AssignIpsModal({
  getVLANs,
  vlans,
  vlan,
  subnet,
  isOpen,
  onClose,
  userIDToWork,
  type,
}) {
  const ajax = useAjax();
  const intl = useIntl();

  const [ips, setIps] = useState([]);
  const [selectedIpHistory, setSelectedIpHistory] = useState(null);
  const [selectedIpObj, setSelectedIpObj] = useState(null);
  const [ipsDropdownOpen, setIpsDropdownOpen] = useState({});
  const [filter, setFilter] = useState("");

  const [isEditIpAddressModalOpen, setIsEditIpAddressModalOpen] =
    useState(false);
  const [isRemoveIpFromServerModalOpen, setIsRemoveIpFromServerModalOpen] =
    useState(false);
  const [isIpHistoryModalOpen, setIsIpHistoryModalOpen] = useState(false);

  const [selectedIpObject, setSelectedIpObject] = useState(null);

  useEffect(() => {
    if (!subnet) {
      return;
    }

    const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
      subnet.ipAddress,
      subnet.netmask
    );

    if (!ipSubnetCalc) {
      return;
    }

    const ips = [];

    for (let i = ipSubnetCalc.ipLow; i <= ipSubnetCalc.ipHigh; i++) {
      const ipStr = IpSubnetCalculator.toString(i);

      if (!ipStr.endsWith(".0") && !ipStr.endsWith(".255")) {
        const ipObj = subnet.ips.find((ip) => ip.ip === ipStr);

        ips.push(ipObj || { ip: ipStr });
      }
    }

    setIps(ips);
  }, [vlans, vlan, subnet]);

  function handleIpsDropdownToggle(key) {
    ipsDropdownOpen[key] = !ipsDropdownOpen[key];
    setIpsDropdownOpen({ ...ipsDropdownOpen });
  }

  function handleEditIpAddressModalOpen(ipObj) {
    setSelectedIpObj(ipObj);
    setIsEditIpAddressModalOpen(true);
  }

  async function handleEditIpAddressModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsEditIpAddressModalOpen(false);
  }

  async function handleRemoveIpAddressClicked(ipObj) {
    setSelectedIpObject(ipObj);
    setIsRemoveIpFromServerModalOpen(true);
  }

  async function handleRemoveIpFromServerModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsRemoveIpFromServerModalOpen(false);
  }

  async function handleSetAsGatewayClicked(ipObj = null) {
    await ajax("/network/changeSubnetGateway", {
      userIDToWork,
      vlanID: vlan._id,
      subnetID: subnet._id,
      newGateway: ipObj ? ipObj.ip : null,
    });

    await getVLANs();
  }

  function handleShowIpHistory(ip) {
    setSelectedIpHistory(ip);
    setIsIpHistoryModalOpen(true);
  }

  function handleIpHistoryModalClosed() {
    setIsIpHistoryModalOpen(false);
  }

  const ipsFiltered = useMemo(() => {
    const _filter = filter.toLowerCase();

    return ips.filter(
      (ip) =>
        !_filter ||
        ip.ip.includes(_filter) ||
        (ip.ip === subnet.gateway && "gateway".includes(_filter)) ||
        ip.server?.hostname.toLowerCase().includes(_filter) ||
        ip.reserve_name?.toLowerCase().includes(_filter)
    );
  }, [filter, ips, subnet]);

  function renderIpContent(ipObject) {
    if (ipObject.ip === subnet?.gateway) {
      if (type === "lan" && ipObject?.server?.hostname) {
        return `${ipObject.server.hostname} [${intl.formatMessage({
          id: "networks.gateway",
        })}]`;
      }

      return <FormattedMessage id="networks.gateway" />;
    }

    if (ipObject.reserve_name) {
      return `${ipObject.reserve_name} (${intl.formatMessage({
        id: "networks.reserved",
      })})`;
    }

    if (!ipObject.server) {
      return ipObject.server_id ? (
        <FormattedMessage
          id="networks.server-not-found"
          values={{ serverID: ipObject.server_id }}
        />
      ) : (
        "-"
      );
    }

    if (type === "lan") {
      return ipObject.server.hostname;
    }

    if (!ipObject.server.user) {
      return ipObject.server.hostname;
    }

    return (
      <FormattedMessage
        id="networks.client-details"
        values={{
          email: ipObject.server.user.email,
          number: ipObject.server.user.clientNumber,
          domain: ipObject.server.hostname,
        }}
      />
    );
  }

  return (
    <>
      <Modal
        className={styles.wrapper}
        isOpen={isOpen}
        toggle={() => onClose(false)}
        size="xl"
      >
        <ModalHeader toggle={() => onClose(false)}>
          <FormattedMessage id="assign-ips-modal.title" />
        </ModalHeader>
        <ModalBody>
          <div className={styles.header}>
            <CustomText
              className={styles.filterInput}
              placeholder={<FormattedMessage id="general.filter..." />}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>

          <div className={styles.tableWrapper}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  headerHeight={40}
                  height={500}
                  rowHeight={40}
                  rowGetter={({ index }) => ipsFiltered[index]}
                  rowCount={ipsFiltered.length}
                  width={width}
                  style={{ overflow: "visible" }}
                  rowStyle={({ index }) => ({
                    overflow: "visible",
                    backgroundColor:
                      index % 2 === 1 ? "var(--light-background)" : "",
                  })}
                >
                  <Column
                    headerRenderer={() => (
                      <FormattedMessage id="assign-ips-modal.ip-address" />
                    )}
                    dataKey="ip"
                    width={130}
                  />
                  <Column
                    width={100}
                    flexGrow={1}
                    headerRenderer={() => (
                      <FormattedMessage id="assign-ips-modal.details" />
                    )}
                    cellRenderer={({ rowIndex }) =>
                      renderIpContent(ipsFiltered[rowIndex])
                    }
                    dataKey=""
                  />
                  <Column
                    width={100}
                    headerRenderer={() => (
                      <FormattedMessage id="assign-ips-modal.actions" />
                    )}
                    style={{ overflow: "visible" }}
                    cellRenderer={({ rowIndex }) =>
                      !ipsFiltered[rowIndex].lock && (
                        <CustomMenu
                          isOpen={ipsDropdownOpen[rowIndex]}
                          toggle={() => handleIpsDropdownToggle(rowIndex)}
                        >
                          {subnet?.gateway !== ipsFiltered[rowIndex].ip &&
                            !ipsFiltered[rowIndex].server_id && (
                              <CustomMenuItem
                                onClick={() =>
                                  handleEditIpAddressModalOpen(
                                    ipsFiltered[rowIndex]
                                  )
                                }
                              >
                                <FormattedMessage id="general.edit" />
                              </CustomMenuItem>
                            )}
                          {(ipsFiltered[rowIndex].server_id ||
                            ipsFiltered[rowIndex].reserve_name) && (
                            <CustomMenuItem
                              onClick={() =>
                                handleRemoveIpAddressClicked(
                                  ipsFiltered[rowIndex]
                                )
                              }
                            >
                              <FormattedMessage id="assign-ips-modal.remove-server" />
                            </CustomMenuItem>
                          )}
                          {subnet?.gateway !== ipsFiltered[rowIndex].ip && (
                            <CustomMenuItem
                              onClick={() =>
                                handleSetAsGatewayClicked(ipsFiltered[rowIndex])
                              }
                            >
                              <FormattedMessage id="assign-ips-modal.set-as-gateway" />
                            </CustomMenuItem>
                          )}
                          {subnet?.gateway === ipsFiltered[rowIndex].ip && (
                            <CustomMenuItem
                              onClick={() => handleSetAsGatewayClicked()}
                            >
                              <FormattedMessage id="assign-ips-modal.remove-gateway" />
                            </CustomMenuItem>
                          )}
                          {type === "wan" && (
                            <CustomMenuItem
                              onClick={() =>
                                handleShowIpHistory(ipsFiltered[rowIndex].ip)
                              }
                            >
                              <FormattedMessage id="assign-ips-modal.ip-history" />
                            </CustomMenuItem>
                          )}
                        </CustomMenu>
                      )
                    }
                    dataKey=""
                  />
                </Table>
              )}
            </AutoSizer>
          </div>

          <EditIpAddressModal
            vlan={vlan}
            subnet={subnet}
            ipObject={selectedIpObj}
            isOpen={isEditIpAddressModalOpen}
            onClose={handleEditIpAddressModalClosed}
            getVLANs={getVLANs}
            userIDToWork={userIDToWork}
            type={type}
          />

          <RemoveIpFromServerModal
            isOpen={isRemoveIpFromServerModalOpen}
            onClose={handleRemoveIpFromServerModalClosed}
            userIDToWork={userIDToWork}
            type={type}
            ipObject={selectedIpObject}
          />
        </ModalBody>
        <ModalFooter>
          <DreamButton color="text" onClick={() => onClose(false)}>
            <FormattedMessage id="general.close" />
          </DreamButton>
        </ModalFooter>
      </Modal>

      <IpHistoryModal
        ip={selectedIpHistory}
        isOpen={isIpHistoryModalOpen}
        onClose={handleIpHistoryModalClosed}
      />
    </>
  );
}

AssignIpsModal.propTypes = {
  vlans: PropTypes.array,
  getVLANs: PropTypes.func,
  vlan: PropTypes.object,
  subnet: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userIDToWork: PropTypes.object,
  type: PropTypes.string,
};

export default AssignIpsModal;
