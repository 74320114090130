import styles from "./index.module.scss";

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

function CollapseTable({ headers, items }) {
  const [openItems, setOpenItems] = useState({});

  function handleItemClicked(key) {
    openItems[key] = !openItems[key];
    setOpenItems({ ...openItems });
  }

  return (
    <Fragment>
      <div className={styles.listHeader}>
        {headers.map((header, key) => (
          <div key={key} className={`${styles.item} ${header.className}`}>
            {header.title}
          </div>
        ))}
      </div>

      {items.length === 0 && (
        <div className={styles.listWrapper}>
          <div className={styles.item}>No rows to show...</div>
        </div>
      )}

      {items.map((item, key) => (
        <div
          key={key}
          className={`${styles.listWrapper} ${
            openItems[key] ? styles.open : ""
          }`}
          onClick={() => handleItemClicked(key)}
        >
          <div className={styles.item}>
            {item.cols.map(({ content, className }, key) => (
              <div key={key} className={className}>
                {content}
              </div>
            ))}
          </div>

          {item.expanded && (
            <div
              className={styles.expanded}
              onClick={(e) => e.stopPropagation()}
            >
              {item.expanded}
            </div>
          )}
        </div>
      ))}
    </Fragment>
  );
}

CollapseTable.propTypes = {
  headers: PropTypes.array,
  items: PropTypes.array,
};

export default CollapseTable;
