import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import WithServers from "../../../components/servers/with-servers";
import { useLang, useServers, useUser } from "../../../utils/hooks";
import { WithRole } from "../../../components/with-role";
import CompleteRegistrationModalStep1 from "../../../components/modals/complete-registration/step1";
import CompleteRegistrationModalStep2 from "../../../components/modals/complete-registration/step2";
import CompleteRegistrationModalStep3 from "../../../components/modals/complete-registration/step3";
import { USER_STATUS_VERIFICATION_REQUIRED } from "../../../utils/user";
import VerificationRequired from "../../../components/verification-required";
import { useNavigate } from "react-router-dom";
import LayoutWithStripe from "../../../components/layout-with-stripe";
import Box from "../../../components/box";
import { FormattedMessage } from "react-intl";

export default function MyCloudServers() {
  const servers = useServers();
  const user = useUser();
  const navigate = useNavigate();
  const lang = useLang("en");

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (user && user._id) {
      if (user.current_parent) {
        const role = user.roles.find(
          (item) => item.parent_user_id === user.current_parent
        );

        if (role.permissions.includes("servers")) {
          navigate(`/${lang}/my-cloud/servers`);
        } else if (role.permissions.includes("networks")) {
          navigate(`/${lang}/my-cloud/networks`);
          setTimeout(() => {
            document
              .querySelector(
                "[class*=sidebar_subNav] [href*='my-cloud/networks']"
              )
              .click();
          }, 1);
        } else if (role.permissions.includes("firewall")) {
          navigate(`/${lang}/my-cloud/firewall`);
          setTimeout(() => {
            document
              .querySelector(
                "[class*=sidebar_subNav] [href*='firewall/security-groups']"
              )
              .click();
          }, 1);
        } else if (
          role.permissions.includes("smtp") ||
          role.permissions.includes("api")
        ) {
          document
            .querySelector("[class*=sidebar_mainNav] [href*='services/smtp']")
            .click();
        } else if (role.permissions.includes("billing")) {
          document
            .querySelector("[class*=_mainNav] [href*='billing/invoices']")
            .click();
        }
      }

      setStep(user.registerStep);
    }
  }, [user, lang, navigate]);

  function handleBackClicked() {
    setStep(step - 1);
  }

  function handleNextClicked() {
    const newStep = step + 1;

    if (newStep > 3) {
      //
    }

    setStep(newStep);
  }

  if (!user._id) {
    return null;
  }

  if (!user.current_parent && user.registerStep !== -1) {
    return (
      <div className={styles.boxes}>
        <Box className={styles.box1}>
          <FormattedMessage id="my-cloud-servers.complete-registration" />
        </Box>

        <Box className={styles.box2}>
          <div className={styles.wrapper}>
            <div className={styles.steps}>
              <div
                className={`${styles.step} ${step === 1 && styles.selected}`}
              >
                <div className={styles.number}>1</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.personal-information" />
                </div>
              </div>
              <div
                className={`${styles.step} ${step === 2 && styles.selected}`}
              >
                <div className={styles.number}>2</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.phone-verification" />
                </div>
              </div>
              <div
                className={`${styles.step} ${step === 3 && styles.selected}`}
              >
                <div className={styles.number}>3</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.payment-details" />
                </div>
              </div>
            </div>

            {step === 1 && (
              <CompleteRegistrationModalStep1 onNext={handleNextClicked} />
            )}

            {step === 2 && (
              <CompleteRegistrationModalStep2
                onNext={handleNextClicked}
                onBack={handleBackClicked}
              />
            )}

            {step === 3 && (
              <LayoutWithStripe>
                <CompleteRegistrationModalStep3
                  onNext={handleNextClicked}
                  location={location}
                />
              </LayoutWithStripe>
            )}
          </div>
        </Box>
      </div>
    );
  }

  if (user.status === USER_STATUS_VERIFICATION_REQUIRED) {
    return <VerificationRequired />;
  }

  return (
    <WithRole permission="servers">
      {user._id && servers && (
        <div className={styles.serversWrapper}>
          <WithServers />
        </div>
      )}
    </WithRole>
  );
}
