import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import isValidDomain from "is-valid-domain";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function CreateTemplateFromServerModal({ isOpen, onClose, server }) {
  const ajax = useAjax();

  const [hostname, setHostname] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setHostname("");
      setError(null);
      setLoading(false);
    }
  }, [isOpen]);

  async function handleCreateClicked() {
    const _hostname = hostname.trim();

    setError(null);

    if (!isValidDomain(_hostname)) {
      return setError(<FormattedMessage id="general.invalid-hostname" />);
    }

    setLoading(true);

    await ajax("/servers/createTemplate", { serverID: server._id, hostname });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="create-template-from-server-modal.title" />
      </ModalHeader>
      <ModalBody>
        {server && (
          <>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="general.template-name" />
              </span>
              <CustomText
                value={hostname}
                onChange={(e) => setHostname(e.target.value)}
              />
            </div>
            <div className={styles.note}>
              <FormattedMessage
                id="create-template-from-server-modal.note"
                values={{ datacenter: server.location }}
              />
            </div>

            {error && <div className="error">{error}</div>}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          onClick={handleCreateClicked}
          disabled={loading}
          color="purple"
        >
          <FormattedMessage id="general.create" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

CreateTemplateFromServerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  server: PropTypes.object,
};

export default CreateTemplateFromServerModal;
