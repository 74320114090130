import styles from "./index.module.scss";

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import sanitize from "sanitize-html";
import Checkbox from "../../checkbox";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import Spinner from "../../spinner";
import KeyDetector from "../../key-detector";
import { FormattedMessage } from "react-intl";

function PromptModal({
  isOpen,
  onClose,
  title,
  message,
  defaultText,
  button1 = {},
  button2 = {},
  acceptOnlyValue = false,
  textType = "text",
  beforeClose = null,
  placeholder = null,
  withCheckbox = null,
}) {
  const inputWrapperRef = useRef();

  const [text, setText] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setText(defaultText || "");
    setError(false);
  }, [defaultText]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputWrapperRef.current.querySelector("input").focus();
      }, 1);
    }
  }, [isOpen]);

  async function handleActionButtonClicked() {
    setError(false);

    if (typeof acceptOnlyValue === "string" && text !== acceptOnlyValue) {
      return setError(true);
    }
    if (typeof acceptOnlyValue === "function") {
      const { status, reason } = await acceptOnlyValue(text);

      if (!status) {
        return setError(reason);
      }
    }

    if (typeof beforeClose === "function") {
      setLoading(true);
      await beforeClose(text, isCheckboxChecked);
      setLoading(false);
    }

    onClose(text, isCheckboxChecked);
  }

  function renderDiv() {
    if (message && message.type === FormattedMessage) {
      return <>{message}</>;
    }

    return (
      <div
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
        className={styles.text}
      ></div>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(null)}
      size="lg"
    >
      <ModalHeader className={styles.header} toggle={() => onClose(null)}>
        {title}
      </ModalHeader>
      <ModalBody className={styles.body}>
        <KeyDetector onKeyDetected={handleActionButtonClicked}>
          {renderDiv()}
          <div className={styles.inputWrapper} ref={inputWrapperRef}>
            <CustomText
              value={text}
              type={textType}
              onChange={(e) => setText(e.target.value)}
              placeholder={placeholder}
            />
          </div>
          {withCheckbox && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                label={withCheckbox}
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
            </div>
          )}

          {error && (
            <div className={`error ${styles.error}`}>
              {typeof error === "boolean" && (
                <div>
                  <FormattedMessage
                    id="prompt-modal.error"
                    values={{
                      value: acceptOnlyValue,
                    }}
                  />
                </div>
              )}

              {(typeof error === "string" || typeof error === "object") &&
                error}
            </div>
          )}
        </KeyDetector>
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          <DreamButton
            disabled={loading}
            icon={button1.icon || false}
            color={button1.color || "green"}
            onClick={() => onClose(null)}
            textColor={button1.textColor || false}
          >
            {button1.text || <FormattedMessage id="general.close" />}
          </DreamButton>
          <DreamButton
            disabled={loading}
            icon={button2.icon || false}
            color={button2.color || "green"}
            onClick={handleActionButtonClicked}
            textColor={button2.textColor || false}
            href={button2.href}
            as={button2.as}
          >
            {loading ? <Spinner /> : ""}
            {button2.text || <FormattedMessage id="general.ok" />}
          </DreamButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

PromptModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.any,
  message: PropTypes.any,
  defaultText: PropTypes.string,
  button1: PropTypes.object,
  button2: PropTypes.object,
  acceptOnlyValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.func,
  ]),
  textType: PropTypes.string,
  beforeClose: PropTypes.func,
  placeholder: PropTypes.string,
  withCheckbox: PropTypes.string,
};

export default PromptModal;
