import React from "react";
import { FormattedMessage } from "react-intl";

export const getTranslatedLog = (log) => {
  switch (log.log_type) {
    case "LOG_TYPE_USER_REGISTER":
      return <FormattedMessage id="account-logs.register" />;
    case "LOG_TYPE_USER_LOGIN":
      return <FormattedMessage id="account-logs.login" />;
    case "LOG_TYPE_ROLE_TEMPLATE_CREATED":
      return (
        <FormattedMessage
          id="account-logs.role-template-created"
          values={{ name: log.data.roleName }}
        />
      );
    case "LOG_TYPE_ROLE_TEMPLATE_EDITED":
      return (
        <FormattedMessage
          id="account-logs.role-template-edited"
          values={{ name: log.data.roleName }}
        />
      );
    case "LOG_TYPE_ROLE_TEMPLATE_REMOVED":
      return (
        <FormattedMessage
          id="account-logs.role-template-removed"
          values={{ name: log.data.roleName }}
        />
      );
    case "LOG_TYPE_CREATED_NEW_USER":
      return (
        <FormattedMessage
          id="account-logs.created-new-user"
          values={{
            email: log.data.email,
            roleName: log.data.roleName,
          }}
        />
      );
    case "LOG_TYPE_REMOVED_USER":
      return (
        <FormattedMessage
          id="account-logs.removed-user"
          values={{
            email: log.data.email,
          }}
        />
      );
    case "LOG_TYPE_EDIT_USER":
      return (
        <FormattedMessage
          id="account-logs.edit-user"
          values={{
            email: log.data.email,
            roleName: log.data.roleName,
          }}
        />
      );
    case "LOG_TYPE_UPDATE_DETAILS":
      return <FormattedMessage id="account-logs.update-details" />;
    case "LOG_TYPE_CHANGE_PHONE_NUMBER":
      return (
        <FormattedMessage
          id="account-logs.change-phone-number"
          values={{
            phone: log.data.phone,
          }}
        />
      );
    case "LOG_TYPE_CHANGE_EMAIL":
      return (
        <FormattedMessage
          id="account-logs.change-email"
          values={{
            email: log.data.email,
          }}
        />
      );
    case "LOG_TYPE_PASSWORD_UPDATED":
      return <FormattedMessage id="account-logs.password-updated" />;
    case "LOG_TYPE_2FA_ACTIVATED":
      return <FormattedMessage id="account-logs.2fa-activated" />;
    case "LOG_TYPE_2FA_DISABLED":
      return <FormattedMessage id="account-logs.2fa-disabled" />;
    case "LOG_TYPE_2FA_NEW_DEVICE":
      return <FormattedMessage id="account-logs.2fa-new-device" />;
    case "LOG_TYPE_RESET_ALL_SERVERS_PASSWORD":
      return <FormattedMessage id="account-logs.reset-all-servers-password" />;
    case "LOG_TYPE_UPDATE_AUTO_LOGOUT_MINUTES":
      return (
        <FormattedMessage
          id="account-logs.update-auto-logout-minutes"
          values={{ minutes: log.data.minutes }}
        />
      );
    case "LOG_TYPE_ADD_INVOICES_EMAIL":
      return (
        <FormattedMessage
          id="account-logs.add-invoices-email"
          values={{ email: log.data.email }}
        />
      );
    case "LOG_TYPE_REMOVE_INVOICES_EMAIL":
      return (
        <FormattedMessage
          id="account-logs.remove-invoices-email"
          values={{ email: log.data.email }}
        />
      );
    case "LOG_TYPE_UPDATE_CREDIT_CARD":
      return (
        <FormattedMessage
          id="account-logs.update-credit-card"
          values={{
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
          }}
        />
      );
    case "LOG_TYPE_SET_CARD_AS_PRIMARY":
      return (
        <FormattedMessage
          id="account-logs.set-card-as-primary"
          values={{
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
          }}
        />
      );
    case "LOG_TYPE_REMOVE_CREDIT_CARD":
      return (
        <FormattedMessage
          id="account-logs.remove-credit-card"
          values={{
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
          }}
        />
      );
    case "LOG_TYPE_SUBMIT_TICKET":
      return (
        <FormattedMessage
          id="account-logs.submit-ticket"
          values={{
            ticket_number: log.data.ticket_number,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_ADD_ALIAS":
      return (
        <FormattedMessage
          id="account-logs.firewall-add-alias"
          values={{
            name: log.data.name,
            ip: log.data.ip,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_REMOVE_ALIAS":
      return (
        <FormattedMessage
          id="account-logs.firewall-remove-alias"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_EDIT_ALIAS":
      return (
        <FormattedMessage
          id="account-logs.firewall-edit-alias"
          values={{
            fromName: log.data.fromName,
            toName: log.data.toName,
            fromIP: log.data.fromIP,
            toIP: log.data.toIP,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_ADD_IPSET":
      return (
        <FormattedMessage
          id="account-logs.firewall-add-ipset"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_REMOVE_IPSET":
      return (
        <FormattedMessage
          id="account-logs.firewall-remove-ipset"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_EDIT_IPSET":
      return (
        <FormattedMessage
          id="account-logs.firewall-edit-ipset"
          values={{
            fromName: log.data.fromName,
            toName: log.data.toName,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_ADD_SECURITY_GROUP":
      return (
        <FormattedMessage
          id="account-logs.firewall-add-security-group"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_EDIT_SECURITY_GROUP":
      return (
        <FormattedMessage
          id="account-logs.firewall-edit-security-group"
          values={{
            fromName: log.data.fromName,
            toName: log.data.toName,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_REMOVE_SECURITY_GROUP":
      return (
        <FormattedMessage
          id="account-logs.firewall-remove-security-group"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_ADD_RULE":
      return (
        <FormattedMessage
          id="account-logs.firewall-add-rule"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_EDIT_RULE":
      return (
        <FormattedMessage
          id="account-logs.firewall-edit-rule"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_REMOVE_RULE":
      return (
        <FormattedMessage
          id="account-logs.firewall-remove-rule"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_FIREWALL_ASSIGN_SERVERS":
      return (
        <FormattedMessage
          id="account-logs.firewall-assign-servers"
          values={{
            name: log.data.name,
            totalDomains: log.data.totalDomains,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_ADD_VLAN":
      return (
        <FormattedMessage
          id="account-logs.network-add-vlan"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_DELETE_VLAN":
      return (
        <FormattedMessage
          id="account-logs.network-delete-vlan"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_ADD_SUBNET":
      return (
        <FormattedMessage
          id="account-logs.network-add-subnet"
          values={{
            name: log.data.name,
            ipAddressScope:
              log.data.ipAddressScope || log.data.ipAddressScopeCustom,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_DELETE_SUBNET":
      return (
        <FormattedMessage
          id="account-logs.network-delete-subnet"
          values={{
            name: log.data.name,
            ipAddress: log.data.ipAddress,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_CHANGE_GATEWAY":
      return (
        <FormattedMessage
          id="account-logs.network-change-gateway"
          values={{
            oldGateway: log.data.oldGateway,
            newGateway: log.data.newGateway,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_ATTACH_IP_TO_SERVER":
      return (
        <FormattedMessage
          id="account-logs.network-attach-ip-to-server"
          values={{
            hostname: log.data.hostname,
            ip: log.data.ip,
          }}
        />
      );
    case "LOG_TYPE_NETWORK_REMOVE_IP_FROM_SERVER":
      return (
        <FormattedMessage
          id="account-logs.network-remove-ip-from-server"
          values={{
            ip: log.data.ip,
          }}
        />
      );
    case "LOG_TYPE_CREATE_GROUP":
      return (
        <FormattedMessage
          id="account-logs.create-group"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_EDIT_GROUP":
      return (
        <FormattedMessage
          id="account-logs.edit-group"
          values={{
            fromName: log.data.fromName,
            toName: log.data.toName,
          }}
        />
      );
    case "LOG_TYPE_REMOVE_GROUP":
      return (
        <FormattedMessage
          id="account-logs.remove-group"
          values={{
            name: log.data.name,
          }}
        />
      );
    case "LOG_TYPE_ASSIGN_SERVERS_TO_GROUP":
      return (
        <FormattedMessage
          id="account-logs.assign-servers-to-group"
          values={{
            name: log.data.name,
            totalServers: log.data.totalServers,
          }}
        />
      );
    case "LOG_TYPE_CREATE_NEW_SERVER":
      return (
        <FormattedMessage
          id="account-logs.create-new-server"
          values={{
            hostname: log.data.hostname,
            location: log.data.location,
            image: log.data.image,
            cpu: log.data.cpu,
            ram_mb: log.data.ram_mb,
            ssd_gb: log.data.disks_gb[0].size,
            additional_ssd_gb:
              log.data.disks_gb.length > 1 ? (
                <>
                  , additional disk=
                  <b>
                    {log.data.disks_gb.slice(1).reduce((s, d) => s + d.size, 0)}
                  </b>
                </>
              ) : (
                ""
              ),
            backup: log.data.backup ? (
              <>
                , <b>backup</b>
              </>
            ) : (
              ""
            ),
          }}
        />
      );
  }

  return `${log.log_type} ${JSON.stringify(log.data)}`;
};
