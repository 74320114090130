import React from "react";
import { IntlProvider as _IntlProvider } from "react-intl-original";
import { FormattedMessage as _FormattedMessage } from "react-intl-original";
import { useIntl as _useIntl } from "react-intl-original";

export const IntlProvider = (props) => {
  return <_IntlProvider {...props} />;
};

export const FormattedMessage = (props) => {
  const extendedProps = {
    ...props,
    values: {
      ...props.values,
      br: <br />,
      b: (val) => <b>{val}</b>,
      span: (val) => <span>{val}</span>,
    },
  };

  if (import.meta.env.DEV) {
    return (
      <span data-tid={extendedProps.id}>
        <_FormattedMessage {...extendedProps} />
      </span>
    );
  }

  return <_FormattedMessage {...extendedProps} />;
};

export const useIntl = (props) => _useIntl(props);
