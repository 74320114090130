import styles from "./upgrade-managed-hosting-content.module.scss";

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import CheckSvg from "../../svgs/check.svg?react";
import HelpSvg from "../../svgs/help.svg?react";
import Radio from "../../radio";
import { FormattedMessage } from "react-intl";
import { currencySymbols } from "../../../utils/billing";

function UpgradeManagedHostingContent({
  border = true,
  managedHostings,
  selectedAddons,
  setSelectedAddons,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState({});
  const { professional, advanced, devops } = useMemo(() => {
    return {
      professional: managedHostings.find(
        (mh) =>
          mh.type === "managed-hosting" &&
          mh.name.toLowerCase().includes("professional")
      ),
      advanced: managedHostings.find(
        (mh) =>
          mh.type === "managed-hosting" &&
          mh.name.toLowerCase().includes("advanced")
      ),
      devops: managedHostings.find(
        (mh) =>
          mh.type === "managed-hosting" &&
          mh.name.toLowerCase().includes("devops")
      ),
    };
  }, [managedHostings]);

  function handleManagedHostingChanged(item) {
    if (!selectedAddons[item._id]) {
      const keys = Object.keys(selectedAddons);

      for (let i = 0; i < keys.length; i++) {
        const serverMonitor = managedHostings.find((sm) =>
          keys.includes(sm._id)
        );

        if (serverMonitor) {
          delete selectedAddons[serverMonitor._id];
        }
      }
    }

    if (selectedAddons[item._id]) {
      delete selectedAddons[item._id];
    } else {
      selectedAddons[item._id] = item;
    }

    setSelectedAddons({ ...selectedAddons });
  }

  function getBoxTitle(product) {
    if (product === professional) {
      return "general.professional";
    } else if (product === advanced) {
      return "general.advanced";
    } else {
      return "general.devops";
    }
  }

  function handleItemTooltipToggle(item) {
    isTooltipOpen[item._id] = !isTooltipOpen[item._id];
    setIsTooltipOpen({ ...isTooltipOpen });
  }

  function renderBox(product) {
    return (
      <div
        className={`${styles.box} ${border ? styles.border : ""} ${
          selectedAddons[product._id] && styles.selected
        }`}
        onClick={() => handleManagedHostingChanged(product)}
      >
        <div className={styles.radio}>
          <Radio
            value={product._id}
            checked={!!selectedAddons[product._id]}
            onClick={(e) =>
              e.stopPropagation() && handleManagedHostingChanged(product)
            }
          />
          <span className={styles.title}>
            <FormattedMessage id={getBoxTitle(product)} />

            {product.tooltip_info && (
              <>
                <div id={`tooltip-monitor-${product._id}`}>
                  <div>
                    <HelpSvg />
                  </div>
                </div>

                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen[product._id]}
                  target={`tooltip-monitor-${product._id}`}
                  toggle={() => handleItemTooltipToggle(product)}
                >
                  {product.tooltip_info}
                </Tooltip>
              </>
            )}
          </span>
        </div>

        {product === professional && (
          <div className={styles.info}>
            <div className={styles.row}>
              <CheckSvg />
              <span>Real-Time Monitoring</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Data Backup and Protection</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Firewall and Networking</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Operating System Maintenance</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Domain Name System (DNS)</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Virtual Machine Resource Management</span>
            </div>
          </div>
        )}

        {product === advanced && (
          <div className={styles.info}>
            <div className={styles.row}>
              <CheckSvg />
              <span className={styles.bold}>Include all basic</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Software Installation</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Database Management</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Load Balancer</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Centralized Configuration</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Phone call</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>WhatsApp Group</span>
            </div>
          </div>
        )}

        {product === devops && (
          <div className={styles.info}>
            <div className={styles.row}>
              <CheckSvg />
              <span className={styles.bold}>include all Professional</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Code Debugging</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>DR and HA Arrays</span>
            </div>
            <div className={styles.row}>
              <CheckSvg />
              <span>Continuous Integration</span>
            </div>
          </div>
        )}

        <div className={styles.price}>
          {currencySymbols[product.currency]}
          {product.price} <FormattedMessage id="general.monthly" />
        </div>
      </div>
    );
  }

  if (!professional || !advanced || !devops) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {renderBox(professional)}

      {renderBox(advanced)}

      {renderBox(devops)}
    </div>
  );
}

UpgradeManagedHostingContent.propTypes = {
  border: PropTypes.bool,
  managedHostings: PropTypes.array,
  selectedAddons: PropTypes.object,
  setSelectedAddons: PropTypes.func,
};

export default UpgradeManagedHostingContent;
