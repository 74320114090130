/* eslint-disable react-refresh/only-export-components */
// const XXX = lazy(() => import(XXX));
import "./styles/bootstrap.min.css";
import "./styles/react-circular-progressbar.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "./styles/react-tagsinput.css";
import "./styles/react-virtualized.css";
import "./styles/globals.scss";
import messages from "./translations/en.json";

import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import App from "./App.tsx";
import mainStore from "./store/index.js";
import { getCurrentWLDVPS, getServerUrl } from "./utils/wldvps.js";
import {
  setAlertModalData,
  setConfirmModalData,
  setPromptModalData,
  setWLDVPS,
} from "./store/settings.js";
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Spinner from "./components/spinner/index.jsx";

import ServerLayout from "./components/server-layout/index.jsx";
import ServerSettingsLayout from "./components/server-settings/layout.jsx";
import { lazy, Suspense } from "react";

import CloudLayout from "./components/cloud-layout/index.jsx";

import MyCloudServers from "./pages/my-cloud/servers/index.jsx";
import MyCloudTemplates from "./pages/my-cloud/templates.jsx";
import MyCloudProducts from "./pages/my-cloud/products.jsx";
import MyCloudGroups from "./pages/my-cloud/groups.jsx";
import MyCloudNetworks from "./pages/my-cloud/networks/index.jsx";
import ExternalsIps from "./pages/my-cloud/networks/externals-ips.jsx";

const Login = lazy(() => import("./pages/user/login.jsx"));
const Register = lazy(() => import("./pages/user/register.jsx"));
const Accounts = lazy(() => import("./pages/user/accounts.jsx"));
const ResetPassword = lazy(() => import("./pages/user/reset-password.jsx"));
const ConfirmPassword = lazy(() => import("./pages/user/confirm-password.jsx"));
const ForgotPassword = lazy(() => import("./pages/user/forgot-password.jsx"));
const LoginWithSMS = lazy(() => import("./pages/user/login-sms.jsx"));
const TwoFactorAuthentication = lazy(
  () => import("./pages/user/two-factor-authentication.jsx")
);

const SupportMyTickets = lazy(
  () => import("./pages/support/my-tickets/index.jsx")
);
const SupportViewTicket = lazy(
  () => import("./pages/support/my-tickets/ticket_number.jsx")
);
const SupportOpenNewTicket = lazy(
  () => import("./pages/support/open-new-ticket.jsx")
);
const SupportSettings = lazy(() => import("./pages/support/settings.jsx"));
const SupportGmailTickets = lazy(
  () => import("./pages/support/gmail-blacklist.jsx")
);

const LayoutWithStripe = lazy(
  () => import("./components/layout-with-stripe/index.jsx")
);

const BillingCheckout = lazy(() => import("./pages/billing/checkout.jsx"));
const BillingManageCreditCardWrapper = lazy(
  () => import("./pages/billing/manage-credit-card.jsx")
);
const BillingLeadsReport = lazy(
  () => import("./pages/billing/leads-report.jsx")
);
const BillingVatReport = lazy(() => import("./pages/billing/vat-report.jsx"));
const BillingAboutToExpireCC = lazy(
  () => import("./pages/billing/about-to-expire-cc.jsx")
);
const BillingPendingInvoices = lazy(
  () => import("./pages/billing/pending-invoices.jsx")
);
const BillingCron = lazy(() => import("./pages/billing/billing-cron.jsx"));
const BillingChangePaymentForm = lazy(
  () => import("./pages/billing/change-payment-form.jsx")
);
const BillingUnpaidCustomers = lazy(
  () => import("./pages/billing/unpaid-customers.jsx")
);
const BillingDomains = lazy(() => import("./pages/billing/domains.jsx"));
const Calculator = lazy(() => import("./pages/billing/calculator/index.jsx"));
const BillingPriceQuote = lazy(
  () => import("./pages/billing/price-quote/index.jsx")
);
const BillingPriceQuoteNew = lazy(
  () => import("./pages/billing/price-quote/new.jsx")
);
const IntegratorBilling = lazy(
  () => import("./pages/billing/integrator-billing.jsx")
);
const InvoicesRouter = lazy(() => import("./pages/billing/router.jsx"));
const BillingV2PaymentEstimator = lazy(
  () => import("./pages/billing-v2/payment-estimator.jsx")
);
const BillingV2CreateInvoice = lazy(
  () => import("./pages/billing-v2/create-invoice.jsx")
);

const ClientsList = lazy(
  () => import("./pages/clients/clients-list/index.jsx")
);
const AccountBiReports = lazy(() => import("./pages/clients/bi-reports.jsx"));
const ClientsUntrustedClients = lazy(
  () => import("./pages/clients/untrusted-clients.jsx")
);
const ClientsListRouting = lazy(
  () => import("./pages/clients/clients-list/[id]/index.jsx")
);
const ClientsListSummary = lazy(
  () => import("./pages/clients/clients-list/[id]/summary.jsx")
);
const ClientsListCreditsManagement = lazy(
  () => import("./pages/clients/clients-list/[id]/credits-management.jsx")
);
const ClientsListServers = lazy(
  () => import("./pages/clients/clients-list/[id]/servers.jsx")
);
const ClientsListOtherProducts = lazy(
  () => import("./pages/clients/clients-list/[id]/other-products.jsx")
);
const ClientsListDocuments = lazy(
  () => import("./pages/clients/clients-list/[id]/documents.jsx")
);
const ClientsListTickets = lazy(
  () => import("./pages/clients/clients-list/[id]/tickets.jsx")
);
const ClientsListComments = lazy(
  () => import("./pages/clients/clients-list/[id]/comments.jsx")
);
const ClientsListInvoices = lazy(
  () => import("./pages/clients/clients-list/[id]/invoices.jsx")
);
const ClientsListOrganization = lazy(
  () => import("./pages/clients/clients-list/[id]/organization.jsx")
);

const ServicesSmtpRouter = lazy(
  () => import("./pages/services/smtp/index.jsx")
);
const ServicesSmtpDomains = lazy(
  () => import("./pages/services/smtp/domains.jsx")
);
const ServicesSmtpSendingStatistics = lazy(
  () => import("./pages/services/smtp/sending-statistics.jsx")
);
const ServicesSmtpIpsGroups = lazy(
  () => import("./pages/services/smtp/ips-groups.jsx")
);
const ServicesSmtpWaitingToJoin = lazy(
  () => import("./pages/services/smtp/waiting-to-join.jsx")
);
const ServicesApiRouter = lazy(() => import("./pages/services/api/index.jsx"));
const ServicesAuthRouter = lazy(
  () => import("./pages/services/auth/index.jsx")
);

import ServerOverview from "./pages/my-cloud/servers/[id]/overview.jsx";
import ServerUsageGraphs from "./pages/my-cloud/servers/[id]/usage-graphs.jsx";
import ServerSnapshots from "./pages/my-cloud/servers/[id]/snapshots.jsx";
import ServerBackups from "./pages/my-cloud/servers/[id]/backups.jsx";
import ServerTasks from "./pages/my-cloud/servers/[id]/tasks.jsx";
import ServerLogs from "./pages/my-cloud/servers/[id]/server-logs.jsx";
import ServerConsole from "./pages/my-cloud/servers/[id]/console.jsx";
import ServerPayment from "./pages/my-cloud/servers/[id]/payment.jsx";
import ServerNotes from "./pages/my-cloud/servers/[id]/notes.jsx";

const LayoutFirewall = lazy(
  () => import("./components/layout-firewall/index.jsx")
);
const FirewallSecurityGroups = lazy(
  () => import("./pages/my-cloud/firewall/security-groups.jsx")
);
const FirewallAlias = lazy(() => import("./pages/my-cloud/firewall/alias.jsx"));
const FirewallIpSet = lazy(
  () => import("./pages/my-cloud/firewall/ip-set.jsx")
);

const ServerSettingsIPv4 = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/ipv4.jsx")
);
const ServerSettingsFirewall = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/firewall.jsx")
);
const AddonsRouter = lazy(
  () =>
    import("./pages/my-cloud/servers/[id]/server-settings/addons/router.jsx")
);
const ServerSettingsBootOrder = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/boot-order.jsx")
);
const ServerSettingsHotplug = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/hotplug.jsx")
);
const ServerSettingsChangePlan = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/change-plan.jsx")
);
const ServerSettingsChangeOS = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/change-os.jsx")
);
const ServerSettingsCustomISO = lazy(
  () => import("./pages/my-cloud/servers/[id]/server-settings/custom-iso.jsx")
);
const ServerSettingsChangePayment = lazy(
  () =>
    import("./pages/my-cloud/servers/[id]/server-settings/change-payment.jsx")
);
const ServerSettingsChangePassword = lazy(
  () =>
    import("./pages/my-cloud/servers/[id]/server-settings/change-password.jsx")
);

const AccountDetails = lazy(
  () => import("./pages/account/account-details.jsx")
);
const AccountPermissions = lazy(
  () => import("./pages/account/permissions/index.jsx")
);
const AccountPermissionsInviteUsers = lazy(
  () => import("./pages/account/permissions/invite-users.jsx")
);
const AccountPermissionsCreateNewRole = lazy(
  () => import("./pages/account/permissions/create-new-role/index.jsx")
);
const AccountSecurity = lazy(() => import("./pages/account/security.jsx"));
const AccountGeneralSettings = lazy(
  () => import("./pages/account/general-settings.jsx")
);
const AccountLogs = lazy(() => import("./pages/account/logs.jsx"));
const AccountTerms = lazy(() => import("./pages/account/terms.jsx"));

const ManagementProducts = lazy(
  () => import("./pages/management/products.jsx")
);
const ManagementProductsAndPrices = lazy(
  () => import("./pages/management/servers-and-prices.jsx")
);
const ManagementIso = lazy(() => import("./pages/management/iso.jsx"));
const ManagementJobsManager = lazy(
  () => import("./pages/management/jobs-manager.jsx")
);
const ManagementProxmoxSettings = lazy(
  () => import("./pages/management/proxmox-settings.jsx")
);
const ManagementNodesStatus = lazy(
  () => import("./pages/management/nodes-status.jsx")
);
const ManagementCrons = lazy(() => import("./pages/management/crons.jsx"));
const ManagementBlacklist = lazy(
  () => import("./pages/management/blacklist.jsx")
);
const ManagementWhitelabel = lazy(
  () => import("./pages/management/whitelabel.jsx")
);
const ManagementEmails = lazy(() => import("./pages/management/emails.jsx"));
const ManagementEmergency = lazy(
  () => import("./pages/management/emergency.jsx")
);
const EmailsManager = lazy(
  () => import("./pages/management/emails-manager.jsx")
);

const CreateNewServer = lazy(
  () => import("./pages/my-cloud/create-new-server.jsx")
);

const linkIcon = document.createElement("link");
linkIcon.rel = "icon";
linkIcon.type = "image/png";

const wldvps = getCurrentWLDVPS();

if (wldvps) {
  import(`./styles/${wldvps}.css`);

  linkIcon.href = `/img/wldvps/${wldvps}/favicon.ico`;
  mainStore.dispatch(setWLDVPS(wldvps));
} else {
  linkIcon.href = "/img/wldvps/dreamvps/favicon.ico";
  mainStore.dispatch(setWLDVPS("dreamvps"));
}
document.head.appendChild(linkIcon);

window.serverUrl = getServerUrl();
window.setAlertModalData = setAlertModalData;
window.setConfirmModalData = setConfirmModalData;
window.setPromptModalData = setPromptModalData;

function wrapRoutesWithSuspense(routes: RouteObject[]) {
  return routes.map((route) => ({
    ...route,
    element: (
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        {route.element}
      </Suspense>
    ),
  }));
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <IntlProvider locale="en" defaultLocale="en" messages={messages}>
        <App />
      </IntlProvider>
    ),
    children: [
      {
        path: ":lang/my-cloud",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          {
            path: "servers",
            element: <MyCloudServers />,
          },
          {
            path: "servers/:id",
            element: <ServerLayout />,
            children: [
              { path: "overview", element: <ServerOverview /> },
              { path: "usage-graphs", element: <ServerUsageGraphs /> },
              { path: "snapshots", element: <ServerSnapshots /> },
              { path: "backups", element: <ServerBackups /> },
              { path: "tasks", element: <ServerTasks /> },
              { path: "server-logs", element: <ServerLogs /> },
              { path: "console", element: <ServerConsole /> },
              { path: "payment", element: <ServerPayment /> },
              { path: "notes", element: <ServerNotes /> },
              {
                path: "server-settings",
                element: <ServerSettingsLayout />,
                children: wrapRoutesWithSuspense([
                  { path: "ipv4", element: <ServerSettingsIPv4 /> },
                  { path: "firewall", element: <ServerSettingsFirewall /> },
                  { path: "addons", element: <AddonsRouter /> },
                  {
                    path: "boot-order",
                    element: <ServerSettingsBootOrder />,
                  },
                  { path: "hotplug", element: <ServerSettingsHotplug /> },
                  {
                    path: "change-plan",
                    element: <ServerSettingsChangePlan />,
                  },
                  { path: "change-os", element: <ServerSettingsChangeOS /> },
                  {
                    path: "custom-iso",
                    element: <ServerSettingsCustomISO />,
                  },
                  {
                    path: "change-payment",
                    element: <ServerSettingsChangePayment />,
                  },
                  {
                    path: "change-password",
                    element: <ServerSettingsChangePassword />,
                  },
                  {
                    path: "",
                    element: <Navigate to="ipv4" />,
                  },
                ]),
              },
              { path: "", element: <Navigate to="overview" /> },
            ],
          },
          {
            path: "templates",
            element: <MyCloudTemplates />,
          },
          {
            path: "products",
            element: <MyCloudProducts />,
          },
          {
            path: "groups",
            element: <MyCloudGroups />,
          },
          {
            path: "networks",
            element: <MyCloudNetworks />,
          },
          {
            path: "networks/wan",
            element: <MyCloudNetworks type="wan" />,
          },
          {
            path: "networks/externals-ips",
            element: <ExternalsIps />,
          },
          {
            path: "firewall",
            element: <LayoutFirewall />,
            children: [
              {
                path: "security-groups",
                element: <FirewallSecurityGroups />,
              },
              { path: "alias", element: <FirewallAlias /> },
              { path: "ip-set", element: <FirewallIpSet /> },
              { path: "", element: <Navigate to="security-groups" /> },
            ],
          },
          {
            path: "",
            element: <Navigate to="/en/my-cloud/servers" />,
          },
        ]),
      },

      {
        path: ":lang/my-cloud/create-new-server",
        element: (
          <Suspense
            fallback={
              <div>
                <Spinner />
              </div>
            }
          >
            <CreateNewServer />
          </Suspense>
        ),
      },
      {
        path: ":lang/my-cloud/clone-server/:id",
        element: (
          <Suspense
            fallback={
              <div>
                <Spinner />
              </div>
            }
          >
            <CreateNewServer clone />
          </Suspense>
        ),
      },

      {
        path: ":lang/user",
        children: wrapRoutesWithSuspense([
          { path: "reset-password", element: <ResetPassword /> },
          { path: "confirm-password", element: <ConfirmPassword /> },
          { path: "forgot-password", element: <ForgotPassword /> },
          { path: "login-sms", element: <LoginWithSMS /> },
          {
            path: "two-factor-authentication",
            element: <TwoFactorAuthentication />,
          },
          { path: "register", element: <Register /> },
          { path: "login", element: <Login /> },
          { path: "accounts", element: <Accounts /> },
        ]),
      },

      {
        path: ":lang/support",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          { path: "my-tickets", element: <SupportMyTickets /> },
          {
            path: "my-tickets/:ticket_number",
            element: <SupportViewTicket />,
          },
          { path: "open-new-ticket", element: <SupportOpenNewTicket /> },
          { path: "settings", element: <SupportSettings /> },
          { path: "gmail-blacklist", element: <SupportGmailTickets /> },
          { path: "", element: <Navigate to="my-tickets" /> },
        ]),
      },

      {
        path: ":lang/billing/checkout/:token",
        element: (
          <Suspense
            fallback={
              <div>
                <Spinner />
              </div>
            }
          >
            <BillingCheckout />
          </Suspense>
        ),
      },

      {
        path: ":lang/billing",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          {
            path: "invoices",
            element: <InvoicesRouter />,
          },
          {
            path: "payment-estimator",
            element: <BillingV2PaymentEstimator />,
          },
          {
            path: "create-new-invoice",
            element: <BillingV2CreateInvoice />,
          },
          {
            path: "manage-credit-card",
            element: (
              <LayoutWithStripe>
                <BillingManageCreditCardWrapper />
              </LayoutWithStripe>
            ),
          },
          { path: "leads-report", element: <BillingLeadsReport /> },
          { path: "vat-report", element: <BillingVatReport /> },
          { path: "about-to-expire-cc", element: <BillingAboutToExpireCC /> },
          { path: "pending-invoices", element: <BillingPendingInvoices /> },
          { path: "billing-cron", element: <BillingCron /> },
          {
            path: "change-payment-form",
            element: <BillingChangePaymentForm />,
          },
          { path: "unpaid-customers", element: <BillingUnpaidCustomers /> },
          { path: "domains", element: <BillingDomains /> },
          { path: "calculator", element: <Calculator /> },
          { path: "price-quote", element: <BillingPriceQuote /> },
          { path: "price-quote/:userid", element: <BillingPriceQuoteNew /> },
          { path: "integrator-billing", element: <IntegratorBilling /> },
          { path: "", element: <Navigate to="invoices" /> },
        ]),
      },

      {
        path: ":lang/account",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          {
            path: "account-details",
            element: <AccountDetails />,
          },
          {
            path: "authorized-to-receive-service",
            element: <ClientsListOrganization fullPage />,
          },
          {
            path: "permissions",
            element: <AccountPermissions />,
          },
          {
            path: "permissions/invite-users",
            element: <AccountPermissionsInviteUsers />,
          },
          {
            path: "permissions/create-new-role",
            element: <AccountPermissionsCreateNewRole />,
          },
          {
            path: "security",
            element: <AccountSecurity />,
          },
          {
            path: "general-settings",
            element: <AccountGeneralSettings />,
          },
          {
            path: "logs",
            element: <AccountLogs />,
          },
          {
            path: "terms",
            element: <AccountTerms />,
          },
          {
            path: "",
            element: <Navigate to="account-details" />,
          },
        ]),
      },

      {
        path: ":lang/management",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          {
            path: "products",
            element: <ManagementProducts />,
          },
          {
            path: "servers-and-prices",
            element: <ManagementProductsAndPrices />,
          },
          {
            path: "iso",
            element: <ManagementIso />,
          },
          {
            path: "jobs-manager",
            element: <ManagementJobsManager />,
          },
          {
            path: "proxmox-settings",
            element: <ManagementProxmoxSettings />,
          },
          {
            path: "nodes-status",
            element: <ManagementNodesStatus />,
          },
          {
            path: "crons",
            element: <ManagementCrons />,
          },
          {
            path: "blacklist",
            element: <ManagementBlacklist />,
          },
          {
            path: "whitelabel",
            element: <ManagementWhitelabel />,
          },
          {
            path: "emails",
            element: <ManagementEmails />,
          },
          {
            path: "emergency",
            element: <ManagementEmergency />,
          },
          {
            path: "dream-email",
            element: <EmailsManager />,
          },
          {
            path: "",
            element: <Navigate to="products" />,
          },
        ]),
      },

      {
        path: ":lang/clients",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          { path: "clients-list", element: <ClientsList /> },
          {
            path: "team",
            element: <AccountPermissions />,
          },
          {
            path: "team/add-member",
            element: <AccountPermissionsInviteUsers />,
          },
          { path: "bi-reports", element: <AccountBiReports /> },
          { path: "untrusted-clients", element: <ClientsUntrustedClients /> },

          {
            path: "clients-list/:id",
            element: <ClientsListRouting />,
            children: [
              { path: "summary", element: <ClientsListSummary /> },
              {
                path: "credits-management",
                element: <ClientsListCreditsManagement />,
              },
              { path: "servers", element: <ClientsListServers /> },
              {
                path: "other-products",
                element: <ClientsListOtherProducts />,
              },
              { path: "documents", element: <ClientsListDocuments /> },
              { path: "tickets", element: <ClientsListTickets /> },
              {
                path: "authorized-to-receive-service",
                element: <ClientsListOrganization />,
              },
              { path: "comments", element: <ClientsListComments /> },
              { path: "invoices", element: <ClientsListInvoices /> },
              { path: "", element: <Navigate to="summary" /> },
            ],
          },
          { path: "", element: <Navigate to="clients-list" /> },
        ]),
      },

      {
        path: ":lang/services",
        element: <CloudLayout />,
        children: wrapRoutesWithSuspense([
          {
            path: "smtp",
            element: <ServicesSmtpRouter />,
            children: [
              { path: "domains", element: <ServicesSmtpDomains /> },
              {
                path: "sending-statistics",
                element: <ServicesSmtpSendingStatistics />,
              },
              { path: "ips-groups", element: <ServicesSmtpIpsGroups /> },
              {
                path: "waiting-to-join",
                element: <ServicesSmtpWaitingToJoin />,
              },
              {
                path: "",
                element: <Navigate to="/en/services/smtp/domains" />,
              },
            ],
          },

          { path: "api", element: <ServicesApiRouter /> },

          { path: "auth", element: <ServicesAuthRouter /> },

          {
            path: "domains",
            element: <Navigate to="/en/services/smtp/domains" />,
          },
        ]),
      },

      {
        path: "*",
        element: <Navigate to="/en/my-cloud/servers" />,
      },
      {
        path: "",
        element: <Navigate to="/en/my-cloud/servers" />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={mainStore}>
    <RouterProvider router={router} />
  </Provider>
);
