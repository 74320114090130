import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Spinner from "../../spinner";

function ReconnectingModal({ isOpen }) {
  return (
    <Modal className={styles.wrapper} isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage id="reconnecting-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.loader}>
          <Spinner />
        </div>
      </ModalBody>
    </Modal>
  );
}

ReconnectingModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReconnectingModal;
