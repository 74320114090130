import styles from "./index.module.css";

import React, { useEffect, useState } from "react";

export default function PasswordStrengthBar({ password, onChangeScore }) {
  const [strength, setStrength] = useState({ score: 0, label: "None" });

  useEffect(() => {
    const calculateStrength = (pwd) => {
      if (!pwd || pwd.length === 0) {
        return { score: 0, label: "None" };
      }

      let score = 0;
      score += Math.min(5, Math.floor(pwd.length / 2));

      const hasLowercase = /[a-z]/.test(pwd);
      const hasUppercase = /[A-Z]/.test(pwd);
      const hasNumbers = /[0-9]/.test(pwd);
      const hasSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pwd);

      if (hasLowercase) score += 1;
      if (hasUppercase) score += 2;
      if (hasNumbers) score += 2;
      if (hasSpecialChars) score += 3;

      const commonPatterns = [
        "123",
        "abc",
        "qwerty",
        "password",
        "admin",
        "welcome",
        "letmein",
        "monkey",
        "dragon",
        "football",
        "baseball",
        "superman",
      ];

      const lowerPwd = pwd.toLowerCase();
      for (const pattern of commonPatterns) {
        if (lowerPwd.includes(pattern)) {
          score = Math.max(0, score - 3);
          break;
        }
      }

      if (/(.)\1{2,}/.test(pwd)) {
        score = Math.max(0, score - 2);
      }

      if (
        /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/i.test(
          pwd
        )
      ) {
        score = Math.max(0, score - 2);
      }

      score = Math.min(10, score);

      let label;
      if (score === 0) label = "None";
      else if (score < 4) label = "Very Weak";
      else if (score < 6) label = "Weak";
      else if (score < 8) label = "Medium";
      else if (score < 10) label = "Strong";
      else label = "Very Strong";

      return { score, label };
    };

    const result = calculateStrength(password);
    setStrength(result);
    if (onChangeScore) {
      onChangeScore(result.score);
    }
  }, [password, onChangeScore]);

  const getBarClasses = (level, currentScore) => {
    const active = level <= currentScore;
    if (level <= 2) return active ? styles.bgRed : styles.bgGray;
    if (level <= 5) return active ? styles.bgYellow : styles.bgGray;
    if (level <= 8) return active ? styles.bgBlue : styles.bgGray;
    return active ? styles.bgGreen : styles.bgGray;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.barContainer}>
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            className={`${styles.bar} ${getBarClasses(
              index + 1,
              strength.score
            )}`}
          ></div>
        ))}
      </div>
      <div className={styles.label}>{strength.label}</div>
    </div>
  );
}
