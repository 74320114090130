import styles from "./index.module.scss";

import React from "react";
import Box from "../box/index.jsx";
import { useRoles, useUser } from "../../utils/hooks.jsx";
import { FormattedMessage } from "react-intl";
import { getFullName } from "../../utils/user.js";

export function WithRole({ permission, children }) {
  const user = useUser();
  const { isAllowed } = useRoles();

  function renderNotAllowed() {
    let parentUser;

    if (user && Array.isArray(user.roles)) {
      const usr = user.roles.find(
        (role) => role.parent_user_id === user.current_parent
      );

      if (usr) {
        parentUser = usr.parentUser;
      }
    }

    return (
      <div className={styles.notAllowed}>
        <Box>
          <FormattedMessage
            id="general.not-allowed"
            values={{
              name: parentUser ? (
                getFullName(parentUser)
              ) : (
                <FormattedMessage id="general.not-allowed-administrator" />
              ),
            }}
          />
        </Box>
      </div>
    );
  }

  if (permission) {
    if (
      Array.isArray(permission) &&
      permission.filter((item) => isAllowed(item)).length === 0
    ) {
      return renderNotAllowed();
    } else if (typeof permission === "string" && !isAllowed(permission)) {
      return renderNotAllowed();
    }
  }

  return children || null;
}
