import styles from "./payment.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { useAjax, useAlert, useWLDVPS } from "../../../../utils/hooks";
import { WithRole } from "../../../../components/with-role";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../../components/box";
import Spinner from "../../../../components/spinner";
import CustomText from "../../../../components/custom-text";
import CustomReactSelect from "../../../../components/custom-react-select";
import Checkbox from "../../../../components/checkbox";
import DreamButton from "../../../../components/dream-button";
import ClickTable from "../../../../components/click-table";
import {
  currencySymbols,
  getSymbolsForReactSelect,
} from "../../../../utils/billing";
import { format, isFirstDayOfMonth } from "date-fns";
import { useOutletContext } from "react-router-dom";

const currencySymbolsMapped = getSymbolsForReactSelect();

export default function ServerPayment() {
  const ajax = useAjax();
  const alert = useAlert();
  const wldvps = useWLDVPS();
  const intl = useIntl();
  const { server } = useOutletContext();

  const payEveryOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 48, 60,
  ].map((num) => ({
    label: (
      <>
        {num}{" "}
        {num === 1 ? (
          <FormattedMessage id="general.month" />
        ) : (
          <FormattedMessage id="general.months" />
        )}
      </>
    ),

    value: num,
  }));
  const payEveryOptionsRef = useRef(payEveryOptions);

  const payDayOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28,
  ].map((num) => ({
    label: num,
    value: num,
  }));
  const payDayOptionsRef = useRef(payDayOptions);

  const paymentTypeOptions = [
    { label: <FormattedMessage id="general.hourly" />, value: "hourly" },
    { label: <FormattedMessage id="general.monthly" />, value: "monthly" },
  ];
  const paymentTypeOptionsRef = useRef(paymentTypeOptions);

  const [currency, setCurrency] = useState(currencySymbolsMapped[0]);
  const [fixedPrice, setFixedPrice] = useState("0");
  const [nextPayDay, setNextPayDay] = useState(new Date());
  const [payDay, setPayDay] = useState(payDayOptions[0]);
  const [payEvery, setPayEvery] = useState(payEveryOptions[0]);
  const [paymentType, setPaymentType] = useState(paymentTypeOptions[0]);
  const [freeBandwidth, setFreeBandwidth] = useState(false);
  const [freeBandwidthUpTo, setFreeBandwidthUpTo] = useState(0);

  const [simulatedHourlyCronItems, setSimulatedHourlyCronItems] =
    useState(null);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrency(
      currencySymbolsMapped.find(
        (item) => item.value === server.payment.currency
      )
    );
    setFixedPrice(server.payment.fixed_price?.toString() || "-1");
    setNextPayDay(new Date(server.payment.next_pay_day));
    setPayDay(
      payDayOptionsRef.current.find(
        (item) => item.value === server.payment.payDay
      )
    );
    if (server.payment.payEvery)
      setPayEvery(
        payEveryOptionsRef.current.find(
          (item) => item.value === server.payment.payEvery
        )
      );
    setPaymentType(
      paymentTypeOptionsRef.current.find(
        (item) => item.value === server.payment.payment_type
      )
    );
    setFreeBandwidth(server.payment.free_bandwidth);
    setFreeBandwidthUpTo(server.payment.free_bandwidth_up_to);
  }, [server, wldvps]);

  const runHourlyCron = useCallback(async () => {
    if (server.payment.payment_type !== "hourly") {
      return;
    }

    const data = await ajax("/billing-deprecated/simulateHourlyCron", {
      userID: server.user_id,
      serverID: server._id,
      withoutSocket: true,
    });

    if (data.result === "success" && data.items[0]) {
      data.items[0].data = data.items[0].data.filter((item) => item.amount > 0);

      setSimulatedHourlyCronItems(data.items[0].data);
    } else {
      setSimulatedHourlyCronItems([]);
    }
  }, [ajax, server]);

  useEffect(() => {
    runHourlyCron();
  }, [runHourlyCron]);

  async function handleSaveClicked() {
    const _fixedPrice = parseFloat(fixedPrice);
    let _freeBandwidthUpTo = parseFloat(freeBandwidthUpTo);

    setError(false);

    if (!Number.isFinite(_fixedPrice)) {
      return setError(
        <FormattedMessage id="server-payment.wrong-fixed-price" />
      );
    }
    if (!Number.isFinite(_freeBandwidthUpTo)) {
      return setError(
        <FormattedMessage id="server-payment.wrong-free-traffic-number" />
      );
    }

    setLoading(true);
    await ajax("/admin/saveServerPayment", {
      currency: currency.value,
      fixedPrice: _fixedPrice,
      nextPayDay,
      payDay: payDay.value,
      payEvery: payEvery.value,
      paymentType: paymentType.value,
      freeBandwidth: freeBandwidth,
      freeBandwidthUpTo: _freeBandwidthUpTo,
      serverID: server._id,
    });
    setLoading(false);

    alert({
      title: <FormattedMessage id="server-payment.save-server-payment.title" />,
      message: (
        <FormattedMessage id="server-payment.save-server-payment.content" />
      ),
      notification: true,
    });
  }

  const simulatedHourlyCronItemsTotal = useMemo(
    () =>
      simulatedHourlyCronItems?.reduce(
        (total, item) => total + item.amount * item.quantity,
        0
      ),
    [simulatedHourlyCronItems]
  );

  return (
    <WithRole permission="admin.sales">
      <div className={styles.wrapper}>
        <Box>
          {server.payment.free_of_charge_until &&
            new Date(server.payment.free_of_charge_until) > new Date() && (
              <div className={styles.totallyFreeUntil}>
                *{" "}
                <FormattedMessage
                  id="totally-free-until"
                  values={{
                    date: format(
                      server.payment.free_of_charge_until,
                      "dd/MM/yyyy"
                    ),
                  }}
                />
              </div>
            )}

          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.fixed-price" />
            </span>
            <CustomText
              value={fixedPrice}
              onChange={(e) => setFixedPrice(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.currency" />
            </span>
            <CustomReactSelect
              instanceId="server-payment-currency"
              options={currencySymbolsMapped}
              value={currency}
              onChange={(item) => setCurrency(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.payment-type" />
            </span>
            <CustomReactSelect
              instanceId="server-payment-payment-type"
              options={paymentTypeOptions}
              value={paymentType}
              onChange={(item) => setPaymentType(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.pay-every" />
            </span>
            <CustomReactSelect
              instanceId="create-addon-modal-pay-every"
              options={payEveryOptions}
              value={payEvery}
              onChange={(item) => setPayEvery(item)}
            />
          </div>
          {/* <div className={styles.row}>
              <span>
                <FormattedMessage id="server-payment.pay-day" />
              </span>
              <CustomReactSelect
                instanceId="create-addon-modal-pay-day"
                options={payDayOptions}
                value={payDay}
                onChange={(item) => setPayDay(item)}
              />
            </div> */}
          <div className={styles.row}>
            <span>
              {server.payment.payment_type === "monthly" ? (
                <FormattedMessage id="server-payment.next-pay-day" />
              ) : (
                <FormattedMessage id="server-payment.last-pay-day" />
              )}
            </span>
            <DatePicker
              wrapperClassName={`select ${styles.nextPayDayDatePicker}`}
              selected={nextPayDay}
              onChange={(date) => setNextPayDay(date)}
              placeholderText={intl.formatMessage({ id: "general.select" })}
              showTimeSelect={server.payment.payment_type === "hourly"}
              dateFormat={
                server.payment.payment_type === "monthly"
                  ? "dd/MM/yyyy"
                  : "dd/MM/yyyy HH:mm"
              }
            />
            {!isFirstDayOfMonth(nextPayDay) ? (
              <span className={styles.partialPayment}>
                * <FormattedMessage id="next-payment-partial" />
              </span>
            ) : null}
          </div>
          <div className={styles.row}>
            <Checkbox
              label="server-payment.free-traffic"
              checked={freeBandwidth}
              onChange={(e) => setFreeBandwidth(e.target.checked)}
            />
            {freeBandwidth && (
              <div className={styles.freeBandwidthWrapper}>
                <div className={styles.text}>
                  <FormattedMessage id="general.up-to" tagName="span" />{" "}
                  <CustomText
                    value={freeBandwidthUpTo}
                    onChange={(e) => setFreeBandwidthUpTo(e.target.value)}
                  />
                  TB
                </div>
                <div className={styles.description}>
                  <FormattedMessage id="server-payment.free-traffic-description" />
                </div>
              </div>
            )}
          </div>

          {error && <div className={`error ${styles.error}`}>{error}</div>}

          <DreamButton
            disabled={loading}
            loading={loading}
            color="light-purple"
            onClick={() => handleSaveClicked()}
          >
            <FormattedMessage id="general.save" />
          </DreamButton>
        </Box>

        {server.payment.payment_type === "hourly" && (
          <Box title={<FormattedMessage id="server-payment.next-payment" />}>
            <ClickTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.name" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.description" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.amount" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {!simulatedHourlyCronItems && (
                  <tr>
                    <td colSpan={5}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {simulatedHourlyCronItems?.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {simulatedHourlyCronItems?.map((item, key) => (
                  <tr key={key}>
                    <td>{item.name}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {parseFloat(item.amount).toFixed(2)}
                    </td>
                    <td>{parseFloat(item.quantity).toFixed(2)}</td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {parseFloat(item.amount * item.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}

                {simulatedHourlyCronItems && (
                  <tr>
                    <td colSpan={4}></td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {simulatedHourlyCronItemsTotal.toFixed(2)}
                    </td>
                  </tr>
                )}
              </tbody>
            </ClickTable>
          </Box>
        )}
      </div>
    </WithRole>
  );
}
