const cloudURL = "https://cloud.dreamvps.com";

const hosts = {
  "cloud.livevps.co.il": "livevps",
  "cloud.punchvps.com": "punchvps",
};

export const getCurrentWLDVPS = () => {
  let href = window.location.href;

  if (
    import.meta.env.DEV &&
    ["livevps", "punchvps"].includes(import.meta.env.VITE_APP_WHITELABEL)
  ) {
    return import.meta.env.VITE_APP_WHITELABEL;
  }

  if (/^https?:\/\/cloud\.punchvps\.com/.test(href)) {
    return "punchvps";
  }
  if (/^https?:\/\/cloud\.livevps\.co\.il/.test(href)) {
    return "livevps";
  }

  return null;
};

export const getAllWLDVPS = (whitelabelSettings) => {
  let whitelabels = [
    ...new Set(whitelabelSettings.map((setting) => setting.whitelabel)),
  ];

  return whitelabels;
};

export const getAllWLDVPSForSelect = (whitelabelSettings) => {
  let whitelabels = [
    ...new Set(whitelabelSettings.map((setting) => setting.whitelabel)),
  ];

  return whitelabels.map((wl, idx) => ({
    label: wl || "DreamVPS",
    value: wl,
    key: idx,
  }));
};

export const getHostOfWLDVPS = (wldvps) => {
  const item = Object.keys(hosts).find((key) => hosts[key] === wldvps);

  if (!item) {
    return "dreamvps.com";
  }

  return item.replace("cloud.", "").replace("console.", "");
};

export const getProductionUrlOfWLDVPS = (wldvps) => {
  const item = Object.keys(hosts).find((key) => hosts[key] === wldvps);

  if (!item) {
    return cloudURL;
  }

  return `https://${item}`;
};

export const getServerUrl = () => {
  if (import.meta.env.VITE_APP_IS_STAGING === "1") {
    return "https://cloud-staging.dreamvps.com:3001";
  }

  if (import.meta.env.DEV) {
    return "http://localhost:3001";
  }

  return hosts[window.location.host]
    ? `https://${window.location.host}/api`
    : `${cloudURL}/api`;
};

export const getWebSocketUrl = () => {
  if (import.meta.env.VITE_APP_IS_STAGING === "1") {
    return "https://cloud-staging.dreamvps.com:3001";
  }

  if (import.meta.env.DEV) {
    return "http://localhost:3001";
  }

  return hosts[window.location.host]
    ? `https://${window.location.host}`
    : cloudURL;
};
