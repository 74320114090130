import styles from "./step2.module.scss";

import React, { useState, useRef, useEffect } from "react";
import parsePhoneNumber from "libphonenumber-js";
import PropTypes from "prop-types";
import { useAjax, useInitialDataFetching, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import PhoneInput from "../../phone-input";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { countriesPhoneCodes } from "../../../utils/countries";

const RESEND_TIMER_INIT = 120;
let timerID;

function CompleteRegistrationModalStep2({ onNext, onBack }) {
  const ajax = useAjax();
  const user = useUser();
  const initialDataFetching = useInitialDataFetching();

  const [mode, setMode] = useState("before");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(RESEND_TIMER_INIT);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    timerID = setInterval(() => {
      if (counterRef.current > 0) {
        setCounter(counterRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  async function handleSendClicked() {
    setError(false);

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, user.country);

    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      return setError(<FormattedMessage id="invalid-phone-number" />);
    }

    setLoading(true);
    const data = await ajax(`/users/sendSMS`, {
      phoneCode: parsedPhoneNumber.countryCallingCode,
      phoneNumber: parsedPhoneNumber.nationalNumber,
      action: "registration-step",
    });
    setLoading(false);

    if (data.result === "success") {
      setMode("after");

      setCounter(RESEND_TIMER_INIT);
    } else if (data.message === "already-exists") {
      setError(
        <FormattedMessage id="complete-registration-modal.already-exists" />
      );
    } else {
      setError(data.message);
    }
  }

  async function handleNextClicked() {
    setError(false);

    if (code.trim() === "") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      action: "registration-step",
    });

    await initialDataFetching();
    setLoading(false);

    if (data.result === "success") {
      onNext();
    } else if (data.message === "already-exists") {
      setError(
        <FormattedMessage id="complete-registration-modal.already-exists" />
      );
    } else {
      setError(
        <FormattedMessage id="complete-registration-modal.wrong-code" />
      );
    }
  }

  function handleResendClicked() {
    setCounter(RESEND_TIMER_INIT);
    handleSendClicked();
  }

  function onClose() {
    setError(false);

    if (mode === "before") {
      onBack();
    } else {
      setMode("before");
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <FormattedMessage id="complete-registration-modal.verify-account" />
      </div>

      {mode === "before" && (
        <>
          <div className={styles.text}>
            <FormattedMessage id="complete-registration-modal.verify-account-text" />
          </div>
          <div>
            <PhoneInput
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              countryCode={user.country}
            />
          </div>
        </>
      )}

      {mode === "after" && (
        <>
          <div className={styles.text}>
            <FormattedMessage
              id="complete-registration-modal.after-sms"
              values={{
                number: `(+${countriesPhoneCodes[user.country]})${phoneNumber}`,
              }}
            />
          </div>
          <div>
            <CustomText
              placeholder={
                <FormattedMessage id="complete-registration-modal.enter-code" />
              }
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
        </>
      )}

      {error && <div className={`error ${styles.error}`}>{error}</div>}

      <div className={styles.buttons}>
        {mode === "before" && (
          <DreamButton
            disabled={loading}
            color="purple"
            onClick={handleSendClicked}
          >
            <FormattedMessage id="general.send" />
          </DreamButton>
        )}

        {mode === "after" && (
          <div className={styles.afterButtons}>
            <DreamButton
              disabled={loading}
              color="purple"
              onClick={handleNextClicked}
            >
              <FormattedMessage id="general.next" />
            </DreamButton>
            <DreamButton
              disabled={loading || counterRef.current > 0}
              color="purple"
              onClick={handleResendClicked}
            >
              {counterRef.current > 0 ? (
                <FormattedMessage
                  id="general.resend-code-counter"
                  values={{ counter: counterRef.current }}
                />
              ) : (
                <FormattedMessage id="general.resend-code" />
              )}
            </DreamButton>
          </div>
        )}

        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.back" />
        </DreamButton>
      </div>
    </div>
  );
}

CompleteRegistrationModalStep2.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default CompleteRegistrationModalStep2;
