import styles from "./index.module.scss";

import React from "react";
import BillingManageCreditCardWrapper from "../../pages/billing/manage-credit-card-wrapper";
import {
  getSuspendedReason,
  USER_STATUS_SUSPENDED_PAYMENT_ISSUE,
} from "../../utils/user";
import Box from "../box";
import { FormattedMessage } from "react-intl";
import { useUser } from "../../utils/hooks";

export default function LockedUser() {
  const user = useUser();

  if (user.status === USER_STATUS_SUSPENDED_PAYMENT_ISSUE) {
    return <BillingManageCreditCardWrapper />;
  }

  return (
    <div className={styles.wrapper}>
      <Box>
        <div>
          <FormattedMessage
            id="locked-user.description"
            values={{
              reason: (
                <FormattedMessage
                  id={`locked-user.issue-${getSuspendedReason(user.status)}`}
                />
              ),
              phoneNumber: user.companyPhone,
            }}
          />
        </div>
      </Box>
    </div>
  );
}
