import styles from "./index.module.scss";

import React from "react";
import SortTopSvg from "../components/svgs/sort-top.svg?react";
import SortDownSvg from "../components/svgs/sort-down.svg?react";
import sanitizeHtml from "sanitize-html";
import passwordValidator from "password-validator";

export const handleSortClicked = (e, newSort, sort, setSort) => {
  e.preventDefault();

  if (newSort === sort) {
    setSort(`${newSort}_rev`);
  } else {
    setSort(newSort);
  }
};

export const renderSortIcon = (ofSort, currentSort) => {
  let reverse = false;

  if (currentSort.endsWith("_rev")) {
    currentSort = currentSort.replace("_rev", "");
    reverse = true;
  }

  if (ofSort === currentSort) {
    return reverse ? <SortTopSvg /> : <SortDownSvg />;
  }

  return null;
};

export const encodeFileAsDataURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result);
    };

    reader.readAsDataURL(file);
  });
};

export const inverseStringCase = (str) =>
  [...str]
    .map((char) =>
      char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
    )
    .join("");

export const waitForElm = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();

export const isStrongPassword = (password) => {
  return schema.validate(password);
};

export const selectText = (node) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn("Could not select text in node: Unsupported browser.");
  }
};

export const getDayAsText = (day) => {
  switch (day) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    default:
      return "Saturday";
  }
};

export const syntaxHighlight = (json) => {
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
};

export const proxmoxStringToObject = (string) => {
  if (!string) {
    return null;
  }

  const ret = {};

  const stringArr = string.split(",");

  for (const k in stringArr) {
    const pair = stringArr[k].split("=");
    ret[pair[0]] = pair[1];
  }

  return ret;
};

export const filterLang = (pageProps) => {
  let lang;

  if (pageProps && pageProps.lang) {
    lang = pageProps.lang;
  } else {
    lang = "en";
  }

  return ["en", "he"].includes(lang) ? lang : "en";
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripTags = (text) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

export const getDirection = (content) => {
  content = stripTags(content);

  return content[0] >= "א" && content[0] <= "ת"
    ? { direction: "rtl", textAlign: "right" }
    : { direction: "ltr", textAlign: "left" };
};

export const cutString = (
  str,
  maxLength,
  CuttedWrapper = null,
  cuttedWrapperProps = {}
) => {
  if (str.length > maxLength) {
    str = `${str.slice(0, maxLength)}...`;

    if (CuttedWrapper) {
      return <CuttedWrapper {...cuttedWrapperProps}>{str}</CuttedWrapper>;
    }
  }

  return str;
};

export const setDir = (text) => {
  const stripped = stripTags(text);
  const isRTL = stripped[0] >= "א" && stripped[0] <= "ת";

  return (
    <div
      style={{
        direction: isRTL ? "rtl" : "ltr",
        textAlign: isRTL ? "right" : "left",
      }}
    >
      {text}
    </div>
  );
};

export const noop = () => {};

export const sanitize = (html) =>
  sanitizeHtml(html, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "a",
      "br",
      "pre",
      "p",
      "div",
      "span",
    ],
    allowedAttributes: {
      a: ["href", "target", "rel"],
    },
    allowedIframeHostnames: [],
  });

export const asyncTimeout = (interval) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, interval);
  });
};

export const getSpecificID = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const specific = urlSearchParams.get("specific");

  return specific;
};

export const removeNonAscii = (str) => {
  if (str === null || str === "") {
    return false;
  } else {
    str = str.toString();
  }

  return str.replace(/[^\x20-\x7E]/g, "");
};

export const abbreviateNumber = (value) => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export function queryStringToJSON(qs) {
  qs = qs || location.search.slice(1);

  var pairs = qs.split("&");
  var result = {};
  pairs.forEach(function (p) {
    var pair = p.split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || "");

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isMongoDbID = (str) => {
  return /^[a-f\d]{24}$/i.test(str);
};

export const sortObjectByKeys = (objectToSort) => {
  return Object.keys(objectToSort)
    .sort()
    .reduce((obj, key) => {
      obj[key] = objectToSort[key];
      return obj;
    }, {});
};

export const isEnglishOnly = (str) => {
  let pattern = "^[a-zA-Z][a-zA-Z0-9]*$";

  const regex = new RegExp(pattern);

  return regex.test(str);
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const placeholder = (width, height, margin, circle) => {
  const borderRadius = circle ? { borderRadius: "50%" } : {};

  return (
    <div
      className={styles.loadWraper}
      style={{ ...borderRadius, width, maxWidth: "100%", height, margin }}
    >
      <div className={styles.activity}></div>
    </div>
  );
};

export const createLinkAndGo = (href, target = "_blank") => {
  const a = document.createElement("a");

  a.href = href;
  a.target = target;

  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 1);
};

export const isIterable = (obj) => {
  if (obj == null) {
    return false;
  }

  return typeof obj[Symbol.iterator] === "function";
};

export const replaceNCharsWithAsterisks = (inputString, n) => {
  if (n <= 0) {
    return inputString;
  }

  const asterisks = "*".repeat(n);

  const pattern = new RegExp(`^.{${n}}`);

  const replacedString = inputString.replace(pattern, asterisks);

  return replacedString;
};
