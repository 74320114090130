import styles from "./no-servers.module.scss";

import React from "react";
import CloudSvg from "../svgs/cloud2.svg?react";
import { useLang, useRoles, useWLDVPS } from "../../utils/hooks";
import { FormattedMessage } from "react-intl";
import DreamButton from "../dream-button";

export default function NoServers() {
  const lang = useLang("en");
  const { isAllowed } = useRoles();
  const wldvps = useWLDVPS();

  return (
    <>
      <div className={`mb16 ${styles.card}`}>
        {wldvps === "dreamvps" && <CloudSvg />}
        <div className={`mb8 ${styles.title}`}>
          <FormattedMessage id="servers.create-title" />
        </div>
        <div className={`mb36 ${styles.subtitle}`}>
          <FormattedMessage id="servers.create-subtitle" />
        </div>
        {isAllowed("servers") && (
          <div>
            <DreamButton
              icon="arrow"
              color="green"
              to={`/${lang}/my-cloud/create-new-server`}
            >
              <FormattedMessage id="servers.create-button" />
            </DreamButton>
          </div>
        )}
      </div>

      {/* <div className={styles.card}>
        <div className={`mb8 ${styles.title}`}>
          <FormattedMessage id="servers.migrate-title" />
        </div>
        <div className={`mb36 ${styles.subtitle}`}>
          <FormattedMessage id="servers.migrate-subtitle" />
        </div>
        <div>
          <DreamButton icon="arrow" color="green">
            <FormattedMessage id="servers.migrate-button" />
          </DreamButton>
        </div>
      </div> */}
    </>
  );
}
