import styles from "./snapshots.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import useIsMounted from "ismounted";
import { useAjax } from "../../../../utils/hooks";
import { WithRole } from "../../../../components/with-role";
import { format, fromUnixTime } from "date-fns";
import RestoreFromBackupModal from "../../../../components/modals/restore-from-backup";
import CreateServerFromBackupModal from "../../../../components/modals/create-server-from-backup";
import RestoreFileDirectoryModal from "../../../../components/modals/restore-file-directory";
import { getSocket } from "../../../../utils/globals";
import Box from "../../../../components/box";
import { FormattedMessage } from "react-intl";
import ClickTable from "../../../../components/click-table";
import Spinner from "../../../../components/spinner";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import { useOutletContext } from "react-router-dom";

export default function ServerBackups() {
  const ajax = useAjax();
  const socket = getSocket();
  const isMounted = useIsMounted();
  const { server } = useOutletContext();

  const [backups, setBackups] = useState(null);
  const [selectedBackup, setSelectedBackup] = useState(null);

  const [isRollbackBackupModalOpen, setIsRollbackBackupModalOpen] =
    useState(false);
  const [
    isCreateServerFromBackupModalOpen,
    setIsCreateServerFromBackupModalOpen,
  ] = useState(false);
  const [isRestoreFileDirectoryModalOpen, setIsRestoreFileDirectoryModalOpen] =
    useState(false);

  const listBackups = useCallback(async () => {
    const data = await ajax("/backups/list", { serverID: server._id });

    if (!isMounted.current) {
      return;
    }

    if (data.result === "success") {
      data.backups.sort((a, b) => a["backup-time"] - b["backup-time"]);

      setBackups(data.backups);
    }
  }, [ajax, server, isMounted]);

  const updateBackups = useCallback(() => {
    listBackups();
  }, [listBackups]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    listBackups();

    socket.on("update-backups", updateBackups);

    return () => {
      socket.off("update-backups", updateBackups);
    };
  }, [listBackups, socket, updateBackups]);

  function handleRollbackClicked(backup) {
    setSelectedBackup(backup);
    setIsRollbackBackupModalOpen(true);
  }

  function handleRollbackBackupModalClosed() {
    setIsRollbackBackupModalOpen(false);
  }

  function handleCreateServerFromBackupClicked(backup) {
    setSelectedBackup(backup);
    setIsCreateServerFromBackupModalOpen(true);
  }

  function handleCreateServerFromBackupModalClosed() {
    setIsCreateServerFromBackupModalOpen(false);
  }

  function handleRestoreFileDirectoryClicked(backup) {
    setSelectedBackup(backup);
    setIsRestoreFileDirectoryModalOpen(true);
  }

  function handleRestoreFileDirectoryModalClosed() {
    setIsRestoreFileDirectoryModalOpen(false);
  }

  function handleBackupDropdownToggle(backup) {
    backup.isDropdownOpen = !backup.isDropdownOpen;
    setBackups([...backups]);
  }

  return (
    <WithRole permission="servers">
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="server-backups.description" />
      </Box>

      <Box className={styles.wrapper}>
        <div className={styles.title}>
          <FormattedMessage id="server-backups.title" />
        </div>

        <div className={styles.table}>
          <ClickTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-snapshots.date" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!backups && (
                <tr>
                  <td colSpan={2}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {backups?.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {backups?.map((backup, idx) => (
                <tr key={idx}>
                  <td>
                    {format(fromUnixTime(backup["backup-time"]), "d/M/y HH:mm")}
                  </td>
                  <td>
                    <CustomMenu
                      isOpen={backup.isDropdownOpen}
                      toggle={() => handleBackupDropdownToggle(backup)}
                    >
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleRestoreFileDirectoryClicked(backup)
                        }
                      >
                        <FormattedMessage id="server-backups.restore-file-directory" />
                      </CustomMenuItem>
                      {server.status === "Active" && (
                        <CustomMenuItem
                          disabled={server.isWorking}
                          onClick={() => handleRollbackClicked(backup)}
                        >
                          <FormattedMessage id="server-backups.restore-backup" />
                        </CustomMenuItem>
                      )}
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleCreateServerFromBackupClicked(backup)
                        }
                      >
                        <FormattedMessage id="server-backups.create-server-from-backup" />
                      </CustomMenuItem>
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </ClickTable>
        </div>
      </Box>

      <RestoreFromBackupModal
        isOpen={isRollbackBackupModalOpen}
        onClose={handleRollbackBackupModalClosed}
        server={server}
        backup={selectedBackup}
      />

      <CreateServerFromBackupModal
        isOpen={isCreateServerFromBackupModalOpen}
        onClose={handleCreateServerFromBackupModalClosed}
        server={server}
        backup={selectedBackup}
      />

      <RestoreFileDirectoryModal
        isOpen={isRestoreFileDirectoryModalOpen}
        onClose={handleRestoreFileDirectoryModalClosed}
        server={server}
        backup={selectedBackup}
      />
    </WithRole>
  );
}
