import styles from "./index.module.scss";

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopySvg from "../../svgs/copy.svg?react";
import RefreshSvg from "../../svgs/refresh.svg?react";
import CheckSvg from "../../svgs/check.svg?react";
import { PasswordGenerator } from "../../../utils/security";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";
import { selectText } from "../../../utils";

function GeneratePasswordModal({ isOpen, onClose }) {
  const passwordRef = useRef();

  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    handleGenerateClicked();
  }, []);

  function handleGenerateClicked() {
    let password;

    do {
      password = PasswordGenerator.generate(16);

      if (
        /\d/.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      ) {
        break;
      }
    } while (true);

    setGeneratedPassword(password);

    if (passwordRef.current) {
      selectText(passwordRef.current);
    }
  }

  function handleCopyToClipboardClicked() {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <Modal
      className={styles.generatePasswordModal}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="generate-password-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.passwordBox}>
          <span ref={passwordRef}>{generatedPassword}</span>

          {!copied && (
            <CopyToClipboard
              text={generatedPassword}
              onCopy={handleCopyToClipboardClicked}
            >
              <CopySvg />
            </CopyToClipboard>
          )}

          {copied && <CheckSvg />}

          <RefreshSvg onClick={handleGenerateClicked} />
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="purple" onClick={() => onClose(generatedPassword)}>
          <FormattedMessage id="generate-password-modal.action" />
        </DreamButton>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

GeneratePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GeneratePasswordModal;
