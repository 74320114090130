import React from "react";
import { FormattedMessage } from "react-intl";

export const JOB_SERVER_START = "JOB_SERVER_START";
export const JOB_SERVER_STOP = "JOB_SERVER_STOP";
export const JOB_SERVER_RESTART = "JOB_SERVER_RESTART";
export const JOB_SERVER_CLONE = "JOB_SERVER_CLONE";
export const JOB_SERVER_DESTROY = "JOB_SERVER_DESTROY";
export const JOB_SERVER_CREATE = "JOB_SERVER_CREATE";
export const JOB_SERVER_CREATE_FROM_TEMPLATE =
  "JOB_SERVER_CREATE_FROM_TEMPLATE";
export const JOB_SERVER_REINSTALL = "JOB_SERVER_REINSTALL";
export const JOB_CREATE_SNAPSHOT = "JOB_CREATE_SNAPSHOT";
export const JOB_REMOVE_SNAPSHOT = "JOB_REMOVE_SNAPSHOT";
export const JOB_ROLLBACK_SNAPSHOT = "JOB_ROLLBACK_SNAPSHOT";
export const JOB_CHANGE_PASSWORD = "JOB_CHANGE_PASSWORD";
export const JOB_CHANGE_HOSTNAME = "JOB_CHANGE_HOSTNAME";
export const JOB_CHANGE_TAG = "JOB_CHANGE_TAG";
export const JOB_EDIT_SNAPSHOT = "JOB_EDIT_SNAPSHOT";
export const JOB_CHANGE_PLAN = "JOB_CHANGE_PLAN";
export const JOB_PREPARE_SERVER = "JOB_PREPARE_SERVER";
export const JOB_MULTI_CHARGE = "JOB_MULTI_CHARGE";
export const JOB_RESTORE_BACKUP = "JOB_RESTORE_BACKUP";
export const JOB_RESTORE_BACKUP_NEW_SERVER = "JOB_RESTORE_BACKUP_NEW_SERVER";
export const JOB_CREATE_TEMPLATE_FROM_SERVER =
  "JOB_CREATE_TEMPLATE_FROM_SERVER";
export const JOB_REMOVE_TEMPLATE_OF_SERVER = "JOB_REMOVE_TEMPLATE_OF_SERVER";

export function getServerByTask(servers, serverID) {
  if (!servers) {
    return false;
  }

  const server = servers.filter((s) => s._id === serverID);

  return server.length ? server[0] : false;
}

export function getTypeOfTask(task) {
  switch (task.type) {
    case JOB_SERVER_START:
      return <FormattedMessage id="tasks.start" />;
    case JOB_SERVER_STOP:
      return <FormattedMessage id="tasks.stop" />;
    case JOB_SERVER_RESTART:
      return <FormattedMessage id="tasks.restart" />;
    case JOB_SERVER_CLONE:
      return <FormattedMessage id="tasks.clone" />;
    case JOB_SERVER_DESTROY:
      return <FormattedMessage id="tasks.destroy" />;
    case JOB_SERVER_CREATE:
      return <FormattedMessage id="tasks.create" />;
    case JOB_SERVER_CREATE_FROM_TEMPLATE:
      return <FormattedMessage id="tasks.create" />;
    case JOB_SERVER_REINSTALL:
      return <FormattedMessage id="tasks.reinstall" />;
    case JOB_CREATE_SNAPSHOT:
      return <FormattedMessage id="tasks.create-snapshot" />;
    case JOB_EDIT_SNAPSHOT:
      return <FormattedMessage id="tasks.edit-snapshot" />;
    case JOB_REMOVE_SNAPSHOT:
      return <FormattedMessage id="tasks.remove-snapshot" />;
    case JOB_ROLLBACK_SNAPSHOT:
      return <FormattedMessage id="tasks.rollback-snapshot" />;
    case JOB_CHANGE_PASSWORD:
      return <FormattedMessage id="tasks.change-password" />;
    case JOB_CHANGE_HOSTNAME:
      return <FormattedMessage id="tasks.change-hostname" />;
    case JOB_CHANGE_TAG:
      return <FormattedMessage id="tasks.change-tag" />;
    case JOB_CHANGE_PLAN:
      return <FormattedMessage id="tasks.change-plan" />;
    case JOB_PREPARE_SERVER:
      return <FormattedMessage id="tasks.prepare-server" />;
    case JOB_MULTI_CHARGE:
      return <FormattedMessage id="tasks.multi-charge" />;
    case JOB_RESTORE_BACKUP:
      return <FormattedMessage id="tasks.restore-backup" />;
    case JOB_RESTORE_BACKUP_NEW_SERVER:
      return <FormattedMessage id="tasks.restore-backup-new-server" />;
    case JOB_CREATE_TEMPLATE_FROM_SERVER:
      return <FormattedMessage id="tasks.template-from-server" />;
    case JOB_REMOVE_TEMPLATE_OF_SERVER:
      return <FormattedMessage id="tasks.remove-template-of-server" />;

    default:
      return task.type;
  }
}

export const renderSchedulerAction = (action) => {
  switch (action) {
    case "start-server":
      return <FormattedMessage id="scheduler.start-server" />;
    case "restart-server":
      return <FormattedMessage id="scheduler.restart-server" />;
    case "stop-server-soft":
      return <FormattedMessage id="scheduler.stop-server-soft" />;
    case "stop-server-hard":
      return <FormattedMessage id="scheduler.stop-server-hard" />;
    case "create-snapshot":
      return <FormattedMessage id="scheduler.create-snapshot" />;
    case "remove-snapshot":
      return <FormattedMessage id="scheduler.remove-snapshot" />;
    case "scale-server":
      return <FormattedMessage id="scheduler.scale-server" />;
  }
};

export function getColorOfTask(status) {
  switch (status.toLowerCase()) {
    case "success":
      return "var(--light-green)";
    case "failed":
      return "var(--red)";
    default:
      return "var(--primary-text)";
  }
}
