import styles from "./step1.module.scss";

import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { useAjax, useInitialDataFetching, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { countries, getCountryByCode } from "../../../utils/countries";

const countriesArray = Object.keys(countries).map((k, idx) => ({
  label: k,
  value: countries[k],
  key: idx,
}));

function CompleteRegistrationModalStep1({ onNext, onClose }) {
  const ajax = useAjax();
  const user = useUser();
  const initialDataFetching = useInitialDataFetching();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [stateRegion, setStateRegion] = useState("");
  const [hideState, setHideState] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setCompanyName(user.companyName || "");
    setCountry(
      countriesArray.find((country) => country.value === user.countryCode).value
    );
    setStateRegion(user.stateRegion || "");

    if (user.countryCode !== "IL") {
      if (user.city) {
        setCity(user.city);
      }
      if (user.address) {
        setAddress(user.address);
      }
    }
  }, [user]);

  useEffect(() => {
    if (country === "IL") {
      setHideState(true);
    }
  }, [city, country]);

  async function handleNextClicked() {
    setError(false);

    let _firstName = firstName.trim();
    let _lastName = lastName.trim();
    let _companyName = companyName.trim();
    let _stateRegion = stateRegion.trim();

    if (
      !_firstName ||
      !_lastName ||
      !_companyName ||
      !country ||
      !city ||
      !address
    ) {
      setError(<FormattedMessage id="general.fill-all-fields" />);
      return;
    }

    setLoading(true);
    await ajax(`/users/updateClientData`, {
      firstName: _firstName,
      lastName: _lastName,
      companyName: _companyName,
      country,
      stateRegion: _stateRegion,
      address: typeof address == "string" ? address : address.value,
      city: typeof city === "string" ? city : city.value,
      updateRegisterStep: true,
    });

    await initialDataFetching();
    setLoading(false);

    onNext();
  }

  async function handleAsyncCities(value, callback) {
    const data = await ajax("/cities/get", { value });

    const options = data.cities.map((city) => ({
      label: city,
      value: city,
    }));

    callback(options);
  }

  async function handleAsyncAddresses(value, callback) {
    const data = await ajax("/cities/getAddresses", {
      city: city.value,
      value,
    });

    const options = data.addresses.map((address) => ({
      label: address,
      value: address,
    }));

    callback(options);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.allFields}>
        <b>
          <FormattedMessage id="general.all-fields-required" />
        </b>
      </div>

      <div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.first-name" />
          </span>
          <CustomText
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="given-name"
            tabIndex={1}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.last-name" />
          </span>
          <CustomText
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="family-name"
            tabIndex={2}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.company-name" />
          </span>
          <CustomText
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            autoComplete="organization"
            tabIndex={3}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.country" />
          </span>
          <div className={styles.select}>
            <div className="control">
              <ReactCountryFlag
                countryCode={country}
                style={{ fontSize: "40px" }}
              />
              {getCountryByCode(country)}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.city" />
          </span>
          {!hideState && (
            <CustomText
              value={city}
              onChange={(e) => setCity(e.target.value)}
              tabIndex={5}
            />
          )}
          {hideState && (
            <AsyncSelect
              className={styles.select}
              loadOptions={handleAsyncCities}
              value={city}
              onChange={(option) => setCity(option)}
              tabIndex={7}
              placeholder={<FormattedMessage id="general.only-hebrew" />}
            />
          )}
        </div>
        {!hideState && (
          <div className={styles.row}>
            <span>
              <FormattedMessage id="general.state-region" />
            </span>
            <CustomText
              value={stateRegion}
              onChange={(e) => setStateRegion(e.target.value)}
              autoComplete="street-address"
              tabIndex={9}
            />
          </div>
        )}
        <div className={styles.row}>
          <span>
            <FormattedMessage id="general.address" />
          </span>
          {!hideState && (
            <CustomText
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              tabIndex={6}
            />
          )}
          {hideState && (
            <AsyncSelect
              className={styles.select}
              loadOptions={handleAsyncAddresses}
              value={address}
              onChange={(option) => setAddress(option)}
              tabIndex={8}
              placeholder={<FormattedMessage id="general.only-hebrew" />}
            />
          )}
        </div>
      </div>

      {error && (
        <div className={`error ${styles.error}`}>
          <FormattedMessage id="general.fill-all-fields" />
        </div>
      )}

      <div className={styles.buttons}>
        <DreamButton
          disabled={loading}
          color="purple"
          onClick={handleNextClicked}
          tabIndex={12}
        >
          <FormattedMessage id="general.next" />
        </DreamButton>
        {onClose && (
          <DreamButton disabled={loading} color="text" onClick={onClose}>
            <FormattedMessage id="general.cancel" />
          </DreamButton>
        )}
      </div>
    </div>
  );
}

CompleteRegistrationModalStep1.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default CompleteRegistrationModalStep1;
