import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";

function ServerLogModal({ job, isOpen, onClose }) {
  if (!job) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="server-log-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.infoRow}>
          <span className={styles.date}>
            {format(new Date(job.created_at), "dd.MM.yy | HH:mm")}
          </span>
        </div>

        <div className={styles.diffWrapper}>
          <div>
            {job.data.cpu && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.cpu" />:{" "}
                </span>
                {job.data.cpu}
              </div>
            )}
            {job.data.image && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.image" />:{" "}
                </span>
                {job.data.image}
              </div>
            )}
            {job.data.ram_mb && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.ram" />:{" "}
                </span>
                {job.data.ram_mb}MB
              </div>
            )}
            {job.data.disks_gb[0] && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.disk" />:{" "}
                </span>
                {job.data.disks_gb[0].size}GB
              </div>
            )}
            {typeof job.data.disks_gb.length > 1 && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.additional_disk" />:{" "}
                </span>
                {job.data.disks_gb
                  .slice(1)
                  .reduce((sum, disk) => sum + disk.size, 0)}
                GB
              </div>
            )}
            {typeof job.data.backup !== "undefined" && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="server-log-modal.backup" />:{" "}
                </span>
                {
                  <FormattedMessage
                    id={job.data.backup ? "general.yes" : "general.no"}
                  />
                }
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

ServerLogModal.propTypes = {
  job: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ServerLogModal;
