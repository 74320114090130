import styles from "./products.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import HeaderWithNavs from "../../components/cloud-layout/headers-with-navs";
import { WithRole } from "../../components/with-role";
import { useAjax, useUser, useWLDVPS } from "../../utils/hooks";
import { FormattedMessage } from "react-intl";
import Box from "../../components/box";
import ClickTable from "../../components/click-table";
import Spinner from "../../components/spinner";
import { currencySymbols } from "../../utils/billing";

export default function MyCloudProducts() {
  const user = useUser();
  const ajax = useAjax();
  const wldvps = useWLDVPS();

  const [otherProducts, setOtherProducts] = useState(null);

  const getOtherProduct = useCallback(async () => {
    if (!user._id) {
      return;
    }

    const data = await ajax("/users/getOtherProduct", { userID: user._id });

    if (data.result === "success") {
      const otherProducts = data.otherProducts.filter(
        (op) => op.whitelabel === wldvps
      );
      setOtherProducts(otherProducts);
    }
  }, [ajax, user, wldvps]);

  useEffect(() => {
    getOtherProduct();
  }, [getOtherProduct]);

  return (
    <WithRole>
      <HeaderWithNavs title={<FormattedMessage id="my-cloud-products.title" />}>
        <Box className={styles.descriptionBox}>
          <FormattedMessage id="products.description" />
        </Box>

        <Box>
          <ClickTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-name" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-quantity" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.pay-every" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.next-pay-day" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-price" />
                </th>
              </tr>
            </thead>
            <tbody>
              {!otherProducts && (
                <tr>
                  <td colSpan={5}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {otherProducts?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}
              {otherProducts?.map((otherProduct, idx) => (
                <tr key={idx}>
                  <td>{otherProduct.name}</td>
                  <td>{otherProduct.quantity}</td>
                  <td>
                    <>
                      {otherProduct.payEvery}
                      <FormattedMessage
                        id={`general.${
                          otherProduct.payEvery === 1 ? "month" : "months"
                        }`}
                      />
                    </>
                  </td>
                  <td>
                    {format(new Date(otherProduct.next_pay_day), "d/M/y")}
                  </td>
                  <td>
                    {currencySymbols[otherProduct.currency]}
                    {otherProduct.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </ClickTable>
        </Box>
      </HeaderWithNavs>
    </WithRole>
  );
}
