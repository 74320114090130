import styles from "./sidebar-mobile.module.scss";

import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CancelSvg from "../svgs/cancel.svg?react";
import sidebarUser from "./sidebar-user.json";
import sidebarIntegratorTop from "./sidebar-integrator-top.json";
import sidebarWhitelabel from "./sidebar-whitelabel.json";
import sidebarSuperAdmin from "./sidebar-super-admin.json";
import produce from "immer";
import { FormattedMessage } from "react-intl";
import { useLang, useUser } from "../../utils/hooks";
import { SUPER_ADMIN, USER, WHITELABEL } from "../../utils/user";

function SidebarMobile({ isOpen, onClose }) {
  const lang = useLang("en");
  const user = useUser();

  const [openItem, setOpenItem] = useState(null);

  const sidebar = useMemo(() => {
    let arr;

    if (user.role === USER) {
      arr = sidebarUser;
    } else if (user.role === WHITELABEL) {
      if (user.integrator === "top") {
        arr = sidebarIntegratorTop;
      } else {
        arr = sidebarWhitelabel;
      }
    } else if (user.role === SUPER_ADMIN) {
      arr = sidebarSuperAdmin;
    }

    return produce(arr, (draft) => {
      for (const item of draft) {
        item.title = <FormattedMessage id={item.title} />;

        for (const link of item.links) {
          link.title = <FormattedMessage id={link.title} />;
          link.to = link.to.replace("{lang}", lang);
        }
      }
    });
  }, [user, lang]);

  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
      <CancelSvg onClick={() => onClose(true)} className={styles.close} />

      {sidebar.map((item, key) => (
        <Fragment key={key}>
          <div
            className={`${styles.mainItem} ${
              openItem === key ? styles.selected : ""
            }`}
            onClick={() => setOpenItem(key)}
          >
            {item.title}
          </div>
          {item.links.map((link, key2) => (
            <div
              key={key2}
              className={`${styles.item} ${
                item.links.length - 1 === key2 ? styles.last : ""
              } ${openItem === key ? "" : styles.hidden}`}
            >
              <Link to={link.to} onClick={() => onClose()}>
                {link.title}
              </Link>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
}

SidebarMobile.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SidebarMobile;
