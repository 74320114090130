import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function ProgressBar({ current, max, text, page = "" }) {
  const prec = (current / max) * 100;

  return (
    <div
      className={
        page === "package-details" ? styles.packgeDetails : styles.wrapper
      }
    >
      <div
        className={`${styles.progress} ${
          page === "package-details" &&
          (prec < 56
            ? ""
            : prec >= 56 && prec <= 85
            ? styles.yellow
            : styles.red)
        }`}
        style={{ width: `${prec}%` }}
      ></div>
      <div
        className={`${styles.progressNumber} ${
          page === "package-details" && (prec >= 56 ? styles.white : "")
        }`}
      >
        {text ? text : `${parseInt(prec)}%`}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  current: PropTypes.number,
  max: PropTypes.number,
  text: PropTypes.string,
  page: PropTypes.string,
};

export default ProgressBar;
