import styles from "./index.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import sanitize from "sanitize-html";
import { useAjax } from "../../../utils/hooks";
import { getFullName } from "../../../utils/user";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../../custom-react-select";
import DreamButton from "../../dream-button";

function UserSelectorModal({
  title,
  content,
  withALL,
  withMe = false,
  additionalRows = [],
  filter = { email: 1, firstName: 1, lastName: 1 },
  isOpen,
  onClose = () => {},
  usersFilter = () => true,
  children,
}) {
  const ajax = useAjax();

  const filterRef = useRef(filter);

  const usersFilterRef = useRef(usersFilter);
  usersFilterRef.current = usersFilter;

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/users/getAllUsers", {
      filter: filterRef.current,
      withMe,
    });

    if (data.result === "success") {
      const users = data.users.filter(usersFilterRef.current).map((user) => ({
        label: `${user.email} | ${getFullName(user)}`,
        value: user._id,
      }));

      if (withALL) {
        users.unshift({
          label: <FormattedMessage id="all" />,
          value: "ALL",
        });
      }

      if (Array.isArray(additionalRows)) {
        users.unshift(...additionalRows);
      }

      setUsers(users);
    }
  }, [ajax, withALL, withMe, additionalRows]);

  useEffect(() => {
    if (isOpen) {
      getAllUsers();
    }
  }, [isOpen, getAllUsers]);

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        {content && (
          <div
            className={styles.contentWrapper}
            dangerouslySetInnerHTML={{ __html: sanitize(content) }}
          ></div>
        )}

        <CustomReactSelect
          instanceId="user-selector-modal"
          options={users}
          value={selectedUser}
          onChange={(item) => setSelectedUser(item)}
        />

        {children}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={!selectedUser}
          color="purple"
          onClick={() => onClose(selectedUser)}
        >
          <FormattedMessage id="general.ok" />
        </DreamButton>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

UserSelectorModal.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  withALL: PropTypes.bool,
  withMe: PropTypes.bool,
  additionalRows: PropTypes.array,
  filter: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  usersFilter: PropTypes.func,
  ajax: PropTypes.func,
  children: PropTypes.any,
};

export default UserSelectorModal;
