export const getPrimaryCard = (user) => {
  if (!user.creditCards) {
    return false;
  }

  const card = user.creditCards.find((card) => card.primary);

  return card || false;
};

export function detectCardType(cardNumber) {
  const sanitizedNumber = cardNumber.replace(/\D/g, "");

  const cardPatterns = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MasterCard:
      /^(5[1-5][0-9]{14}|2(?:2[2-9][0-9]{12}|[3-6][0-9]{13}|7[01][0-9]{12}|720[0-9]{12}))$/,
    "American Express": /^3[47][0-9]{13}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (const [cardType, pattern] of Object.entries(cardPatterns)) {
    if (pattern.test(sanitizedNumber)) {
      return cardType;
    }
  }

  return null;
}
