import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";

function EditSnapshotModal({ server, snapshot, isOpen, onClose }) {
  const ajax = useAjax();

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (snapshot?.description) {
      setDescription(snapshot.description);
    }
  }, [snapshot]);

  async function handleEditClicked() {
    await ajax("/snapshots/edit", {
      serverID: server._id,
      name: snapshot.name,
      description,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="edit-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <CustomText
            placeholder={<FormattedMessage id="general.description" />}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="purple" onClick={handleEditClicked}>
          <FormattedMessage id="general.save-changes" />
        </DreamButton>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

EditSnapshotModal.propTypes = {
  snapshot: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditSnapshotModal;
