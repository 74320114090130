import styles from "./externals-ips.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import HeaderWithNavs from "../../../components/cloud-layout/headers-with-navs";
import { WithRole } from "../../../components/with-role";
import AssignIpToServerModal from "../../../components/modals/assign-ip-to-server";
import { FormattedMessage } from "react-intl";
import Box from "../../../components/box";
import ClickTable from "../../../components/click-table";
import Spinner from "../../../components/spinner";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import DreamButton from "../../../components/dream-button";
import { useAjax, useConfirm } from "../../../utils/hooks";

export default function ExternalsIps() {
  const ajax = useAjax();
  const confirm = useConfirm();

  const [externals, setExternals] = useState(null);
  const [isAssignIpToServerModalOpen, setIsAssignIpToServerModalOpen] =
    useState(false);

  const getAllExternals = useCallback(async () => {
    const data = await ajax("/network/getAllExternalsIps");

    if (data.result === "success") {
      setExternals(data.ips);
    }
  }, [ajax]);

  useEffect(() => {
    getAllExternals();
  }, [getAllExternals]);

  function handleDropDownToggle(external) {
    external.dropdownOpen = !external.dropdownOpen;
    setExternals([...externals]);
  }

  function handleRemoveMenuClicked(external) {
    confirm({
      title: <FormattedMessage id="externals-ips.remove.title" />,
      message: (
        <FormattedMessage
          id="externals-ips.remove.content"
          values={{
            ip: external.ip,
            domain: external.server.hostname,
          }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax(`/network/removeAssignedExternalIp`, {
          serverID: external.server._id,
        });

        await getAllExternals();
      },
    });
  }

  async function handleAssignIpToServerModalClosed(state) {
    if (state) {
      await getAllExternals();
    }

    setIsAssignIpToServerModalOpen(false);
  }

  return (
    <WithRole permission="super-admin.support">
      <HeaderWithNavs title={<FormattedMessage id="externals-ips.title" />}>
        <div className={styles.wrapper}>
          <Box>
            <ClickTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="externals-ips.ip" />
                  </th>
                  <th>
                    <FormattedMessage id="externals-ips.server" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!externals && (
                  <tr>
                    <td colSpan={3}>
                      <Spinner />
                    </td>
                  </tr>
                )}

                {externals?.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {externals?.map((external, key) => (
                  <tr key={key}>
                    <td>{external.ip}</td>
                    <td>{external.server.hostname}</td>
                    <td>
                      <CustomMenu
                        isOpen={external.dropdownOpen}
                        toggle={() => handleDropDownToggle(external)}
                      >
                        <CustomMenuItem
                          onClick={() => handleRemoveMenuClicked(external)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ClickTable>

            <DreamButton
              color="light-purple"
              onClick={() => setIsAssignIpToServerModalOpen(true)}
            >
              <FormattedMessage id="externals-ips.assign-ip-to-server" />
            </DreamButton>
          </Box>
        </div>

        <AssignIpToServerModal
          isOpen={isAssignIpToServerModalOpen}
          onClose={handleAssignIpToServerModalClosed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}
