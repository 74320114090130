import styles from "./private-networking.module.scss";

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import IpSubnetCalculator from "ip-subnet-calculator";
import { normalizeLanIp, validateIPaddress } from "../../utils/networks";
import { Modal, ModalBody } from "reactstrap";
import { dataCenters } from "../../utils/datacenters";
import { FormattedMessage } from "react-intl";
import Spinner from "../spinner";
import CustomReactSelect from "../custom-react-select";
import CustomText from "../custom-text";
import CountrySelector from "../country-selector";
import DreamButton from "../dream-button";
import { useAjax, useAlert, usePrompt, useUser } from "../../utils/hooks";
import { SUPER_ADMIN } from "../../utils/user";

function PrivateNetworking({
  vlans,
  location,
  setLocation,
  vlanName,
  setVlanName,
  ipScope,
  setIpScope,
  ipAddress,
  setIpAddress,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  server,
  forUserID,
  gatewayOptions,
  setGatewayOptions,
  gatewayOption_KeepCurrentConfig,

  staticLocation,
  staticVlanName,
  staticVlanID,
  asSelector = false,
  hideIpAddressSelect = false,
  hideGatewaySelect = false,
}) {
  const ajax = useAjax();
  const user = useUser();
  const serverRef = useRef(server);

  const lanNameWrapperRef = useRef();

  const prompt = usePrompt();
  const promptRef = useRef(prompt);
  const alert = useAlert();

  const [existsVlans, setExistsVlans] = useState(null);
  const [ipAddressOptions, setIpAddressOptions] = useState([]);
  const [overrideGateway, setOverrideGateway] = useState(false);

  const [customScope, setCustomScope] = useState(null);

  const [isPrivateNetworkingModalOpen, setIsPrivateNetworkingModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const netmaskOptions = useMemo(
    () =>
      [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((number) => ({
        value: number,
        label: number,
      })),
    []
  );

  const userIDToWork = useMemo(() => {
    if (user.role === SUPER_ADMIN) {
      if (forUserID) {
        return forUserID;
      } else {
        return "ALL";
      }
    } else if (user.current_parent) {
      return user.current_parent;
    } else {
      return serverRef.current ? serverRef.current.user_id : user._id;
    }
  }, [forUserID, user._id, user.role, user.current_parent]);

  const lansOptions = useMemo(() => {
    const options = [
      {
        value: "",
        label: <FormattedMessage id="private-networking.create-new-lan" />,
      },
    ];

    if (vlans) {
      options.push(
        ...vlans.map((vlan) => ({
          value: vlan._id,
          label: vlan.vlanName,
        }))
      );
    }

    return options;
  }, [vlans]);

  const ipScopeOptions = useMemo(() => {
    let scopes = [];

    if (vlanName && vlans) {
      const vlanObj = vlans.find((item) => item.vlanName === vlanName);

      if (vlanObj && vlanObj.subnets[0]) {
        const nip = normalizeLanIp(vlanObj.subnets[0].ipAddress);

        scopes.push({
          label: nip,
          value: nip,
        });
      }
    }

    if (scopes.length === 0) {
      scopes.push(
        {
          label: "172.16.0.0",
          value: "172.16.0.0",
        },
        {
          label: "192.168.0.0",
          value: "192.168.0.0",
        },
        {
          label: "10.0.0.0",
          value: "10.0.0.0",
        },
        {
          label: <FormattedMessage id="general.custom" />,
          value: -1,
        }
      );
    }

    if (customScope) {
      scopes.unshift(customScope);
    }

    return scopes;
  }, [vlanName, vlans, customScope]);

  const isVlanNameInUse = useMemo(
    () => !!existsVlans?.find((vlan) => vlan.value && vlan.label === vlanName),
    [vlanName, existsVlans]
  );

  const currentSubnet = useMemo(() => {
    if (!existsVlans || !ipScope) {
      return null;
    }

    const existsVlan = existsVlans.find(
      (vlan) => vlan.value && vlan.label === vlanName
    );

    if (!existsVlan) {
      return null;
    }

    const subnet = existsVlan.value.subnets.find(
      (item) => item.ipAddress === ipScope.value
    );

    return subnet;
  }, [vlanName, existsVlans, ipScope]);

  const getVLANs = useCallback(async () => {
    if (!location) {
      return;
    }

    const data = await ajax("/network/getVLANs", {
      userIDToWork,
      location: location.value,
      type: "lan",
    });

    const existsVlans = data.vlans.map((vlan) => ({
      label: vlan.vlanName,
      value: vlan,
    }));
    existsVlans.push({
      label: (
        <>
          {"["}
          <FormattedMessage id="general.new" />
          {"]"}
        </>
      ),
      value: null,
    });
    setExistsVlans(existsVlans);

    return existsVlans;
  }, [ajax, location, userIDToWork]);

  useEffect(() => {
    if (netmaskOptions[4]) {
      setNetmask(netmaskOptions[4]);
    } else {
      setNetmask(netmaskOptions[0]);
    }
  }, [netmaskOptions, setNetmask]);

  useEffect(() => {
    getVLANs();
  }, [getVLANs]);

  useEffect(() => {
    setIpAddress(null);

    if (setGateway) {
      setGateway(null);
    }
  }, [ipScope, netmask, setIpAddress, setGateway]);

  useEffect(() => {
    if (
      gateway &&
      ipAddress &&
      gateway.value === ipAddress.value &&
      setGateway
    ) {
      setGateway(null);
    }
  }, [gateway, ipAddress, setGateway]);

  useEffect(() => {
    if ((!hideIpAddressSelect && !ipAddress) || !netmask || !vlanName) {
      return;
    }

    const gateways = [];

    if (gatewayOption_KeepCurrentConfig) {
      gateways.push({
        value: null,
        label: <FormattedMessage id="keep-current-config" />,
      });
    }

    gateways.push({ value: "", label: <FormattedMessage id="no-gateway" /> });

    let normalizeIp;
    if (hideIpAddressSelect) {
      if (!ipScope || ipScope.value === -1) {
        return;
      }

      normalizeIp = normalizeLanIp(ipScope.value);
    } else {
      if (!ipAddress) {
        return;
      }
      normalizeIp = normalizeLanIp(ipAddress.value);
    }

    const ipSubnetCalculator = IpSubnetCalculator.calculateSubnetMask(
      normalizeIp,
      netmask.value
    );

    for (
      let i = ipSubnetCalculator.ipLow + 1;
      i <= ipSubnetCalculator.ipHigh;
      i++
    ) {
      const ip = IpSubnetCalculator.toString(i);

      if (ip !== ipAddress?.value && !ip.endsWith(".255")) {
        gateways.push({ label: ip, value: ip });
      }
    }

    setGatewayOptions(gateways);
  }, [
    ipAddress,
    ipScope,
    setGatewayOptions,
    netmask,
    vlanName,
    hideIpAddressSelect,
    vlans,
    gatewayOption_KeepCurrentConfig,
  ]);

  useEffect(() => {
    if (
      ipScope &&
      typeof ipScope.value === "string" &&
      !ipScope.value.endsWith(".0")
    ) {
      let arr = ipScope.value.split(".");
      arr[3] = 0;
      arr = arr.join(".");

      const newCustomScope = { label: arr, value: arr };
      setCustomScope(newCustomScope);
      setIpScope(newCustomScope);
    }
  }, [ipScope, setIpScope]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.email === "erez@od.co.il") {
      if (!vlanName) {
        setVlanName("default-lan");
      }

      if (!ipScope && ipScopeOptions.length > 0) {
        setIpScope(ipScopeOptions[0]);
      }

      if (!netmask && netmaskOptions.length > 0) {
        setNetmask(netmaskOptions[0]);
      }

      if (!ipAddress && ipAddressOptions.length > 0) {
        setIpAddress(ipAddressOptions[0]);
      }

      if (!gateway && gatewayOptions.length > 0 && setGateway) {
        setGateway(gatewayOptions[gatewayOptions.length - 1]);
      }
    }
  }, [
    user,
    lansOptions,
    setVlanName,
    vlanName,
    ipScope,
    ipScopeOptions,
    setIpScope,
    netmask,
    netmaskOptions,
    setNetmask,
    ipAddress,
    ipAddressOptions,
    setIpAddress,
    gateway,
    gatewayOptions,
    setGateway,
  ]);

  useEffect(() => {
    async function fetch() {
      const params = {
        userIDToWork: userIDToWork === "ALL" ? user._id : userIDToWork,
        ipScope: ipScope.value,
        netmask: netmask.value,
      };

      if (staticLocation) {
        params.location = staticLocation;
      } else {
        if (!location) {
          return;
        }

        params.location =
          typeof location === "object" ? location.value : location;
      }

      const data = await ajax("/network/getFreeLanIps", params);

      if (vlanName && vlans) {
        const vlanObj = vlans.find((item) => item.vlanName === vlanName);

        if (vlanObj?.subnets[0]) {
          data.ips = data.ips.filter((ip) => ip !== vlanObj.subnets[0].gateway);
        }
      }

      const ipAddressOptions = data.ips
        .filter((ip) => !ip.endsWith(".255"))
        .map((ip) => ({ label: ip, value: ip }));

      setIpAddressOptions(ipAddressOptions);
    }

    if (vlanName && ipScope && ipScope.value === -1) {
      promptRef
        .current({
          title: (
            <FormattedMessage id="private-networking.custom-ip-scope.title" />
          ),
          message: (
            <FormattedMessage id="private-networking.custom-ip-scope.message" />
          ),
          acceptOnlyValue: (ip) => {
            if (!validateIPaddress(ip)) {
              return {
                status: false,
                reason: (
                  <FormattedMessage id="private-networking.custom-ip-scope.error.wrong-ip" />
                ),
              };
            }

            if (!normalizeLanIp(ip)) {
              return {
                status: false,
                reason: (
                  <FormattedMessage id="private-networking.custom-ip-scope.error.wrong-scope" />
                ),
              };
            }

            return { status: true };
          },
          beforeClose: (ip) => {
            const newCustomScope = { label: ip, value: ip };
            setCustomScope(newCustomScope);
            setIpScope(newCustomScope);
          },
        })
        .then((promptRes) => {
          if (!promptRes) {
            setCustomScope(null);
            setIpScope(null);
          }
        });
    } else if (vlanName && ipScope && netmask) {
      fetch();
    }
  }, [
    ajax,
    location,
    netmask,
    staticLocation,
    user._id,
    userIDToWork,
    ipScope,
    vlanName,
    vlans,
    setIpScope,
  ]);

  useEffect(() => {
    async function getConfig() {
      if (!server) {
        return;
      }

      setOverrideGateway(false);

      const data = await ajax("/proxmox/nodes/qemu/getConfig", {
        serverID: server._id,
      });

      const gateways = Object.keys(data.data || []).filter((key) =>
        data.data[key].toString().includes("gw=")
      );

      if (gateways.length >= 1) {
        setOverrideGateway(true);
      }
    }

    getConfig();
  }, [ajax, server]);

  useEffect(() => {
    if (staticVlanName) {
      setVlanName(staticVlanName);
    }
  }, [setVlanName, staticVlanName, staticVlanID]);

  useEffect(() => {
    if (hideGatewaySelect && currentSubnet?.gateway && setGateway) {
      setGateway(currentSubnet.gateway);
    }
  }, [currentSubnet, hideGatewaySelect, setGateway]);

  function handleVlanNameChanged(item) {
    if (item.value) {
      setVlanName(item.label);
      setIpScope(null);
      setNetmask(null);
      return;
    }

    setVlanName(null);
    setIpScope(null);
    setIpAddress(null);

    if (setGateway) {
      setGateway(null);
    }

    setIsPrivateNetworkingModalOpen(true);
  }

  function handlePrivateNetworkingModalClosed() {
    setVlanName(null);
    setIpScope(null);
    setIpAddress(null);

    if (setGateway) {
      setGateway(null);
    }

    setIsPrivateNetworkingModalOpen(false);
  }

  function handleIpAdressScopeChanged(item) {
    setIpScope({ label: item.value.ipAddress, value: item.value.ipAddress });
    setNetmask({ label: item.value.netmask, value: item.value.netmask });
  }

  async function handleCreateNewVlanClicked() {
    setLoading(true);
    const data = await ajax("/network/addPrivateNetworkCardForUser", {
      userIDToWork,
      location,
      vlanName,
      ip: ipScope.value,
      netmask: netmask.value,
      gateway: gateway.value,
    });

    if (data.result === "success") {
      await getVLANs();

      if (lanNameWrapperRef.current) {
        lanNameWrapperRef.current.querySelector(
          "[class*=-singleValue]"
        ).innerText = vlanName;
      }

      setVlanName(vlanName);
      setIpScope(null);
      setIpAddress(null);

      if (setGateway) {
        setGateway(null);
      }

      setIsPrivateNetworkingModalOpen(false);
    } else {
      alert({
        title: (
          <FormattedMessage id="modals.add-ip-address-scope.errors.add.title" />
        ),
        message: (
          <FormattedMessage
            id={`modals.add-ip-address-scope.errors.${data.message}`}
            values={{ number: data.number }}
          />
        ),
      });
    }

    setLoading(false);
  }

  function renderLanNameSelect() {
    if (asSelector) {
      if (!existsVlans) {
        return <Spinner />;
      }

      return (
        <CustomReactSelect
          options={existsVlans}
          onChange={handleVlanNameChanged}
        />
      );
    }

    return (
      <CustomText
        value={vlanName || ""}
        onChange={(e) => setVlanName(e.target.value)}
      />
    );
  }

  function renderLanNameSection() {
    if (!server) {
      return (
        <>
          <div className="drow f100">
            <span>
              <FormattedMessage id="add-ip-address-scope-modal.location" />
            </span>
            {staticLocation && (
              <div>
                <ReactCountryFlag
                  countryCode={
                    ["TA"].includes(staticLocation) ? "IL" : staticLocation
                  }
                  style={{ fontSize: "40px" }}
                />
                <b>{staticLocation}</b>
              </div>
            )}
            {!staticLocation && (
              <CountrySelector
                value={location}
                onChange={(country) => setLocation(country)}
                countriesFilter={dataCenters}
              />
            )}
          </div>

          {(location || staticVlanName) && (
            <div className="drow f100" ref={lanNameWrapperRef}>
              <span>
                <FormattedMessage id="private-networking.lan-name" />
              </span>
              {staticVlanName && <div>{staticVlanName}</div>}
              {!staticVlanName && renderLanNameSelect()}
            </div>
          )}
        </>
      );
    }

    return (
      <div className="drow f100">
        <span>
          <FormattedMessage id="private-networking.lan-name" />
        </span>
        {renderLanNameSelect()}
      </div>
    );
  }

  function renderIpScopeSection() {
    if (!vlanName) {
      return null;
    }

    if (asSelector) {
      if (!existsVlans) {
        return (
          <div className={`drow f100 ${styles.ipAddress}`}>
            <span className={styles.text}>
              <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
            </span>
            <Spinner />
          </div>
        );
      }

      let vlanObj = existsVlans.find((item) => item.label === vlanName);

      if (!vlanObj) {
        return (
          <div className={`drow f100 ${styles.ipAddress}`}>
            <span className={styles.text}>
              <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
            </span>
            <Spinner />
          </div>
        );
      }

      vlanObj = vlanObj.value;
      const options = vlanObj.subnets.map((subnet) => ({
        label: `${subnet.ipAddress}/${subnet.netmask}`,
        value: subnet,
      }));

      return (
        <div className={`drow f100 ${styles.ipAddress}`}>
          <span className={styles.text}>
            <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
          </span>
          <CustomReactSelect
            options={options}
            value={ipScope}
            onChange={handleIpAdressScopeChanged}
          />
          {netmask && (
            <div className={styles.netmask}>
              {"/"}
              {netmask.label}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={`drow f100 ${styles.ipAddress}`}>
        <span className={styles.text}>
          <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
        </span>
        <CustomReactSelect
          options={ipScopeOptions}
          value={ipScope}
          onChange={(item) => setIpScope(item)}
        />
        <span className={styles.seperator}>/</span>
        <CustomReactSelect
          className={styles.netmask}
          placeholder={<FormattedMessage id="general.netmask" />}
          options={netmaskOptions}
          value={netmask}
          onChange={(item) => setNetmask(item)}
        />
      </div>
    );
  }

  function renderIpAddressSection() {
    if (hideIpAddressSelect || !vlanName || !ipScope || !netmask) {
      return null;
    }

    return (
      <>
        <div className={`drow f100 ${styles.ipAddress}`}>
          <span className={styles.text}>
            <FormattedMessage id="private-networking.ip-address" />
          </span>
          <CustomReactSelect
            placeholder={<FormattedMessage id="general.select..." />}
            options={ipAddressOptions}
            value={ipAddress}
            onChange={(item) => setIpAddress(item)}
          />
        </div>
      </>
    );
  }

  function renderGatewaySection() {
    if (
      !vlanName ||
      (!hideGatewaySelect && !hideIpAddressSelect && !ipAddress) ||
      !netmask
    ) {
      return null;
    }

    return (
      <>
        <div className="drow f100">
          <span>
            <FormattedMessage id="private-networking.gateway" />
          </span>
          {hideGatewaySelect && (
            <div>
              {currentSubnet && currentSubnet.gateway ? (
                currentSubnet.gateway
              ) : (
                <FormattedMessage id="general.none" />
              )}
            </div>
          )}
          {!hideGatewaySelect && setGateway && (
            <CustomReactSelect
              options={gatewayOptions}
              value={gateway}
              onChange={(item) => setGateway(item)}
            />
          )}
        </div>

        {overrideGateway && gateway && gateway.value && (
          <div className="warning">
            <FormattedMessage id="private-networking.gateway-override" />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.privateNetworkingWrapper}
    >
      {renderLanNameSection()}

      {renderIpScopeSection()}

      {renderIpAddressSection()}

      {renderGatewaySection()}

      {isVlanNameInUse && !asSelector && (
        <div className="warning">
          <FormattedMessage id="private-networking.vlan-name-exists" />
        </div>
      )}

      <Modal
        isOpen={isPrivateNetworkingModalOpen}
        toggle={handlePrivateNetworkingModalClosed}
      >
        <ModalBody>
          <PrivateNetworking
            location={location}
            staticLocation={location}
            setLocation={setLocation}
            vlanName={vlanName}
            setVlanName={setVlanName}
            ipScope={ipScope}
            setIpScope={setIpScope}
            ipAddress={ipAddress}
            setIpAddress={setIpAddress}
            netmask={netmask}
            setNetmask={setNetmask}
            gateway={gateway}
            setGateway={setGateway}
            forUserID={userIDToWork?.value}
            gatewayOptions={gatewayOptions}
            setGatewayOptions={setGatewayOptions}
            server={null}
            hideIpAddressSelect
          />
          <div>
            <DreamButton
              loading={loading}
              disabled={loading}
              onClick={handleCreateNewVlanClicked}
            >
              <FormattedMessage id="general.create" />
            </DreamButton>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

PrivateNetworking.propTypes = {
  vlans: PropTypes.array,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setLocation: PropTypes.func,
  vlanName: PropTypes.string,
  setVlanName: PropTypes.func,
  ipScope: PropTypes.object,
  setIpScope: PropTypes.func,
  ipAddress: PropTypes.object,
  setIpAddress: PropTypes.func,
  netmask: PropTypes.object,
  setNetmask: PropTypes.func,
  gateway: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setGateway: PropTypes.func,
  server: PropTypes.object,
  forUserID: PropTypes.string,
  gatewayOptions: PropTypes.array,
  setGatewayOptions: PropTypes.func,

  staticLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  staticVlanName: PropTypes.string,
  staticVlanID: PropTypes.string,
  asSelector: PropTypes.bool,
  hideIpAddressSelect: PropTypes.bool,
  hideGatewaySelect: PropTypes.bool,
  gatewayOption_KeepCurrentConfig: PropTypes.bool,
};

export default PrivateNetworking;
