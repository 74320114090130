import styles from "./index.module.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import { FormattedMessage } from "react-intl";
import { countries, countriesHeb } from "../../utils/countries";
import { useLang } from "../../utils/hooks";

export const COUNTRY_SELECTOR_COLOR_NORMAL = "normal";
export const COUNTRY_SELECTOR_COLOR_TRANSPARENT = "transparent";

export const countrySelectorOptions = (lang, svg = null) => {
  const countryObj = lang === "he" ? countriesHeb : countries;
  let cdnUrl = null;

  if (svg) {
    cdnUrl = "https://my.click.press/api/assets/img/round-flags/";
  }

  return Object.keys(countryObj).map((k) => {
    const label = (
      <div className={styles.item}>
        <ReactCountryFlag
          countryCode={countryObj[k]}
          svg={svg}
          cdnUrl={cdnUrl}
        />
        <span className={styles.text}>{k}</span>
      </div>
    );

    return {
      value: countryObj[k],
      label,
    };
  });
};

function CountrySelector({
  value,
  onChange,
  disabled,
  defaultCountry = null,
  countriesFilter = false,
  withAllOption = false,
  color = COUNTRY_SELECTOR_COLOR_NORMAL,
  svg = null,
}) {
  const lang = useLang();
  const onChangeRef = useRef(onChange);

  const [selected, setSelected] = useState(false);

  const selectOptions = useMemo(() => {
    let ret;

    if (Array.isArray(countriesFilter)) {
      ret = countrySelectorOptions(lang, svg).filter((o) =>
        countriesFilter.includes(o.value)
      );
    } else {
      ret = countrySelectorOptions(lang, svg);
    }

    if (withAllOption) {
      if (ret[0] && ret[0].value) {
        ret.unshift({
          value: null,
          label: (
            <div className={styles.item}>
              <span className={styles.text}>
                <FormattedMessage id="all" />
              </span>
            </div>
          ),
        });
      }
    }

    return ret;
  }, [countriesFilter, withAllOption, svg, lang]);

  useEffect(() => {
    if (defaultCountry) {
      onChangeRef.current(
        selectOptions.find((item) => item.value === defaultCountry)
      );
    }
  }, [defaultCountry, selectOptions]);

  useEffect(() => {
    let selected = false;

    if (value) {
      const options = countrySelectorOptions(lang, svg);

      selected = options.find(
        (o) => o.value === (typeof value === "string" ? value : value.value)
      );
    }

    setSelected(selected);
  }, [value, svg, lang]);

  return (
    <Select
      instanceId="country-selector"
      value={selected}
      onChange={onChange}
      placeholder={<FormattedMessage id="general.select-country" />}
      className={`${styles.countrySelectorWrapper} ${
        disabled ? styles.disabled : ""
      } ${styles[`color-${color}`]}`}
      options={selectOptions}
      tabIndex={disabled ? "-1" : null}
    />
  );
}

CountrySelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultCountry: PropTypes.string,
  withAllOption: PropTypes.bool,
  countriesFilter: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  color: PropTypes.string,
  svg: PropTypes.bool,
  helpers: PropTypes.object,
};

export default CountrySelector;
