import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Spinner from "../../spinner";
import DreamButton from "../../dream-button";
import { useAjax, useLogout } from "../../../utils/hooks";

function IntegratorTermsModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const logout = useLogout();

  const [terms, setTerms] = useState(null);
  const [isAcceptDisabled, setIsAcceptDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getTermsContent() {
      const data = await ajax("/integrator/getTermsContent", {});

      setTerms(data.terms_content);
    }

    if (isOpen) {
      getTermsContent();
    }
  }, [ajax, isOpen]);

  async function handleAcceptClicked() {
    setLoading(true);

    await ajax("/integrator/acceptTerms", {});

    setLoading(false);
    onClose();
  }

  function handleOnScroll(e) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setIsAcceptDisabled(false);
    }
  }

  async function handleLogoutClicked() {
    setLoading(true);

    logout();
  }

  return (
    <Modal className={styles.wrapper} isOpen={isOpen} size="lg">
      <ModalHeader>
        <FormattedMessage id="terms-of-service" />
      </ModalHeader>
      <ModalBody>
        {!terms && (
          <div>
            <Spinner />
          </div>
        )}
        {terms && (
          <>
            <div className={styles.scrollToBottom}>
              <FormattedMessage id="scroll-to-bottom" />
            </div>

            <div
              className={styles.termsBox}
              dangerouslySetInnerHTML={{ __html: terms }}
              onScroll={handleOnScroll}
            ></div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          <DreamButton
            loading={loading}
            disabled={isAcceptDisabled || loading}
            onClick={handleAcceptClicked}
          >
            <FormattedMessage id="accept" />
          </DreamButton>
          <DreamButton
            loading={loading}
            disabled={loading}
            onClick={handleLogoutClicked}
            color="red"
          >
            <FormattedMessage id="decline" />
          </DreamButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

IntegratorTermsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default IntegratorTermsModal;
