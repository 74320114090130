import Spinner from "../spinner";
import styles from "./index.module.scss";

import React from "react";

export default function LoadingPage() {
  return (
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  );
}
