import isValidDomain from "is-valid-domain";
import { proxmoxStringToObject } from ".";

export const normalizeLanIp = (ip, withX = false) => {
  const ipArr = ip.split(".");

  const match = ip.match(/^172\.(1[6-9]|2[0-9]|3[0-1])\./);
  if (match) {
    return withX
      ? `172.${match[1]}.x.x`
      : `172.${match[1]}.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  } else if (ip.startsWith("19")) {
    return withX
      ? "192.168.x.x"
      : `192.168.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  } else if (ip.startsWith("10")) {
    return withX
      ? "10.x.x.x"
      : `10.${ipArr[1] || "0"}.${ipArr[2] || "0"}.${ipArr[3] || "0"}`;
  }

  return false;
};

export const SubnetNetmasksTable = [
  {
    networkBits: 20,
    subnetMask: "255.255.240.0",
  },
  {
    networkBits: 21,
    subnetMask: "255.255.248.0",
  },
  {
    networkBits: 22,
    subnetMask: "255.255.252.0",
  },
  {
    networkBits: 23,
    subnetMask: "255.255.254.0",
  },
  {
    networkBits: 24,
    subnetMask: "255.255.255.0",
  },
  {
    networkBits: 25,
    subnetMask: "255.255.255.128",
  },
  {
    networkBits: 26,
    subnetMask: "255.255.255.192",
  },
  {
    networkBits: 27,
    subnetMask: "255.255.255.224",
  },
  {
    networkBits: 28,
    subnetMask: "255.255.255.240",
  },
  {
    networkBits: 29,
    subnetMask: "255.255.255.248",
  },
  {
    networkBits: 30,
    subnetMask: "255.255.255.252",
  },
];

export const SubnetNetmasksTableIPv6 = [
  {
    networkBits: 123,
    subnetMask: "-",
  },
];

export const validatePort = (port) => {
  port = parseInt(port);

  return typeof port === "number" && port >= 0 && port <= 65535;
};

export const validateIPaddress = (ipaddress) => {
  const expression =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

  return expression.test(ipaddress);
};

export const incrementIPaddress = (ipaddress) => {
  if (!validateIPaddress(ipaddress)) {
    return null;
  }

  const ipaddressArr = ipaddress.split(".");

  for (let i = 3; i >= 0; i--) {
    ipaddressArr[i] = parseInt(ipaddressArr[i]) + 1;

    if (ipaddressArr[i] <= 255) {
      return ipaddressArr.join(".");
    }

    ipaddressArr[i] = 0;
  }

  return null;
};

export const incBytesArray = (arr) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] === 0xff) {
      arr[i] = 0;
    } else {
      arr[i] += 0x01;
      return arr;
    }
  }

  return arr;
};

export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0, l = arr1.length; i < l; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arr1[i].equals(arr2[i])) return false;
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const parseNets = (data) => {
  const keys = Object.keys(data);
  const wans = {};
  const lans = {};
  const ipconfigs = {};
  let dns;
  let gateway;

  for (let i = 0; i < keys.length; i++) {
    if (keys[i].startsWith("net")) {
      if (data[keys[i]].includes("vmbr1")) {
        lans[keys[i].replace("net", "")] = data[keys[i]];
      } else {
        wans[keys[i].replace("net", "")] = data[keys[i]];
      }
    }

    if (keys[i].startsWith("ipconfig")) {
      ipconfigs[keys[i].replace("ipconfig", "")] = data[keys[i]];

      if (proxmoxStringToObject(data[keys[i]]).gw) {
        gateway = proxmoxStringToObject(data[keys[i]]).gw;
      }
    }

    if (keys[i].startsWith("nameserver")) {
      dns = data[keys[i]];
    }
  }

  return { wans, lans, ipconfigs, dns, gateway };
};

export const dataCenterOptions = [
  { label: "Israel", value: "IL" },
  { label: "Netherlands", value: "NL" },
  { label: "United States", value: "US" },
];

export const isValidSubdomain = (subdomain) => {
  const subdomainArr = subdomain.split(".");

  return (
    subdomainArr.length >= 3 && isValidDomain(subdomain, { subdomain: true })
  );
};

export const isLanIP = (ip) => {
  var components = ip.split(".");

  if (
    components[0] === "10" ||
    (components[0] === "192" && components[1] === "168") ||
    (components[0] === "172" && components[1] >= 16 && components[1] <= 31)
  ) {
    return true;
  } else {
    return false;
  }
};
