import styles from "./step3.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cardValidator from "card-validator";
import { useStripe } from "@stripe/react-stripe-js";
import CVCSvg from "../../svgs/cvc.svg?react";
import SslSecurePaymentSvg from "../../svgs/ssl-secure-payment.svg?react";
import CardsSvg from "../../svgs/cards.svg?react";
import { useDispatch } from "react-redux";
import { setFetchedInitialData } from "../../../store/settings";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { useAjax, useLang, useUser } from "../../../utils/hooks";

function CompleteRegistrationModalStep3({ onNext, location }) {
  const ajax = useAjax();
  const user = useUser();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const lang = useLang("en");
  const navigate = useNavigate();

  const [creditCard, setCreditCard] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [cvv, setCVV] = useState("");

  const [sessionId, setSessionId] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCheckoutSession = useCallback(async () => {
    const data = await ajax(`/stripe/createStripeCheckoutSession`, {
      userID: user._id,
      redirect: window.location.href,
      params: {
        location,
      },
    });

    if (data.result === "success") {
      setSessionId(data.sessionID);
    }
  }, [ajax, user._id, location]);

  useEffect(() => {
    if (user.payment_gateway === "stripe") {
      fetchCheckoutSession();
    }
  }, [user.payment_gateway, fetchCheckoutSession]);

  async function handleCheckoutRedirectClicked() {
    setLoading(true);

    if (user.payment_gateway === "stripe") {
      await stripe.redirectToCheckout({
        sessionId,
      });
    } else if (user.payment_gateway === "payplus") {
      const data = await ajax("/payplus/generatePaymentLink", {
        backToUrl: location.href,
        isPrimary: true,
      });
      location.href = data.url;
    } else if (user.payment_gateway === "smartpay") {
      const data = await ajax("/smartpay/generatePaymentLink", {
        backToUrl: location.href,
        isPrimary: true,
        lang,
      });

      const token = data.url.split("/");

      navigate(
        `/${lang}/billing/checkout/${
          token[token.length - 1]
        }?back=${encodeURIComponent(location.pathname)}`
      );
    }
  }

  useEffect(() => {
    if (user.registerStep === -1) {
      onNext();
    }
  }, [onNext, user.registerStep]);

  async function handleCompleteClicked() {
    setError(false);

    const monthYearArr = monthYear.split("/");
    const month = monthYearArr[0];
    const year = monthYearArr[1];

    const cardValidatorNumber = cardValidator.number(creditCard);
    const cardValidatorMonth = cardValidator.expirationMonth(month);
    const cardValidatorYear = cardValidator.expirationYear(year);
    const cardValidatorCVV = cardValidator.cvv(cvv);

    if (!cardValidatorNumber.isValid && creditCard.length !== 9) {
      return setError("complete-registration-modal.credit-card-number-wrong");
    } else if (!cardValidatorMonth.isValid) {
      return setError("complete-registration-modal.credit-card-month-wrong");
    } else if (!cardValidatorYear.isValid) {
      return setError("complete-registration-modal.credit-card-year-wrong");
    } else if (!cardValidatorCVV.isValid) {
      return setError("complete-registration-modal.credit-card-cvv-wrong");
    }

    setLoading(true);

    const data = await ajax(`/credit-card/updateCreditCard`, {
      creditCard,
      cvv,
      month,
      year,
    });
    setLoading(false);

    if (data.result === "success") {
      dispatch(setFetchedInitialData(false));
      navigate(`/${lang}/my-cloud/servers`);
    } else {
      setError("general.general-error");
    }
  }

  function handleMonthYearChanged(e) {
    const lengthBefore = monthYear.length;
    let newMonthYear = e.target.value;

    if (lengthBefore === 1 && newMonthYear.length === 2) {
      newMonthYear = `${newMonthYear}/`;
    }
    if (lengthBefore === 3 && newMonthYear.length === 2) {
      newMonthYear = newMonthYear.substr(0, 1);
    }

    setMonthYear(newMonthYear);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.desc}>
        <FormattedMessage id="complete-registration-modal.credit-card-desc" />
      </div>

      {user.payment_gateway === "icount" && (
        <>
          <div className={styles.fields}>
            <div className={styles.box}>
              <div className={styles.text}>
                <FormattedMessage id="general.card-number" />
              </div>
              <div>
                <CustomText
                  placeholder="xxxx xxxx xxxx xxxx"
                  value={creditCard}
                  onChange={(e) => setCreditCard(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.text}>
                <FormattedMessage id="general.valid-through" />
              </div>
              <div>
                <CustomText
                  placeholder="MM/YY"
                  maxLength={5}
                  value={monthYear}
                  onChange={handleMonthYearChanged}
                />
              </div>
            </div>
          </div>

          <div className={styles.fields}>
            <div className={`${styles.box} ${styles.boxSec}`}>
              <div className={styles.text}>
                <FormattedMessage id="general.security-code" />
              </div>
              <div>
                <CustomText
                  placeholder="CVC"
                  value={cvv}
                  onChange={(e) => setCVV(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.text}>&nbsp;</div>
              <div className={styles.cvcWrapper}>
                <CVCSvg />
                <span className={styles.whereIsCVC}>
                  <FormattedMessage id="complete-registration-modal.where-is-cvc" />
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {error && (
        <div className="error">
          <FormattedMessage id={error} />
        </div>
      )}

      <hr />

      <div className={styles.footer}>
        <div>
          <div className={styles.sslSecurePaymentSvg}>
            <SslSecurePaymentSvg />
          </div>
          <div className={styles.cards}>
            <CardsSvg />
          </div>
        </div>
        <div className={styles.completeButton}>
          {user.payment_gateway === "icount" && (
            <DreamButton
              disabled={loading}
              icon="arrow"
              color="green"
              onClick={handleCompleteClicked}
            >
              <FormattedMessage id="general.complete" />
            </DreamButton>
          )}
          {user.payment_gateway !== "icount" && (
            <DreamButton
              disabled={loading}
              color="light-purple"
              onClick={handleCheckoutRedirectClicked}
            >
              <FormattedMessage id="general.add" />
            </DreamButton>
          )}
        </div>
      </div>
    </div>
  );
}

CompleteRegistrationModalStep3.propTypes = {
  onNext: PropTypes.func,
  location: PropTypes.object,
  image: PropTypes.string,
  cpu: PropTypes.number,
  ram_mb: PropTypes.number,
  backup: PropTypes.bool,
  privateNetworking: PropTypes.object,
  paymentType: PropTypes.string,
  hostnames: PropTypes.array,
  password: PropTypes.string,
  tag: PropTypes.string,
  group: PropTypes.string,
  ipType: PropTypes.string,
  attachServerToClient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedFreeDays: PropTypes.number,
  publicNetworking: PropTypes.bool,
  promotionCode: PropTypes.string,
  securityGroup: PropTypes.bool,
};

export default CompleteRegistrationModalStep3;
