import styles from "./smartpay.module.scss";

import React from "react";
import DreamButton from "../../dream-button";
import { FormattedMessage } from "react-intl";
import {
  useAjax,
  useAlert,
  useInitialDataFetching,
} from "../../../utils/hooks";
import { useState } from "react";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";
import { getYear } from "date-fns";
import { useEffect } from "react";
import { detectCardType } from "../../../utils/credit-cards";
import { getCreditCardSvg } from "../../../utils/billing";

const expirationMonthOptions = [];
for (let i = 1; i <= 12; i++) {
  expirationMonthOptions.push({ value: i, label: i });
}

const expirationYearOptions = [];
const year = getYear(new Date());
for (let i = year; i <= year + 15; i++) {
  expirationYearOptions.push({ value: i, label: i.toString().slice(-2) });
}

export default function UpdateCreditCardQuickSmartpay({
  userID,
  loading,
  setLoading,
  isOpen,
  onClose,
}) {
  const ajax = useAjax();
  const alert = useAlert();
  const fetchInitialData = useInitialDataFetching();

  const [cardNumber, setCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState(null);
  const [expirationYear, setExpirationYear] = useState(null);

  const [CreditCardSvg, setCreditCardSvg] = useState(null);

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setCardNumber("");
    setExpirationMonth(null);
    setExpirationYear(null);
    setError(false);
  }, [isOpen]);

  useEffect(() => {
    const type = detectCardType(cardNumber);
    if (!type) {
      setCreditCardSvg(null);
      return;
    }

    const svg = getCreditCardSvg(type || "");

    setCreditCardSvg(svg);
  }, [cardNumber]);

  async function handleAddClicked() {
    setError(false);

    const _cardNumber = cardNumber.replace(/\s/g, "");

    if (!CreditCardSvg) {
      return setError(<FormattedMessage id="wrong-card-number" />);
    }
    if (!expirationMonth || !expirationYear) {
      return setError(<FormattedMessage id="expiration-date-required" />);
    }

    setLoading(true);
    const data = await ajax("/smartpay/addCreditCard", {
      userID,
      cardNumber: _cardNumber,
      expirationMonth: expirationMonth.value,
      expirationYear: expirationYear.value,
    });

    if (data.result === "error") {
      setLoading(false);
      return alert({
        title: <FormattedMessage id="error" />,
        message: <FormattedMessage id="error-while-adding-card" />,
      });
    }

    await fetchInitialData();
    onClose(true);
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <span className={styles.text}>
          <FormattedMessage id="card-number" />
        </span>
        <div className={styles.cardNumber}>
          <CustomText
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
          {CreditCardSvg}
        </div>
      </div>
      <div className={styles.monthYear}>
        <div>
          <span className={styles.text}>
            <FormattedMessage id="month" />
          </span>
          <CustomReactSelect
            options={expirationMonthOptions}
            value={expirationMonth}
            onChange={(item) => setExpirationMonth(item)}
          />
        </div>
        <div>
          <span className={styles.text}>
            <FormattedMessage id="year" />
          </span>
          <CustomReactSelect
            options={expirationYearOptions}
            value={expirationYear}
            onChange={(item) => setExpirationYear(item)}
          />
        </div>
      </div>
      <div>
        {error && <div className="error">{error}</div>}

        <DreamButton
          loading={loading}
          disabled={loading}
          onClick={handleAddClicked}
        >
          <FormattedMessage id="add" />
        </DreamButton>
      </div>
    </div>
  );
}
