import styles from "./index.module.scss";

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopySvg from "../../svgs/copy.svg?react";
import CheckSvg from "../../svgs/check.svg?react";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";

function BackupLinkGeneratedModal({ isOpen, onClose, url }) {
  const urlRef = useRef();

  const [copied, setCopied] = useState(false);

  function handleCopyToClipboardClicked() {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="backup-link-generated-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.urlBox}>
          <span className={styles.url} ref={urlRef}>
            {url}
          </span>

          {!copied && (
            <CopyToClipboard text={url} onCopy={handleCopyToClipboardClicked}>
              <CopySvg />
            </CopyToClipboard>
          )}

          {copied && <CheckSvg />}
        </div>

        <div className={styles.note}>
          <FormattedMessage id="backup-link-generated-modal.note" />
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="purple" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

BackupLinkGeneratedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
};

export default BackupLinkGeneratedModal;
