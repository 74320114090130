import styles from "./overview.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import TrashSvg from "../../../../components/svgs/trash.svg?react";
import { useAjax, useConfirm, useLang } from "../../../../utils/hooks";
import { renderSchedulerAction } from "../../../../utils/tasks";
import ScheduleNewTaskModal from "../../../../components/modals/schedule-new-task";
import HelpSvg from "../../../../components/svgs/help.svg?react";
import { Tooltip } from "reactstrap";
import { FormattedMessage } from "react-intl";
import ClickTable from "../../../../components/click-table";
import DreamButton from "../../../../components/dream-button";
import { useOutletContext } from "react-router-dom";

export default function ScheduledTasks() {
  const lang = useLang("en");
  const ajax = useAjax();
  const confirm = useConfirm();
  const { server } = useOutletContext();

  const [tasks, setTasks] = useState([]);
  const [isScheduleNewTaskModalOpen, setIsScheduleNewTaskModalOpen] =
    useState(false);

  const [isScheduleTasksTooltipOpen, setIsScheduleTasksTooltipOpen] =
    useState(false);

  const getScheduledTasks = useCallback(async () => {
    const data = await ajax(`/scheduler/getScheduledTasks`, {
      serverID: server._id,
    });

    setTasks(data.tasks);
  }, [ajax, server]);

  useEffect(() => {
    getScheduledTasks();
  }, [getScheduledTasks]);

  function handleScheduleTaskClicked() {
    setIsScheduleNewTaskModalOpen(true);
  }

  async function handleScheduleNewTaskModalClosed(status) {
    if (status) {
      await getScheduledTasks();
    }

    setIsScheduleNewTaskModalOpen(false);
  }

  function handleRemoveSnapshotClicked(task) {
    confirm({
      title: <FormattedMessage id="server-tasks.remove-scheduled-task.title" />,
      message: (
        <FormattedMessage id="server-tasks.remove-scheduled-task.content" />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/scheduler/removeScheduledTask", {
          serverID: server._id,
          taskID: task._id,
        });
        await getScheduledTasks();
      },
    });
  }

  return (
    <>
      <div className={styles.title}>
        <div className={`${styles.bold} ${styles.smallHeader}`}>
          <FormattedMessage id="overview.schedule-tasks" />
          <div id="tooltip-schedule-tasks">
            <div>
              <HelpSvg />
            </div>
          </div>
          <Tooltip
            placement="bottom"
            isOpen={isScheduleTasksTooltipOpen}
            target="tooltip-schedule-tasks"
            toggle={() =>
              setIsScheduleTasksTooltipOpen(!isScheduleTasksTooltipOpen)
            }
          >
            <FormattedMessage id="overview.schedule-tasks.tooltip" />
          </Tooltip>
        </div>
        <div className={styles.link}>
          <Link
            href="/[lang]/my-cloud/servers/[id]/tasks"
            to={`/${lang}/my-cloud/servers/${server._id}/tasks`}
          >
            <FormattedMessage id="overview.view-all" />
          </Link>
        </div>
      </div>
      <div className={styles.table}>
        <ClickTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="overview.action" />
              </th>
              <th>
                <FormattedMessage id="overview.frequency" />
              </th>
              <th>
                <FormattedMessage id="overview.next-run" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <FormattedMessage id="overview.no-tasks" />
                </td>
              </tr>
            )}

            {tasks.length > 0 &&
              tasks.map((task, key) => (
                <tr key={key}>
                  <td>{renderSchedulerAction(task.action)}</td>
                  <td className="capitalize">{task.frequency}</td>
                  <td>{format(new Date(task.next_run), "d/M/y HH:mm")}</td>
                  <td>
                    <TrashSvg
                      className="pointer"
                      onClick={() => handleRemoveSnapshotClicked(task)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </ClickTable>
      </div>
      {server.status === "Active" && (
        <div className={styles.button}>
          <DreamButton
            disabled={server.isWorking}
            color="light-purple"
            onClick={handleScheduleTaskClicked}
          >
            <FormattedMessage id="general.add" />
          </DreamButton>
        </div>
      )}

      <ScheduleNewTaskModal
        isOpen={isScheduleNewTaskModalOpen}
        onClose={handleScheduleNewTaskModalClosed}
        server={server}
      />
    </>
  );
}
