import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import ClickTable from "../../click-table";
import DreamButton from "../../dream-button";

function ServerIpsModal({ hostname, ips, isOpen, onClose }) {
  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="server-ips-modal.title" values={{ hostname }} />
      </ModalHeader>
      <ModalBody>
        <ClickTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="ip" />
              </th>
              <th>
                <FormattedMessage id="netmask" />
              </th>
              <th>
                <FormattedMessage id="gateway" />
              </th>
              <th>
                <FormattedMessage id="type" />
              </th>
              <th>
                <FormattedMessage id="vlan-name" />
              </th>
            </tr>
          </thead>
          <tbody>
            {ips.map((ip, key) => (
              <tr key={key}>
                <td>{ip.ip}</td>
                <td>{ip.netmask}</td>
                <td>{ip.gateway}</td>
                <td>{ip.type}</td>
                <td>{ip.vlanName}</td>
              </tr>
            ))}
          </tbody>
        </ClickTable>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

ServerIpsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  hostname: PropTypes.string,
  ips: PropTypes.array,
};

export default ServerIpsModal;
