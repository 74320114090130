import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../../custom-react-select";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";
import Radio from "../../radio";
import Checkbox from "../../checkbox";

function EditIpAddressModal({
  vlan,
  subnet,
  ipObject,
  isOpen,
  onClose,
  userIDToWork,
  type,
}) {
  const ajax = useAjax();

  const [serverID, setServerID] = useState(null);
  const [reserveName, setReserveName] = useState("");
  const [networkCards, setNetworkCards] = useState(null);
  const [removeOtherNetworkCards, setRemoveOtherNetworkCards] = useState(false);
  const [action, setAction] = useState("do-not-assign-to-vm");
  const [domains, setDomains] = useState([]);

  const [loading, setLoading] = useState(false);

  const getAllDomains = useCallback(async () => {
    const data = await ajax("/servers/getAllDomainsUserToWork", {
      userIDToWork: userIDToWork?.value,
      onlyActive: true,
      dataCenter: vlan.location,
    });

    if (data.result === "success") {
      const domains = data.domains.map((domain) => {
        const domainWithSameName = data.domains.find(
          (dmn) =>
            dmn.hostname === domain.hostname &&
            dmn._id.toString() !== domain._id.toString()
        );

        return {
          label: `${domain.hostname} ${
            domainWithSameName ? `[${domain._id}]` : ""
          }`,
          value: domain._id,
        };
      });

      if (type === "wan") {
        domains.unshift({
          label: <FormattedMessage id="edit-ip-address-modal.reserve" />,
          value: -2,
        });
      }

      setDomains(domains);
    }
  }, [ajax, userIDToWork?.value, type, vlan]);

  const getNetworkCards = useCallback(async () => {
    const data = await ajax("/network/getNetworkCardsOfServer", {
      serverID: serverID.value,
      type,
    });

    setNetworkCards(data.cards);
  }, [ajax, serverID, type]);

  useEffect(() => {
    if (isOpen) {
      setServerID(null);
      setReserveName("");
      setNetworkCards(null);
      setAction("do-not-assign-to-vm");
      setRemoveOtherNetworkCards(false);
      setDomains([]);

      setLoading(false);

      getAllDomains();
    }
  }, [isOpen, getAllDomains]);

  useEffect(() => {
    if (serverID) {
      getNetworkCards();
    }
  }, [serverID, getNetworkCards]);

  useEffect(() => {
    if (!ipObject || !domains) {
      return;
    }

    if (ipObject.reserve_name) {
      setServerID(domains[0]);
      setReserveName(ipObject.reserve_name);
    } else {
      const domain = domains.find((d) => d.value === ipObject.server_id);

      if (domain) {
        setServerID(domain);
      }
    }
  }, [domains, ipObject]);

  async function handleUpdateClicked() {
    if (!serverID) {
      return;
    }

    setLoading(true);
    await ajax("/network/attachIpToServer", {
      action,
      removeOtherNetworkCards,
      ip: ipObject.ip,
      serverID: serverID.value,
      reserveName: serverID.value === -2 ? reserveName : null,
      subnetID: subnet._id,
      type,
      userIDToWork: userIDToWork?.value,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="edit-ip-address-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <label>
            <span>
              <FormattedMessage id="edit-ip-address-modal.ip-address" />
            </span>
            {ipObject?.ip}
          </label>
        </div>
        <div className={styles.row}>
          <label>
            <span>
              <FormattedMessage id="edit-ip-address-modal.server" />
            </span>
            <CustomReactSelect
              value={serverID}
              onChange={(item) => setServerID(item)}
              options={domains}
            />
          </label>
        </div>
        {serverID?.value === -2 && (
          <div className={styles.row}>
            <label>
              <span>
                <FormattedMessage id="edit-ip-address-modal.reserve-name" />
              </span>
              <CustomText
                value={reserveName}
                onChange={(e) => setReserveName(e.target.value)}
              />
            </label>
          </div>
        )}

        {networkCards && (
          <div className={styles.radios}>
            <Radio
              label="do-not-assign-to-vm"
              checked={action === "do-not-assign-to-vm"}
              onChange={() => setAction("do-not-assign-to-vm")}
            />
            {networkCards.map((card, key) => (
              <Radio
                key={key}
                label={
                  <FormattedMessage
                    id="assign-to-card"
                    values={{
                      netNum: card.netNum,
                      macAddress: card.macAddress,
                    }}
                  />
                }
                checked={action === `assign-to-card-${card.netNum}`}
                onChange={() => setAction(`assign-to-card-${card.netNum}`)}
              />
            ))}
            <Radio
              label="assign-to-new-network-card"
              checked={action === "assign-to-new-network-card"}
              onChange={() => setAction("assign-to-new-network-card")}
            />
            {action === "assign-to-new-network-card" &&
              networkCards.length > 0 && (
                <Checkbox
                  label="remove-other-network-cards"
                  checked={removeOtherNetworkCards}
                  onChange={(e) => setRemoveOtherNetworkCards(e.target.checked)}
                />
              )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          loading={loading}
          disabled={loading || (!networkCards && !reserveName)}
          color="purple"
          onClick={handleUpdateClicked}
        >
          <FormattedMessage id="general.update" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

EditIpAddressModal.propTypes = {
  vlan: PropTypes.object,
  subnet: PropTypes.object,
  ipObject: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userIDToWork: PropTypes.object,
  type: PropTypes.oneOf(["lan", "wan"]),
};

export default EditIpAddressModal;
