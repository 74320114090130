import styles from "./snapshots.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { format, fromUnixTime } from "date-fns";
import TakeSnapshotModal from "../../../../components/modals/take-snapshot";
import EditSnapshotModal from "../../../../components/modals/edit-snapshot";
import RollbackSnapshotModal from "../../../../components/modals/rollback-snapshot";
import CreateServerFromSnapshotModal from "../../../../components/modals/create-server-from-snapshot";
import ScheduledRemoveSnapshotModal from "../../../../components/modals/scheduled-remove-snapshot";
import { useAjax, useConfirm, useRoles } from "../../../../utils/hooks";
import { getSocket } from "../../../../utils/globals";
import { FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import ClickTable from "../../../../components/click-table";
import Spinner from "../../../../components/spinner";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import DreamButton from "../../../../components/dream-button";
import { useOutletContext } from "react-router-dom";

export default function ServerSnapshots() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const { isAllowed } = useRoles();
  const socket = getSocket();
  const { server } = useOutletContext();

  const [snapshots, setSnapshots] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [isTakeSnapshotModalOpen, setIsTakeSnapshotModalOpen] = useState(false);
  const [isEditSnapshotModalOpen, setIsEditSnapshotModalOpen] = useState(false);
  const [isRollbackSnapshotModalOpen, setIsRollbackSnapshotModalOpen] =
    useState(false);
  const [
    isCreateServerFromSnapshotModalOpen,
    setIsCreateServerFromSnapshotModalOpen,
  ] = useState(false);
  const [
    isScheduledRemoveSnapshotModalOpen,
    setIsScheduledRemoveSnapshotModalOpen,
  ] = useState(false);

  const listSnapshots = useCallback(async () => {
    const data = await ajax("/snapshots/list", { serverID: server._id });

    if (data.result === "success") {
      data.data.sort((a, b) => {
        if (a.snaptime === b.snaptime) {
          return 0;
        }
        return a.snaptime > b.snaptime ? 1 : -1;
      });

      setSnapshots(data.data);
    }
  }, [ajax, server]);

  const updateSnapshots = useCallback(() => {
    listSnapshots();
  }, [listSnapshots]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    listSnapshots();

    socket.on("update-snapshots", updateSnapshots);

    return () => {
      socket.off("update-snapshots", updateSnapshots);
    };
  }, [listSnapshots, socket, updateSnapshots]);

  function handleTakeClicked() {
    setIsTakeSnapshotModalOpen(true);
  }

  function handleTakeSnapshotModalClosed() {
    setIsTakeSnapshotModalOpen(false);
  }

  function handleRemoveAllClicked() {
    confirm({
      title: (
        <FormattedMessage id="server-snapshots.remove-all-snapshots.title" />
      ),
      message: (
        <FormattedMessage id="server-snapshots.remove-all-snapshots.content" />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/snapshots/removeAll", { serverID: server._id });
      },
    });
  }

  function handleEditClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsEditSnapshotModalOpen(true);
  }

  async function handleEditSnapshotModalClosed(state) {
    if (state) {
      await listSnapshots();
    }

    setIsEditSnapshotModalOpen(false);
  }

  function handleRollbackClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsRollbackSnapshotModalOpen(true);
  }

  function handleRollbackSnapshotModalClosed() {
    setIsRollbackSnapshotModalOpen(false);
  }

  function handleCreateServerFromSnapshotClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsCreateServerFromSnapshotModalOpen(true);
  }

  function handleCreateServerFromSnapshotModalClosed() {
    setIsCreateServerFromSnapshotModalOpen(false);
  }

  function handleSnapshotDropdownToggle(snapshot) {
    snapshot.isDropdownOpen = !snapshot.isDropdownOpen;
    setSnapshots([...snapshots]);
  }

  function handleScheduledRemoveSnapshotClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsScheduledRemoveSnapshotModalOpen(true);
  }

  function handleScheduledRemoveSnapshotModalClosed() {
    setIsScheduledRemoveSnapshotModalOpen(false);
  }

  function handleRemoveSnapshotClicked(snapshot) {
    confirm({
      title: <FormattedMessage id="server-snapshots.remove" />,
      message: (
        <FormattedMessage
          id="server-snapshots.remove-content"
          values={{ name: snapshot.name }}
        />
      ),
      beforeClose: async (state) => {
        if (state === "button2") {
          await ajax("/snapshots/remove", {
            serverID: server._id,
            name: snapshot.name,
          });
          await listSnapshots();
        }
      },
    });
  }

  return (
    <>
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="server-snapshots.description" />
      </Box>

      <Box className={styles.wrapper}>
        <div className={styles.table}>
          <ClickTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-snapshots.name" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.date" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.ram" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.table-description" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!snapshots && (
                <tr>
                  <td colSpan={5}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {snapshots?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {snapshots?.map((snapshot, idx) => (
                <tr key={idx}>
                  <td>{snapshot.name}</td>
                  <td>
                    {format(
                      fromUnixTime(snapshot.snaptime),
                      "dd/MM/yyyy HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {snapshot.vmstate ? (
                      <FormattedMessage id="server-snapshots.yes" />
                    ) : (
                      <FormattedMessage id="server-snapshots.no" />
                    )}
                  </td>
                  <td>{snapshot.description}</td>
                  <td>
                    <CustomMenu
                      isOpen={snapshot.isDropdownOpen}
                      toggle={() => handleSnapshotDropdownToggle(snapshot)}
                    >
                      {isAllowed("servers") && (
                        <CustomMenuItem
                          onClick={() => handleRollbackClicked(snapshot)}
                        >
                          <FormattedMessage id="server-snapshots.rollback" />
                        </CustomMenuItem>
                      )}

                      {isAllowed("servers") && (
                        <>
                          <CustomMenuItem
                            onClick={() =>
                              handleCreateServerFromSnapshotClicked(snapshot)
                            }
                          >
                            <FormattedMessage id="server-snapshots.create-server" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            onClick={() => handleEditClicked(snapshot)}
                          >
                            <FormattedMessage id="server-snapshots.edit" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            onClick={() =>
                              handleScheduledRemoveSnapshotClicked(snapshot)
                            }
                          >
                            <FormattedMessage id="server-snapshots.scheduled-remove" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            onClick={() =>
                              handleRemoveSnapshotClicked(snapshot)
                            }
                          >
                            <FormattedMessage id="server-snapshots.remove" />
                          </CustomMenuItem>
                        </>
                      )}
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </ClickTable>
        </div>
        {isAllowed("servers") && (
          <>
            <br />

            <div className={styles.buttonWrapper}>
              <DreamButton
                disabled={server?.isWorking}
                color="light-purple"
                onClick={handleTakeClicked}
              >
                <FormattedMessage id="server-snapshots.take-snapshot" />
              </DreamButton>
              <DreamButton
                disabled={server?.isWorking}
                color="light-purple"
                onClick={handleRemoveAllClicked}
              >
                <FormattedMessage id="server-snapshots.remove-all-snapshots" />
              </DreamButton>
            </div>
          </>
        )}
      </Box>

      <TakeSnapshotModal
        isOpen={isTakeSnapshotModalOpen}
        onClose={handleTakeSnapshotModalClosed}
        server={server}
      />

      <EditSnapshotModal
        isOpen={isEditSnapshotModalOpen}
        onClose={handleEditSnapshotModalClosed}
        server={server}
        snapshot={selectedSnapshot}
      />

      <RollbackSnapshotModal
        isOpen={isRollbackSnapshotModalOpen}
        onClose={handleRollbackSnapshotModalClosed}
        server={server}
        snapshot={selectedSnapshot}
      />

      <CreateServerFromSnapshotModal
        isOpen={isCreateServerFromSnapshotModalOpen}
        onClose={handleCreateServerFromSnapshotModalClosed}
        server={server}
        snapshot={selectedSnapshot}
      />

      <ScheduledRemoveSnapshotModal
        isOpen={isScheduledRemoveSnapshotModalOpen}
        onClose={handleScheduledRemoveSnapshotModalClosed}
        server={server}
        snapshot={selectedSnapshot}
      />
    </>
  );
}
