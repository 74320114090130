import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PrivateNetworking from "../../create-new-server/private-networking";
import { FormattedMessage } from "react-intl";
import DreamButton from "../../dream-button";
import { useAjax, useAlert } from "../../../utils/hooks";

function AddIpAddressScopeModal({ vlan, userIDToWork, isOpen, onClose }) {
  const ajax = useAjax();
  const alert = useAlert();

  const [gatewayOptions, setGatewayOptions] = useState(null);

  const [location, setLocation] = useState(null);

  const [vlanName, setVlanName] = useState(null);
  const [ipScope, setIpScope] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);

  const [loading, setLoading] = useState(false);

  async function handleAddClicked() {
    setLoading(true);
    const data = await ajax("/network/addPrivateNetworkCardForUser", {
      userIDToWork: userIDToWork?.value,
      location: vlan.location,
      vlanName,
      ip: ipScope.value,
      netmask: netmask.value,
      gateway: gateway.value,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      alert({
        title: (
          <FormattedMessage id="modals.add-ip-address-scope.errors.add.title" />
        ),
        message: (
          <FormattedMessage
            id={`modals.add-ip-address-scope.errors.${data.message}`}
            values={{ number: data.number }}
          />
        ),
      });
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)} size="lg">
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="general.ip-address-scope" />
      </ModalHeader>
      <ModalBody>
        <PrivateNetworking
          location={location}
          setLocation={setLocation}
          vlanName={vlanName}
          setVlanName={setVlanName}
          ipScope={ipScope}
          setIpScope={setIpScope}
          ipAddress={ipAddress}
          setIpAddress={setIpAddress}
          netmask={netmask}
          setNetmask={setNetmask}
          gateway={gateway}
          setGateway={setGateway}
          server={null}
          forUserID={userIDToWork?.value}
          gatewayOptions={gatewayOptions}
          setGatewayOptions={setGatewayOptions}
          staticLocation={vlan?.location}
          staticVlanName={vlan?.vlanName}
          staticVlanID={vlan?._id}
          hideIpAddressSelect
        />
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading || !vlanName || !netmask || !gateway}
          color="purple"
          onClick={handleAddClicked}
        >
          <FormattedMessage id="general.add" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

AddIpAddressScopeModal.propTypes = {
  vlan: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  userIDToWork: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddIpAddressScopeModal;
