import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../checkbox";
import DreamButton from "../../dream-button";

function RollbackSnapshotModal({ server, snapshot, isOpen, onClose }) {
  const ajax = useAjax();

  const [turnOnAfter, setTurnOnAfter] = useState(false);
  const [removeAfter, setRemoveAfter] = useState(false);

  async function handleRollbackClicked() {
    await ajax("/snapshots/rollback", {
      serverID: server._id,
      name: snapshot.name,
      turnOnAfter,
      removeAfter,
    });

    onClose(true);
  }

  if (!snapshot) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="rollback-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.title}>{snapshot.name}</div>
        <div className={styles.description}>
          <FormattedMessage id="rollback-snapshot-modal.description" />
        </div>
        <div className={`${styles.checkbox} ${styles.first}`}>
          <Checkbox
            label="rollback-snapshot-modal.turn-on-after"
            checked={turnOnAfter}
            onChange={() => setTurnOnAfter(!turnOnAfter)}
          />
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            label="rollback-snapshot-modal.remove-snapshot-after"
            checked={removeAfter}
            onChange={() => setRemoveAfter(!removeAfter)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="purple" onClick={handleRollbackClicked}>
          <FormattedMessage id="general.rollback" />
        </DreamButton>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

RollbackSnapshotModal.propTypes = {
  snapshot: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RollbackSnapshotModal;
