import styles from "./manage-credit-card.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import cardValidator from "card-validator";
import { Link } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import TrashSvg from "../../components/svgs/trash-purple.svg?react";
import CVCSvg from "../../components/svgs/cvc.svg?react";
import CardsSvg from "../../components/svgs/cards-lighter.svg?react";
import { WithRole } from "../../components/with-role";
import { FormattedMessage } from "react-intl";
import Box from "../../components/box";
import DreamButton from "../../components/dream-button";
import CustomText from "../../components/custom-text";
import Checkbox from "../../components/checkbox";
import { useAjax, useConfirm, useLang, useUser } from "../../utils/hooks";
import { getCreditCardSvg, isracardValidation } from "../../utils/billing";
import UpdateCreditCardQuickModal from "../../components/modals/update-credit-card-quick";

export default function BillingManageCreditCard() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const stripe = useStripe();
  const user = useUser();
  const lang = useLang("en");

  const [creditCard, setCreditCard] = useState("");
  const [validThrough, setValidThrough] = useState("");
  const [cvv, setCVV] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);

  const [creditCards, setCreditCards] = useState([]);
  const [cardType, setCardType] = useState(false);

  const [sessionId, setSessionId] = useState(false);

  const [
    isUpdateCreditCardQuickModalOpen,
    setIsUpdateCreditCardQuickModalOpen,
  ] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCheckoutSession = useCallback(async () => {
    const data = await ajax(`/stripe/createStripeCheckoutSession`, {
      userID: user._id,
      redirect: window.location.href,
      params: {},
    });

    if (data.result === "success") {
      setSessionId(data.sessionID);
    }
  }, [ajax, user._id]);

  useEffect(() => {
    if (user.payment_gateway === "stripe") {
      fetchCheckoutSession();
    }
  }, [user.payment_gateway, fetchCheckoutSession]);

  async function handleCheckoutRedirectClicked() {
    setLoading(true);

    if (user.payment_gateway === "stripe") {
      await stripe.redirectToCheckout({
        sessionId,
      });
    } else if (user.payment_gateway === "payplus") {
      const data = await ajax("/payplus/generatePaymentLink", {
        backToUrl: location.href,
        isPrimary: true,
      });
      location.href = data.url;
    }
  }

  const getCreditCards = useCallback(async () => {
    const data = await ajax("/credit-card/getCreditCards", {
      userID: user._id,
    });

    setCreditCards(data.creditCards);

    if (data.creditCards?.length === 0) {
      setIsPrimary(true);
    }
  }, [ajax, user]);

  useEffect(() => {
    getCreditCards();
  }, [getCreditCards]);

  useEffect(() => {
    if (isracardValidation(creditCard)) {
      return setCardType("isracard");
    }

    const validator = cardValidator.number(creditCard);

    setCardType(validator.isValid ? validator.card.type : false);
  }, [creditCard]);

  function handleValidThroughChanged(e) {
    const lengthBefore = validThrough.length;
    let newMonthYear = e.target.value;

    if (lengthBefore === 1 && newMonthYear.length === 2) {
      newMonthYear = `${newMonthYear}/`;
    }
    if (lengthBefore === 3 && newMonthYear.length === 2) {
      newMonthYear = newMonthYear.substr(0, 1);
    }

    setValidThrough(newMonthYear);
  }

  async function handleAddCreditCardClicked() {
    if (user.payment_gateway === "smartpay") {
      setIsUpdateCreditCardQuickModalOpen(true);
      return;
    }

    const validThroughArr = validThrough.split("/");
    const month = validThroughArr[0];
    const year = validThroughArr[1];

    setError(false);

    if (!cardType) {
      return setError(
        <FormattedMessage id="billing-manage-credit-card.wrong-credit-card" />
      );
    }

    const cardValidatorMonth = cardValidator.expirationMonth(month);
    const cardValidatorYear = cardValidator.expirationYear(year);
    const cardValidatorCVV = cardValidator.cvv(cvv);

    if (!cardValidatorMonth.isValid) {
      return setError(
        <FormattedMessage id="billing-manage-credit-card.wrong-month" />
      );
    } else if (!cardValidatorYear.isValid) {
      return setError(
        <FormattedMessage id="billing-manage-credit-card.wrong-year" />
      );
    } else if (!cardValidatorCVV.isValid) {
      return setError(
        <FormattedMessage id="billing-manage-credit-card.wrong-cvv" />
      );
    }

    setLoading(true);

    const data = await ajax("/credit-card/updateCreditCard", {
      cardType,
      isPrimary,
      creditCard,
      cvv,
      month,
      year,
    });
    setLoading(false);

    if (data.result === "success") {
      setCreditCard("");
      setValidThrough("");
      setCVV("");
      setIsPrimary(false);
      setCardType(false);

      await getCreditCards();
    } else {
      setError(<FormattedMessage id="general.general-error" />);
    }
  }

  async function handleUpdateCreditCardQuickModalClosed(state) {
    if (state) {
      await getCreditCards();
    }

    setIsUpdateCreditCardQuickModalOpen(false);
  }

  async function handleSetAsPrimaryCreditCardClicked(card) {
    await ajax("/credit-card/setAsPrimary", {
      creditCardId: card._id,
    });

    await getCreditCards();
  }

  function handleRemoveCreditCardClicked(card) {
    confirm({
      title: (
        <FormattedMessage id="billing-manage-credit-card.remove-card-title" />
      ),
      message: (
        <FormattedMessage id="billing-manage-credit-card.remove-card-content" />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/credit-card/removeCreditCard", {
          creditCardId: card._id,
        });

        await getCreditCards();
      },
    });
  }

  if (!user.current_parent && user.registerStep !== -1) {
    return (
      <Box>
        <Link href="/[lang]/my-cloud" to={`/${lang}/my-cloud`}>
          <FormattedMessage id="general.complete-registration-first" />
        </Link>
      </Box>
    );
  }

  return (
    <WithRole permission="billing">
      <div className={styles.wrapper}>
        <h1 className={styles.mainTitle}>
          <FormattedMessage id="billing-manage-credit-card.main-title" />
        </h1>

        <hr />

        {creditCards?.length === 0 && (
          <div className={styles.noCards}>
            <FormattedMessage id="billing-manage-credit-card.no-cards" />
          </div>
        )}

        {creditCards?.map((card, key) => (
          <div key={key} className={styles.creditCardWrapper}>
            <div className={styles.cardSvg}>
              {getCreditCardSvg(card.cardType)}
            </div>
            <div className={`${styles.text} ${styles.numbers}`}>
              ************{card.last4Digits}
            </div>
            <div
              className={`${styles.text} ${styles.date} ${
                card.primary ? "" : styles.notPrimary
              }`}
            >
              {card.cardMonth}/{card.cardYear}
            </div>
            <div
              className={`${styles.primaryWrapper} ${
                card.primary ? "" : styles.notPrimary
              }`}
            >
              {card.primary && (
                <div className={styles.primary}>
                  <FormattedMessage id="billing-manage-credit-card.primary" />
                </div>
              )}
              {!card.primary && (
                <DreamButton
                  color="light-purple"
                  onClick={() => handleSetAsPrimaryCreditCardClicked(card)}
                >
                  <FormattedMessage id="billing-manage-credit-card.set-as-primary-button" />
                </DreamButton>
              )}
            </div>
            <div
              className={`${styles.actions} ${
                card.primary ? styles.disabled : ""
              }`}
            >
              <TrashSvg onClick={() => handleRemoveCreditCardClicked(card)} />
            </div>
          </div>
        ))}

        <div className={styles.addNewCreditCardWrapper}>
          <div className={styles.title}>
            <FormattedMessage id="billing-manage-credit-card.add-new-credit-card" />
          </div>

          {user.payment_gateway === "icount" && (
            <>
              <div className={styles.inputs}>
                <div className={styles.input}>
                  <span>
                    <FormattedMessage id="billing-manage-credit-card.card-number" />
                  </span>
                  <CustomText
                    value={creditCard}
                    onChange={(e) => setCreditCard(e.target.value)}
                    placeholder="xxxx xxxx xxxx xxxx"
                  />
                  {cardType && getCreditCardSvg(cardType)}
                </div>
                <div className={`${styles.input} ${styles.short}`}>
                  <span>
                    <FormattedMessage id="billing-manage-credit-card.valid-through" />
                  </span>
                  <CustomText
                    maxLength={5}
                    value={validThrough}
                    onChange={handleValidThroughChanged}
                    placeholder="MM/YY"
                  />
                </div>
                <div className={`${styles.input} ${styles.short}`}>
                  <span>
                    <FormattedMessage id="billing-manage-credit-card.security-code" />
                  </span>
                  <CustomText
                    value={cvv}
                    onChange={(e) => setCVV(e.target.value)}
                    placeholder="CVC"
                  />
                </div>
                <div className={styles.cvcWrapper}>
                  <CVCSvg />
                  <span>
                    <FormattedMessage id="billing-manage-credit-card.cvc-digits" />
                  </span>
                </div>
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  disabled={creditCards?.length === 0}
                  label="billing-manage-credit-card.set-as-primary"
                  checked={isPrimary}
                  onChange={(e) => setIsPrimary(e.target.checked)}
                />
              </div>
              <div className={styles.hrWrapper}>
                <hr />
              </div>
            </>
          )}

          <div className={styles.buttonWrapper}>
            <DreamButton
              disabled={loading}
              color="light-purple"
              onClick={
                ["icount", "smartpay"].includes(user.payment_gateway)
                  ? handleAddCreditCardClicked
                  : handleCheckoutRedirectClicked
              }
            >
              <FormattedMessage id="billing-manage-credit-card.add-new-card" />
            </DreamButton>

            <div>
              <FormattedMessage
                id="billing-manage-credit-card.accepted-cards"
                tagName="span"
              />
              <CardsSvg />
            </div>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </div>

      <UpdateCreditCardQuickModal
        isOpen={isUpdateCreditCardQuickModalOpen}
        onClose={handleUpdateCreditCardQuickModalClosed}
        user={user}
      />
    </WithRole>
  );
}
