import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithRole } from "../../components/with-role";
import LayoutMainAction from "../../components/layout-main-action";
import ServerSelectorModal from "../../components/modals/server-selector";
import { getSocket } from "../../utils/globals";
import { useSelector } from "react-redux";
import isValidDomain from "is-valid-domain";
import { FormattedMessage } from "react-intl";
import Box from "../../components/box";
import ClickTable from "../../components/click-table";
import Spinner from "../../components/spinner";
import CustomMenu from "../../components/custom-menu";
import CustomMenuItem from "../../components/custom-menu/item";
import { useAjax, useConfirm, usePrompt } from "../../utils/hooks";

const socket = getSocket();

const userIDToWork = null;

export default function MyCloudTemplates() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const { tasks } = useSelector((state) => ({
    tasks: state.tasks,
  }));

  // const [userIDToWork, setUserIDToWork] = useState(null);

  const [templates, setTemplates] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);

  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState({});
  const [isServerSelectorModalOpen, setIsServerSelectorModalOpen] =
    useState(false);

  const getTemplates = useCallback(async () => {
    const data = await ajax("/users/getTemplates");

    if (data.result === "success") {
      setTemplates(data.templates);
    }
  }, [ajax]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const handleSocketUpdated = useCallback(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("template-created", handleSocketUpdated);

    return () => {
      socket.off("template-created", handleSocketUpdated);
    };
  }, [handleSocketUpdated]);

  function handleTemplateMenuToggle(idx) {
    isTemplateMenuOpen[idx] = !isTemplateMenuOpen[idx];
    setIsTemplateMenuOpen({ ...isTemplateMenuOpen });
  }

  function handleRenameClicked(template) {
    prompt({
      title: <FormattedMessage id="my-cloud.templates.rename.title" />,
      message: <FormattedMessage id="my-cloud.templates.rename.message" />,
      acceptOnlyValue: (hostname) => {
        const _hostname = hostname.trim();

        if (!isValidDomain(_hostname)) {
          return {
            status: false,
            reason: <FormattedMessage id="general.invalid-domain" />,
          };
        }

        return {
          status: true,
        };
      },
      beforeClose: async (hostname) => {
        const _hostname = hostname.trim();

        await ajax("/servers/renameTemplate", {
          userIDToWork: userIDToWork?.value,
          templateID: template._id,
          hostname: _hostname,
        });

        await getTemplates();
      },
    });
  }

  function handleRemoveClicked(template, idx) {
    confirm({
      title: <FormattedMessage id="my-cloud.templates.remove.title" />,
      message: (
        <FormattedMessage
          id="my-cloud.templates.remove.message"
          values={{ hostname: template.hostname }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/users/removeTemplate", {
          userIDToWork: userIDToWork?.value,
          templateID: template._id,
        });

        templates.splice(idx, 1);
        setTemplates([...templates]);
      },
    });
  }

  async function handleServerSelectorModalClosed(state) {
    if (state) {
      await ajax("/servers/createTemplate", {
        hostname: `${selectedServer.hostname}-template`,
        serverID: selectedServer._id,
      });
    }

    setIsServerSelectorModalOpen(false);
  }

  const handleCreateNewClicked = useCallback(() => {
    setIsServerSelectorModalOpen(true);
  }, []);

  const menuButtons = useMemo(
    () => [
      {
        title: <FormattedMessage id="my-cloud.templates.create-new" />,
        onClick: handleCreateNewClicked,
      },
    ],
    [handleCreateNewClicked]
  );

  const isCreatingTemplate = useMemo(
    () =>
      tasks &&
      tasks.data.find(
        (task) =>
          task.type === "JOB_CREATE_TEMPLATE_FROM_SERVER" && !task.finishedAt
      ),
    [tasks]
  );

  return (
    <WithRole permission="servers">
      <LayoutMainAction
        title={<FormattedMessage id="my-cloud.templates.title" />}
        buttons={menuButtons}
      >
        <Box>
          <ClickTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="general.hostname" />
                </th>
                <th>
                  <FormattedMessage id="general.template-id" />
                </th>
                <th>
                  <FormattedMessage id="general.datacenter" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isCreatingTemplate && (
                <tr>
                  <td colSpan={3}>
                    <FormattedMessage id="my-cloud.templates.creating" />
                  </td>
                  <td>
                    <Spinner />
                  </td>
                </tr>
              )}

              {!templates && (
                <tr>
                  <td colSpan={4}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {templates?.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {templates?.map((template, idx) => (
                <tr key={idx}>
                  <td>{template.hostname}</td>
                  <td>{template._id}</td>
                  <td>{template.datacenter}</td>
                  <td>
                    <CustomMenu
                      isOpen={isTemplateMenuOpen[idx]}
                      toggle={() => handleTemplateMenuToggle(idx)}
                    >
                      <CustomMenuItem
                        onClick={() => handleRenameClicked(template, idx)}
                      >
                        <FormattedMessage id="general.rename" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        onClick={() => handleRemoveClicked(template, idx)}
                      >
                        <FormattedMessage id="general.remove" />
                      </CustomMenuItem>
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </ClickTable>
        </Box>
      </LayoutMainAction>

      <ServerSelectorModal
        title={
          <FormattedMessage id="my-cloud-templates.servers-selector.title" />
        }
        selectedServer={selectedServer}
        setSelectedServer={setSelectedServer}
        isOpen={isServerSelectorModalOpen}
        onClose={handleServerSelectorModalClosed}
        onlyActive
      />
    </WithRole>
  );
}
