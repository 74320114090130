import styles from "./sidebar.module.scss";

import React, { useEffect, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoMini from "../svgs/logo-mini.svg?react";
import { useLang, useRoles, useUser, useWLDVPS } from "../../utils/hooks";
import { setSidebarSelection } from "../../store/settings";
import {
  SUPER_ADMIN,
  USER,
  USER_STATUS_ACTIVE,
  WHITELABEL,
} from "../../utils/user";
import sidebarUser from "./sidebar-user.json";
import sidebarIntegratorTop from "./sidebar-integrator-top.json";
import sidebarIntegrator from "./sidebar-integrator.json";
import sidebarIntegratorEndClient from "./sidebar-integrator-end-client.json";
import sidebarWhitelabel from "./sidebar-whitelabel.json";
import sidebarSuperAdmin from "./sidebar-super-admin.json";
import produce from "immer";
import { FormattedMessage } from "react-intl";
import SvgSelector from "../svg-selector";
import { useState } from "react";

let rotationVelocity = 0;
let currentRotation = 0;
let lastTimestamp = null;
const clickIncrement = 30;
const frictionFactor = 0.99;
const minimumVelocity = 0.5;
const direction = 1;

function Sidebar() {
  const lang = useLang("en");
  const user = useUser();
  const wldvps = useWLDVPS();
  const { isAllowed } = useRoles();
  const dispatch = useDispatch();
  const { sidebarSelection } = useSelector((state) => ({
    sidebarSelection: state.settings.sidebarSelection,
  }));
  const location = useLocation();
  const [miniLogoTransform, setMiniLogoTransform] = useState(
    `rotate(${currentRotation}deg)`
  );

  const blockRef = useRef(false);

  const sidebar = useMemo(() => {
    let arr;

    if (user.integrator === "top") {
      arr = sidebarIntegratorTop;
    } else if (user.integrator === "integrator") {
      arr = sidebarIntegrator;
    } else if (user.integrator === "end-client") {
      arr = sidebarIntegratorEndClient;
    } else {
      if (user.role === USER) {
        arr = sidebarUser;
      } else if (user.role === WHITELABEL) {
        arr = sidebarWhitelabel;
      } else if (user.role === SUPER_ADMIN) {
        arr = sidebarSuperAdmin;
      }
    }

    return produce(arr, (draft) => {
      for (const item of draft) {
        item.title = <FormattedMessage id={item.title} />;

        for (const link of item.links) {
          link.title = <FormattedMessage id={link.title} />;
          link.to = link.to.replace("{lang}", lang);
        }
      }
    });
  }, [user, lang]);

  useEffect(() => {
    if (blockRef.current) {
      return;
    }

    sidebar.forEach((item) => {
      if (item) {
        item.links.forEach((link) => {
          if (location.pathname.includes(link.to)) {
            blockRef.current = true;

            dispatch(
              setSidebarSelection({
                selectedSidebarItem: item,
                selectedSidebarSubItem: link,
              })
            );
          }
        });
      }
    });

    if (!blockRef.current) {
      dispatch(
        setSidebarSelection({
          selectedSidebarItem: sidebar[0],
          selectedSidebarSubItem: sidebar[0].links[0],
        })
      );
    }
  }, [sidebar, dispatch, location]);

  function handleSidebarFilter(item) {
    if (!item) {
      return false;
    }

    if (user.current_parent && item.permissions) {
      const role = user.roles.find(
        (item) => item.parent_user_id === user.current_parent
      );

      for (let i = 0; i < item.permissions.length; i++) {
        if (role.permissions.includes(item.permissions[i])) {
          return true;
        }
      }

      return false;
    }

    if (
      user.current_parent &&
      ["emails-history", "permissions", "logs", "general-settings"].includes(
        item.id
      )
    ) {
      return false;
    }

    if (item.id === "create-new-server" && !isAllowed("servers")) {
      return false;
    }

    if (user.role === WHITELABEL && user.integrator === "top") {
      if (
        [
          "sidebar.unpaid-customers",
          "sidebar.billing.create-new-invoice",
        ].includes(item.id)
      ) {
        return false;
      }
    }

    if (
      user.role === USER &&
      !["dreamvps", "livevps", "punchvps", "datatech"].includes(
        user.whitelabel
      ) &&
      item.id === "sidebar.manage-credit-card"
    ) {
      return false;
    }

    if (item.permissions) {
      for (const permission of item.permissions) {
        if (permission.startsWith("user.")) {
          return permission.includes(user._id);
        }
      }
    }

    return true;
  }

  function handleSidebarItemClicked(item) {
    dispatch(
      setSidebarSelection({
        selectedSidebarItem: item,
        selectedSidebarSubItem: item.links[0],
      })
    );
  }

  function handleSidebarSubItemClicked(subItem) {
    dispatch(
      setSidebarSelection({
        selectedSidebarItem: sidebarSelection.selectedSidebarItem,
        selectedSidebarSubItem: subItem,
      })
    );
  }

  function isItemDisabled(item) {
    if (
      user.current_parent ||
      (user.registerStep === -1 && user.status === USER_STATUS_ACTIVE) ||
      [
        "servers",
        "my-tickets",
        "open-new-ticket",
        "my-cloud",
        "support",
      ].includes(item.id)
    ) {
      return "";
    }

    return styles.disabled;
  }

  function handleMiniLogoClicked() {
    rotationVelocity = Math.abs(rotationVelocity) + clickIncrement;

    if (!lastTimestamp) {
      lastTimestamp = performance.now();
      requestAnimationFrame(animateRotation);
    }
  }

  function animateRotation(timestamp) {
    if (!lastTimestamp) {
      lastTimestamp = timestamp;
    }

    const deltaTime = (timestamp - lastTimestamp) / 1000;
    lastTimestamp = timestamp;

    if (rotationVelocity >= 300) {
      console.log("__ sidebar.jsx[232]");
    }

    rotationVelocity *= frictionFactor;

    if (rotationVelocity > minimumVelocity) {
      const rotationIncrement = rotationVelocity * deltaTime * direction;

      currentRotation += rotationIncrement;

      setMiniLogoTransform(`rotate(${currentRotation}deg)`);

      requestAnimationFrame(animateRotation);
    } else {
      rotationVelocity = 0;
      lastTimestamp = null;
    }
  }

  function renderSubItem() {
    if (!sidebarSelection.selectedSidebarItem) {
      return null;
    }

    return (
      <div className={styles.items}>
        <div className={styles.currentPage}>
          <SvgSelector icon={sidebarSelection.selectedSidebarItem.icon} />
          <span>{sidebarSelection.selectedSidebarItem.title}</span>
        </div>
        <div className={styles.line}>
          <hr />
        </div>
        <div className={`mt24 ${styles.subItems}`}>
          {sidebarSelection.selectedSidebarItem.links
            .filter(handleSidebarFilter)
            .map((link, idx) => (
              <Link
                to={link.to}
                key={idx}
                className={`mb16 ${styles.subItem} ${
                  sidebarSelection.selectedSidebarSubItem === link
                    ? styles.selected
                    : ""
                } ${isItemDisabled(link)}`}
                onClick={() => handleSidebarSubItemClicked(link)}
              >
                {link.title}
              </Link>
            ))}
        </div>
      </div>
    );
  }

  function renderMiniLogo() {
    if (["livevps", "punchvps"].includes(wldvps)) {
      return (
        <div
          onClick={handleMiniLogoClicked}
          className="svg mt28 mb28"
          style={{
            transform: miniLogoTransform,
          }}
        />
      );
    }

    return (
      <LogoMini
        onClick={handleMiniLogoClicked}
        className="svg mt28 mb28"
        style={{
          transform: miniLogoTransform,
        }}
      />
    );
  }

  return (
    <div className={`${styles.sidebarWrapper}`}>
      <div className={styles.sidebarFloat}>
        <div className={styles.mainNav}>
          {renderMiniLogo()}

          {sidebar.filter(handleSidebarFilter).map((item, idx) => (
            <Link
              key={idx}
              to={item.links[0].to}
              className={`${styles.box} ${
                sidebarSelection.selectedSidebarItem?.title === item.title
                  ? styles.selected
                  : ""
              } ${isItemDisabled(item)}`}
              onClick={() => handleSidebarItemClicked(item)}
            >
              <SvgSelector icon={item.icon} />
              {item.title}
            </Link>
          ))}
        </div>
        <div className={styles.subNav}>{renderSubItem()}</div>
      </div>
    </div>
  );
}

export default Sidebar;
