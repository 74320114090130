import React from "react";
import PropTypes from "prop-types";
import UserSvg from "../svgs/user.svg?react";
import LockSvg from "../svgs/lock.svg?react";
import FacebookMiniSvg from "../svgs/facebook-mini.svg?react";
import GoogleMiniSvg from "../svgs/google-mini.svg?react";
import CloudSvg from "../svgs/cloud.svg?react";
import SupportSvg from "../svgs/support.svg?react";
import BillingSvg from "../svgs/billing.svg?react";
import AccountSvg from "../svgs/account.svg?react";
import ApiSvg from "../svgs/api.svg?react";
import AffiliateSvg from "../svgs/affiliate.svg?react";
import RamSvg from "../svgs/ram.svg?react";
import ManagementSvg from "../svgs/management.svg?react";
import DiamondSvg from "../svgs/diamond.svg?react";
import RightSvg from "../svgs/right.svg?react";
import EmailSvg from "../svgs/email.svg?react";

function SvgSelector({ icon }) {
  if (icon === "user") {
    return <UserSvg />;
  } else if (icon === "lock") {
    return <LockSvg />;
  } else if (icon === "facebook-mini") {
    return <FacebookMiniSvg />;
  } else if (icon === "google-mini") {
    return <GoogleMiniSvg />;
  } else if (icon === "cloud") {
    return <CloudSvg />;
  } else if (icon === "support") {
    return <SupportSvg />;
  } else if (icon === "billing") {
    return <BillingSvg />;
  } else if (icon === "account") {
    return <AccountSvg />;
  } else if (icon === "api") {
    return <ApiSvg />;
  } else if (icon === "affiliate") {
    return <AffiliateSvg />;
  } else if (icon === "ram") {
    return <RamSvg />;
  } else if (icon === "management") {
    return <ManagementSvg />;
  } else if (icon === "diamond") {
    return <DiamondSvg />;
  } else if (icon === "right") {
    return <RightSvg />;
  } else if (icon === "email") {
    return <EmailSvg />;
  } else {
    console.error(`${icon} - Missing SVG`);
    return null;
  }
}

SvgSelector.propTypes = {
  icon: PropTypes.string,
};

export default SvgSelector;
