import styles from "./tasks.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { format } from "date-fns";
import TrashSvg from "../../../../components/svgs/trash.svg?react";
import EditSvg from "../../../../components/svgs/edit-purple.svg?react";
import ScheduleNewTaskModal from "../../../../components/modals/schedule-new-task";
import { useAjax, useConfirm } from "../../../../utils/hooks";
import { renderSchedulerAction } from "../../../../utils/tasks";
import { WithRole } from "../../../../components/with-role";
import { FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import ClickTable from "../../../../components/click-table";
import Spinner from "../../../../components/spinner";
import DreamButton from "../../../../components/dream-button";
import { getDayAsText } from "../../../../utils";
import { useOutletContext } from "react-router-dom";

export default function ServerTasks() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const [selectedTask, setSelectedTask] = useState(null);
  const { server } = useOutletContext();

  const [tasks, setTasks] = useState(null);
  const [isScheduleNewTaskModalOpen, setIsScheduleNewTaskModalOpen] =
    useState(false);

  const getScheduledTasks = useCallback(async () => {
    const data = await ajax(`/scheduler/getScheduledTasks`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      setTasks(data.tasks);
    }
  }, [ajax, server]);

  useEffect(() => {
    getScheduledTasks();
  }, [getScheduledTasks]);

  function handleCreateNewTaskClicked() {
    setSelectedTask(null);
    setIsScheduleNewTaskModalOpen(true);
  }

  async function handleScheduleNewTaskModalClosed(status) {
    if (status) {
      await getScheduledTasks();
    }

    setIsScheduleNewTaskModalOpen(false);
  }

  function handleEditScheduledTaskClicked(task) {
    setSelectedTask(task);
    setIsScheduleNewTaskModalOpen(true);
  }

  function handleRemoveScheduledTaskClicked(task) {
    confirm({
      title: <FormattedMessage id="server-tasks.remove-scheduled-task.title" />,
      message: (
        <FormattedMessage id="server-tasks.remove-scheduled-task.content" />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/scheduler/removeScheduledTask", {
          serverID: server._id,
          taskID: task._id,
        });
        await getScheduledTasks();
      },
    });
  }

  function renderAt(task) {
    if (task.frequency === "weekly") {
      return task.at.reduce(
        (str, item, key) =>
          `${str}${key > 0 ? ", " : ""}${getDayAsText(parseInt(item) + 1)}`,
        ""
      );
    } else if (task.frequency === "monthly") {
      return `${task.at[0]}st`;
    }

    return "-";
  }

  return (
    <WithRole permission="servers">
      <div className={styles.wrapper}>
        <Box className={styles.descriptionBox}>
          <FormattedMessage id="server-tasks.description" />
        </Box>

        <Box>
          <div>
            <ClickTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="server-tasks.action" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.frequency" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.at" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.once" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.next-run" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!tasks && (
                  <tr>
                    <td colSpan={6}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {tasks?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {tasks?.map((task, key) => (
                  <tr key={key}>
                    <td>{renderSchedulerAction(task.action)}</td>
                    <td className="capitalize">{task.frequency}</td>
                    <td
                      className="capitalize"
                      dangerouslySetInnerHTML={{ __html: renderAt(task) }}
                    ></td>
                    <td>
                      {task.once ? <FormattedMessage id="general.yes" /> : ""}
                    </td>
                    <td>{format(new Date(task.next_run), "d/M/y HH:mm")}</td>
                    <td>
                      <EditSvg
                        className="pointer"
                        onClick={() => handleEditScheduledTaskClicked(task)}
                      />
                      <TrashSvg
                        className="pointer"
                        onClick={() => handleRemoveScheduledTaskClicked(task)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </ClickTable>
          </div>
          <div className={styles.button}>
            <DreamButton
              disabled={server?.isWorking}
              color="light-purple"
              onClick={handleCreateNewTaskClicked}
            >
              <FormattedMessage id="server-tasks.create" />
            </DreamButton>
          </div>
        </Box>

        <ScheduleNewTaskModal
          isOpen={isScheduleNewTaskModalOpen}
          onClose={handleScheduleNewTaskModalClosed}
          server={server}
          editTask={selectedTask}
        />
      </div>
    </WithRole>
  );
}
