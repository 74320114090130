import styles from "./parent-selector.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setFetchedInitialData } from "../../store/settings";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAjax, useAlert, useLang, useUser } from "../../utils/hooks";

export default function ParentSelector() {
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();
  const lang = useLang("en");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSelector, setShowSelector] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function handleDomClicked() {
      setShowSelector(false);
    }

    window.document.addEventListener("click", handleDomClicked);

    return () => {
      window.document.removeEventListener("click", handleDomClicked);
    };
  }, []);

  async function handleChangeParent(parentID) {
    setLoading(true);

    const data = await ajax(`/users/changeWorkspace`, {
      parentID,
    });

    if (data.result === "error") {
      await alert({
        title: <FormattedMessage id="general.error" />,
        message: <FormattedMessage id="general.ip-not-authorized" />,
      });
      setLoading(false);
    } else {
      navigate(`/${lang}/my-cloud/servers`);
      dispatch(setFetchedInitialData(false));
    }
  }

  function handleChangeAccountClicked(e) {
    e.preventDefault();
    e.stopPropagation();

    setShowSelector(true);
  }

  async function handleBackToYourAccountClicked() {
    setLoading(true);

    const data = await ajax("/integrator/backToYourAccount");

    setLoading(false);

    if (data.result === "success") {
      location.href = "/";
    } else {
      setLoading(false);
    }
  }

  async function handleSwitchToDemoAccountClicked() {
    setLoading(true);

    const data = await ajax("/integrator/switchToDemoAccount");

    if (data.result === "success") {
      location.href = "/";
    } else {
      setLoading(false);
    }
  }

  const workingUnder = useMemo(() => {
    if (!user.current_parent) {
      return null;
    }

    return user.roles.find((r) => r.parent_user_id === user.current_parent)
      .parentUser.email;
  }, [user]);

  if (user.backToUserId) {
    return (
      <div className={styles.wrapper}>
        <a
          href="#"
          onClick={handleBackToYourAccountClicked}
          className={loading ? "disabled" : ""}
        >
          <FormattedMessage id="back-to-your-account" />
        </a>
      </div>
    );
  }

  if (user.integrator === "top") {
    return (
      <div className={styles.wrapper}>
        <a
          href="#"
          onClick={handleSwitchToDemoAccountClicked}
          className={loading ? "disabled" : ""}
        >
          <FormattedMessage id="switch-to-demo-account" />
        </a>
      </div>
    );
  }

  if (!user.roles || user.roles.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.workingUnder}>
        <div>
          <FormattedMessage
            id="parent-selector.working-under.new"
            values={{
              name: workingUnder || "My account",
            }}
          />
        </div>
        <a
          href="#"
          onClick={handleChangeAccountClicked}
          className={loading ? "disabled" : ""}
        >
          <FormattedMessage id="general.change-account" />
        </a>
      </div>

      {showSelector && (
        <div className={styles.selector} onClick={(e) => e.stopPropagation()}>
          <div
            onClick={() => handleChangeParent(null)}
            className={user.current_parent ? "" : "disabled"}
          >
            {user.email} (<FormattedMessage id="general.my-account" />)
          </div>
          {user.roles.map((role, idx) => (
            <div
              key={idx}
              onClick={() => handleChangeParent(role.parentUser._id)}
              className={
                user.current_parent === role.parentUser._id ? "disabled" : ""
              }
            >
              {role.parentUser.email}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
