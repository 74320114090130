import styles from "./index.module.scss";

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../../custom-react-select";
import Checkbox from "../../checkbox";
import DreamButton from "../../dream-button";
import { readableBytes } from "../../../utils/servers";
import ReactDatePicker from "react-datepicker";
import { parseISO } from "date-fns";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const daysToKeepSnapshotsOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
];

function ScheduleNewTaskModal({ server, isOpen, onClose, editTask }) {
  const ajax = useAjax();
  const user = useUser();

  const [action, setAction] = useState(false);
  const [frequency, setFrequency] = useState(false);
  const [at, setAt] = useState({});
  const [time, setTime] = useState(null);

  const [daysToKeepSnapshots, setDaysToKeepSnapshots] = useState(null);

  const [setCpuTo, setSetCpuTo] = useState(null);
  const [setRamTo, setSetRamTo] = useState(null);
  const [restartServer, setRestartServer] = useState(false);

  const [once, setOnce] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const frequencyOptions = useMemo(
    () => [
      {
        label: <FormattedMessage id="schedule-new-task-modal.daily" />,
        value: "daily",
      },
      {
        label: <FormattedMessage id="schedule-new-task-modal.weekly" />,
        value: "weekly",
      },
      {
        label: <FormattedMessage id="schedule-new-task-modal.monthly" />,
        value: "monthly",
      },
    ],
    []
  );

  const actionsList = useMemo(() => {
    let list = [
      {
        label: <FormattedMessage id="schedule-new-task-modal.action-start" />,
        value: "start-server",
      },
      {
        label: (
          <FormattedMessage id="schedule-new-task-modal.action-stop-soft" />
        ),
        value: "stop-server-soft",
      },
      {
        label: (
          <FormattedMessage id="schedule-new-task-modal.action-stop-hard" />
        ),
        value: "stop-server-hard",
      },
      {
        label: <FormattedMessage id="schedule-new-task-modal.action-restart" />,
        value: "restart-server",
      },
      {
        label: (
          <FormattedMessage id="schedule-new-task-modal.action-create-snapshot" />
        ),
        value: "create-snapshot",
      },
      // {
      //   label: <FormattedMessage id="schedule-new-task-modal.action-scale-server" />,
      //   value: "scale-server",
      // },
    ];

    if (!server.ping) {
      list = list.filter((action) => action.value !== "stop-server-soft");
    }

    return list;
  }, [server]);

  const setCpuToOptions = useMemo(
    () => [
      { label: <FormattedMessage id="general.no-change" />, value: -1 },
      ...user.productPrices.cpu.map((item) => ({
        label: item.value,
        value: item.value,
      })),
    ],
    [user]
  );

  const setRamToOptions = useMemo(
    () => [
      { label: <FormattedMessage id="general.no-change" />, value: -1 },
      ...user.productPrices.ram_mb.map((item) => ({
        label: readableBytes(item.value * Math.pow(1024, 2)),
        value: item.value,
      })),
    ],
    [user]
  );

  useEffect(() => {
    if (isOpen) {
      if (editTask) {
        const frequency = frequencyOptions.find(
          (option) => option.value === editTask.frequency
        );
        setFrequency(frequency);

        const action = actionsList.find(
          (action) => action.value === editTask.action
        );
        setAction(action);

        setOnce(editTask.once);

        if (action.value === "create-snapshot") {
          setDaysToKeepSnapshots(
            daysToKeepSnapshotsOptions.find(
              (item) =>
                item.value === editTask?.extra_data?.days_to_keep_snapshots
            )
          );
        } else if (action.value === "scale-server") {
          setSetCpuTo(
            setCpuToOptions.find(
              (item) => item.value === editTask?.extra_data?.set_cpu_to
            )
          );
          setSetRamTo(
            setRamToOptions.find(
              (item) => item.value === editTask?.extra_data?.set_ram_to
            )
          );
          setRestartServer(editTask?.extra_data?.restart_server);
        }

        setTimeout(() => {
          setTime(parseISO(editTask.time));

          const at = {};
          editTask.at.forEach((item) => {
            at[item] = true;
          });

          setAt(at);
        }, 1);
      } else {
        setFrequency(false);
        setAt({});
        setTime(null);
        setAction(false);
        setOnce(false);

        setDaysToKeepSnapshots(null);

        setSetCpuTo(null);
        setSetRamTo(null);

        setRestartServer(false);
      }
    }
  }, [
    isOpen,
    editTask,
    frequencyOptions,
    actionsList,
    setCpuToOptions,
    setRamToOptions,
  ]);

  useEffect(() => {
    setTime(null);
    setAt({});
  }, [frequency]);

  async function handleScheduleTaskClicked() {
    setError(false);

    if (!action) {
      return setError(
        <FormattedMessage id="schedule-new-task-modal.empty-action" />
      );
    } else if (!frequency) {
      return setError(
        <FormattedMessage id="schedule-new-task-modal.empty-frequency" />
      );
    } else if (!time) {
      return setError(
        <FormattedMessage id="schedule-new-task-modal.empty-time" />
      );
    } else if (action.value === "create-snapshot" && !daysToKeepSnapshots) {
      return setError(
        <FormattedMessage id="schedule-new-task-modal.empty-days-to-keep-snapshots" />
      );
    } else if (action.value === "scale-server" && (!setCpuTo || !setRamTo)) {
      return setError(
        <FormattedMessage id="schedule-new-task-modal.empty-set-cpu-or-ram-to" />
      );
    }

    if (frequency.value !== "daily" && Object.keys(at).length === 0) {
      return setError(
        <FormattedMessage
          id={
            frequency.value === "weekly"
              ? "schedule-new-task-modal.empty-at-weekly"
              : "schedule-new-task-modal.empty-at-monthly"
          }
        />
      );
    }

    setLoading(true);

    let extraData;

    if (action.value === "create-snapshot") {
      extraData = {
        days_to_keep_snapshots: daysToKeepSnapshots?.value || 14,
      };
    } else if (action.value === "scale-server") {
      extraData = {
        set_cpu_to: setCpuTo.value,
        set_ram_to: setRamTo.value,
        restart_server: restartServer,
      };
    }

    await ajax("/scheduler/scheduleTask", {
      serverID: server._id,
      action: action.value,
      frequency: frequency.value,
      at: Object.keys(at).filter((k) => at[k]),
      time,
      extraData,
      once,
      editTaskID: editTask?._id,
    });

    setLoading(false);

    onClose(true);
  }

  function handleDayClicked(day) {
    at[day] = !at[day];
    setAt({ ...at });
  }

  function handleWeekClicked(week) {
    const _at = {};
    _at[week] = true;
    setAt(_at);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editTask
              ? "schedule-new-task-modal.title-edit"
              : "schedule-new-task-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <div className={styles.text}>
            <FormattedMessage id="schedule-new-task-modal.action" />
          </div>
          <div className={styles.select}>
            <Select
              value={action}
              onChange={(value) => setAction(value)}
              options={actionsList}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.text}>
            <FormattedMessage id="schedule-new-task-modal.frequency" />
          </div>
          <div className={styles.select}>
            <Select
              value={frequency}
              onChange={(value) => setFrequency(value)}
              options={frequencyOptions}
            />
          </div>
        </div>

        {frequency?.value === "weekly" && (
          <div className={`${styles.buttonsWrapper} ${styles.spaceBetween}`}>
            {days.map((day, key) => (
              <div
                key={key}
                className={at[key] ? styles.selected : ""}
                onClick={() => handleDayClicked(key)}
              >
                {day[0]}
              </div>
            ))}
          </div>
        )}

        {frequency?.value === "monthly" && (
          <div className={styles.buttonsWrapper}>
            <div
              className={at[1] ? styles.selected : ""}
              onClick={() => handleWeekClicked(1)}
            >
              1st
            </div>
            <div
              className={at[15] ? styles.selected : ""}
              onClick={() => handleWeekClicked(15)}
            >
              15st
            </div>
          </div>
        )}

        {frequency && (
          <div className={styles.row}>
            <div className={styles.text}>
              <FormattedMessage id="schedule-new-task-modal.time" />
            </div>
            <div className={styles.select}>
              <ReactDatePicker
                selected={time}
                onChange={(date) => setTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </div>
          </div>
        )}

        {action.value === "create-snapshot" && (
          <div className={styles.row}>
            <div className={`${styles.text} ${styles.long}`}>
              <FormattedMessage id="schedule-new-task-modal.keep-snapshots-1" />
            </div>
            <CustomReactSelect
              className={styles.daysToKeepSnapshotsSelect}
              options={daysToKeepSnapshotsOptions}
              value={daysToKeepSnapshots}
              onChange={(item) => setDaysToKeepSnapshots(item)}
            />
            <div className={`${styles.text} ${styles.long2}`}>
              <FormattedMessage id="schedule-new-task-modal.keep-snapshots-2" />
            </div>
          </div>
        )}

        {action.value === "scale-server" && (
          <>
            <div className={styles.row}>
              <div className={`${styles.text} ${styles.long}`}>
                <FormattedMessage id="schedule-new-task-modal.set-cpu" />
              </div>
              <CustomReactSelect
                className={styles.daysToKeepSnapshotsSelect}
                options={setCpuToOptions}
                value={setCpuTo}
                onChange={(item) => setSetCpuTo(item)}
              />
            </div>
            <div className={styles.row}>
              <div className={`${styles.text} ${styles.long}`}>
                <FormattedMessage id="schedule-new-task-modal.set-ram" />
              </div>
              <CustomReactSelect
                className={styles.daysToKeepSnapshotsSelect}
                options={setRamToOptions}
                value={setRamTo}
                onChange={(item) => setSetRamTo(item)}
              />
            </div>
            <div className={styles.row}>
              <div className={`${styles.text} ${styles.long2}`}>
                <Checkbox
                  label="schedule-new-task-modal.restart"
                  checked={restartServer}
                  onChange={(e) => setRestartServer(e.target.checked)}
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.row}>
          <div className={`${styles.text} ${styles.long2}`}>
            <Checkbox
              label="schedule-new-task-modal.once"
              checked={once}
              onChange={(e) => setOnce(e.target.checked)}
            />
          </div>
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="purple"
          onClick={handleScheduleTaskClicked}
        >
          <FormattedMessage
            id={editTask ? "general.edit" : "general.schedule"}
          />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}
ScheduleNewTaskModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tasks: PropTypes.array,
  editTask: PropTypes.object,
};

export default ScheduleNewTaskModal;
