import React, { useCallback, useRef, useEffect, useState } from "react";
import io from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import useIsMounted from "ismounted";
import { useIntl } from "react-intl";
import { setReconnecting, setTotalServers, setWLDVPS } from "../store/settings";
import { updateServers } from "../store/servers";
import { updateUserDetails } from "../store/user";
import { getTotalActiveServers } from "./servers";
import { getHostOfWLDVPS, getWebSocketUrl } from "./wldvps";
import { UPDATE_TASKS } from "../store/tasks";
import { SET_NOTIFICATIONS } from "../store/notifications";
import { getSocket, setSocket } from "./globals";
import { FormattedMessage } from "react-intl";
import { SUPER_ADMIN, USER, WHITELABEL } from "./user";
import { sanitize } from ".";

export const useServers = () => {
  const { servers } = useSelector(
    (state) => ({
      servers: state.servers,
    }),
    (prev, next) =>
      JSON.stringify(prev.servers) === JSON.stringify(next.servers)
  );

  return servers;
};

export const useUrls = () => {
  const wldvps = useWLDVPS();

  const url = getHostOfWLDVPS(wldvps);

  return {
    IL_CONSOLE_URL: `https://ilconsole.${url}`,
    NL_CONSOLE_URL: `https://nlconsole.${url}`,
    US_CONSOLE_URL: `https://usconsole.${url}`,
    TA_CONSOLE_URL: `https://taconsole.${url}`,
  };
};

export const useInitialDataFetching = () => {
  const ajax = useAjax();
  const dispatch = useDispatch();
  const { connect } = useWebSocket();

  return async (connectToSocket = false) => {
    const data = await ajax("/initialData");

    if (data.result === "success") {
      if (data.integrator === "top") {
        dispatch(setWLDVPS(data.whitelabel));
      }

      dispatch(updateUserDetails(data));

      if (connectToSocket) {
        connect();
      }

      return data;
    }

    return false;
  };
};

export const useLimits = () => {
  const user = useUser();
  const servers = useServers();

  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const [serversLimit, setServersLimit] = useState(false);

  useEffect(() => {
    if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
      setServersLimit(Number.MAX_SAFE_INTEGER);
    } else if (servers) {
      setServersLimit(
        user.serversLimit - getTotalActiveServers(servers, tasks)
      );
    }
  }, [servers, tasks, user.serversLimit, user.role]);

  return { serversLimit };
};

export const useWLDVPS = () => {
  const { wldvps } = useSelector((state) => ({
    wldvps: state.settings.wldvps,
  }));

  return wldvps;
};

export const useWebSocket = () => {
  const dispatch = useDispatch();
  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const logout = useLogout("en");
  const logoutRef = useRef(logout);

  let timerID = useRef();
  let lastNotificationID = useRef();

  const connect = useCallback(() => {
    const curSocket = getSocket();

    if (curSocket) {
      curSocket.removeAllListeners("servers-update");
      curSocket.removeAllListeners("tasks-update");
      curSocket.removeAllListeners("auto-logout");
      curSocket.disconnect(true);
    }

    const socket = io(getWebSocketUrl(), {
      withCredentials: true,
    });

    socket.on("servers-update", (data) => {
      dispatch(updateServers(data.servers));
      dispatch(setTotalServers(data.totalServers));
    });

    socket.on("tasks-update", (data) => {
      data.tasks.forEach((task) => {
        if (task.custom_prec > 0) {
          task.step = task.custom_prec;
          task.totalSteps = 100;
        }
      });

      dispatch({
        type: UPDATE_TASKS,
        payload: { data: data.tasks, lastRequest: +new Date() },
      });

      if (
        data.notifications &&
        data.notifications[0] &&
        lastNotificationID.current !== data.notifications[0]._id
      ) {
        lastNotificationID.current = data.notifications[0]._id;

        dispatch({
          type: SET_NOTIFICATIONS,
          payload: data.notifications,
        });
      }
    });

    socket.on("auto-logout", () => {
      logoutRef.current();
    });

    socket.on("maintenance", () => {
      window.location.href = "/";
    });

    socket.on("reconnecting", (data) => {
      dispatch(setReconnecting(data.state));
    });

    setSocket(socket);
  }, [dispatch]);

  useEffect(() => {
    if (!serversFetching) {
      return;
    }

    const socket = getSocket();

    if (socket) {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }

      timerID.current = setTimeout(() => {
        socket.emit("update-filters", {
          page: serversFetching.page,
          maxServersToShow: serversFetching.maxServersToShow,
          totalServers: serversFetching.totalServers,
          filter: serversFetching.filter,
          showOnly: serversFetching.showOnly,
          sortBy: serversFetching.sortBy,
          sortDirection: serversFetching.sortDirection,
          group: serversFetching.group,
        });
      }, 100);
    }
  }, [serversFetching]);

  return { connect };
};

export const useAjaxValidator = () => {
  const alert = useAlert();

  return (data, onSuccess) => {
    if (data.result === "success") {
      onSuccess();
    } else {
      alert({
        title: <FormattedMessage id="general.error" />,
        message: <FormattedMessage id="general.general-error" />,
      });
    }
  };
};

export const useFailedActionHandler = () => {
  const alert = useAlert();

  return (actionAjaxData) => {
    if (
      Array.isArray(actionAjaxData.failed) &&
      actionAjaxData.failed.length > 0
    ) {
      alert({
        title: <FormattedMessage id="servers.errors.locked.title" />,
        message: (
          <div>
            <FormattedMessage
              id="servers.errors.locked.message"
              values={{
                servers: actionAjaxData.failed
                  .map((server) => <b>{server.hostname}</b>)
                  .join(<br />),
              }}
            />
          </div>
        ),
      });
    }
  };
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useLang = () => {
  const location = useLocation();
  const user = useUser();

  const [lang, setLang] = useState(
    localStorage.getItem("lang") || import.meta.env.VITE_APP_DEFAULT_LANGUAGE
  );

  useEffect(() => {
    const localLang = localStorage.getItem("lang");
    if (!user & localLang) {
      return setLang(localLang);
    }
    const defaultLanguage =
      user.lang || import.meta.env.VITE_APP_DEFAULT_LANGUAGE;

    if (location.pathname === "/") {
      return setLang(defaultLanguage);
    }

    const otherLanguages = JSON.parse(import.meta.env.VITE_APP_OTHER_LANGUAGES);

    if (otherLanguages.find((lng) => location.pathname.startsWith(`/${lng}`))) {
      setLang(location.pathname.slice(1, 3));
    } else {
      setLang(defaultLanguage);
    }
  }, [location, user]);

  return lang;
};

// export const useLang = () => {
//   const location = useLocation();

//   const [lang, setLang] = useState(import.meta.env.VITE_APP_DEFAULT_LANGUAGE);

//   useEffect(() => {
//     if (location.pathname === "/") {
//       return setLang(import.meta.env.VITE_APP_DEFAULT_LANGUAGE);
//     }

//     const otherLanguages = JSON.parse(import.meta.env.VITE_APP_OTHER_LANGUAGES);

//     if (otherLanguages.find((lng) => location.pathname.startsWith(`/${lng}`))) {
//       setLang(location.pathname.slice(1, 3));
//     } else {
//       setLang(import.meta.env.VITE_APP_DEFAULT_LANGUAGE);
//     }
//   }, [location]);

//   return lang;
// };

export const useLogout = (defaultLang) => {
  const lang = useLang(defaultLang);
  const ajax = useAjax();

  return async () => {
    await ajax(`/users/logout`, {});
    location.href = `/${lang}/user/login`;
  };
};

export const useAlert = () => {
  const dispatch = useDispatch();

  return ({ title, message, button, notification, alertStyle, modalSize }) => {
    return new Promise((resolve) => {
      dispatch(
        window.setAlertModalData({
          isOpen: true,
          title,
          message,
          button,
          notification,
          alertStyle,
          size: modalSize,
          resolve,
        })
      );
    });
  };
};

export const useConfirm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return ({
    title,
    message,
    button1 = {
      text: intl.formatMessage({ id: "cancel" }),
      color: "gray",
    },
    button2 = {
      text: intl.formatMessage({ id: "yes" }),
      color: "light-purple",
    },
    buttons = null,
    beforeClose = null,
  }) => {
    return new Promise((resolve) => {
      dispatch(
        window.setConfirmModalData({
          isOpen: true,
          title,
          message,
          button1,
          button2,
          buttons,
          resolve,
          beforeClose,
        })
      );
    });
  };
};

export const usePrompt = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return ({
    title,
    message,
    defaultText = "",
    button1 = {
      text: intl.formatMessage({ id: "cancel" }),
      color: "gray",
    },
    button2 = {
      text: intl.formatMessage({ id: "ok" }),
      color: "light-purple",
    },
    acceptOnlyValue,
    textType = "text",
    beforeClose = null,
    placeholder = null,
    withCheckbox = null,
  }) => {
    return new Promise((resolve) => {
      dispatch(
        window.setPromptModalData({
          isOpen: true,
          title,
          message,
          defaultText,
          button1,
          button2,
          resolve,
          acceptOnlyValue:
            typeof acceptOnlyValue === "string"
              ? sanitize(acceptOnlyValue)
              : acceptOnlyValue,
          textType,
          beforeClose,
          placeholder,
          withCheckbox,
        })
      );
    });
  };
};

export const useAjax = (project = null) => {
  let serverUrl = window.serverUrl;

  if (project === "monitor") {
    serverUrl = import.meta.env.DEV
      ? "http://localhost:3003"
      : "https://monitor2.dreamvps.com/api";
  }

  const ajax = useCallback(
    async (url, params = {}, config = {}) => {
      do {
        try {
          const { data } = await axios.post(`${serverUrl}${url}`, params, {
            ...config,
            withCredentials: true,
          });

          if (
            data.result === "error" &&
            data.message == "blocked-user" &&
            !location.href.endsWith("/user/login")
          ) {
            location.href = "/user/login";
            return;
          }

          return data;
        } catch (err) {
          const ret = {
            result: "error",
            message: "server-connection-failed",
            err: err.stack,
          };

          if (url === "/initialData") {
            return ret;
          }

          return ret;
        }
      } while (true); // eslint-disable-line
    },
    [serverUrl]
  );

  return ajax;
};

export const useTasks = () => {
  const { tasks } = useSelector((state) => ({
    tasks: state.tasks,
  }));

  return tasks;
};

export const useUser = () => {
  const { user } = useSelector(
    (state) => ({ user: state.user }),
    (prev, next) => JSON.stringify(prev.user) === JSON.stringify(next.user)
  );

  return user;
};

export const useRoles = () => {
  const { user } = useSelector(
    (state) => ({ user: state.user }),
    (prev, next) => prev.user._id === next.user._id
  );

  const isAllowed = (role) => {
    if (!user.current_parent) {
      if (
        user.role === WHITELABEL &&
        role &&
        ((typeof role === "string" && role.startsWith("super-admin.")) ||
          (Array.isArray(role) &&
            role.find((item) => item.startsWith("super-admin."))))
      ) {
        return false;
      }

      if (user.role === USER) {
        if (user.integrator === "top") {
          if (Array.isArray(role)) {
            for (const r of role) {
              if (r.startsWith("super-admin.")) {
                return true;
              }
            }
          } else if (!role.startsWith("super-admin.")) {
            return true;
          }
        }

        if (
          role &&
          ((typeof role === "string" &&
            (role.startsWith("super-admin.") || role.startsWith("admin."))) ||
            (Array.isArray(role) &&
              (role.find((item) => item.startsWith("super-admin.")) ||
                role.find((item) => item.startsWith("admin.")))))
        ) {
          return false;
        }
      }

      return true;
    }

    const roles = user.roles.find(
      (r) => r.parent_user_id === user.current_parent
    );
    if (!roles) {
      return true;
    }

    if (user.integrator === "top") {
      if (Array.isArray(role)) {
        for (const r of role) {
          if (r.startsWith("super-admin.")) {
            return true;
          }
        }
      } else if (!role.startsWith("super-admin.")) {
        return true;
      }
    }

    return (
      (Array.isArray(role) &&
        roles.permissions.filter((item) => role && role.includes(item)).length >
          0) ||
      (typeof role === "string" &&
        roles.permissions &&
        roles.permissions.includes(role))
    );
  };

  return { isAllowed };
};

export const useTraceUpdate = (props) => {
  const prev = useRef(props);

  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});

    if (Object.keys(changedProps).length > 0) {
      console.info("Changed props:", changedProps);
    }

    prev.current = props;
  });
};

export const useNotifications = () => {
  const { notifications } = useSelector(
    (state) => ({
      notifications: state.notifications,
    }),
    (prev, next) =>
      JSON.stringify(prev.notifications) === JSON.stringify(next.notifications)
  );

  return notifications;
};

export const useRunningTasks = () => {
  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  return (taskType, site = null) => {
    if (!tasks) {
      return [];
    }

    const foundTasks = tasks.data.filter(
      (task) =>
        !task.finishedAt &&
        (!site || task.data.siteID === site._id) &&
        task.type === taskType
    );

    return foundTasks;
  };
};

export const useAutoLogout = () => {
  const logout = useLogout();
  const user = useUser();

  const secondsWithoutActive = useRef(0);
  const [timerID, setTimerID] = useState(null);

  useEffect(() => {
    if (!import.meta.env.PROD) {
      return;
    }

    if (timerID || !user || typeof user.auto_logout_minutes !== "number") {
      return;
    }

    const id = setInterval(() => {
      secondsWithoutActive.current++;

      if (secondsWithoutActive.current >= user.auto_logout_minutes * 60) {
        logout();
      }
    }, 1000);

    setTimerID(id);
  }, [timerID, logout, user]);

  useEffect(() => {
    if (!import.meta.env.PROD) {
      return;
    }

    function handleMouseMove() {
      secondsWithoutActive.current = 0;
    }

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
};

export function useTaskStatus() {
  return function render(task) {
    if (task.failed || task.step === -1) {
      return "failed";
    }

    if (task.finishedAt || task.finished_at) {
      return task.failed || task.step === -1 ? "failed" : "success";
    }

    return -1;
  };
}

export const useSmsCounter = (initialTime = 30) => {
  const isMounted = useIsMounted();

  const [timerID, setTimerID] = useState(null);

  const [counter, setCounter] = useState(initialTime);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  useEffect(() => {
    return () => {
      clearInterval(timerID);
    };
  }, [timerID]);

  return {
    start: () => {
      const tid = setInterval(() => {
        if (!isMounted.current) {
          return;
        }

        if (counterRef.current > 0) {
          setCounter(counterRef.current - 1);
        }
      }, 1000);

      setTimerID(tid);
    },
    reset: () => {
      setCounter(initialTime);
    },
    counter,
  };
};

export const useCatchHrefAndGo = () => {
  const navigate = useNavigate();

  return (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();

      navigate(e.target.href.replace(/^.*\/\/[^/]+/, ""));
    }
  };
};

export default function usePState(key, initialValue) {
  const [state, setInternalState] = useState(initialValue);

  useEffect(() => {
    const value = localStorage.getItem(key);

    if (!value) return;

    setInternalState(JSON.parse(value));
  }, [key]);

  const setState = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
    setInternalState(value);
  };

  return [state, setState];
}
