import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function ChangeServerUserNameModal({ server, isOpen, onClose }) {
  const ajax = useAjax();

  const [username, setUsername] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setUsername("");
      setLoading(false);
    }
  }, [isOpen]);

  async function handleSaveClicked() {
    setLoading(true);
    const data = await ajax("/servers/setUserName", {
      username,
      serverID: server._id,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="change-server-username-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.description}>
          <FormattedMessage id="change-server-username-modal.description" />
        </div>
        <div>
          <CustomText
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton
          onClick={handleSaveClicked}
          disabled={loading}
          color="purple"
        >
          <FormattedMessage id="general.save" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

ChangeServerUserNameModal.propTypes = {
  server: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangeServerUserNameModal;
