import styles from "./with-servers-groups.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useIsMounted from "ismounted";
import ChevronDownSvg from "../svgs/chevron-down.svg?react";
import CreateGroupAndAssignModal from "../modals/create-group-and-assign";
import { useDispatch } from "react-redux";
import { setServersFetchingGroup } from "../../store/settings";
import { FormattedMessage } from "react-intl";
import Filters from "../filters";
import DreamButton from "../dream-button";
import { useAjax, useLang } from "../../utils/hooks";

function WithServersGroups() {
  const lang = useLang("en");
  const ajax = useAjax();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [isOpen, setIsOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isCreateGroupAndAssignModalOpen, setIsCreateGroupAndAssignModalOpen] =
    useState(false);

  const getGroups = useCallback(async () => {
    const data = await ajax("/groups/getAll");

    if (!isMounted.current) {
      return;
    }

    if (data.result === "success") {
      const groups = [
        {
          _id: false,
          title: <FormattedMessage id="general.all" />,
          active: true,
        },
        ...data.groups.map((group) => ({
          _id: group._id,
          title: group.name,
          assigned_servers: group.assigned_servers,
          active: false,
        })),
      ];

      setGroups(groups);
      setActiveItem(groups[0]);
    }
  }, [setGroups, ajax, isMounted]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  function handleFiltersClicked(item) {
    setActiveItem(item);

    dispatch(setServersFetchingGroup(item));
  }

  async function handleCreateGroupAndAssignModalClosed(state) {
    if (state) {
      await getGroups();
    }

    setIsCreateGroupAndAssignModalOpen(false);
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.filters} ${isOpen ? styles.isOpen : ""}`}>
        <div>
          <div className={styles.title} onClick={() => setIsOpen(!isOpen)}>
            <FormattedMessage id="with-servers.filters.title" />
            <ChevronDownSvg />
          </div>
          <div>
            <Filters
              items={groups}
              activeItem={activeItem}
              onClick={handleFiltersClicked}
            />
          </div>
        </div>
        {isOpen && (
          <div className={styles.buttons}>
            <DreamButton
              color="text"
              textColor="purple"
              to={`/${lang}/my-cloud/groups`}
            >
              <FormattedMessage id="with-servers.filters.manage-groups" />
            </DreamButton>
            <DreamButton
              color="text"
              textColor="purple"
              onClick={() => setIsCreateGroupAndAssignModalOpen(true)}
            >
              <FormattedMessage id="with-servers.filters.create-new" />
            </DreamButton>
          </div>
        )}
      </div>

      <CreateGroupAndAssignModal
        isOpen={isCreateGroupAndAssignModalOpen}
        onClose={handleCreateGroupAndAssignModalClosed}
      />
    </div>
  );
}

WithServersGroups.propTypes = {
  groups: PropTypes.array,
  setGroups: PropTypes.func,
};

export default WithServersGroups;
