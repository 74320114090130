import React from "react";
import IsraelSvg from "../components/svgs/israel.svg?react";
import NetherlandsSvg from "../components/svgs/netherlands.svg?react";
import UsaSvg from "../components/svgs/usa.svg?react";

export const countries = {
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bonaire, Saint Eustatius and Saba ": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  "British Virgin Islands": "VG",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Curacao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  "Democratic Republic of the Congo": "CD",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  "East Timor": "TL",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  "Ivory Coast": "CI",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Kosovo: "XK",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "North Korea": "KP",
  "Northern Mariana Islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "Republic of the Congo": "CG",
  Reunion: "RE",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Korea": "KR",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen": "SJ",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  "U.S. Virgin Islands": "VI",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  "United States Minor Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Vatican: "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};

export const countriesHeb = {
  אפגניסטן: "AF",
  "איי אלנד": "AX",
  אלבניה: "AL",
  "אלג'יריה": "DZ",
  "סמואה האמריקנית": "AS",
  אנדורה: "AD",
  אנגולה: "AO",
  אנגילה: "AI",
  אנטארקטיקה: "AQ",
  "אנטיגואה וברבודה": "AG",
  ארגנטינה: "AR",
  ארמניה: "AM",
  ארובה: "AW",
  אוסטרליה: "AU",
  אוסטריה: "AT",
  "אזרבייג'ן": "AZ",
  "איי בהאמה": "BS",
  בחריין: "BH",
  בנגלדש: "BD",
  ברבדוס: "BB",
  בלארוס: "BY",
  בלגיה: "BE",
  בליז: "BZ",
  בנין: "BJ",
  ברמודה: "BM",
  בהוטן: "BT",
  בוליביה: "BO",
  "בונייר, סנט אוסטטיוס וסאבה": "BQ",
  "בוסניה והרצגובינה": "BA",
  בוטסוואנה: "BW",
  "איי בובה": "BV",
  ברזיל: "BR",
  "טריטוריה בריטית באוקיי": "IO",
  "איי הבתולה הבריטיים": "VG",
  ברוניי: "BN",
  בולגריה: "BG",
  "בורקינה פאסו": "BF",
  בורונדי: "BI",
  קמבודיה: "KH",
  קמרון: "CM",
  קנדה: "CA",
  "כף ורדה": "CV",
  "איי קיימן": "KY",
  "הרפובליקה המרכז-אפריקאית": "CF",
  "צ'אד": "TD",
  "צ'ילה": "CL",
  סין: "CN",
  "איי כריסטמס": "CX",
  "איי קוקוס": "CC",
  קולומביה: "CO",
  קומורוס: "KM",
  "איי קוק": "CK",
  "קוסטה ריקה": "CR",
  קרואטיה: "HR",
  קובה: "CU",
  קוראסאו: "CW",
  קפריסין: "CY",
  "הרפובליקה הצ'כית": "CZ",
  "הרפובליקה הדמוקרטית של קונגו": "CD",
  דנמרק: "DK",
  "ג'יבוטי": "DJ",
  דומיניקה: "DM",
  "הרפובליקה הדומיניקנית": "DO",
  "מזרח טימור": "TL",
  אקוודור: "EC",
  מצרים: "EG",
  "אל סלבדור": "SV",
  "גיניאה המשוונית": "GQ",
  אריתריאה: "ER",
  אסטוניה: "EE",
  אתיופיה: "ET",
  "איי פוקלנד": "FK",
  "איי פארו": "FO",
  פיגי: "FJ",
  פינלנד: "FI",
  צרפת: "FR",
  "גיאנה הצרפתית": "GF",
  "פולינזיה הצרפתית": "PF",
  "טריטוריות דרומיות של צרפת": "TF",
  גבון: "GA",
  גמביה: "GM",
  גאורגיה: "GE",
  גרמניה: "DE",
  גאנה: "GH",
  גיברלטר: "GI",
  יוון: "GR",
  גרינלנד: "GL",
  גרנדה: "GD",
  גוואדלופ: "GP",
  גואם: "GU",
  גואטמלה: "GT",
  גרנזי: "GG",
  גיניאה: "GN",
  "גיניאה-ביסאו": "GW",
  גיאנה: "GY",
  האיטי: "HT",
  "האי וורד והאי מקדונלד": "HM",
  הונדורס: "HN",
  "הונג קונג": "HK",
  הונגריה: "HU",
  איסלנד: "IS",
  הודו: "IN",
  אינדונזיה: "ID",
  איראן: "IR",
  עיראק: "IQ",
  אירלנד: "IE",
  "האי מאן": "IM",
  ישראל: "IL",
  איטליה: "IT",
  "חוף השנהב": "CI",
  "ג'מייקה": "JM",
  יפן: "JP",
  "ג'רזי": "JE",
  ירדן: "JO",
  קזחסטן: "KZ",
  קניה: "KE",
  קיריבטי: "KI",
  קוסובו: "XK",
  כווית: "KW",
  קירגיזסטן: "KG",
  לאוס: "LA",
  לטביה: "LV",
  לבנון: "LB",
  לסוטו: "LS",
  ליבריה: "LR",
  לוב: "LY",
  ליכטנשטיין: "LI",
  ליטא: "LT",
  לוקסמבורג: "LU",
  מקאו: "MO",
  מקדוניה: "MK",
  מדגסקר: "MG",
  מלאווי: "MW",
  מלזיה: "MY",
  מלדיבים: "MV",
  מאלי: "ML",
  מלטה: "MT",
  "איי מרשל": "MH",
  מרטיניק: "MQ",
  מאוריטניה: "MR",
  מאוריציוס: "MU",
  מאיוט: "YT",
  מקסיקו: "MX",
  מיקרונזיה: "FM",
  מולדובה: "MD",
  מונקו: "MC",
  מונגוליה: "MN",
  מונטנגרו: "ME",
  מונסראט: "MS",
  מרוקו: "MA",
  מוזמביק: "MZ",
  מיאנמר: "MM",
  נמיביה: "NA",
  נאורו: "NR",
  נפאל: "NP",
  הולנד: "NL",
  "קלדוניה החדשה": "NC",
  "ניו זילנד": "NZ",
  ניקרגואה: "NI",
  "ניז'ר": "NE",
  ניגריה: "NG",
  ניוו: "NU",
  "האי נורפוק": "NF",
  "צפון קוריאה": "KP",
  "איי מריאנה הצפוניים": "MP",
  נורווגיה: "NO",
  עומאן: "OM",
  פקיסטן: "PK",
  פאלאו: "PW",
  "השטחים הפלסטיניים": "PS",
  פנמה: "PA",
  "פפואה גיניאה החדשה": "PG",
  פרגוואי: "PY",
  פרו: "PE",
  פיליפינים: "PH",
  פיטקרן: "PN",
  פולין: "PL",
  פורטוגל: "PT",
  "פוארטו ריקו": "PR",
  קטאר: "QA",
  "הרפובליקה של קונגו": "CG",
  ריאוניון: "RE",
  רומניה: "RO",
  רוסיה: "RU",
  רואנדה: "RW",
  "סנט ברתולומיאו": "BL",
  "סנט הלנה": "SH",
  "סנט קיטס ונוויס": "KN",
  "סנט לוסיה": "LC",
  "סן מרטן": "MF",
  "סן פייר ומיקלון": "PM",
  "סנט וינסנט והגרנדינים": "VC",
  סמואה: "WS",
  "סן מרינו": "SM",
  "סאו טומה ופרינסיפה": "ST",
  "ערב הסעודית": "SA",
  סנגל: "SN",
  סרביה: "RS",
  סיישל: "SC",
  "סיירה לאון": "SL",
  סינגפור: "SG",
  "סינט מארטן": "SX",
  סלובקיה: "SK",
  סלובניה: "SI",
  "איי סולומון": "SB",
  סומליה: "SO",
  "דרום אפריקה": "ZA",
  "ג'ורג'יה הדרומית ואיי סנדוויץ'": "GS",
  "קוריאה הדרומית": "KR",
  "דרום סודן": "SS",
  ספרד: "ES",
  "סרי לנקה": "LK",
  סודן: "SD",
  סורינאם: "SR",
  "סוואלברד ויין": "SJ",
  סווזילנד: "SZ",
  שבדיה: "SE",
  שווייץ: "CH",
  סוריה: "SY",
  טייוואן: "TW",
  "טג'יקיסטן": "TJ",
  טנזניה: "TZ",
  תאילנד: "TH",
  טוגו: "TG",
  טוקלאו: "TK",
  טונגה: "TO",
  "טרינידד וטובגו": "TT",
  טוניסיה: "TN",
  טורקיה: "TR",
  טורקמניסטן: "TM",
  "איי טורקס וקאיקוס": "TC",
  טובאלו: "TV",
  "איי הבתולה האמריקניים": "VI",
  אוגנדה: "UG",
  אוקראינה: "UA",
  "איחוד האמירויות הערביות": "AE",
  "הממלכה המאוחדת": "GB",
  "ארצות הברית": "US",
  "האיים המרוחקים הקטנים של ארצות הברית": "UM",
  אורוגוואי: "UY",
  אוזבקיסטן: "UZ",
  ונואטו: "VU",
  וטיקן: "VA",
  ונצואלה: "VE",
  וייטנאם: "VN",
  "וואליס ופוטונה": "WF",
  "הסהרה המערבית": "EH",
  תימן: "YE",
  זמביה: "ZM",
  זימבבואה: "ZW",
};

export const countriesPhoneCodes = {
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BA: "387",
  BB: "+1-246",
  WF: "681",
  BL: "590",
  BM: "+1-441",
  BN: "673",
  BO: "591",
  BH: "973",
  BI: "257",
  BJ: "229",
  BT: "975",
  JM: "+1-876",
  BV: "",
  BW: "267",
  WS: "685",
  BQ: "599",
  BR: "55",
  BS: "+1-242",
  JE: "+44-1534",
  BY: "375",
  BZ: "501",
  RU: "7",
  RW: "250",
  RS: "381",
  TL: "670",
  RE: "262",
  TM: "993",
  TJ: "992",
  RO: "40",
  TK: "690",
  GW: "245",
  GU: "+1-671",
  GT: "502",
  GS: "",
  GR: "30",
  GQ: "240",
  GP: "590",
  JP: "81",
  GY: "592",
  GG: "+44-1481",
  GF: "594",
  GE: "995",
  GD: "+1-473",
  GB: "44",
  GA: "241",
  SV: "503",
  GN: "224",
  GM: "220",
  GL: "299",
  GI: "350",
  GH: "233",
  OM: "968",
  TN: "216",
  JO: "962",
  HR: "385",
  HT: "509",
  HU: "36",
  HK: "852",
  HN: "504",
  HM: " ",
  VE: "58",
  PR: "+1-787 and 1-939",
  PS: "970",
  PW: "680",
  PT: "351",
  SJ: "47",
  PY: "595",
  IQ: "964",
  PA: "507",
  PF: "689",
  PG: "675",
  PE: "51",
  PK: "92",
  PH: "63",
  PN: "870",
  PL: "48",
  PM: "508",
  ZM: "260",
  EH: "212",
  EE: "372",
  EG: "20",
  ZA: "27",
  EC: "593",
  IT: "39",
  VN: "84",
  SB: "677",
  ET: "251",
  SO: "252",
  ZW: "263",
  SA: "966",
  ES: "34",
  ER: "291",
  ME: "382",
  MD: "373",
  MG: "261",
  MF: "590",
  MA: "212",
  MC: "377",
  UZ: "998",
  MM: "95",
  ML: "223",
  MO: "853",
  MN: "976",
  MH: "692",
  MK: "389",
  MU: "230",
  MT: "356",
  MW: "265",
  MV: "960",
  MQ: "596",
  MP: "+1-670",
  MS: "+1-664",
  MR: "222",
  IM: "+44-1624",
  UG: "256",
  TZ: "255",
  MY: "60",
  MX: "52",
  IL: "972",
  FR: "33",
  IO: "246",
  SH: "290",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  NI: "505",
  NL: "31",
  NO: "47",
  NA: "264",
  VU: "678",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NZ: "64",
  NP: "977",
  NR: "674",
  NU: "683",
  CK: "682",
  XK: "",
  CI: "225",
  CH: "41",
  CO: "57",
  CN: "86",
  CM: "237",
  CL: "56",
  CC: "61",
  CA: "1",
  CG: "242",
  CF: "236",
  CD: "243",
  CZ: "420",
  CY: "357",
  CX: "61",
  CR: "506",
  CW: "599",
  CV: "238",
  CU: "53",
  SZ: "268",
  SY: "963",
  SX: "599",
  KG: "996",
  KE: "254",
  SS: "211",
  SR: "597",
  KI: "686",
  KH: "855",
  KN: "+1-869",
  KM: "269",
  ST: "239",
  SK: "421",
  KR: "82",
  SI: "386",
  KP: "850",
  KW: "965",
  SN: "221",
  SM: "378",
  SL: "232",
  SC: "248",
  KZ: "7",
  KY: "+1-345",
  SG: "65",
  SE: "46",
  SD: "249",
  DO: "+1-809 and 1-829",
  DM: "+1-767",
  DJ: "253",
  DK: "45",
  VG: "+1-284",
  DE: "49",
  YE: "967",
  DZ: "213",
  US: "1",
  UY: "598",
  YT: "262",
  UM: "1",
  LB: "961",
  LC: "+1-758",
  LA: "856",
  TV: "688",
  TW: "886",
  TT: "+1-868",
  TR: "90",
  LK: "94",
  LI: "423",
  LV: "371",
  TO: "676",
  LT: "370",
  LU: "352",
  LR: "231",
  LS: "266",
  TH: "66",
  TF: "",
  TG: "228",
  TD: "235",
  TC: "+1-649",
  LY: "218",
  VA: "379",
  VC: "+1-784",
  AE: "971",
  AD: "376",
  AG: "+1-268",
  AF: "93",
  AI: "+1-264",
  VI: "+1-340",
  IS: "354",
  IR: "98",
  AM: "374",
  AL: "355",
  AO: "244",
  AQ: "",
  AS: "+1-684",
  AR: "54",
  AU: "61",
  AT: "43",
  AW: "297",
  IN: "91",
  AX: "+358-18",
  AZ: "994",
  IE: "353",
  ID: "62",
  UA: "380",
  QA: "974",
  MZ: "258",
};

export const getCountryByCode = (countryCode) => {
  if (!countryCode) {
    return null;
  }

  let countryRet = false;

  Object.keys(countries).forEach((country) => {
    if (countries[country] === countryCode) {
      countryRet = country;
    }
  });

  return countryRet;
};

export const getCountryByCodeHeb = (countryCode) => {
  if (!countryCode) {
    return null;
  }
  let countryRet = false;
  Object.keys(countriesHeb).forEach((country) => {
    if (countriesHeb[country] === countryCode) {
      countryRet = country;
    }
  });
  return countryRet;
};

export const getCountryIcon = (countryCode) => {
  if (!countryCode) {
    return null;
  }

  switch (countryCode.toLowerCase()) {
    case "nl":
      return <NetherlandsSvg />;
    case "il":
    case "ta":
      return <IsraelSvg />;
    default:
      return <UsaSvg />;
  }
};

export const locationToText = (location) => {
  switch (location.toLowerCase()) {
    case "il":
      return "ישראל";
    case "nl":
      return "הולנד";
    case "us":
      return `ארה"ב`;
  }

  return null;
};
