import styles from "./server-logs.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { AutoSizer, Table, Column } from "react-virtualized";
import { useAjax, useTaskStatus } from "../../../../utils/hooks";
import { getColorOfTask, getTypeOfTask } from "../../../../utils/tasks";
import { Spinner } from "reactstrap";
import ServerLogModal from "../../../../components/modals/server-log";
import Box from "../../../../components/box";
import { FormattedMessage } from "react-intl";
import { useOutletContext } from "react-router-dom";

export default function ServerLogs() {
  const ajax = useAjax();
  const taskStatus = useTaskStatus();
  const taskStatusRef = useRef(taskStatus);
  const { server } = useOutletContext();

  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isServerLogModalOpen, setIsServerLogModalOpen] = useState(false);

  const listForServer = useCallback(async () => {
    const data = await ajax("/jobs/listForServer", {
      serverID: server._id,
    });

    if (data.result === "success") {
      data.jobs.forEach((job) => {
        job.status = taskStatusRef.current(job);
      });

      setJobs(data.jobs.reverse());
    }
  }, [ajax, server]);

  useEffect(() => {
    listForServer();
  }, [listForServer]);

  function handleServerLogModalOpen(job) {
    if (!getTdClass(job)) {
      return;
    }

    setSelectedJob(job);
    setIsServerLogModalOpen(true);
  }

  function handleServerLogModalClosed() {
    setIsServerLogModalOpen(false);
  }

  function getTdClass(job) {
    return [
      "JOB_SERVER_CREATE",
      "JOB_SERVER_CREATE_FROM_TEMPLATE",
      "JOB_CHANGE_PLAN",
    ].includes(job.type)
      ? styles.clickable
      : "";
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.box} title={" "}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              headerHeight={40}
              height={500}
              rowHeight={40}
              rowGetter={({ index }) => jobs[index]}
              rowCount={jobs.length}
              width={width}
              style={{ overflow: "visible" }}
              rowStyle={{ overflow: "visible" }}
            >
              <Column
                headerRenderer={() => <FormattedMessage id="server-log.date" />}
                dataKey=""
                width={150}
                cellRenderer={({ rowIndex }) => (
                  <div>
                    {format(
                      new Date(jobs[rowIndex].created_at),
                      "dd.MM.yy | HH:mm"
                    )}
                  </div>
                )}
              />
              <Column
                headerRenderer={() => (
                  <FormattedMessage id="server-log.action-change" />
                )}
                dataKey=""
                width={200}
                cellRenderer={({ rowIndex }) => (
                  <div
                    className={`${styles.bold} ${getTdClass(jobs[rowIndex])}`}
                    onClick={() => handleServerLogModalOpen(jobs[rowIndex])}
                  >
                    {getTypeOfTask(jobs[rowIndex])}
                  </div>
                )}
              />
              <Column
                headerRenderer={() => (
                  <FormattedMessage id="server-log.created-by" />
                )}
                dataKey="created_by"
                width={170}
              />
              <Column
                headerRenderer={() => (
                  <FormattedMessage id="server-log.status" />
                )}
                dataKey=""
                width={170}
                flexGrow={1}
                cellRenderer={({ rowIndex }) => (
                  <>
                    {jobs[rowIndex].status === -1 &&
                      jobs[rowIndex].startedAt && <Spinner />}
                    {jobs[rowIndex].status !== -1 && (
                      <b
                        style={{
                          color: jobs[rowIndex].status
                            ? getColorOfTask(jobs[rowIndex].status)
                            : "var(--light-green)",
                        }}
                      >
                        {jobs[rowIndex].status ? (
                          <FormattedMessage id={jobs[rowIndex].status} />
                        ) : (
                          <FormattedMessage id="general.success" />
                        )}
                      </b>
                    )}
                  </>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      </Box>

      <ServerLogModal
        isOpen={isServerLogModalOpen}
        onClose={handleServerLogModalClosed}
        job={selectedJob}
      />
    </div>
  );
}
