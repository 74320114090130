import React from "react";
import PropTypes from "prop-types";
import { Label, Input } from "reactstrap";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";

function Checkbox({
  label,
  checked,
  onChange,
  onClick,
  readOnly,
  disabled,
  className,
}) {
  return (
    <Label
      className={`${styles.checkboxWrapper} ${
        disabled ? styles.disabled : ""
      } ${className || ""}`}
      check
    >
      <Input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        readOnly={readOnly}
        className={styles.checkboxInput} // Add a class for styling purposes
      />
      <span className={styles.checkmark}></span>

      {label && (
        <>
          {typeof label === "string" && <FormattedMessage id={label} />}
          {typeof label === "object" && label}
        </>
      )}
    </Label>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Checkbox;
