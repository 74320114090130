import styles from "./layout.module.scss";

import React from "react";
import { Outlet, useLocation, useParams, Link } from "react-router-dom";
import { useLang, useRoles, useUser } from "../../utils/hooks";
import Box from "../box";
import { FormattedMessage } from "react-intl";
import { SUPER_ADMIN } from "../../utils/user";
import { useOutletContext } from "react-router-dom";

function ServerSettingsLayout() {
  const { id } = useParams();
  const lang = useLang("en");
  const user = useUser();
  const { isAllowed } = useRoles();
  const location = useLocation();
  const { server } = useOutletContext();

  return (
    <>
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="server-settings-layout.description" />
      </Box>

      <div className={styles.wrapper}>
        <div className={styles.navWrapper}>
          <div className={styles.nav}>
            {server.status === "Active" && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/ipv4") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/ipv4"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/ipv4`}
                >
                  <FormattedMessage id="server-settings-layout.ipv4" />
                </Link>
              </div>
            )}

            <div
              className={`${styles.item} ${
                location.pathname.endsWith("/server-settings/firewall") &&
                styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/firewall"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/firewall`}
              >
                <FormattedMessage id="server-settings-layout.firewall" />
              </Link>
            </div>

            {isAllowed("servers") && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/addons") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/addons"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/addons`}
                >
                  <FormattedMessage id="server-settings-layout.addons" />
                </Link>
              </div>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/boot-order") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/boot-order"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/boot-order`}
                >
                  <FormattedMessage id="server-settings-layout.boot-order" />
                </Link>
              </div>
            )}

            {(user.role === SUPER_ADMIN ||
              user.email.endsWith("@terrazone.io") ||
              user.email.endsWith("@safe-t.com")) && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/hotplug") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/hotplug"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/hotplug`}
                >
                  <FormattedMessage id="server-settings-layout.hotplug" />
                </Link>
              </div>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <>
                <div
                  className={`${styles.item} ${
                    location.pathname.endsWith(
                      "/server-settings/change-plan"
                    ) && styles.selected
                  }`}
                >
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/server-settings/change-plan"
                    to={`/${lang}/my-cloud/servers/${id}/server-settings/change-plan`}
                  >
                    <FormattedMessage id="server-settings-layout.change-plan" />
                  </Link>
                </div>

                {/* <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/manage-hosting") &&
                  styles.selected
                }`}
              >
                <Link
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/manage-hosting`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.manage-hosting" />
                  </a>
                </Link>
              </div> */}

                {/* <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/manage-addons") &&
                  styles.selected
                }`}
              >
                <Link
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/manage-addons`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.manage-addons" />
                  </a>
                </Link>
              </div> */}

                {/* <div
                className={`${styles.item} ${
                  location.pathname.endsWith("/server-settings/server-monitor") &&
                  styles.selected
                }`}
              >
                <Link
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/server-monitor`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.server-monitor" />
                  </a>
                </Link>
              </div> */}
              </>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <>
                <div
                  className={`${styles.item} ${
                    location.pathname.endsWith("/server-settings/change-os") &&
                    styles.selected
                  }`}
                >
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/server-settings/change-os"
                    to={`/${lang}/my-cloud/servers/${id}/server-settings/change-os`}
                  >
                    <FormattedMessage id="server-settings-layout.change-os" />
                  </Link>
                </div>

                <div
                  className={`${styles.item} ${
                    location.pathname.endsWith("/server-settings/custom-iso") &&
                    styles.selected
                  }`}
                >
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/server-settings/custom-iso"
                    to={`/${lang}/my-cloud/servers/${id}/server-settings/custom-iso`}
                  >
                    <FormattedMessage id="server-settings-layout.custom-iso" />
                  </Link>
                </div>
              </>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith(
                    "/server-settings/change-payment"
                  ) && styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/change-payment"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/change-payment`}
                >
                  <FormattedMessage id="server-settings-layout.change-payment" />
                </Link>
              </div>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <div
                className={`${styles.item} ${
                  location.pathname.endsWith(
                    "/server-settings/change-password"
                  ) && styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/change-password"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/change-password`}
                >
                  <FormattedMessage id="server-settings-layout.change-password" />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className={styles.children}>
          <Outlet context={{ server }} />
        </div>
      </div>
    </>
  );
}

export default ServerSettingsLayout;
