import React from "react";
import UserAvatarPng from "../svgs/user-avatar.png";

export default function UserAvatar(user) {
  let profilePicture;

  if (user) {
    profilePicture = user.profilePicture;
  }

  return (
    <div>
      <img src={profilePicture || UserAvatarPng} width={36} height={36} />
    </div>
  );
}
