import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useAjax } from "../../../utils/hooks";
import PlusSvg from "../../svgs/plus.svg?react";
import MinusSvg from "../../svgs/minus.svg?react";
import DownloadSvg from "../../svgs/download.svg?react";
import LinkSvg from "../../svgs/link.svg?react";
import FolderSvg from "../../svgs/folder.svg?react";
import FileSvg from "../../svgs/file.svg?react";
import BlockSvg from "../../svgs/block.svg?react";
import BackupLinkGeneratedModal from "../backup-link-generated";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import ClickTable from "../../click-table";
import DreamButton from "../../dream-button";
import { readableBytes } from "../../../utils/servers";

function RestoreFileDirectoryModal({ server, isOpen, onClose, backup }) {
  const ajax = useAjax();

  const [files, setFiles] = useState(null);

  const [selectedUrl, setSelectedUrl] = useState(null);

  const [isBackupLinkGeneratedModalOpen, setIsBackupLinkGeneratedModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const volumeID = useMemo(() => {
    if (!backup) {
      return "";
    }

    const date = new Date(backup["backup-time"] * 1000);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

    return `PBS2:backup/${backup["backup-type"]}/${backup["backup-id"]}/${formattedDate}`;
  }, [backup]);

  const listFilesInBackup = useCallback(async () => {
    const data = await ajax("/backups/listFilesInBackup", {
      serverID: server._id,
      volumeID,
      filepath: "/",
    });

    if (data.files) {
      data.files.forEach((file) => {
        if (file.text.match(/drive-scsi[0-9]+.img\.fidx/)) {
          const diskNum = parseInt(file.text.match(/\d+/)[0]);
          file.text = `Disk ${diskNum + 1}`;
        }
      });
    }

    setFiles(data.files);
  }, [volumeID, server, ajax]);

  useEffect(() => {
    if (isOpen) {
      listFilesInBackup();
    }
  }, [isOpen, listFilesInBackup]);

  async function handleFileClicked(file) {
    if (file.leaf !== 0) {
      return;
    }

    if (file.files) {
      delete file.files;
      setFiles([...files]);
      return;
    }

    setLoading(true);

    const data = await ajax("/backups/listFilesInBackup", {
      serverID: server._id,
      volumeID,
      filepath: file.filepath,
    });

    setLoading(false);

    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        if (file.text === "part") {
          file.text = "Partition";
        }
      });

      file.files = data.files;
    } else {
      file.files = [{ empty: true }];
    }

    setFiles([...files]);
  }

  async function handleDownloadClicked(e, file) {
    let elm = e.target;

    while (elm.tagName !== "A") {
      elm = elm.parentElement;
    }

    if (elm.getAttribute("fetched")) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const data = await ajax("/backups/prepareFileForDownload", {
      serverID: server._id,
      volid: volumeID,
      file,
    });

    elm.href = data.url;
    elm.setAttribute("fetched", "1");
    elm.click();
  }

  async function handleGenerateLinkClicked(e, file) {
    e.stopPropagation();

    const data = await ajax("/backups/generateLink", {
      serverID: server._id,
      volid: volumeID,
      file,
    });

    setSelectedUrl(data.url);

    setIsBackupLinkGeneratedModalOpen(true);
  }

  function handleBackupLinkGeneratedModalClosed() {
    setIsBackupLinkGeneratedModalOpen(false);
  }

  function renderType(type) {
    switch (type) {
      case "v":
        return "Virtual";
      case "d":
        return "Directory";
      case "f":
        return "File";
      default:
        return type;
    }
  }

  function handleSortFiles(a, b) {
    return a.text - b.text;
  }

  function renderFiles(files, indentation) {
    return files.sort(handleSortFiles).map((file, key) => (
      <Fragment key={key}>
        <tr onClick={() => handleFileClicked(file)}>
          <td>
            <div
              className={styles.fileNameWrapper}
              style={{ marginInlineStart: `${indentation * 20 + 50}px` }}
            >
              {indentation > 0 && <div className={styles.line}></div>}
              {indentation > 0 && <div className={styles.lineH}></div>}
              {file.leaf === 0 &&
                (file.files ? (
                  <MinusSvg className={styles.expandSvg} />
                ) : (
                  <PlusSvg className={styles.expandSvg} />
                ))}{" "}
              {file.type === "v" && <BlockSvg className={styles.fileSvg} />}
              {file.type === "d" && <FolderSvg className={styles.fileSvg} />}
              {file.type === "f" && <FileSvg className={styles.fileSvg} />}
              {file.empty ? (
                <FormattedMessage id="restore-file-directory-modal.directory-empty" />
              ) : (
                file.text
              )}
            </div>
          </td>
          <td>{file.size && readableBytes(file.size)}</td>
          <td>
            {file.mtime && format(new Date(file.mtime), "dd/MM/yy HH:mm:ss")}
          </td>
          <td>{renderType(file.type)}</td>
          <td>
            {file.type !== "v" && !file.empty && (
              <a
                onClick={(e) => handleDownloadClicked(e, file)}
                href="#"
                target="_blank"
                rel="noreferrer"
              >
                <DownloadSvg className={styles.downloadSvg} />
              </a>
            )}
          </td>
          <td>
            {file.type !== "v" && !file.empty && (
              <LinkSvg
                className={styles.linkSvg}
                onClick={(e) => handleGenerateLinkClicked(e, file)}
              />
            )}
          </td>
        </tr>

        {file.files && renderFiles(file.files, indentation + 1)}
      </Fragment>
    ));
  }

  if (!backup) {
    return null;
  }

  return (
    <>
      <Modal
        className={styles.wrapper}
        isOpen={isOpen}
        toggle={() => onClose(false)}
        size="xl"
      >
        <ModalHeader toggle={() => onClose(false)}>
          <FormattedMessage id="restore-file-directory-modal.title" />
        </ModalHeader>
        <ModalBody>
          <div className={styles.description1}>
            <FormattedMessage id="restore-file-directory-modal.description1" />
          </div>
          <div className={styles.description2}>
            <FormattedMessage id="restore-file-directory-modal.description2" />
          </div>

          <ClickTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="general.name" />
                </th>
                <th>
                  <FormattedMessage id="general.size" />
                </th>
                <th>
                  <FormattedMessage id="general.modified" />
                </th>
                <th>
                  <FormattedMessage id="general.type" />
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className={loading ? styles.loading : ""}>
              {!files && (
                <tr>
                  <td colSpan={6}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {files && renderFiles(files, 0)}
            </tbody>
          </ClickTable>

          {loading && <Spinner className={styles.loadingSpinner} />}
        </ModalBody>
        <ModalFooter>
          <DreamButton
            disabled={loading}
            color="text"
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="general.close" />
          </DreamButton>
        </ModalFooter>
      </Modal>

      <BackupLinkGeneratedModal
        isOpen={isBackupLinkGeneratedModalOpen}
        onClose={handleBackupLinkGeneratedModalClosed}
        url={selectedUrl}
      />
    </>
  );
}

RestoreFileDirectoryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  backup: PropTypes.object,
};

export default RestoreFileDirectoryModal;
