import styles from "./password.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import GeneratePasswordModal from "../modals/generate-password";
import { FormattedMessage } from "react-intl";
import CustomText from "../custom-text";
import DreamButton from "../dream-button";
import { useAjax, useAlert, useUser } from "../../utils/hooks";
import PasswordStrengthBar from "../password-strength-bar";

function SecurityPassword({ inModal }) {
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] =
    useState(false);

  async function handleChangeClicked() {
    setError(false);

    let data;
    if (password === "snooze" && passwordConfirm === "snooze") {
      setLoading(true);
      data = await ajax("/users/snoozePassword");
      setLoading(false);
    } else {
      if (passwordScore <= 1) {
        return setError(<FormattedMessage id="general.weak-password" />);
      } else if (password !== passwordConfirm) {
        return setError(<FormattedMessage id="general.password-mismatch" />);
      }

      setLoading(true);
      data = await ajax("/users/updatePassword", {
        password,
      });
      setLoading(false);
    }

    if (data.result === "error") {
      return setError(<FormattedMessage id="general.same-password" />);
    }

    if (inModal) {
      inModal();
    } else {
      alert({
        title: (
          <FormattedMessage id="security-password.change-password.title" />
        ),
        message: (
          <FormattedMessage id="security-password.change-password.content" />
        ),
        notification: true,
      });
    }
  }

  function handleGeneratePasswordModalOpen() {
    setIsGeneratePasswordModalOpen(true);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordConfirm(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  if (!user._id) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {user.passwordLastTimeChanged && (
        <div className={styles.lastTimeWrapper}>
          <FormattedMessage
            id={
              inModal
                ? "security-password.last-time-changed-in-modal"
                : "security-password.last-time-changed"
            }
            values={{
              date: format(
                new Date(user.passwordLastTimeChanged),
                "dd/MM/yy HH:mm"
              ),
            }}
          />
        </div>
      )}

      <div className={styles.row}>
        <label>
          <span>
            <FormattedMessage id="security-password.new-password" />
          </span>
          <CustomText
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
      </div>
      <div className={styles.row}>
        <label>
          <span>
            <FormattedMessage id="security-password.confirm-password" />
          </span>
          <CustomText
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </label>
      </div>

      <div className={styles.passwordStrengthBarWrapper}>
        <PasswordStrengthBar
          password={password}
          onChangeScore={setPasswordScore}
        />
      </div>

      {error && <div className={`error ${styles.error}`}>{error}</div>}

      <div
        className={`${styles.buttonsWrapper} ${inModal ? styles.inModal : ""}`}
      >
        <DreamButton
          disabled={loading}
          color="light-purple"
          onClick={handleChangeClicked}
        >
          {password === "snooze" && passwordConfirm === "snooze" ? (
            <FormattedMessage id="security-password.snooze" />
          ) : (
            <FormattedMessage id="security-password.change" />
          )}
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="light-purple"
          onClick={handleGeneratePasswordModalOpen}
        >
          <FormattedMessage id="security-password.generate" />
        </DreamButton>
      </div>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </div>
  );
}

SecurityPassword.propTypes = {
  inModal: PropTypes.func,
};

export default SecurityPassword;
