import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getTranslatedLog } from "../../../utils/logs";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import ClickTable from "../../click-table";
import Spinner from "../../spinner";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function IpHistoryModal({ ip, isOpen, onClose }) {
  const ajax = useAjax();

  const [logs, setLogs] = useState(null);

  const fetchIpHistory = useCallback(async () => {
    setLogs(null);

    const data = await ajax("/network/fetchIpHistory", { ip });

    setLogs(data.logs);
  }, [ajax, ip]);

  useEffect(() => {
    if (isOpen) {
      fetchIpHistory();
    }
  }, [isOpen, fetchIpHistory]);

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="ip-history-modal.title" />
      </ModalHeader>
      <ModalBody>
        <ClickTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="general.hostname" />
              </th>
              <th>
                <FormattedMessage id="general.type" />
              </th>
              <th>
                <FormattedMessage id="general.date" />
              </th>
            </tr>
          </thead>
          <tbody>
            {!logs && (
              <tr>
                <td>
                  <Spinner />
                </td>
              </tr>
            )}

            {logs?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {logs?.map((log, key) => (
              <tr key={key}>
                <td>{log.data.hostname}</td>
                <td>
                  <div>{getTranslatedLog(log)}</div>
                </td>
                <td>
                  {format(new Date(log.created_at), "dd/MM/yyyy HH:mm:ss")}
                </td>
              </tr>
            ))}
          </tbody>
        </ClickTable>
      </ModalBody>
      <ModalFooter>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

IpHistoryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  ip: PropTypes.string,
};

export default IpHistoryModal;
