import styles from "./servers-list.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import IsraelSvg from "../svgs/hebrew.svg?react";
import NetherlandsSvg from "../svgs/netherlands.svg?react";
import UsaSvg from "../svgs/usa.svg?react";
import RunningSvg from "../svgs/running.svg?react";
import PlaySvg from "../svgs/play.svg?react";
import TerminatedSvg from "../svgs/terminated.svg?react";
import StoppedSvg from "../svgs/stopped.svg?react";
import EllipseSvg from "../svgs/ellipse.svg?react";
import LongArrowLeftSvg from "../svgs/long-arrow-left.svg?react";
import LongArrowRightSvg from "../svgs/long-arrow-right-purple.svg?react";
import ChevronDownSvg from "../svgs/chevron-down.svg?react";
import Overview from "../server/overview";
import {
  useAjax,
  useConfirm,
  useFailedActionHandler,
  useLang,
  usePrompt,
  useRoles,
  useServers,
  useUser,
} from "../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getOsLogo } from "../../utils/servers";
import { handleServerActionClicked } from "../server-layout/server-actions";
import TakeSnapshotModal from "../modals/take-snapshot";
import { DropdownItem } from "reactstrap";
import {
  setMaxServersToShow,
  setServersFetchingFilter,
  setServersPage,
} from "../../store/settings";
import NoServers from "./no-servers";
import { differenceInSeconds } from "date-fns";
import { FormattedMessage } from "react-intl";
import Spinner from "../spinner";
import ClipboardCopy from "../clipboard-copy";
import Checkbox from "../checkbox";
import CustomTag from "../custom-tag";
import CustomMenu from "../custom-menu";
import CustomMenuItem from "../custom-menu/item";
import CustomReactSelect from "../custom-react-select";
import CustomText from "../custom-text";
import { SUPER_ADMIN, USER, WHITELABEL } from "../../utils/user";

function ServersList({ selectedServersIds, setSelectedServersIds }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useLang("en");
  const user = useUser();
  const ajax = useAjax();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const servers = useServers();
  const failedActionHandler = useFailedActionHandler();
  const { isAllowed } = useRoles();
  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const maxToShowOptions = [
    { label: <FormattedMessage id="servers-list.10" />, value: 10 },
    { label: <FormattedMessage id="servers-list.20" />, value: 20 },
    { label: <FormattedMessage id="servers-list.30" />, value: 30 },
    { label: <FormattedMessage id="servers-list.50" />, value: 50 },
    { label: <FormattedMessage id="servers-list.100" />, value: 100 },
  ];

  const [selectedServer, setSelectedServer] = useState(null);
  const [isServerMenuOpen, setIsServerMenuOpen] = useState([]);
  const [expandedServers, setExpandedServers] = useState({});
  const [isTakeSnapshotModalOpen, setIsTakeSnapshotModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setServersPage(0));
  }, [dispatch, serversFetching.filter]);

  function renderServerStatus(server, mobile = false) {
    if (server.status !== "Active") {
      return <TerminatedSvg />;
    }

    let status;
    if (server.isWorking || !server.is_ready) {
      status = <Spinner />;
    } else {
      if (server.running) {
        status = mobile ? <PlaySvg /> : <RunningSvg />;
      } else {
        status = mobile ? null : <StoppedSvg />;
      }
    }

    if (server.lockMode) {
      //
    }

    return status;
  }

  function toggleServerMenu(index) {
    isServerMenuOpen[index] = !isServerMenuOpen[index];
    setIsServerMenuOpen([...isServerMenuOpen]);
  }

  function handleItemClicked(serverID) {
    expandedServers[serverID] = !expandedServers[serverID];
    setExpandedServers({ ...expandedServers });
  }

  function handleCheckboxChanged(serverID) {
    if (serverID) {
      selectedServersIds[serverID] = !selectedServersIds[serverID];
      setSelectedServersIds({ ...selectedServersIds });

      return;
    }

    const switchTo = !isAllCheckboxChecked();

    servers.forEach((server) => {
      if (typeof selectedServersIds[server._id] !== "boolean") {
        selectedServersIds[server._id] = false;
      }
    });

    Object.keys(selectedServersIds).forEach((serverID) => {
      selectedServersIds[serverID] = switchTo;
    });
    setSelectedServersIds({ ...selectedServersIds });
  }

  function isAllCheckboxChecked() {
    const keys = Object.keys(selectedServersIds);

    if (keys.length !== servers.length) {
      return false;
    }

    return !keys.find((serverID) => !selectedServersIds[serverID]);
  }

  function handleTakeSnapshotModalOpen(server) {
    setSelectedServer(server);
    setIsTakeSnapshotModalOpen(true);
  }

  function handleTakeSnapshotModalClosed() {
    setIsTakeSnapshotModalOpen(false);
  }

  function getLocationSvg(location) {
    switch (location) {
      case "NL":
        return <NetherlandsSvg />;
      case "US":
        return <UsaSvg />;
    }

    return <IsraelSvg />;
  }

  function renderMainIP(server) {
    if (!server.is_ready) {
      return <div className={styles.notReadyWrapper}></div>;
    }

    return (
      <div
        className={styles.mainIPWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        {server.dedicatedip && (
          <>
            <span className={styles.mainIP}>
              <span className={styles.bold}>
                <FormattedMessage id="general.main-ip" />:{" "}
              </span>
              {server.dedicatedip}{" "}
            </span>
            <ClipboardCopy text={server.dedicatedip}></ClipboardCopy>
          </>
        )}
        {!server.dedicatedip && (
          <span className={styles.mainIP}>
            <span className={styles.bold}>
              <FormattedMessage id="general.no-ip" />
            </span>
          </span>
        )}
      </div>
    );
  }

  function getCpuUsage(server) {
    if (!server.proxmox_cpu || !server.running) {
      return 0;
    }

    const value = Math.min(100, (server.proxmox_cpu || 0) * 100);

    return value;
  }

  function getRamUsage(server) {
    if (!server.proxmox_mem || !server.running) {
      return 0;
    }

    const value = Math.min(
      100,
      (server.proxmox_mem / server.proxmox_maxmem || 0) * 100
    );

    return value;
  }

  function getColor(value) {
    const hue = ((1 - value) * 120).toString(10);

    return ["hsl(", hue, ",100%,50%)"].join("");
  }

  function renderPerformances(server) {
    const cpu = getCpuUsage(server);
    let cpuColor = getColor(cpu / 100);

    const ram = getRamUsage(server);
    let ramColor = getColor(ram / 100);

    const diffInSeconds = differenceInSeconds(
      new Date(server.proxmox_live_net_time),
      new Date(server.proxmox_live_net_time_before)
    );

    let netin, netout;
    if (server.running) {
      netin =
        ((server.proxmox_live_netin - server.proxmox_live_netin_before) *
          0.000008) /
        diffInSeconds;
      netout =
        ((server.proxmox_live_netout - server.proxmox_live_netout_before) *
          0.000008) /
        diffInSeconds;
    } else {
      netin = 0;
      netout = 0;
    }

    netin = isNaN(netin) ? 0 : netin;
    netout = isNaN(netout) ? 0 : netout;

    const netinPrec = (netin / (server.proxmox_vmbr_rate * 8)) * 100;
    let netinColor = getColor(netin / (server.proxmox_vmbr_rate * 8));

    const netoutPrec = (netout / (server.proxmox_vmbr_rate * 8)) * 100;
    let netoutColor = getColor(netout / (server.proxmox_vmbr_rate * 8));

    if (user.general_settings.cpu_ram === "solid") {
      cpuColor = "#daddf9";
      ramColor = "#daddf9";
      netinColor = "#daddf9";
      netoutColor = "#daddf9";
    }

    if (user.general_settings.cpu_ram === "hide" || !server.is_ready) {
      return <div className={styles.slidersNoReadyWrapper}></div>;
    }

    return (
      <div>
        <div>
          <div className={styles.sliderWrapper}>
            <div style={{ width: `${cpu}%`, backgroundColor: cpuColor }}>
              <span>CPU: {cpu.toFixed(2)}%</span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.sliderWrapper}>
            <div style={{ width: `${ram}%`, backgroundColor: ramColor }}>
              <span>RAM: {ram.toFixed(2)}%</span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.sliderWrapper}>
            <div
              style={{
                width: `${netinPrec}%`,
                backgroundColor: netinColor,
              }}
            >
              <span>Inbound traffic: {netin.toFixed(2)}Mbit/s</span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.sliderWrapper}>
            <div
              style={{
                width: `${netoutPrec}%`,
                backgroundColor: netoutColor,
              }}
            >
              <span>Outbound traffic: {netout.toFixed(2)}Mbit/s</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderServerRow(server, key) {
    return (
      <div key={key}>
        <div
          className={`${styles.serverWrapper} ${
            expandedServers[server._id] ? styles.isOpen : ""
          } ${user.role === USER ? "" : styles.admin} ${
            user.general_settings.cpu_ram === "hide" ? styles.short : ""
          } ${serversFetching.loading ? "disabled" : ""}`}
        >
          <div className={styles.item}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                checked={selectedServersIds[server._id] || false}
                onChange={() => handleCheckboxChanged(server._id)}
              />
            </div>
            <div className={styles.domainWrapper}>
              <div className={styles.domain}>
                {!server.is_ready && server.hostname}
                {server.is_ready && (
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/overview"
                    to={`/${lang}/my-cloud/servers/${server._id}/overview`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {server.hostname}
                  </Link>
                )}
              </div>
              {renderMainIP(server)}
              {server.belong_to_firewall_id && (
                <div className={styles.userEmailWrapper}>
                  * <FormattedMessage id="belongs-to-firewall" />
                </div>
              )}
              {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                <div className={styles.userEmailWrapper}>
                  {server.user?.email}
                </div>
              )}
            </div>
            <div className={styles.osImage}>
              <img
                src={`/img/${getOsLogo(server.image)}`}
                width={24}
                height={24}
              />{" "}
            </div>
            <div className={styles.countryWrapper}>
              {getLocationSvg(server.location)}
            </div>
            <div className={styles.performancesWrapper}>
              {renderPerformances(server)}
            </div>
            <div className={styles.tagWrapper}>
              {server.tag && <CustomTag>{server.tag}</CustomTag>}
            </div>
            <div className={styles.statusWrapper}>
              {renderServerStatus(server)}
            </div>
            <div className={styles.statusWrapperMobile}>
              {renderServerStatus(server, true)}
            </div>
            <div
              className={styles.actionsWrapper}
              onClick={(e) => e.stopPropagation()}
            >
              {server.status === "Active" && server.is_ready && (
                <CustomMenu
                  isOpen={isServerMenuOpen[key]}
                  toggle={() => toggleServerMenu(key)}
                  className={styles.serverActionsMenu}
                >
                  <CustomMenuItem
                    to={`/${lang}/my-cloud/servers/${server._id}/overview`}
                  >
                    <FormattedMessage id="servers-list.server-details" />
                  </CustomMenuItem>

                  {isAllowed("servers") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      to={`/${lang}/my-cloud/servers/${server._id}/console`}
                    >
                      <FormattedMessage id="servers-list.view-console" />
                    </CustomMenuItem>
                  )}

                  <DropdownItem divider />

                  {isAllowed("servers") && !server.running && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "start-server",
                          server,
                          ajax,
                          lang,
                          confirm,
                          prompt,
                          dispatch,
                          navigate,
                          failedActionHandler
                        )
                      }
                    >
                      <FormattedMessage id="servers-list.start-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers") && server.running && (
                    <>
                      {server.ping && (
                        <>
                          <CustomMenuItem
                            disabled={server.isWorking}
                            onClick={() =>
                              handleServerActionClicked(
                                "soft-shutdown",
                                server,
                                ajax,
                                lang,
                                confirm,
                                prompt,
                                dispatch,
                                navigate,
                                failedActionHandler
                              )
                            }
                          >
                            <FormattedMessage id="servers-list.soft-shutdown-server" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            disabled={server.isWorking}
                            onClick={() =>
                              handleServerActionClicked(
                                "soft-reboot",
                                server,
                                ajax,
                                lang,
                                confirm,
                                prompt,
                                dispatch,
                                navigate,
                                failedActionHandler
                              )
                            }
                          >
                            <FormattedMessage id="servers-list.soft-reboot-server" />
                          </CustomMenuItem>
                        </>
                      )}

                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "hard-shutdown",
                            server,
                            ajax,
                            lang,
                            confirm,
                            prompt,
                            dispatch,
                            navigate,
                            failedActionHandler
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.hard-shutdown-server" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "hard-reboot",
                            server,
                            ajax,
                            lang,
                            confirm,
                            prompt,
                            dispatch,
                            navigate,
                            failedActionHandler
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.hard-reboot-server" />
                      </CustomMenuItem>

                      <DropdownItem divider />
                    </>
                  )}

                  {isAllowed("servers") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "reinstall",
                          server,
                          ajax,
                          lang,
                          confirm,
                          prompt,
                          dispatch,
                          navigate,
                          failedActionHandler
                        )
                      }
                    >
                      <FormattedMessage id="servers-list.reinstall-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "clone",
                          server,
                          ajax,
                          lang,
                          confirm,
                          prompt,
                          dispatch,
                          navigate,
                          failedActionHandler
                        )
                      }
                    >
                      <FormattedMessage id="servers-list.clone-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers") && (
                    <>
                      <DropdownItem divider />

                      <CustomMenuItem
                        disabled={server.isWorking}
                        color="red"
                        onClick={() =>
                          handleServerActionClicked(
                            "destroy",
                            server,
                            ajax,
                            lang,
                            confirm,
                            prompt,
                            dispatch,
                            navigate,
                            failedActionHandler
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.destroy-server" />
                      </CustomMenuItem>
                    </>
                  )}
                </CustomMenu>
              )}
            </div>
          </div>

          <div className={styles.hr}>
            {server.is_ready && <hr />}
            {!server.is_ready && <div className={styles.nonHrGap}></div>}
          </div>

          {!expandedServers[server._id] && server.is_ready && (
            <div
              className={`${styles.downArrowWrapper} ${
                user.role === USER ? "" : styles.admin
              }`}
              onClick={() => handleItemClicked(server._id)}
            >
              <div>
                <ChevronDownSvg />
              </div>
            </div>
          )}

          <div
            className={styles.overviewWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {expandedServers[server._id] && <Overview server={server} />}
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            className={styles.quickActions}
          >
            {isAllowed("servers") && !server.running && (
              <>
                <span
                  onClick={() =>
                    handleServerActionClicked(
                      "start-server",
                      server,
                      ajax,
                      lang,
                      confirm,
                      prompt,
                      dispatch,
                      navigate,
                      failedActionHandler
                    )
                  }
                >
                  <FormattedMessage id="servers-list.start" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers") && server.running && (
              <>
                <span
                  className={server.isWorking ? "link-disabled" : ""}
                  onClick={() =>
                    handleServerActionClicked(
                      server.ping ? "soft-reboot" : "hard-reboot",
                      server,
                      ajax,
                      lang,
                      confirm,
                      prompt,
                      dispatch,
                      navigate,
                      failedActionHandler
                    )
                  }
                >
                  <FormattedMessage id="servers-list.restart" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers") && (
              <>
                <span
                  className={server.isWorking ? "link-disabled" : ""}
                  onClick={() => handleTakeSnapshotModalOpen(server)}
                >
                  <FormattedMessage id="servers-list.snapshot" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers") && (
              <>
                <span>
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/console"
                    to={`/${lang}/my-cloud/servers/${server._id}/console`}
                    className={server.isWorking ? "link-disabled" : ""}
                  >
                    <FormattedMessage id="servers-list.console" />
                  </Link>
                </span>
                <EllipseSvg />
              </>
            )}

            <span>
              <Link
                href="/[lang]/my-cloud/servers/[id]/overview"
                to={`/${lang}/my-cloud/servers/${server._id}/overview`}
              >
                <FormattedMessage id="servers-list.full-view" />
              </Link>
            </span>
          </div>

          {expandedServers[server._id] && (
            <div
              className={styles.upArrowWrapper}
              onClick={() => handleItemClicked(server._id)}
            >
              <ChevronDownSvg />
            </div>
          )}
        </div>
        <div style={{ height: "16px" }}></div>
      </div>
    );
  }

  const maxPages = useMemo(
    () =>
      parseInt(
        serversFetching.totalServers / serversFetching.maxServersToShow.value
      ) + 1,
    [serversFetching.maxServersToShow.value, serversFetching.totalServers]
  );

  function renderPagination(position) {
    return (
      <div className={`${styles.paginationWrapper} ${styles[position]}`}>
        <div className={styles.maxServersToShowWrapper}>
          <CustomReactSelect
            options={maxToShowOptions}
            value={serversFetching.maxServersToShow}
            onChange={(item) => dispatch(setMaxServersToShow(item))}
          />
        </div>

        <div className={styles.text}>
          {`${serversFetching.page + 1} / ${maxPages}`}{" "}
          <FormattedMessage id="general.total" />
        </div>
        <div
          className={`${styles.box} ${styles.first} ${
            serversFetching.page === 0 ? styles.disabled : ""
          }`}
          onClick={() => dispatch(setServersPage(serversFetching.page - 1))}
        >
          <LongArrowLeftSvg />
        </div>
        <div
          className={`${styles.box} ${
            serversFetching.page + 1 === maxPages ? styles.disabled : ""
          }`}
          onClick={() => dispatch(setServersPage(serversFetching.page + 1))}
        >
          <LongArrowRightSvg />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.listHeader}>
        <div className={`${styles.item} ${styles.checkbox}`}>
          <Checkbox
            checked={isAllCheckboxChecked()}
            onChange={() => handleCheckboxChanged()}
          />
        </div>

        <CustomText
          className={styles.filterInput}
          placeholder={<FormattedMessage id="servers-list.filter-servers" />}
          value={serversFetching.filter}
          onChange={(e) => dispatch(setServersFetchingFilter(e.target.value))}
        />

        {renderPagination("top")}
      </div>

      {servers.length === 0 && <NoServers />}

      {servers.map((server, key) => renderServerRow(server, key))}

      {renderPagination("bottom")}

      <TakeSnapshotModal
        isOpen={isTakeSnapshotModalOpen}
        onClose={handleTakeSnapshotModalClosed}
        server={selectedServer}
      />
    </>
  );
}

ServersList.propTypes = {
  selectedServersIds: PropTypes.object,
  setSelectedServersIds: PropTypes.func,
};

export default ServersList;
