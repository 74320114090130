import React from "react";
import { updateIsWorking } from "../../store/servers";
import { setServerToClone } from "../../store/settings";
import { FormattedMessage } from "react-intl";

async function reinstall(server, ajax, lang, prompt, navigate) {
  const val = await prompt({
    title: <FormattedMessage id="server-layout.reinstall.title" />,
    message: (
      <FormattedMessage
        id="server-layout.reinstall.message"
        values={{ hostname: server.hostname }}
      />
    ),
    button1: {
      text: <FormattedMessage id="general.was-mistake" />,
      color: "white",
    },
    button2: {
      text: <FormattedMessage id="general.yes-sure" />,
      color: "white",
    },
    acceptOnlyValue: server.hostname,
  });

  if (val === server.hostname) {
    await ajax("/servers/reinstall", { serversIds: [server._id] });
    navigate(`/${lang}/my-cloud/servers`);
  }
}

function destroy(server, ajax, lang, prompt, navigate) {
  prompt({
    title: <FormattedMessage id="server-layout.destroy.title" />,
    message: (
      <FormattedMessage
        id="server-layout.destroy.message"
        values={{ hostname: server.hostname }}
      />
    ),
    button1: {
      text: <FormattedMessage id="general.was-mistake" />,
      color: "white",
    },
    button2: {
      text: <FormattedMessage id="general.yes-sure" />,
      color: "white",
    },
    acceptOnlyValue: server.hostname,
    withCheckbox: "server-layout.destroy.with-backups",
    beforeClose: async (val, withBackups) => {
      if (val === server.hostname) {
        await ajax("/servers/destroy", {
          serversIds: [server._id],
          withBackups,
        });
        navigate(`/${lang}/my-cloud/servers`);
      }
    },
  });
}

async function serverAction(
  action,
  server,
  ajax,
  dispatch,
  failedActionHandler
) {
  let actionType;
  let isSoft = false;

  switch (action) {
    case "start-server": {
      actionType = "start";
      break;
    }
    case "soft-shutdown": {
      actionType = "stop";
      isSoft = true;
      break;
    }
    case "soft-reboot": {
      actionType = "reset";
      isSoft = true;
      break;
    }
    case "hard-shutdown": {
      actionType = "stop";
      break;
    }
    case "hard-reboot": {
      actionType = "reset";
      break;
    }
  }

  dispatch(updateIsWorking([server._id], true));

  const data = await ajax("/servers/action", {
    serversIds: [server._id],
    actionType,
    isSoft,
  });

  failedActionHandler(data);
}

async function manageTags(server, ajax, prompt) {
  const text = await prompt({
    title: <FormattedMessage id="servers-list.set-tag-title" />,
    message: <FormattedMessage id="servers-list.set-tag-content" />,
    defaultText: server.tag,
  });

  if (text === null) {
    return;
  }

  await ajax("/servers/setTag", {
    tag: text || "",
    serverID: server._id,
  });
}

export async function handleServerActionClicked(
  action,
  server,
  ajax,
  lang,
  confirm,
  prompt,
  dispatch,
  navigate,
  failedActionHandler
) {
  if (action === "clone") {
    dispatch(setServerToClone(server));
    navigate(`/${lang}/my-cloud/clone-server/${server._id}`);
    return;
  }

  if (action === "manage-tags") {
    manageTags(server, ajax, prompt);
    return;
  }

  if (action === "reinstall") {
    reinstall(server, ajax, lang, prompt, navigate);
    return;
  }

  if (action === "destroy") {
    destroy(server, ajax, lang, prompt, navigate);
    return;
  }

  confirm({
    title: <FormattedMessage id={`server-layout.${action}.title`} />,
    message: <FormattedMessage id={`server-layout.${action}.message`} />,
    button1: {
      text: <FormattedMessage id="general.was-mistake" />,
      color: "white",
    },
    button2: {
      text: <FormattedMessage id="general.yes-sure" />,
      color: "white",
    },
    beforeClose: async (state) => {
      if (state === "button2") {
        await serverAction(action, server, ajax, dispatch, failedActionHandler);
      }
    },
  });
}
