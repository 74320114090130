import styles from "./index.module.scss";

import React, { useCallback, useState, useEffect } from "react";
import {
  getDaysInMonth,
  differenceInDays,
  endOfMonth,
  format,
  fromUnixTime,
} from "date-fns";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import Checkbox from "../../checkbox";
import DreamButton from "../../dream-button";
import { currencySymbols } from "../../../utils/billing";

function CreateServerFromBackupModal({ server, backup, isOpen, onClose }) {
  const user = useUser();
  const ajax = useAjax();

  const [hostname, setHostname] = useState("");
  const [hourlyPriceOn, setHourlyPriceOn] = useState(false);
  const [hourlyPriceOff, setHourlyPriceOff] = useState(false);
  const [addonPrice, setAddonPrice] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(false);
  const [monthlyBackupPrice, setMonthlyBackupPrice] = useState(false);
  const [firstMonthPayment, setFirstMonthPayment] = useState(false);
  const [firstMonthBackupPayment, setFirstMonthBackupPayment] = useState(false);
  const [enableBackup, setEnableBackup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchPrice = useCallback(async () => {
    if (!user._id) {
      return;
    }

    const data = await ajax(`/billing/calculator/calculateNewServerPrice`, {
      userID: user._id,
      paymentPeriod: server.payment.payment_type,
      cpu: server.cpu,
      ram_mb: server.ram_mb,
      disks_gb: server.disks_gb,
      backup: server.backup,
      addonsIds: server.addons.map((addon) => addon.addon_id),
    });

    if (!isOpen) {
      return;
    }

    if (server.payment.payment_type === "hourly") {
      setHourlyPriceOn(data.hourlyOnServerPrice.priceForOne);
      setHourlyPriceOff(data.hourlyOffServerPrice.priceForOne);
      setAddonPrice(data.monthlyAddonsPrices.totalPrice);
    }

    if (server.payment.payment_type === "monthly") {
      setMonthlyPrice(
        data.monthlyServerPrice.priceForOne +
          data.monthlyAddonsPrices.totalPrice
      );
      setMonthlyBackupPrice(data.monthlyServerPrice.backupPrice);

      const curDate = new Date();
      const daysInMonth = getDaysInMonth(curDate);
      const daysLeft = differenceInDays(endOfMonth(curDate), curDate) - 2;

      setFirstMonthPayment(
        ((data.monthlyServerPrice.priceForOne +
          data.monthlyAddonsPrices.totalPrice) /
          daysInMonth) *
          daysLeft
      );
      setFirstMonthBackupPayment(
        (data.monthlyServerPrice.backupPrice / daysInMonth) * daysLeft
      );
    }
  }, [ajax, user, server, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    fetchPrice();

    setHostname(
      `${server.hostname}-restored-${format(
        fromUnixTime(backup["backup-time"]),
        "dd-MM-yy"
      )}`
    );

    setLoading(false);
    setError(false);
  }, [isOpen, server, backup, fetchPrice]);

  async function handleTakeClicked() {
    setError(false);

    let name;
    if (backup.volid) {
      name = backup.volid;
    } else {
      name = `PBS2:backup/${backup["backup-type"]}/${backup["backup-id"]}/${
        new Date(backup["backup-time"] * 1000).toISOString().split(".")[0] + "Z"
      }`;
    }

    setLoading(true);

    const data = await ajax(`/backups/createNewServerFromBackup`, {
      serverID: server._id,
      hostname,
      name,
      enableBackup,
    });

    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    }
  }

  return (
    <Modal
      size="lg"
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="create-server-from-backup-modal.title" />
      </ModalHeader>
      {backup && (
        <ModalBody className={styles.wrapper}>
          <div className={styles.description}>
            <FormattedMessage
              id="create-server-from-backup-modal.description1"
              values={{
                paymentType: <span>{server.payment.payment_type}</span>,
                name: (
                  <span>
                    {format(fromUnixTime(backup["backup-time"]), "d/M/y HH:mm")}
                  </span>
                ),
              }}
            />
          </div>
          <br />
          <div className={styles.description}>
            <FormattedMessage id="create-server-from-backup-modal.description2" />
          </div>

          {server.payment.payment_type === "hourly" &&
            hourlyPriceOn &&
            hourlyPriceOff &&
            addonPrice && (
              <div className={styles.box}>
                <div>
                  <FormattedMessage
                    id="create-server-from-backup-modal.info-box-hourly"
                    values={{
                      poweredOn: (
                        <span>
                          {currencySymbols[user.currency]}
                          {hourlyPriceOn.toFixed(2)}/hr
                        </span>
                      ),
                      poweredOff: (
                        <span>
                          {currencySymbols[user.currency]}
                          {hourlyPriceOff.toFixed(2)}/hr
                        </span>
                      ),
                      addons: (
                        <div>
                          Monthly addons price{" "}
                          <span>
                            {currencySymbols[user.currency]}
                            {addonPrice.toFixed(2)}
                          </span>
                        </div>
                      ),
                      bandwidth: (
                        <div>
                          Bandwidth usage of VPS is charged at{" "}
                          <span>$0.01 per GB</span>
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
            )}

          {server.payment.payment_type === "monthly" &&
            firstMonthPayment &&
            monthlyPrice && (
              <div className={styles.box}>
                <div>
                  <FormattedMessage
                    id="create-server-from-backup-modal.info-box-monthly"
                    values={{
                      firstMonthPayment: (
                        <b>
                          {currencySymbols[user.currency]}
                          {(
                            firstMonthPayment +
                            (enableBackup ? firstMonthBackupPayment : 0)
                          ).toFixed(2)}
                        </b>
                      ),
                      monthlyPrice: (
                        <b>
                          {currencySymbols[user.currency]}
                          {(
                            monthlyPrice +
                            (enableBackup ? monthlyBackupPrice : 0)
                          ).toFixed(2)}
                        </b>
                      ),
                    }}
                  />
                </div>
              </div>
            )}

          <div className={styles.row}>
            <span className={styles.col}>
              <FormattedMessage id="create-server-from-backup-modal.hostname" />
            </span>
            <CustomText
              value={hostname}
              onChange={(e) => setHostname(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <Checkbox
              label="create-server-from-backup-modal.backup"
              checked={enableBackup}
              onChange={(e) => setEnableBackup(e.target.checked)}
            />
          </div>

          {error && <div className="error">{error}</div>}
        </ModalBody>
      )}
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="create-server-from-backup-modal.action" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}
CreateServerFromBackupModal.propTypes = {
  backup: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateServerFromBackupModal;
