import styles from "./usage-graphs.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import useIsMounted from "ismounted";
import { useAjax } from "../../../../utils/hooks";
import { format, fromUnixTime } from "date-fns";
import { FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import Spinner from "../../../../components/spinner";
import CustomChart from "../../../../components/custom-chart";
import { readableBytes, renderTraffic } from "../../../../utils/servers";
import { useOutletContext } from "react-router-dom";

export default function ServerUsageGraphs() {
  const ajax = useAjax();
  const isMounted = useIsMounted();
  const { server } = useOutletContext();

  const options = [
    { value: "hour", label: <FormattedMessage id="general.hour" /> },
    { value: "day", label: <FormattedMessage id="general.day" /> },
    { value: "week", label: <FormattedMessage id="general.week" /> },
    { value: "month", label: <FormattedMessage id="general.month" /> },
    { value: "year", label: <FormattedMessage id="general.year" /> },
  ];

  const [timeframe, setTimeframe] = useState(options[0]);
  const [cpuChartData, setCpuChartData] = useState([]);
  const [ramChartData, setRamChartData] = useState([]);
  const [networkChartData, setNetworkChartData] = useState([]);
  const [diskChartData, setDiskChartData] = useState([]);
  const [serverStatus, setServerStatus] = useState(null);

  const [loading, setLoading] = useState(false);

  const getRRDStatistics = useCallback(async () => {
    setLoading(true);
    const data = await ajax("/proxmox/nodes/qemu/getRRDStatistics", {
      serverID: server._id,
      timeframe: timeframe.value,
    });

    if (!isMounted.current) {
      return;
    }

    const cpuChartData = [["Date", "cpu"]];
    const ramChartData = [["Date", "mem", "maxmem"]];
    const networkChartData = [["Date", "netin", "netout"]];
    const diskChartData = [["Date", "diskread", "diskwrite"]];

    if (data.result === "success") {
      data.data.forEach((item) => {
        const time = format(
          fromUnixTime(item.time),
          `dd/MM/yyyy ${
            timeframe !== "year" && timeframe !== "month" ? "HH:mm:ss" : ""
          }`
        );

        cpuChartData.push([time, item.cpu * 100]);
        ramChartData.push([
          time,
          item.mem / Math.pow(1024, 3),
          item.maxmem / Math.pow(1024, 3),
        ]);
        networkChartData.push([
          time,
          item.netin / Math.pow(1024, 2),
          item.netout / Math.pow(1024, 2),
        ]);
        diskChartData.push([
          time,
          item.diskread / Math.pow(1024, 3),
          item.diskwrite / Math.pow(1024, 3),
        ]);
      });

      setServerStatus(data.status);
    }

    setCpuChartData(cpuChartData);
    setRamChartData(ramChartData);
    setNetworkChartData(networkChartData);
    setDiskChartData(diskChartData);

    setLoading(false);
  }, [ajax, server, timeframe, isMounted]);

  useEffect(() => {
    getRRDStatistics();
  }, [getRRDStatistics, timeframe]);

  function renderBound(number) {
    return readableBytes(number);
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.descriptionBox}>
        <FormattedMessage id="server-overview.description" />
      </Box>

      <div className={styles.row}>
        <Box className={styles.bigBox}>
          <div>
            <div className={styles.title}>
              <FormattedMessage id="server-overview.graphs-range" />
              {loading && <Spinner />}
            </div>
            <div className={styles.select}>
              <Select
                value={timeframe}
                onChange={(value) => setTimeframe(value)}
                options={options}
                isDisabled={loading}
              />
            </div>
          </div>
          <div>
            <div className={styles.totalBandwidthTitle}>
              <FormattedMessage id="server-overview.total-traffic" />
            </div>
            <div className={styles.totalBandwidth}>
              <span className={styles.big}>{renderTraffic(server)}</span>{" "}
              <span className={styles.normal}>/ 5TB</span>
            </div>
            <div className={styles.hr}>
              <hr />
            </div>
            <div className={styles.inbound}>
              Inbound: {serverStatus ? renderBound(serverStatus.netin) : "-"}
            </div>
            <div className={styles.outbound}>
              Outbound: {serverStatus ? renderBound(serverStatus.netout) : "-"}
            </div>
          </div>
        </Box>
      </div>
      <div className={styles.row}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-overview.cpu-usage" />
          </div>
          <div className={styles.chart}>
            <CustomChart
              chartType="ColumnChart"
              data={cpuChartData}
              unique={timeframe}
            />
          </div>
        </Box>
      </div>
      <div className={styles.row}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-overview.memory-usage" />
          </div>
          <div className={styles.chart}>
            <CustomChart
              chartType="ColumnChart"
              data={ramChartData}
              unique={timeframe}
            />
          </div>
        </Box>
      </div>
      <div className={styles.row}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-overview.network-traffic" />
          </div>
          <div className={styles.chart}>
            <CustomChart
              chartType="ColumnChart"
              data={networkChartData}
              unique={timeframe}
            />
          </div>
        </Box>
      </div>
      <div className={styles.row}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-overview.disk-io" />
          </div>
          <div className={styles.chart}>
            <CustomChart
              chartType="ColumnChart"
              data={diskChartData}
              unique={timeframe}
            />
          </div>
        </Box>
      </div>
    </div>
  );
}
