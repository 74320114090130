import styles from "./index.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import validator from "validator";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Spinner from "../../spinner";
import CustomText from "../../custom-text";
import Checkbox from "../../checkbox";
import DreamButton from "../../dream-button";
import { useAjax } from "../../../utils/hooks";

function EditBaloonModal({ isOpen, onClose, server }) {
  const ajax = useAjax();

  const [memory, setMemory] = useState(null);
  const [minMem, setMinMem] = useState("");
  const [balloon, setBalloon] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAdvancedBaloonSettings = useCallback(async () => {
    const data = await ajax("/servers/getAdvancedBaloonSettings", {
      serverID: server._id,
    });

    if (data.balloon === 0) {
      setMinMem("");
      setBalloon(false);
    } else {
      setMinMem(data.balloon || data.memory);
      setBalloon(true);
    }

    setMemory(data.memory);
  }, [ajax, server]);

  useEffect(() => {
    if (isOpen) {
      getAdvancedBaloonSettings();

      setError(null);
      setLoading(false);
    }
  }, [isOpen, getAdvancedBaloonSettings]);

  async function handleSaveClicked() {
    setError(null);

    if (balloon && !validator.isNumeric(minMem)) {
      return setError(
        <FormattedMessage id="edit-baloon-modal.error.balloon" />
      );
    }

    setLoading(true);

    await ajax("/servers/setAdvancedBaloonSettings", {
      serverID: server._id,
      minMem,
      balloon,
    });

    await getAdvancedBaloonSettings();

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="edit-baloon-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className="drow f200">
          <span>Memory (MiB):</span>
          {memory ? memory : <Spinner />}
        </div>
        <div className="drow f200">
          <span>Minimum memory (MiB):</span>
          <CustomText
            disabled={!balloon}
            value={minMem}
            onChange={(e) => setMinMem(e.target.value)}
          />
        </div>
        <div className="drow">
          <Checkbox
            label={<>Ballooning Device?</>}
            checked={balloon}
            onChange={(e) => setBalloon(e.target.checked)}
          />
        </div>
        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="purple"
          onClick={handleSaveClicked}
        >
          <FormattedMessage id="general.save" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

EditBaloonModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  server: PropTypes.object,
};

export default EditBaloonModal;
