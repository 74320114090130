import styles from "./index.module.scss";

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { CircleFlag } from "react-circle-flags";
import ReactSelect from "react-select";
import ChevronDown from "../svgs/chevron-down.svg?react";
import { useLang } from "../../utils/hooks";
import {
  countries,
  countriesHeb,
  countriesPhoneCodes,
} from "../../utils/countries";

const SingleValue = ({ data }) => {
  return (
    <div className={styles.option}>
      <CircleFlag countryCode={data.countryCode} height="25" />
    </div>
  );
};

SingleValue.propTypes = {
  data: PropTypes.any,
};

const IndicatorsContainer = () => <ChevronDown className={styles.arrowDown} />;

function CountryCodeSelector({ value, onChange, defaultCountry = null }) {
  const lang = useLang();

  const options = useMemo(() => {
    const ret = [];
    const obj = lang === "he" ? countriesHeb : countries;

    for (const k in obj) {
      if (countriesPhoneCodes[obj[k]]) {
        let phoneCode = countriesPhoneCodes[obj[k]];
        if (!phoneCode.startsWith("+")) {
          phoneCode = `+${phoneCode}`;
        }

        const countryCode = obj[k].toLowerCase();

        ret.push({
          label: (
            <div className={styles.option}>
              <CircleFlag countryCode={countryCode} height="20" />
              <span>{k}</span>
              <span className={styles.phoneCode}>({phoneCode})</span>
            </div>
          ),
          value: k,
          countryCode,
          phoneCode,
        });
      }
    }

    return ret;
  }, [lang]);

  useEffect(() => {
    if (defaultCountry && !value) {
      const option = options.find(
        (item) =>
          item.countryCode.toLowerCase() === defaultCountry.toLowerCase()
      );

      onChange(option);
    }
  }, [defaultCountry, value, options, onChange]);

  return (
    <div>
      <ReactSelect
        className={styles.wrapper}
        options={options}
        value={value}
        onChange={onChange}
        components={{ SingleValue, IndicatorsContainer }}
        placeholder=""
        classNames={{ valueContainer: () => "ValueContainer" }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            display: "flex",
            flexDirection: "row-reverse",
            border: 0,
            background: "transparent",
            height: "45px",
          }),
          menu: (baseStyles) => ({ ...baseStyles, width: "320px" }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0px",
            maxWidth: "33px",
          }),
        }}
      />
    </div>
  );
}

CountryCodeSelector.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string,
};

export default CountryCodeSelector;
