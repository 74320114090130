import styles from "./overview.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { format } from "date-fns";
import isValidDomain from "is-valid-domain";
import EditSvg from "../svgs/edit.svg?react";
import HelpSvg from "../svgs/help.svg?react";
import { Modal, ModalBody, ModalHeader, Tooltip } from "reactstrap";
import ServerSettingsChangePassword from "../../pages/my-cloud/servers/[id]/server-settings/change-password";
import WanSelectorModal from "../modals/wan-selector";
import EditBaloonModal from "../modals/edit-baloon-settings";
import { getImageName, renderBandwidth } from "../../utils/servers";
import { getSocket } from "../../utils/globals";
import ChangeServerUserNameModal from "../modals/change-server-username";
import { Link } from "react-router-dom";
import ServerIpsModal from "../modals/server-ips";
import { FormattedMessage } from "react-intl";
import Spinner from "../spinner";
import ClipboardCopy from "../clipboard-copy";
import {
  useAjax,
  useLang,
  usePrompt,
  useRoles,
  useUser,
} from "../../utils/hooks";
import { getCountryByCode, getCountryIcon } from "../../utils/countries";
import { SUPER_ADMIN } from "../../utils/user";

export default function Overview({ server }) {
  const ajax = useAjax();
  const prompt = usePrompt();
  const user = useUser();
  const lang = useLang();
  const { isAllowed } = useRoles();
  const socket = getSocket();

  const [hostname, setHostname] = useState(null);
  const [location, setLocation] = useState(null);
  const [image, setImage] = useState(null);
  const [cpu, setCPU] = useState(null);
  const [ram_mb, setRamMB] = useState(null);
  const [ssd_gb, setSsdGB] = useState(null);
  const [additional_ssd_gb, setAdditionalSsdGB] = useState(null);
  const [node, setNode] = useState(null);
  const [vmid, setVMID] = useState(null);
  const [tag, setTag] = useState(null);
  const [created_at, setCreatedAt] = useState(null);
  const [terminated_at, setTerminatedAt] = useState(null);
  const [status, setStatus] = useState(null);
  const [isWorking, setIsWorking] = useState(null);

  const [securityGroups, setSecurityGroups] = useState(null);
  const [firewallStatus, setFirewallStatus] = useState(null);

  const [dedicatedip, setDedicatedip] = useState(null);
  const [ipsOfServer, setIpsOfServer] = useState(null);
  const [isXipTooltipOpen, setIsXipTooltipOpen] = useState(false);

  const [
    isServerSettingsChangeUserNameModalOpen,
    setIsServerSettingsChangeUserNameModalOpen,
  ] = useState(false);
  const [
    isServerSettingsChangePasswordModalOpen,
    setIsServerSettingsChangePasswordModalOpen,
  ] = useState(false);
  const [isWanSelectorModalOpen, setIsWanSelectorModalOpen] = useState(false);
  const [isEditBaloonModalOpen, setIsEditBaloonModalOpen] = useState(false);
  const [isServerIpsModalOpen, setIsServerIpsModalOpen] = useState(false);

  useEffect(() => {
    if (!server) {
      return;
    }

    setDedicatedip(server.dedicatedip);

    setHostname(server.hostname);
    setLocation(server.location);
    setImage(server.image);
    setCPU(server.cpu);
    setRamMB(server.ram_mb);
    setSsdGB(Array.isArray(server.disks_gb) ? server.disks_gb[0]?.size : 0);
    setAdditionalSsdGB(
      server.disks_gb.slice(1).reduce((sum, disk) => sum + disk.size, 0)
    );
    setNode(server.node);
    setVMID(server.vmid);
    setTag(server.tag);
    setCreatedAt(server.created_at);
    setTerminatedAt(server.terminated_at);
    setStatus(server.status);
    setIsWorking(server.is_working);
  }, [server]);

  const handleServerUpdated = useCallback(() => {
    //
  }, []);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("update-server", handleServerUpdated);

    return () => {
      socket.off("update-server", handleServerUpdated);
    };
  }, [socket, handleServerUpdated]);

  useEffect(() => {
    async function getIpsOfServer() {
      if (!server) {
        return;
      }

      const data = await ajax("/network/getIpsOfServer", {
        serverID: server._id,
      });

      setIpsOfServer(data.ips);
    }

    getIpsOfServer();
  }, [ajax, server]);

  const getSecurityGroupsOfServer = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/firewall/getSecurityGroupsOfServer", {
      serverID: server._id,
      withFirewallStatus: true,
    });

    setSecurityGroups(data.serverGroups);
    setFirewallStatus(data.firewallStatus);
  }, [server, ajax]);

  useEffect(() => {
    getSecurityGroupsOfServer();
  }, [getSecurityGroupsOfServer]);

  function renderUsername() {
    if (!image) {
      return null;
    }

    if (server.custom_username) {
      return server.custom_username;
    }

    if (image.toLowerCase().indexOf("windows") > -1) {
      return "Administrator";
    } else if (image.toLowerCase().indexOf("pfsense") > -1) {
      return "admin";
    }

    return "root";
  }

  async function handleCopyPasswordClicked() {
    const data = await ajax("/servers/getServerPassword", {
      serverID: server._id,
    });

    copy(data.password);
  }

  function handleEditUserNameClicked() {
    setIsServerSettingsChangeUserNameModalOpen(true);
  }

  function handleChangeServerUserNameModalClosed() {
    setIsServerSettingsChangeUserNameModalOpen(false);
  }

  function handleEditPasswordClicked() {
    setIsServerSettingsChangePasswordModalOpen(true);
  }

  function handleEditPasswordClosed() {
    setIsServerSettingsChangePasswordModalOpen(false);
  }

  function handleEditIPClicked() {
    setIsWanSelectorModalOpen(true);
  }

  function handleShowAllIpsClicked(e) {
    e.preventDefault();

    setIsServerIpsModalOpen(true);
  }

  function handleServerIpsModalClosed() {
    setIsServerIpsModalOpen(false);
  }

  async function handleWanSelectorModalClosed(ip, subnet) {
    if (ip && subnet) {
      await ajax("/network/attachIPToServer", {
        serverID: server._id,
        ip,
        subnetID: subnet._id,
        type: "wan",
        applyInProxmox: true,
        removeOtherConnectedIps: true,
        userIDToWork: user._id,
      });

      setDedicatedip(ip);
    }

    setIsWanSelectorModalOpen(false);
  }

  async function handleChangeHostnameClicked() {
    const text = await prompt({
      title: <FormattedMessage id="servers-list.set-hostname-title" />,
      message: <FormattedMessage id="servers-list.set-hostname-content" />,
      defaultText: hostname,
      acceptOnlyValue: (text) => {
        if (!isValidDomain(text)) {
          return {
            status: false,
            reason: <FormattedMessage id="servers-list.invalid-domain" />,
          };
        }

        return {
          status: true,
        };
      },
    });

    if (text) {
      await ajax("/servers/setHostName", {
        hostname: text,
        serverID: server._id,
      });
    }
  }

  async function handleChangeTagClicked() {
    const text = await prompt({
      title: <FormattedMessage id="servers-list.set-tag-title" />,
      message: <FormattedMessage id="servers-list.set-tag-content" />,
      defaultText: tag,
    });

    if (text === null) {
      return;
    }

    await ajax("/servers/setTag", {
      tag: text || "",
      serverID: server._id,
    });
  }

  function handleEditBaloonClicked() {
    setIsEditBaloonModalOpen(true);
  }

  function handleEditBaloonModalClosed() {
    setIsEditBaloonModalOpen(false);
  }

  function renderFirewallStatus() {
    if (!securityGroups) {
      return <Spinner />;
    }

    if (!firewallStatus) {
      return (
        <Link
          href="/[lang]/my-cloud/servers/[id]/server-settings/ipv4"
          to={`/${lang}/my-cloud/servers/${server._id}/server-settings/ipv4`}
        >
          <FormattedMessage id="general.disabled" />
        </Link>
      );
    }

    if (securityGroups.length === 0) {
      return <FormattedMessage id="servers-list.no-security-groups" />;
    }

    return (
      <Link
        href="/[lang]/my-cloud/servers/[id]/server-settings/firewall"
        to={`/${lang}/my-cloud/servers/${server._id}/server-settings/firewall`}
      >
        <FormattedMessage
          id="servers-list.security-groups"
          values={{ total: securityGroups.length }}
        />
      </Link>
    );
  }

  if (!server) {
    return null;
  }

  return (
    <div className={styles.overview}>
      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.location" />
          </span>
          <span className={styles.gray}>
            {getCountryIcon(location)} {getCountryByCode(location)}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.ip-address" />
          </span>
          <span className={styles.gray}>
            {dedicatedip} <ClipboardCopy text={dedicatedip} />{" "}
            {server.belong_to_firewall_id && (
              <span>
                * <FormattedMessage id="belongs-to-firewall" />
              </span>
            )}
            {[SUPER_ADMIN].includes(user.role) &&
              isAllowed("networks") &&
              status === "Active" && (
                <EditSvg
                  className={`pointer ${isWorking ? "link-disabled" : ""}`}
                  onClick={handleEditIPClicked}
                />
              )}
            {ipsOfServer?.length > 1 && (
              <a
                className={styles.andNMore}
                href="#"
                onClick={handleShowAllIpsClicked}
              >
                (
                <FormattedMessage
                  id="and-n-more"
                  values={{ n: ipsOfServer.length - 1 }}
                />
                )
              </a>
            )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.user-name" />
          </span>
          <span className={styles.gray}>
            {renderUsername()} <ClipboardCopy text={renderUsername()} />
            {isAllowed("servers") && status === "Active" && (
              <EditSvg
                className={`pointer ${isWorking ? "link-disabled" : ""}`}
                onClick={handleEditUserNameClicked}
              />
            )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.password" />
          </span>
          <span className={styles.gray}>
            ••••••••••• <ClipboardCopy onClick={handleCopyPasswordClicked} />{" "}
            {isAllowed("servers") && status === "Active" && (
              <EditSvg
                className={`pointer ${isWorking ? "link-disabled" : ""}`}
                onClick={handleEditPasswordClicked}
              />
            )}
          </span>
        </div>

        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.domain" />
          </span>
          {!dedicatedip && <span className={styles.gray}>-</span>}
          {dedicatedip && (
            <span className={styles.gray}>
              {`${dedicatedip}.xip.io`}{" "}
              <div className={styles.flex}>
                <ClipboardCopy text={`${dedicatedip}.xip.io`} />
                <div id="tooltip-xip">
                  <div>
                    <HelpSvg />
                  </div>
                </div>
                <Tooltip
                  placement="bottom"
                  isOpen={isXipTooltipOpen}
                  target="tooltip-xip"
                  toggle={() => setIsXipTooltipOpen(!isXipTooltipOpen)}
                >
                  xip.io is a magic domain name that provides wildcard DNS for
                  any IP address.
                </Tooltip>
              </div>
            </span>
          )}
        </div>

        {user.role === SUPER_ADMIN && (
          <div>
            <span className={styles.black}>Node</span>
            <span className={styles.gray}>
              {node} <ClipboardCopy text={node} />
            </span>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.cpu" />
          </span>
          <span className={styles.gray}>
            {cpu} vCPU Xeon Platinum (2.90Ghz)
          </span>{" "}
          {/* <span className={styles.upgrade}>
            <FormattedMessage id="servers-list.upgrade" />
          </span> */}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.ram" />
          </span>
          <span className={styles.gray}>{ram_mb}MB</span>
          {isAllowed("super-admin.support") && (
            <span className={styles.text}>
              <a href="#" onClick={handleEditBaloonClicked}>
                <FormattedMessage id="servers-list.edit-baloon" />
              </a>
            </span>
          )}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.storage" />
          </span>
          <span className={styles.gray}>
            {ssd_gb}GB SSD NVMe{" "}
            {additional_ssd_gb ? `(+${additional_ssd_gb}GB SSD NVMe)` : ""}
          </span>{" "}
          {/* <span className={styles.upgrade}>
            <FormattedMessage id="servers-list.upgrade" />
          </span> */}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.bandwidth" />
          </span>
          <span className={styles.gray}>{renderBandwidth(server)}</span>
        </div>
        <div className={styles.firewallStatus}>
          <span className={styles.black}>
            <FormattedMessage id="general.firewall" />
          </span>
          <span className={styles.gray}>{renderFirewallStatus()}</span>
        </div>
        {user.role === SUPER_ADMIN && (
          <div>
            <span className={styles.black}>VMID</span>
            <span className={styles.gray}>
              {vmid} <ClipboardCopy text={vmid} />
            </span>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.hostname" />
          </span>
          <span className={styles.gray}>
            {hostname} <ClipboardCopy text={hostname} />
            {isAllowed("servers") && status === "Active" && (
              <EditSvg
                className={`pointer ${isWorking ? "link-disabled" : ""}`}
                onClick={handleChangeHostnameClicked}
              />
            )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.tag" />
          </span>
          <span className={styles.gray}>
            {tag || <FormattedMessage id="servers-list.tag-unset" />}{" "}
            {isAllowed("servers") && status === "Active" && (
              <EditSvg
                className={`pointer ${isWorking ? "link-disabled" : ""}`}
                onClick={handleChangeTagClicked}
              />
            )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.image" />
          </span>
          <span className={styles.gray}>{getImageName(image)}</span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.created-date" />
          </span>
          <span className={styles.gray}>
            {format(new Date(created_at), "d/M/y")}
          </span>
        </div>
        {terminated_at && (
          <div>
            <span className={styles.black}>
              <FormattedMessage id="servers-list.terminated-date" />
            </span>
            <span className={styles.gray}>
              {format(new Date(terminated_at), "d/M/y")}
            </span>
          </div>
        )}
      </div>

      <ChangeServerUserNameModal
        isOpen={isServerSettingsChangeUserNameModalOpen}
        onClose={handleChangeServerUserNameModalClosed}
        server={server}
      />

      <Modal
        isOpen={isServerSettingsChangePasswordModalOpen}
        toggle={handleEditPasswordClosed}
      >
        <ModalHeader toggle={handleEditPasswordClosed}>
          <FormattedMessage id="server-settings-change-password.title" />
        </ModalHeader>
        <ModalBody>
          <ServerSettingsChangePassword serverToUse={server} onlyContent />
        </ModalBody>
      </Modal>

      <WanSelectorModal
        location={location}
        isOpen={isWanSelectorModalOpen}
        onClose={handleWanSelectorModalClosed}
      />

      <EditBaloonModal
        server={server}
        isOpen={isEditBaloonModalOpen}
        onClose={handleEditBaloonModalClosed}
      />

      {ipsOfServer && (
        <ServerIpsModal
          isOpen={isServerIpsModalOpen}
          onClose={handleServerIpsModalClosed}
          hostname={server.hostname}
          ips={ipsOfServer}
        />
      )}
    </div>
  );
}
