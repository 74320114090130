import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "../box";
import { FormattedMessage } from "react-intl";
import UserSelectorModal from "../modals/user-selector";
import { useUser } from "../../utils/hooks";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";

function UserSelectorLayout({
  className,
  userIDToWork,
  setUserIDToWork,
  children,
}) {
  const user = useUser();
  const [userLabel, setUserLabel] = useState(null);

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const label = urlSearchParams.get("userlabel");
    const value = urlSearchParams.get("userid");

    if (!label || !value) {
      return;
    }

    setUserIDToWork({ label, value });

    if (label) {
      setUserLabel(label);
    }
  }, [setUserIDToWork]);

  function handleUserSelectorModalOpen(e) {
    e.preventDefault();

    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setUserLabel(user.email);
    setIsUserSelectorModalOpen(false);
  }

  if (![SUPER_ADMIN, WHITELABEL].includes(user.role)) {
    return children;
  }

  return (
    <>
      <Box className={className}>
        <div className={styles.title}>
          <FormattedMessage id="general.user-selector.title" />

          <a href="#" onClick={handleUserSelectorModalOpen}>
            {userLabel || userIDToWork?.label || user.email}
          </a>
        </div>
      </Box>

      {children}

      <UserSelectorModal
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
      />
    </>
  );
}

UserSelectorLayout.propTypes = {
  className: PropTypes.string,
  userIDToWork: PropTypes.object,
  setUserIDToWork: PropTypes.func,
  children: PropTypes.any,
};

export default UserSelectorLayout;
