import styles from "./index.module.scss";

import React, { useMemo, useState } from "react";
import { DropdownItem, Tooltip } from "reactstrap";
import {
  useAjax,
  useConfirm,
  useFailedActionHandler,
  useLang,
  usePrompt,
  useRoles,
  useUser,
} from "../../utils/hooks";
import { handleServerActionClicked } from "./server-actions";
import { getOsLogo, getImageName } from "../../utils/servers";
import InfoSvg from "../svgs/info.svg?react";
import LongArrowLeft from "../svgs/long-arrow-left.svg?react";
import PlaySvg from "../svgs/play.svg?react";
import { useDispatch } from "react-redux";
import { setServersFetchingFilter } from "../../store/settings";
import CreateNewServerModal from "../modals/create-new-product";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  Outlet,
} from "react-router-dom";
import CreateTemplateFromServerModal from "../modals/create-template-from-server";
import { FormattedMessage } from "react-intl";
import Spinner from "../spinner";
import CustomMenu from "../custom-menu";
import CustomMenuItem from "../custom-menu/item";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";
import { getCountryByCode } from "../../utils/countries";
import { sanitize } from "../../utils";
import Navigator from "../navigator";
import { useEffect } from "react";
import { useCallback } from "react";
import { isValidObjectId } from "../../utils/validations";
import { getSocket } from "../../utils/globals";

function fixRDNS(server) {
  if (server.rdns) {
    Object.keys(server.rdns).forEach((key) => {
      server.rdns[key.replace(/_/g, ".")] = server.rdns[key];
    });
  }
}

function ServerLayout() {
  const socket = getSocket();
  const user = useUser();
  const { id } = useParams();
  const lang = useLang("en");
  const confirm = useConfirm();
  const prompt = usePrompt();
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const failedActionHandler = useFailedActionHandler();

  const [server, setServer] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRebootRequiredTooltipOpen, setIsRebootRequiredTooltipOpen] =
    useState(false);
  const [selectedServer, setSelectedServer] = useState(null);
  const [unlocking, setUnlocking] = useState(false);

  const [isCreateNewServerModalOpen, setIsCreateNewServerModalOpen] =
    useState(false);
  const [
    isCreateTemplateFromServerModalOpen,
    setIsCreateTemplateFromServerModalOpen,
  ] = useState(false);

  const fetchServer = useCallback(async () => {
    if (!isValidObjectId(id)) {
      return navigate(`/${lang}/my-cloud/servers`);
    }

    const data = await ajax("/servers/get", {
      serverID: id,
    });

    if (data.result === "error") {
      return navigate(`/${lang}/my-cloud/servers`);
    }

    fixRDNS(data.server);
    setServer(data.server);
  }, [ajax, id, lang, navigate]);

  useEffect(() => {
    fetchServer();
  }, [fetchServer]);

  const handleServerUpdated = useCallback(
    ({ serverID, serverObj }) => {
      if (id === serverID) {
        fixRDNS(serverObj);
        setServer(serverObj);
      }
    },
    [id]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("update-server", handleServerUpdated);

    return () => {
      socket.off("update-server", handleServerUpdated);
    };
  }, [socket, handleServerUpdated]);

  const items = useMemo(() => {
    if (!server) {
      return [];
    }

    const ret = [
      {
        id: "overview",
        title: <FormattedMessage id="server-layout.overview" />,
        to: `/${lang}/my-cloud/servers/${id}/overview`,
        selected: location.pathname.endsWith("/overview"),
      },
      {
        id: "usage-graphs",
        title: <FormattedMessage id="server-layout.usage-graphs" />,
        to: `/${lang}/my-cloud/servers/${id}/usage-graphs`,
        selected: location.pathname.endsWith("/usage-graphs"),
      },
      {
        id: "ipv4",
        title: <FormattedMessage id="server-layout.server-settings" />,
        to: `/${lang}/my-cloud/servers/${id}/server-settings/ipv4`,
        selected: location.pathname.includes("/server-settings/"),
      },
      {
        id: "snapshots",
        title: <FormattedMessage id="server-layout.snapshots" />,
        to: `/${lang}/my-cloud/servers/${id}/snapshots`,
        selected: location.pathname.endsWith("/snapshots"),
      },
      {
        id: "backups",
        title: <FormattedMessage id="server-layout.backups" />,
        to: `/${lang}/my-cloud/servers/${id}/backups`,
        selected: location.pathname.endsWith("/backups"),
      },
      {
        id: "tasks",
        title: <FormattedMessage id="server-layout.tasks" />,
        to: `/${lang}/my-cloud/servers/${id}/tasks`,
        selected: location.pathname.endsWith("/tasks"),
      },
      {
        id: "server-logs",
        title: <FormattedMessage id="server-layout.server-logs" />,
        to: `/${lang}/my-cloud/servers/${id}/server-logs`,
        selected: location.pathname.endsWith("/server-logs"),
      },
      {
        id: "notes",
        title: <FormattedMessage id="server-layout.notes" />,
        to: `/${lang}/my-cloud/servers/${id}/notes`,
        selected: location.pathname.endsWith("/notes"),
      },
      {
        id: "console",
        title: <FormattedMessage id="server-layout.console" />,
        to: `/${lang}/my-cloud/servers/${id}/console`,
        selected: location.pathname.endsWith("/console"),
      },
      {
        id: "payment",
        title: <FormattedMessage id="server-layout.payment" />,
        to: `/${lang}/my-cloud/servers/${id}/payment`,
        selected: location.pathname.endsWith("/payment"),
      },
      {
        id: "show-client",
        title: <FormattedMessage id="server-layout.show-client" />,
        to: `/${lang}/clients/clients-list/${server.user_id}`,
      },
      {
        id: "show-client-servers",
        title: <FormattedMessage id="server-layout.show-client-servers" />,
        onClick: () => {
          dispatch(setServersFetchingFilter(server.user.email));
          navigate(`/${lang}/my-cloud/servers`);
        },
      },
    ].filter(
      (item) =>
        (item.id !== "addons" || isAllowed("servers")) &&
        (item.id !== "payment" || isAllowed("admin.sales")) &&
        (item.id !== "show-client" ||
          [SUPER_ADMIN, WHITELABEL].includes(user.role)) &&
        (item.id !== "show-client-servers" ||
          [SUPER_ADMIN, WHITELABEL].includes(user.role)) &&
        ((server.status === "Active" &&
          (item.id !== "tasks" || isAllowed("servers")) &&
          (item.id !== "console" || isAllowed("servers"))) ||
          item.id === "overview" ||
          item.id === "usage-graphs" ||
          item.id === "server-logs" ||
          item.id === "ipv4" ||
          item.id === "backups" ||
          item.id === "payment" ||
          item.id === "notes" ||
          item.id === "show-client" ||
          item.id === "show-client-servers")
    );

    if (server.status === "Terminated") {
      ret[2].to = `/${lang}/my-cloud/servers/${id}/server-settings/addons`;
    }

    return ret;
  }, [
    dispatch,
    isAllowed,
    lang,
    id,
    server,
    user.role,
    location.pathname,
    navigate,
  ]);

  function handleBackToServersOfClientClicked() {
    dispatch(setServersFetchingFilter(server.user.email));
    navigate(`/${lang}/my-cloud/servers?backToClient=${server.user_id}`);
  }

  function handleUnlockClicked() {
    confirm({
      title: <FormattedMessage id="overview.server-unlock" />,
      message: <FormattedMessage id="overview.server-unlock-confirm" />,
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        setUnlocking(true);
        await ajax("/servers/unlock", { serverID: server._id });
      },
    });
  }

  function handleCreateNewServerModalClosed() {
    setIsCreateNewServerModalOpen(false);
  }

  function handleCreateTemplate() {
    setSelectedServer(server);
    setIsCreateTemplateFromServerModalOpen(true);
  }

  function handleCreateTemplateFromServerModalClosed() {
    setIsCreateTemplateFromServerModalOpen(false);
  }

  const managedHosting = useMemo(() => {
    if (!server || !server.addons) {
      return false;
    }

    const addon = server.addons.find(
      (addon) => addon.addon_type === "managed-hosting" && !addon.whitelabel
    );

    return addon ? addon.name : false;
  }, [server]);

  const cpanelLicense = useMemo(() => {
    if (!server || !server.addons) {
      return false;
    }

    const addon = server.addons.find(
      (addon) => addon.addon_type === "cpanel-license"
    );

    return addon ? addon.name : false;
  }, [server]);

  const rebootRequired = useMemo(() => {
    if (!server) {
      return false;
    }

    let messages = [];

    if (server.reboot_required === "password") {
      messages.push(<FormattedMessage id="network" />);
    }

    if (server.reboot_required === "network") {
      messages.push(<FormattedMessage id="reboot_required.network" />);
    }

    if (server.configDiff) {
      Object.keys(server.configDiff)
        .sort()
        .forEach((key) => {
          if (["cores", "boot", "nameserver"].includes(key)) {
            messages.push(<FormattedMessage id={`reboot_required.${key}`} />);
          } else if (key.match(/ipconfig[0-9][\d]*/g)) {
            messages.push(<FormattedMessage id={`reboot_required.ip`} />);
          } else if (!["vcpus"].includes(key)) {
            messages.push(key);
          }
        });
    }

    messages = [...new Set(messages)];

    return messages.length > 0 ? messages.join("<br />") : "";
  }, [server]);

  function renderStatusSvg() {
    if (server.isWorking) {
      return <Spinner />;
    }

    return server.running && <PlaySvg />;
  }

  if (!server) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backArrows}>
        <Link
          href="/[lang]/my-cloud/servers"
          to={`/${lang}/my-cloud/servers`}
          className={styles.backArrow}
        >
          <LongArrowLeft />
          <FormattedMessage id="server-layout.back-to-servers" />
        </Link>

        {[WHITELABEL, SUPER_ADMIN].includes(user.role) && (
          <div
            className={styles.backArrow}
            onClick={handleBackToServersOfClientClicked}
          >
            <LongArrowLeft />
            <FormattedMessage
              id="server-layout.back-to-servers-of-client"
              values={{ email: server.user.email }}
            />
          </div>
        )}
      </div>

      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.os}>
            <img
              src={`/img/${getOsLogo(server.image)}`}
              width={48}
              height={48}
            />
          </div>
          <div>
            <div className={styles.bold}>
              {server.hostname}
              {renderStatusSvg()}
            </div>
            <div className={styles.normal}>
              {`${server.dedicatedip ? `${server.dedicatedip} · ` : ""}`}
              {getImageName(server.image)} · {getCountryByCode(server.location)}
            </div>

            <div className={styles.tags}>
              {server.readOnlyMode && (
                <div className={styles.tag}>
                  <FormattedMessage id="general.read-only" />
                </div>
              )}
              {server.configCurrent?.lock && (
                <div className={styles.tag}>
                  <FormattedMessage id="general.locked" />
                  <span
                    className={`${styles.unlockButton} ${
                      unlocking ? styles.disabled : ""
                    }`}
                    onClick={handleUnlockClicked}
                  >
                    <FormattedMessage id="general.unlock" />
                  </span>
                </div>
              )}
              {managedHosting && (
                <div className={styles.tag}>{managedHosting}</div>
              )}
              {cpanelLicense && (
                <div className={styles.tag}>{cpanelLicense}</div>
              )}
            </div>
          </div>
        </div>

        {rebootRequired && (
          <>
            <div
              id="tooltip-reboot_required"
              className={styles.rebootRequiredWrapper}
            >
              <div>
                <FormattedMessage id="general.reboot_required" />
                <InfoSvg />
              </div>
            </div>

            <Tooltip
              placement="bottom"
              isOpen={isRebootRequiredTooltipOpen}
              target="tooltip-reboot_required"
              toggle={() =>
                setIsRebootRequiredTooltipOpen(!isRebootRequiredTooltipOpen)
              }
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(rebootRequired) }}
              ></div>
            </Tooltip>
          </>
        )}

        <div className={styles.menuText}>
          <FormattedMessage id="general.actions" />
        </div>

        <div className={styles.menu}>
          <CustomMenu
            isOpen={isMenuOpen}
            toggle={() => setIsMenuOpen(!isMenuOpen)}
          >
            {server.status === "Active" && isAllowed("servers") && (
              <CustomMenuItem
                to={`/${lang}/my-cloud/servers/${server._id}/server-settings/change-plan`}
              >
                <FormattedMessage id="create-new-server-layout.change-plan" />
              </CustomMenuItem>
            )}

            <DropdownItem divider />

            {server.status === "Active" &&
              isAllowed("servers") &&
              !server.running && (
                <CustomMenuItem
                  disabled={server.isWorking}
                  onClick={() =>
                    handleServerActionClicked(
                      "start-server",
                      server,
                      ajax,
                      lang,
                      confirm,
                      prompt,
                      dispatch,
                      navigate,
                      failedActionHandler
                    )
                  }
                >
                  <FormattedMessage id="create-new-server-layout.start-server" />
                </CustomMenuItem>
              )}

            {server.status === "Active" &&
              isAllowed("servers") &&
              server.running && (
                <>
                  {server.ping && (
                    <>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "soft-shutdown",
                            server,
                            ajax,
                            lang,
                            confirm,
                            prompt,
                            dispatch,
                            navigate,
                            failedActionHandler
                          )
                        }
                      >
                        <FormattedMessage id="create-new-server-layout.soft-shutdown-server" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "soft-reboot",
                            server,
                            ajax,
                            lang,
                            confirm,
                            prompt,
                            dispatch,
                            navigate,
                            failedActionHandler
                          )
                        }
                      >
                        <FormattedMessage id="create-new-server-layout.soft-reboot-server" />
                      </CustomMenuItem>
                    </>
                  )}

                  <CustomMenuItem
                    disabled={server.isWorking}
                    onClick={() =>
                      handleServerActionClicked(
                        "hard-shutdown",
                        server,
                        ajax,
                        lang,
                        confirm,
                        prompt,
                        dispatch,
                        navigate,
                        failedActionHandler
                      )
                    }
                  >
                    <FormattedMessage id="create-new-server-layout.hard-shutdown-server" />
                  </CustomMenuItem>
                  <CustomMenuItem
                    disabled={server.isWorking}
                    onClick={() =>
                      handleServerActionClicked(
                        "hard-reboot",
                        server,
                        ajax,
                        lang,
                        confirm,
                        prompt,
                        dispatch,
                        navigate,
                        failedActionHandler
                      )
                    }
                  >
                    <FormattedMessage id="create-new-server-layout.hard-reboot-server" />
                  </CustomMenuItem>

                  <DropdownItem divider />
                </>
              )}

            {isAllowed("servers") && (
              <CustomMenuItem
                disabled={server.isWorking}
                to={`/${lang}/my-cloud/servers/${server._id}/backups`}
              >
                <FormattedMessage id="create-new-server-layout.create-from-backup" />
              </CustomMenuItem>
            )}

            {isAllowed("servers") && (
              <CustomMenuItem
                disabled={server.isWorking}
                onClick={() =>
                  handleServerActionClicked(
                    "reinstall",
                    server,
                    ajax,
                    lang,
                    confirm,
                    prompt,
                    dispatch,
                    navigate,
                    failedActionHandler
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.reinstall-server" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <CustomMenuItem
                disabled={server.isWorking}
                onClick={() =>
                  handleServerActionClicked(
                    "clone",
                    server,
                    ajax,
                    lang,
                    confirm,
                    prompt,
                    dispatch,
                    navigate,
                    failedActionHandler
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.clone-server" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <CustomMenuItem
                onClick={() =>
                  handleServerActionClicked(
                    "manage-tags",
                    server,
                    ajax,
                    lang,
                    confirm,
                    prompt,
                    dispatch,
                    navigate,
                    failedActionHandler
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.manage-tags" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("super-admin.support") && (
              <CustomMenuItem
                onClick={() => {
                  setSelectedServer(server);
                  setIsCreateNewServerModalOpen(true);
                }}
              >
                <FormattedMessage id="create-new-server-layout.manage-server" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <CustomMenuItem onClick={handleCreateTemplate}>
                <FormattedMessage id="create-new-server-layout.create-template" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers") && (
              <>
                <DropdownItem divider />

                <CustomMenuItem
                  disabled={server.isWorking}
                  color="red"
                  onClick={() =>
                    handleServerActionClicked(
                      "destroy",
                      server,
                      ajax,
                      lang,
                      confirm,
                      prompt,
                      dispatch,
                      navigate,
                      failedActionHandler
                    )
                  }
                >
                  <FormattedMessage id="create-new-server-layout.destroy" />
                </CustomMenuItem>
              </>
            )}
          </CustomMenu>
        </div>
      </div>
      <div className={styles.navigatorWrapper}>
        <Navigator items={items} styleMode={2} />
      </div>
      <div className={styles.hr}>
        <hr />
      </div>
      <div>
        {!server && <Spinner />}
        {server && <Outlet context={{ server }} />}
      </div>

      <CreateNewServerModal
        isOpen={isCreateNewServerModalOpen}
        onClose={handleCreateNewServerModalClosed}
        user={user}
        editServer={selectedServer}
      />

      <CreateTemplateFromServerModal
        isOpen={isCreateTemplateFromServerModalOpen}
        onClose={handleCreateTemplateFromServerModalClosed}
        server={selectedServer}
      />
    </div>
  );
}

export default ServerLayout;
