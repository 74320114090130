import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Navigator({ items, styleMode = 1, className }) {
  function renderItem(item, idx) {
    if ((item.href && item.as) || item.to) {
      return (
        <Link
          key={idx}
          href={item.href}
          to={item.to || item.as}
          className={`${styles.item} ${styles[`mode${styleMode}`]} ${
            item.selected ? styles.selected : ""
          } ${item.disabled ? "disabled" : ""}`}
        >
          {item.title}
        </Link>
      );
    }

    return (
      <div
        key={idx}
        onClick={item.onClick}
        className={`${styles.item} ${styles[`mode${styleMode}`]} ${
          item.selected ? styles.selected : ""
        }`}
      >
        {item.title}
      </div>
    );
  }

  return (
    <div className={`${styles.navigatorWrapper} ${className || ""}`}>
      {/* {withTopHR && <hr />} */}
      {items.map((item, idx) => renderItem(item, idx))}
      {/* {styleMode === 3 && <hr />} */}
    </div>
  );
}

Navigator.propTypes = {
  items: PropTypes.array,
  styleMode: PropTypes.oneOf([1, 2, 3]),
  className: PropTypes.string,
  withTopHR: PropTypes.bool,
};

export default Navigator;
