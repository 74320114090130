import styles from "./costs.module.scss";
import React, { useState } from "react";
import { useEffect } from "react";
import { useAjax, useAlert, useWLDVPS } from "../../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { useCallback } from "react";
import { currencySymbols } from "../../../../utils/billing";

export default function Costs() {
  const ajax = useAjax();
  const wldvps = useWLDVPS();
  const alert = useAlert();
  const { server } = useOutletContext();

  const [monthlyServerPrice, setMonthlyServerPrice] = useState(null);
  const [monthlyAddonsPrices, setMonthlyAddonsPrices] = useState(null);

  const [hourlyOnServerPrice, setHourlyOnServerPrice] = useState(null);
  const [hourlyOffServerPrice, setHourlyOffServerPrice] = useState(null);
  const [hourlyOnAddonsPrices, setHourlyOnAddonsPrices] = useState(null);
  const [hourlyOffAddonsPrices, setHourlyOffAddonsPrices] = useState(null);

  const getCurrentServerPrice = useCallback(async () => {
    const data = await ajax("/billing/realtime/getCurrentServerPrice", {
      serverID: server._id,
    });

    setMonthlyServerPrice(data.monthlyServerPrice);
    setMonthlyAddonsPrices(data.monthlyAddonsPrices);

    setHourlyOnServerPrice(data.hourlyOnServerPrice);
    setHourlyOffServerPrice(data.hourlyOffServerPrice);
    setHourlyOnAddonsPrices(data.hourlyOnAddonsPrices);
    setHourlyOffAddonsPrices(data.hourlyOffAddonsPrices);
  }, [ajax, server._id]);

  useEffect(() => {
    getCurrentServerPrice();
  }, [getCurrentServerPrice]);

  function renderServerPrice() {
    if (server.payment.payment_type === "hourly") {
      if (!hourlyOnServerPrice || !hourlyOffServerPrice) {
        return null;
      }

      return (
        <>
          {currencySymbols[server.payment.currency]}
          {hourlyOnServerPrice.priceForOne.toFixed(2)}{" "}
          <FormattedMessage id="overview.so-far" />
          <br />
          <span style={{ fontSize: "11px" }}>
            {currencySymbols[server.payment.currency]}
            {hourlyOnServerPrice.priceForOne.toFixed(2)}/h On
            {" | "}
            {currencySymbols[server.payment.currency]}
            {hourlyOffServerPrice.priceForOne.toFixed(2)}/h Off
          </span>
        </>
      );
    }

    if (!monthlyServerPrice) {
      return null;
    }

    return `${
      currencySymbols[server.payment.currency]
    }${monthlyServerPrice.priceForOne.toFixed(2)}`;
  }

  async function handleServerPriceClicked(e) {
    e.preventDefault();

    if (server.payment.payment_type === "hourly" && hourlyOnAddonsPrices) {
      alert({
        title: <FormattedMessage id="overview.hourly-cost" />,
        message: (
          <div className={styles.hourlyCostWrapper}>
            <div>
              <div>ON</div>
              <FormattedMessage
                id="overview.server-cost"
                values={{
                  serverCostCurrency: currencySymbols[server.payment.currency],
                  serverCost: hourlyOnServerPrice.priceForOne.toFixed(2),
                }}
              />
              <br />
              <FormattedMessage
                id="overview.addons-cost"
                values={{
                  addonsCostCurrency:
                    currencySymbols[hourlyOnAddonsPrices.currency],
                  addonsCost: hourlyOnAddonsPrices.totalPrice.toFixed(2),
                }}
              />
              <br />
              <hr />
              <FormattedMessage
                id="overview.total-cost"
                values={{
                  totalCostCurrency: currencySymbols[server.payment.currency],
                  totalCost: (
                    hourlyOnServerPrice.priceForOne +
                    hourlyOnAddonsPrices.totalPrice
                  ).toFixed(2),
                }}
              />
            </div>
            <div>
              <div>OFF</div>
              <FormattedMessage
                id="overview.server-cost"
                values={{
                  serverCostCurrency: currencySymbols[server.payment.currency],
                  serverCost: hourlyOffServerPrice.priceForOne.toFixed(2),
                }}
              />
              <br />
              <FormattedMessage
                id="overview.addons-cost"
                values={{
                  addonsCostCurrency:
                    currencySymbols[hourlyOffAddonsPrices.currency],
                  addonsCost: hourlyOffAddonsPrices.totalPrice.toFixed(2),
                }}
              />
              <br />
              <hr />
              <FormattedMessage
                id="overview.total-cost"
                values={{
                  totalCostCurrency: currencySymbols[server.payment.currency],
                  totalCost: (
                    hourlyOffServerPrice.priceForOne +
                    hourlyOffAddonsPrices.totalPrice
                  ) //gidi this is not right
                    .toFixed(2),
                }}
              />
            </div>
          </div>
        ),
      });
    }

    if (server.payment.payment_type === "monthly" && monthlyServerPrice) {
      alert({
        title: <FormattedMessage id="overview.monthly-cost" />,
        message: (
          <>
            <FormattedMessage
              id="overview.server-cost"
              values={{
                serverCostCurrency: currencySymbols[server.payment.currency],
                serverCost: monthlyServerPrice.priceForOne.toFixed(2),
              }}
            />
            <br />
            <FormattedMessage
              id="overview.addons-cost"
              values={{
                addonsCostCurrency:
                  currencySymbols[monthlyAddonsPrices.currency],
                addonsCost: monthlyAddonsPrices.totalPrice.toFixed(2),
              }}
            />
            <br />
            <hr />
            <FormattedMessage
              id="overview.total-cost"
              values={{
                totalCostCurrency: currencySymbols[server.payment.currency],
                totalCost: (
                  monthlyServerPrice.priceForOne +
                  monthlyAddonsPrices.totalPrice
                ).toFixed(2),
              }}
            />
          </>
        ),
      });
    }

    return null;
  }

  return (
    <div className={styles.box}>
      <div className={styles.title}>
        {server.payment.payment_type === "monthly" ? (
          <FormattedMessage id="overview.monthly-cost" />
        ) : (
          <FormattedMessage id="overview.hourly-cost" />
        )}
      </div>
      <div
        className={`${styles.number} ${
          server.payment.payment_type === "monthly" ? "" : styles.cost
        }`}
      >
        <a href="#" onClick={(e) => handleServerPriceClicked(e)}>
          {renderServerPrice()}
        </a>
      </div>
      {server.addons.filter((addon) => addon.whitelabel === wldvps).length >
        0 && (
        <>
          {monthlyAddonsPrices && (
            <div className={styles.subtitle}>
              <b>
                +{currencySymbols[monthlyAddonsPrices.currency]}
                {monthlyAddonsPrices.totalPrice.toFixed(2)}
              </b>{" "}
              <FormattedMessage id="addons" />
            </div>
          )}

          {hourlyOnAddonsPrices && hourlyOffAddonsPrices && (
            <div className={styles.subtitle}>
              <b>
                +{currencySymbols[hourlyOnAddonsPrices.currency]}
                {hourlyOnAddonsPrices.totalPrice.toFixed(2)}/hr
              </b>{" "}
              <FormattedMessage id="addons" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
