import styles from "./overview.module.scss";

import React, { useState } from "react";
import TrackVisibility from "react-on-screen";
import Overview from "../../../../components/server/overview";
import { useAjax, useRoles, useUser } from "../../../../utils/hooks";
import UpgradeManagedHostingModal from "../../../../components/modals/upgrade-managed-hosting";
import MonitorServicesModal from "../../../../components/modals/monitor-services";
import LatestSnapshots from "./latest-snapshots";
import ScheduledTasks from "./scheduled-tasks";
import AdminOptions from "./admin-options";
import { WithRole } from "../../../../components/with-role";
import { differenceInSeconds } from "date-fns";
import Costs from "./costs";
import CostsIntegratorTop from "./costs-integrator-top";
import CostsIntegrator from "./costs-integrator";
import { FormattedMessage } from "react-intl";
import { renderTraffic } from "../../../../utils/servers";
import { SUPER_ADMIN, WHITELABEL } from "../../../../utils/user";
import { useEffect } from "react";
import { useCallback } from "react";
import useIsMounted from "ismounted";
import { useOutletContext } from "react-router-dom";

export default function ServerOverview() {
  const user = useUser();
  const ajax = useAjax();
  const isMounted = useIsMounted();
  const { isAllowed } = useRoles();
  const { server } = useOutletContext();

  const [serverStatus, setServerStatus] = useState(null);

  const [
    isUpgradeManagedHostingModalOpen,
    setIsUpgradeManagedHostingModalOpen,
  ] = useState(false);
  const [
    isUpgradeMonitorServicesModalOpen,
    setIsUpgradeMonitorServicesModalOpen,
  ] = useState(false);

  const getServerUsage = useCallback(async () => {
    const data = await ajax("/servers/usage", { serverID: server._id });

    setServerStatus({ ...data });

    setTimeout(() => {
      if (!isMounted.current) {
        return;
      }

      getServerUsage();
    }, 5000);
  }, [ajax, server, isMounted]);

  useEffect(() => {
    getServerUsage();
  }, [getServerUsage]);

  function handleUpgradeManagedHostingModalClosed() {
    setIsUpgradeManagedHostingModalOpen(false);
  }

  function handleMonitorServicesClosed() {
    setIsUpgradeMonitorServicesModalOpen(false);
  }

  function renderCpuUsage() {
    if (!serverStatus) {
      return "-";
    }

    const value = Math.min(100, (serverStatus.cpu || 0) * 100);

    return `${value.toFixed(2)}%`;
  }

  function renderRamUsage() {
    if (!serverStatus) {
      return "-";
    }

    const value = Math.min(
      100,
      (serverStatus.mem / serverStatus.maxmem || 0) * 100
    );

    return `${value.toFixed(2)}%`;
  }

  function renderNetinNetout() {
    if (!serverStatus) {
      return null;
    }

    const diffInSeconds = differenceInSeconds(
      new Date(serverStatus.proxmox_live_net_time),
      new Date(serverStatus.proxmox_live_net_time_before)
    );

    let netin =
      ((serverStatus.proxmox_live_netin -
        serverStatus.proxmox_live_netin_before) *
        0.000008) /
      diffInSeconds;
    let netout =
      ((serverStatus.proxmox_live_netout -
        serverStatus.proxmox_live_netout_before) *
        0.000008) /
      diffInSeconds;

    netin = isNaN(netin) ? 0 : netin.toFixed(2);
    netout = isNaN(netout) ? 0 : netout.toFixed(2);

    return (
      <div className={styles.netsWrapper}>
        <div>
          <span>
            <FormattedMessage id="general.inbound" />
          </span>
          {netin}Mbit/s
        </div>
        <div>
          <span>
            <FormattedMessage id="general.outbound" />
          </span>
          {netout}Mbit/s
        </div>
      </div>
    );
  }

  return (
    <WithRole permission="servers">
      <div className={styles.wrapper}>
        <div className={styles.usageWrapper}>
          <div className={`${styles.box} ${styles.bandwidth}`}>
            <div className={styles.title}>
              <FormattedMessage id="overview.traffic-usage" />
            </div>
            <div className={`${styles.number} ${styles.bandwidth}`}>
              {renderTraffic(server)}
              {server.payment.payment_type === "monthly" ? " / 5TB" : ""}
            </div>
            <div>{renderNetinNetout()}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <FormattedMessage id="overview.cpu-usage" />
            </div>
            <div className={styles.number}>{renderCpuUsage()}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <FormattedMessage id="overview.ram-usage" />
            </div>
            <div className={styles.number}>{renderRamUsage()}</div>
          </div>

          {user.role !== WHITELABEL && (
            <div className={styles.box}>
              <div className={styles.title}>
                <FormattedMessage id="overview.disk-write-read" />
              </div>
              <div className={`${styles.number} ${styles.disk}`}>
                {serverStatus
                  ? `${parseInt(
                      serverStatus.diskread / Math.pow(1024, 3)
                    )}K read`
                  : "-"}
              </div>
              <div className={`${styles.number} ${styles.disk}`}>
                {serverStatus
                  ? `${parseInt(
                      serverStatus.diskwrite / Math.pow(1024, 3)
                    )}K write`
                  : "-"}
              </div>
            </div>
          )}

          {(!user.integrator || user.integrator === "none") && <Costs />}
          {user.integrator === "top" && <CostsIntegratorTop />}
          {user.integrator === "integrator" && <CostsIntegrator />}
          {user.integrator === "end-client" && <Costs />}
        </div>

        <div className={styles.overviewWrapper}>
          <Overview server={server} />
        </div>

        {server.status === "Active" && (
          <div className={styles.extraWrapper}>
            <TrackVisibility className={styles.box}>
              <LatestSnapshots />
            </TrackVisibility>

            {isAllowed("servers") && (
              <TrackVisibility className={styles.box}>
                <ScheduledTasks />
              </TrackVisibility>
            )}
          </div>
        )}

        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <div className={styles.extraWrapper}>
            <div className={`${styles.box} ${styles.fullWidth}`}>
              <AdminOptions />
            </div>
          </div>
        )}
      </div>

      <UpgradeManagedHostingModal
        isOpen={isUpgradeManagedHostingModalOpen}
        onClose={handleUpgradeManagedHostingModalClosed}
      />

      <MonitorServicesModal
        isOpen={isUpgradeMonitorServicesModalOpen}
        onClose={handleMonitorServicesClosed}
      />
    </WithRole>
  );
}
