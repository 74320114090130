import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import cardValidator from "card-validator";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CustomText from "../../custom-text";
import Spinner from "../../spinner";
import DreamButton from "../../dream-button";
import { useAjax, useLang } from "../../../utils/hooks";
import UpdateCreditCardQuickSmartpay from "./smartpay";

function UpdateCreditCardQuickModal({ isOpen, onClose, user }) {
  const ajax = useAjax();
  const stripe = useStripe();
  const navigate = useNavigate();
  const lang = useLang();

  const [creditCard, setCreditCard] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCVV] = useState("");

  const [sessionId, setSessionId] = useState(false);

  const [generatePaymentLink, setGeneratePaymentLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchCheckoutSession = useCallback(async () => {
    const data = await ajax(`/stripe/createStripeCheckoutSession`, {
      userID: user._id,
      redirect: window.location.href,
    });

    if (data.result === "success") {
      setSessionId(data.sessionID);
    }
  }, [ajax, user]);

  useEffect(() => {
    if (user && user.payment_gateway === "stripe") {
      fetchCheckoutSession();
    }
  }, [user, fetchCheckoutSession]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setCreditCard("");
    setMonth("");
    setYear("");
    setCVV("");
    setGeneratePaymentLink(false);
    setLoading(false);
    setError(false);
  }, [isOpen]);

  async function handleCheckoutRedirectClicked() {
    if (user.payment_gateway === "stripe") {
      await stripe.redirectToCheckout({
        sessionId,
      });
    } else if (user.payment_gateway === "payplus") {
      setGeneratePaymentLink(true);
      const data = await ajax("/payplus/generatePaymentLink", {
        backToUrl: location.href,
        isPrimary: true,
        userID: user._id,
      });
      setGeneratePaymentLink(false);
      location.href = data.url;
    } else if (user.payment_gateway === "smartpay") {
      setGeneratePaymentLink(true);
      const data = await ajax("/smartpay/generatePaymentLink", {
        backToUrl: location.href,
        isPrimary: true,
        userID: user._id,
      });

      setGeneratePaymentLink(false);

      const token = data.url.split("/");

      navigate(
        `/${lang}/billing/checkout/${
          token[token.length - 1]
        }?back=${encodeURIComponent(location.pathname)}`
      );
    }
  }

  async function handleSaveClicked() {
    setError(false);

    const cardValidatorNumber = cardValidator.number(creditCard);
    const cardValidatorMonth = cardValidator.expirationMonth(month);
    const cardValidatorYear = cardValidator.expirationYear(year);
    const cardValidatorCVV = cardValidator.cvv(cvv);

    if (!cardValidatorNumber.isValid && creditCard.length !== 9) {
      return setError("complete-registration-modal.credit-card-number-wrong");
    } else if (!cardValidatorMonth.isValid) {
      return setError("complete-registration-modal.credit-card-month-wrong");
    } else if (!cardValidatorYear.isValid) {
      return setError("complete-registration-modal.credit-card-year-wrong");
    } else if (!cardValidatorCVV.isValid) {
      return setError("complete-registration-modal.credit-card-cvv-wrong");
    }

    setLoading(true);

    await ajax("/credit-card/updateCreditCard", {
      isPrimary: true,
      userIDToSet: user._id,
      creditCard,
      cvv,
      month,
      year,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="update-credit-card-quick-modal.title" />
      </ModalHeader>
      <ModalBody>
        {["icount"].includes(user?.payment_gateway) && (
          <>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.card-number" />
              </span>
              <CustomText
                value={creditCard}
                onChange={(e) => setCreditCard(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.month" />
              </span>
              <CustomText
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                placeholder="MM"
                maxLength={2}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.year" />
              </span>
              <CustomText
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="YY"
                maxLength={2}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.cvv" />
              </span>
              <CustomText
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
            </div>

            {error && (
              <div className="error">
                <FormattedMessage id={error} />
              </div>
            )}
          </>
        )}

        {user?.payment_gateway === "stripe" && (
          <div>
            {!sessionId && <Spinner />}
            {sessionId && (
              <DreamButton
                color="light-purple"
                onClick={handleCheckoutRedirectClicked}
                disabled={generatePaymentLink}
                loading={generatePaymentLink}
              >
                <FormattedMessage id="update-credit-card-quick-modal.update-stripe" />
              </DreamButton>
            )}
          </div>
        )}

        {user?.payment_gateway === "payplus" && (
          <div>
            <DreamButton
              color="light-purple"
              onClick={handleCheckoutRedirectClicked}
              disabled={generatePaymentLink}
              loading={generatePaymentLink}
            >
              <FormattedMessage id="update-credit-card-quick-modal.update-payplus" />
            </DreamButton>
          </div>
        )}

        {user?.payment_gateway === "smartpay" && (
          <UpdateCreditCardQuickSmartpay
            userID={user._id}
            loading={loading}
            setLoading={setLoading}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {["icount"].includes(user?.payment_gateway) && (
          <DreamButton
            disabled={loading}
            color="purple"
            onClick={handleSaveClicked}
            className={styles.saveButton}
          >
            <FormattedMessage id="general.save" tagName="span" />
            {loading && <Spinner />}
          </DreamButton>
        )}
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

UpdateCreditCardQuickModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default UpdateCreditCardQuickModal;
