import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { FormattedMessage } from "react-intl";

export const CUSTOM_REACT_SELECT_COLOR_NORMAL = "normal";
export const CUSTOM_REACT_SELECT_COLOR_TRANSPARENT = "transparent";

function CustomReactSelect({
  instanceId,
  options,
  value,
  onChange,
  isDisabled,
  isSearchable = true,
  className,
  placeholder = null,
  color = CUSTOM_REACT_SELECT_COLOR_NORMAL,
  ref = null,
  clearValue = null,
  FloatingSvg = null,
  formatOptionLabel,
  isIsrael,
  errorText,
}) {
  return (
    <div className={styles.container}>
      {FloatingSvg && <FloatingSvg className={styles.floatingSvg} />}
      <Select
        ref={ref}
        placeholder={
          placeholder ||
          (isIsrael ? "בחרו" : <FormattedMessage id="general.select" />)
        }
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        instanceId={instanceId}
        clearValue={clearValue}
        options={options}
        value={value}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        className={`${styles.wrapper} ${className || ""} ${
          styles[`color-${color}`]
        } ${FloatingSvg ? styles.withFloatingSvg : ""} ${
          errorText ? styles.errorField : ""
        }`}
      />
      {errorText ? (
        <div className={styles.errorFieldText}>
          <FormattedMessage id={errorText} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

CustomReactSelect.propTypes = {
  instanceId: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.any,
  color: PropTypes.string,
  ref: PropTypes.any,
  clearValue: PropTypes.any,
  FloatingSvg: PropTypes.any,
  formatOptionLabel: PropTypes.any,
  isIsrael: PropTypes.bool,
  errorText: PropTypes.string,
  errorStyle: PropTypes.bool,
};

export default CustomReactSelect;
