import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LongArrowRight from "../svgs/long-arrow-right.svg?react";
import PlusSvg from "../svgs/plus.svg?react";
import ArrowButtonSvg from "../svgs/arrow-buttom.svg?react";
import PhoneSvg from "../svgs/phone.svg?react";
import EditSvg from "../svgs/edit.svg?react";
import ExportSvg from "../svgs/exportSvg.svg?react";
import ImportSvg from "../svgs/cloudUpload.svg?react";
import ExternalLinkSvg from "../svgs/external-link.svg?react";
import Spinner from "../spinner";

function DreamButton({
  onClick,
  color = "light-purple",
  disabled,
  className = "",
  children,
  icon = false,
  textColor = false,
  href = false,
  as = false,
  to = false,
  target = null,
  tabIndex = null,
  loading = false,
  buttonType = "button",
}) {
  function renderATag() {
    return (
      <a
        onClick={onClick}
        href={href}
        target={target}
        disabled={disabled}
        className={`${styles[import.meta.env.VITE_APP_APP_NAME]} ${className} ${
          styles.DreamButton
        } ${styles[`color-${color}`]} ${color === "green" ? "grow" : ""} ${
          textColor ? styles[textColor] : ""
        }`}
        tabIndex={tabIndex}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            {icon === "arrow" && <LongArrowRight className="rotate" />}
            {icon === "plus" && <PlusSvg className={styles.iconPlus} />}
            {icon === "small-arrow" && <ArrowButtonSvg className="rotate" />}
            {icon === "phone" && <PhoneSvg />}
            {icon === "edit" && <EditSvg />}
            {icon === "export" && <ExportSvg />}
          </>
        )}
        {children}
      </a>
    );
  }

  if (target === "_blank") {
    return renderATag();
  }

  if (href || as || to) {
    return (
      <Link
        href={href}
        to={to || as}
        target={target}
        disabled={disabled}
        className={`${styles[import.meta.env.VITE_APP_APP_NAME]} ${className} ${
          styles.DreamButton
        } ${styles[`color-${color}`]} ${color === "green" ? "grow" : ""} ${
          textColor ? styles[textColor] : ""
        }`}
        tabIndex={tabIndex}
        onClick={onClick}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            {icon === "arrow" && <LongArrowRight />}
            {icon === "plus" && <PlusSvg className={styles.iconPlus} />}
            {icon === "small-arrow" && <ArrowButtonSvg />}
            {icon === "edit" && <EditSvg />}
            {icon === "export" && <ExportSvg />}
          </>
        )}
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${styles[import.meta.env.VITE_APP_APP_NAME]} ${className} ${
        styles.DreamButton
      } ${styles[`color-${color}`]} ${color === "green" ? "grow" : ""} ${
        textColor ? styles[textColor] : ""
      } ${disabled ? styles.disabled : ""}`}
      tabIndex={tabIndex}
      type={buttonType}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {icon === "arrow" && <LongArrowRight className="rotate" />}
          {icon === "plus" && <PlusSvg className={styles.iconPlus} />}
          {icon === "small-arrow" && <ArrowButtonSvg className="rotate" />}
          {icon === "phone" && <PhoneSvg />}
          {icon === "edit" && <EditSvg className={styles.iconEdit} />}
          {icon === "export" && <ExportSvg />}
          {icon === "import" && <ImportSvg className={styles.iconImport} />}
          {icon === "external-link" && (
            <ExternalLinkSvg className={styles.iconExternalLink} />
          )}
        </>
      )}
      {children}
    </button>
  );
}

DreamButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.any,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  textColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  href: PropTypes.string,
  as: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  tabIndex: PropTypes.number,
  buttonType: PropTypes.string,
};

export default DreamButton;
