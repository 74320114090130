import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchSvg from "../svgs/search.svg?react";
import EyeSvg from "../svgs/eye.svg?react";
import EyeOffSvg from "../svgs/eye-off.svg?react";
import { FormattedMessage } from "react-intl";

function CustomText({
  className,
  inputClassName,
  eyeClassName,
  style = null,
  type = "text",
  value,
  onClick,
  onChange,
  onKeyUp,
  onKeyDown,
  onBlur,
  placeholder,
  disabled,
  icon = false,
  iconPosition = "end",
  maxLength = null,
  readOnly = false,
  autoComplete = null,
  autoCorrect = null,
  spellCheck = null,
  max = null,
  min = null,
  autoFocus = false,
  tabIndex = null,
  name = null,
  id = null,
  errorText,
}) {
  const [showEye, setShowEye] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (type === "password") {
      setShowEye(true);
    }
  }, [type]);

  function handleTogglePassword() {
    setPasswordVisible(!passwordVisible);
  }

  function renderIcon() {
    if (showEye) {
      return passwordVisible ? (
        <div className={styles.eyeWrap}>
          <EyeOffSvg
            className={`${styles.eye} ${eyeClassName || ""}`}
            onClick={handleTogglePassword}
          />
        </div>
      ) : (
        <div className={styles.eyeWrap}>
          <EyeSvg
            className={`${styles.eye} ${eyeClassName || ""}`}
            onClick={handleTogglePassword}
          />
        </div>
      );
    }

    switch (icon) {
      case "search":
        return (
          <SearchSvg
            className={`${styles.floatingIcon} ${styles[iconPosition]}`}
          />
        );
      default:
        return null;
    }
  }

  function renderPlaceholder() {
    if (typeof placeholder === "object" && !value) {
      return (
        <div
          className={styles.placeholder}
          style={{
            height: "100%",
            pointerEvents: "none",
          }}
        >
          {placeholder}
        </div>
      );
    }

    return null;
  }

  if (type === "textarea") {
    return (
      <div className={styles.wrapper}>
        {renderPlaceholder("21px")}

        <textarea
          disabled={disabled}
          placeholder={typeof placeholder === "string" ? placeholder : null}
          className={`${styles.customTextWrapper} ${styles[type]} ${
            className || ""
          }`}
          style={style}
          value={value}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          maxLength={maxLength}
          readOnly={readOnly}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={`${styles.wrapper} ${errorText ? styles.errorField : ""} ${
          className || ""
        }`}
      >
        {renderPlaceholder("8px")}
        <input
          disabled={disabled}
          type={passwordVisible ? "text" : type}
          placeholder={typeof placeholder === "string" ? placeholder : null}
          className={`${inputClassName || ""} ${styles.customTextWrapper} ${
            styles[type]
          } ${styles[iconPosition]} `}
          style={style}
          value={value}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          maxLength={maxLength}
          readOnly={readOnly}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          min={min}
          max={max}
          name={name}
          id={id}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
        />
        {renderIcon()}
      </div>
      {errorText ? (
        <div className={styles.errorFieldText}>
          <FormattedMessage id={errorText} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

CustomText.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  eyeClassName: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.any,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxLength: PropTypes.number,
  iconPosition: PropTypes.oneOf(["start", "end"]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
  errorText: PropTypes.any,
};

export default CustomText;
