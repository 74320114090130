import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Fingerprint2 from "fingerprintjs2";
import { useWebSocket } from "./utils/hooks.jsx";
import {
  setAlertModalData,
  setConfirmModalData,
  setFetchedInitialData,
  setFingerPrint,
  setPromptModalData,
  setReconnecting,
  setWLDVPS,
} from "./store/settings.js";
import { differenceInMonths } from "date-fns";
import { updateUserDetails } from "./store/user.js";
import AlertModal from "./components/modals/alert/index.jsx";
import ConfirmModal from "./components/modals/confirm/index.jsx";
import PromptModal from "./components/modals/prompt/index.jsx";
import ChangeUserPasswordModal from "./components/modals/change-user-password/index.jsx";
import IntegratorTermsModal from "./components/modals/integrator-terms/index.jsx";
import ReconnectingModal from "./components/modals/reconnecting/index.jsx";
import LoadingPage from "./components/loading-page/index.jsx";
import { getSocket } from "./utils/globals.js";
import { useAjax, useUser } from "./utils/hooks.jsx";

let updateLastSeenTimer: NodeJS.Timer;

function App() {
  const location = useLocation();
  const ajax = useAjax();
  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connect } = useWebSocket();
  const { fetchedInitialData, reconnecting } = useSelector((state: any) => ({
    fetchedInitialData: state.settings.fetchedInitialData,
    reconnecting: state.settings.reconnecting,
  }));

  const { alertModalData, confirmModalData, promptModalData } = useSelector(
    (state: any) => ({
      alertModalData: state.settings.alertModalData,
      confirmModalData: state.settings.confirmModalData,
      promptModalData: state.settings.promptModalData,
    })
  );

  const [isChangeUserPasswordModalOpen, setIsChangeUserPasswordModalOpen] =
    useState(false);

  const [isIntegratorTermsModalOpen, setIsIntegratorTermsModalOpen] =
    useState(false);

  // function handleRouteChange(url) {
  //   const socket = getSocket();

  //   if (socket) {
  //     socket.emit("update-real-time", { url });
  //   }
  // }

  useEffect(() => {
    function handleMouseMove() {
      if (updateLastSeenTimer) {
        return;
      }

      updateLastSeenTimer = setTimeout(() => {
        const socket = getSocket();

        if (socket) {
          socket.emit("update-real-time", {});
        }
      }, 1000);
    }

    window.document.addEventListener("mousemove", handleMouseMove);

    // router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      window.document.removeEventListener("mousemove", handleMouseMove);

      // router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (typeof window.requestIdleCallback === "function") {
      requestIdleCallback(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      });
    } else {
      setTimeout(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      }, 500);
    }
  }, [dispatch]);

  useEffect(() => {
    if (user.integrator === "integrator" && !user.terms_accepted) {
      // setIsIntegratorTermsModalOpen(true);
    }
  }, [user]);

  const fetchInitialData = useCallback(async () => {
    if (location.pathname === "/checkout") {
      return dispatch(setFetchedInitialData(true));
    }

    if (fetchedInitialData) {
      return;
    }

    try {
      const data = await ajax("/initialData");

      if (data.result === "success") {
        if (data.integrator === "integrator" && !data.terms_accepted) {
          // setIsIntegratorTermsModalOpen(true);
        }

        if (data.integrator === "top") {
          dispatch(setWLDVPS(data.whitelabel));
        }

        if (
          differenceInMonths(
            new Date(),
            new Date(data.passwordLastTimeChanged)
          ) >= 3
        ) {
          setIsChangeUserPasswordModalOpen(true);
        }

        dispatch(updateUserDetails(data));

        connect();

        if (location.pathname === "/en/user/login") {
          navigate("/en/my-cloud/servers");
        } else {
          navigate(`${location.pathname}${location.search}`);
        }

        dispatch(setReconnecting(data.reconnecting));

        return dispatch(setFetchedInitialData(true));
      } else {
        //
      }
    } catch (err) {
      //
    }

    if (
      location.pathname !== "/en/user/reset-password" &&
      location.pathname !== "/en/user/confirm-password" &&
      location.pathname !== "/en/user/forgot-password" &&
      location.pathname !== "/en/user/login-sms" &&
      location.pathname !== "/en/user/login" &&
      location.pathname !== "/en/user/register" &&
      location.pathname !== "/en/user/two-factor-authentication"
    ) {
      navigate(
        `/en/user/login?redirect=${encodeURIComponent(location.pathname)}`
      );
    }

    dispatch(setFetchedInitialData(true));
  }, [dispatch, ajax, connect, fetchedInitialData, location, navigate]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  function handleAlertClosed(closedVia: any) {
    dispatch(
      setAlertModalData({
        button1: alertModalData.button1,
        button2: alertModalData.button2,
        isOpen: false,
      })
    );

    if (alertModalData.resolve) {
      alertModalData.resolve(closedVia);
    }
  }

  function handleConfirmClosed(closedVia: any) {
    dispatch(
      setConfirmModalData({
        button1: confirmModalData.button1,
        button2: confirmModalData.button2,
        buttons: confirmModalData.buttons,
        isOpen: false,
      })
    );
    confirmModalData.resolve(closedVia);
  }

  function handlePromptClosed(closedVia: any) {
    dispatch(
      setPromptModalData({
        button1: promptModalData.button1,
        button2: promptModalData.button2,
        isOpen: false,
      })
    );
    promptModalData.resolve(closedVia);
  }

  function handleChangeUserPasswordModalClosed() {
    setIsChangeUserPasswordModalOpen(false);
  }

  function handleIntegratorTermsModalClosed() {
    setIsIntegratorTermsModalOpen(false);
  }

  if (!fetchedInitialData) {
    return (
      <div data-path={location.pathname}>
        <LoadingPage />
      </div>
    );
  }

  return (
    <div data-path={location.pathname}>
      <Outlet />

      {alertModalData && (
        <AlertModal
          isOpen={alertModalData.isOpen}
          onClose={handleAlertClosed}
          title={alertModalData.title}
          message={alertModalData.message}
          button={alertModalData.button}
          notification={alertModalData.notification}
          size={alertModalData.size}
        />
      )}

      {confirmModalData && (
        <ConfirmModal
          isOpen={confirmModalData.isOpen}
          onClose={handleConfirmClosed}
          title={confirmModalData.title}
          message={confirmModalData.message}
          button1={confirmModalData.button1}
          button2={confirmModalData.button2}
          buttons={confirmModalData.buttons}
          beforeClose={confirmModalData.beforeClose}
        />
      )}

      {promptModalData && (
        <PromptModal
          isOpen={promptModalData.isOpen}
          onClose={handlePromptClosed}
          title={promptModalData.title}
          message={promptModalData.message}
          defaultText={promptModalData.defaultText}
          button1={promptModalData.button1}
          button2={promptModalData.button2}
          acceptOnlyValue={promptModalData.acceptOnlyValue}
          textType={promptModalData.textType}
          beforeClose={promptModalData.beforeClose}
          placeholder={promptModalData.placeholder}
          withCheckbox={promptModalData.withCheckbox}
        />
      )}

      <IntegratorTermsModal
        isOpen={isIntegratorTermsModalOpen}
        onClose={handleIntegratorTermsModalClosed}
      />

      <ChangeUserPasswordModal
        isOpen={isChangeUserPasswordModalOpen}
        onClose={handleChangeUserPasswordModalClosed}
      />

      <ReconnectingModal isOpen={reconnecting} />
    </div>
  );
}

export default App;
