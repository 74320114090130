import React from "react";
import { differenceInDays, endOfMonth } from "date-fns";
import VisaSvg from "../components/svgs/visa.svg?react";
import AmericanExpressSvg from "../components/svgs/american-express.svg?react";
import MasterCardSvg from "../components/svgs/master-card.svg?react";
import DiscoverSvg from "../components/svgs/discover.svg?react";
import DinersSvg from "../components/svgs/diners.svg?react";
import NoCardSvg from "../components/svgs/nocard.svg?react";

export function getPrice(server, asObject) {
  if (asObject) {
    return {
      currency: server.payment.currency,
      price:
        server.payment.fixed_price > -1
          ? server.payment.fixed_price
          : server.price,
    };
  }

  if (typeof server.price === "object") {
    return (
      <>
        {currencySymbols[server.payment.currency]}
        {server.price.hourlyPriceOn.toFixed(2)}/on{" "}
        {server.price.hourlyPriceOff.toFixed(2)}/off
      </>
    );
  }

  return (
    <>
      {currencySymbols[server.payment.currency]}
      {server.payment.fixed_price > -1
        ? server.payment.fixed_price
        : server.price.toFixed(2)}
    </>
  );
}

export const calculateServerPriceHourlyOn = (
  user,
  serverData,
  productPrices
) => {
  const discount = 1 - user.discount_monthly / 100;

  const { cpu, ram_mb, disks_gb, backup } = serverData;

  const hourlyExtraPrice = parseFloat(
    import.meta.env.VITE_APP_HOURLY_EXTRA_PRICE
  );

  let price = productPrices.basePrice * hourlyExtraPrice * discount;
  let backupPrice = 0;

  let ssdBackupPrice = 0,
    additionalSSDbackupPrice = 0;

  if (cpu) {
    const item = productPrices.cpu.find((c) => c.value === cpu);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
    }
  }

  if (ram_mb) {
    const item = productPrices.ram_mb.find((r) => r.value === ram_mb);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
    }
  }

  if (disks_gb[0]) {
    const item = productPrices.ssd_gb.find((s) => s.value === disks_gb[0].size);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
      ssdBackupPrice = item.price * hourlyExtraPrice * discount;
    }
  }

  if (disks_gb.length > 1) {
    for (const d of disks_gb.slice(1)) {
      const item = productPrices.additional_ssd_gb.find(
        (as) => as.value === d.size
      );
      if (item) {
        price += item.price * hourlyExtraPrice * discount;
        additionalSSDbackupPrice += item.price * hourlyExtraPrice * discount;
      }
    }
  }

  if (backup && !user.free_backups) {
    backupPrice =
      (2.5 + ssdBackupPrice + additionalSSDbackupPrice) *
      productPrices.backup *
      discount;
    price += backupPrice;
  }

  return { price: price / 730, backup: backupPrice / 730 };
};

export const calculateServerPriceHourlyOff = (
  user,
  serverData,
  productPrices
) => {
  const discount = 1 - user.discount_monthly / 100;

  const { disks_gb, backup } = serverData;

  const hourlyExtraPrice = parseFloat(
    import.meta.env.VITE_APP_HOURLY_EXTRA_PRICE
  );

  let price = 0;
  let backupPrice = 0;
  let ssdBackupPrice = 0,
    additionalSSDbackupPrice = 0;

  if (disks_gb[0] && !user.free_backups) {
    ssdBackupPrice =
      (0.008 + ((disks_gb[0].size - 20) / 10) * 0.0007) *
      hourlyExtraPrice *
      discount;
    price += ssdBackupPrice;
  }

  if (disks_gb.length > 1 && !user.free_backups) {
    additionalSSDbackupPrice =
      (0.008 +
        ((disks_gb.slice(1).reduce((s, d) => s + d.size, 0) - 20) / 10) *
          0.0007) *
      hourlyExtraPrice *
      discount;
    price += additionalSSDbackupPrice;
  }

  if (backup && !user.free_backups) {
    backupPrice =
      (0.0035 + ssdBackupPrice + additionalSSDbackupPrice) *
      productPrices.backup *
      discount;
    price += backupPrice;
  }

  return { price, backup: backupPrice };
};

export const getRelevantAddons = (server, wldvps) => {
  return server.addons
    .filter((addon) => addon.whitelabel === wldvps)
    .map((addon) => addon._id);
};

export const payWithOptions = [
  { label: "Credit card", value: "credit-card" },
  { label: "Masav", value: "masav" },
  { label: "Bank Transfer", value: "bank-transfer" },
  // { label: "Payoneer", value: "payoneer" },
  // { label: "Paypal", value: "paypal" },
  { label: "Free Of Charge", value: "free-of-charge" },
];

export const masavTransferBanks = [
  { label: "Benleumi 1", value: "mizrahi", color: "#ff780b" },
  { label: "Mercantile", value: "mercantile", color: "#3A880F" },
  // { label: "Benleumi", value: "benleumi", color: "#ffc10e" },
];

export const currencySymbols = {
  EUR: "€",
  USD: "$",
  ILS: "₪",
  GBP: "£",
};

export const getSymbolsForReactSelect = () => {
  return Object.keys(currencySymbols).map((currency, idx) => ({
    label: `${currency} (${currencySymbols[currency]})`,
    value: currency,
    key: idx,
  }));
};

export const paymentMethodToText = (paymentMethod) => {
  switch (paymentMethod) {
    case "smartpayplus_redirect":
      return "Secured Credit Card";
    default:
      return "-";
  }
};

export const convertCurrency = (number, from, to, rates, fixed = {}) => {
  let ratesTo = rates[to];

  if (from === "USD" && to === "ILS" && fixed[from] > 0) {
    ratesTo = fixed[from];
  }

  return (number / rates[from]) * ratesTo;
};

export const paymentGateways = [
  {
    label: "SmartPay",
    value: "smartpay",
  },
  {
    label: "iCount",
    value: "icount",
  },
  // {
  //   label: "payplus",
  //   value: "payplus",
  // },
  {
    label: "Stripe",
    value: "stripe",
  },
];

export const billingGateways = [
  {
    label: "iCount",
    value: "icount",
  },
  {
    label: "Green Invoice",
    value: "green-invoice",
  },
  {
    label: "DreamVPS B.V",
    value: "dreamvps",
  },
];

export const getVatPrec = (user) => {
  const country = user.country ? user.country.toUpperCase().trim() : "";

  if (user.payment_gateway === "stripe") {
    const vat = country === "NL" ? import.meta.env.VITE_APP_CURRENT_VAT_NL : 0;

    return 1 + vat / 100;
  }

  const city = user.city ? user.city.toLowerCase().trim() : "";

  if (country !== "IL" || city.includes("eilat") || city.includes("אילת")) {
    return 1;
  }

  const vat = import.meta.env.VITE_APP_CURRENT_VAT_IL;

  return 1 + vat / 100;
};

export const getRestOfMonthDays = () => {
  const curDate = new Date();

  return differenceInDays(endOfMonth(curDate), curDate) + 1;
};

export const getClosestPrice = (value, pricingArray, direction) => {
  const min = pricingArray[0].value;
  const max = pricingArray[pricingArray.length - 1].value;

  let checker = pricingArray.find((price) => price.value === value);
  if (checker) {
    return checker;
  }

  let searchValue = value;

  do {
    searchValue += direction === "up" ? 1 : -1;

    if (searchValue > max) {
      return { value: max };
    }
    if (searchValue < min) {
      return { value: min };
    }

    checker = pricingArray.find((price) => price.value === searchValue);
    if (checker) {
      return checker;
    }
  } while (true); /* eslint-disable-line */
};

export const getCreditCardSvg = (cardType) => {
  const lowerCaseCardType = cardType.toLowerCase();

  if (
    lowerCaseCardType.includes("visa") ||
    lowerCaseCardType.includes("ויזה") ||
    lowerCaseCardType === "MAX" ||
    lowerCaseCardType === "MAX VISA"
  ) {
    return <VisaSvg />;
  } else if (
    lowerCaseCardType.includes("mastercard") ||
    lowerCaseCardType.includes("מסטרקארד") ||
    lowerCaseCardType.includes("מאסטרקארד") ||
    lowerCaseCardType === "יורוקרד מסטרקרד" ||
    lowerCaseCardType === "מסטרכרד תייר" ||
    cardType === "MAX"
  ) {
    return <MasterCardSvg />;
  } else if (
    lowerCaseCardType.includes("american-express") ||
    lowerCaseCardType.includes("american express") ||
    lowerCaseCardType === "אמריקן אקספרס"
  ) {
    return <AmericanExpressSvg />;
  } else if (
    lowerCaseCardType.includes("diners-club") ||
    lowerCaseCardType.includes("dinersclub")
  ) {
    return <DinersSvg />;
  } else if (lowerCaseCardType.includes("discover")) {
    return <DiscoverSvg />;
  } else if (lowerCaseCardType.includes("isracard")) {
    return <img src="./img/isracard-logo.png" />;
  } else if (lowerCaseCardType.includes("jcb")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("unionpay")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("maestro")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("mir")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("elo")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("hiper")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("hipercard")) {
    return <NoCardSvg />;
  } else {
    return <NoCardSvg />;
  }

  // switch (cardType) {
  //   case "visa":
  //     return <VisaSvg />;
  //   case "mastercard":
  //     return <MasterCardSvg />;
  //   case "american-express":
  //     return <AmericanExpressSvg />;
  //   case "diners-club":
  //     return <DinersSvg />;
  //   case "discover":
  //     return <DiscoverSvg />;
  //   case "isracard":
  //     return <img src="./img/isracard-logo.png" />;
  //   case "jcb":
  //     return <NoCardSvg />;
  //   case "unionpay":
  //     return <NoCardSvg />;
  //   case "maestro":
  //     return <NoCardSvg />;
  //   case "mir":
  //     return <NoCardSvg />;
  //   case "elo":
  //     return <NoCardSvg />;
  //   case "hiper":
  //     return <NoCardSvg />;
  //   case "hipercard":
  //     return <NoCardSvg />;
  //   default:
  //     return <NoCardSvg />;
  // }
};

export const isracardValidation = (number) => {
  if (number.length < 8 || number.length > 9) {
    return false;
  }

  number = number.padStart(9, "0");
  let sum = 0;

  for (let i = 0; i <= 8; i++) {
    sum += (9 - i) * number[i];
  }

  return sum % 11 === 0;
};

export const getInvoiceAmount = (invoice) => {
  if (!invoice) {
    return "";
  }

  if (import.meta.env.VITE_APP_APP_NAME === "cloud") {
    let amount = invoice.items.reduce(
      (total, curItem) => total + curItem.amount * curItem.quantity,
      0
    );

    amount = amount * (1 + invoice.vat / 100);

    return amount.toFixed(2);
  }

  let amount = null;
  if (["deal", "tax", "tax-receipt", "refund"].includes(invoice.invoice_type)) {
    amount = invoice.items.reduce(
      (total, curItem) =>
        total + parseFloat(curItem.price) * parseFloat(curItem.quantity),
      0
    );

    amount = amount * (1 + invoice.vat / 100);
  } else {
    amount = invoice.payments.reduce(
      (total, curItem) => total + parseFloat(curItem.price),
      0
    );
  }

  return amount.toFixed(2);
};

export const getInvoiceAmountFormatted = (invoice) => {
  if (!invoice) {
    return "";
  }

  return `${currencySymbols[invoice.currency]}${getInvoiceAmount(invoice)}`;
};

export const getInvoicePaymentMethod = (invoice) => {
  if (invoice.payments[0]) {
    if (invoice.payments[0].type === -1) {
      return "unpaid";
    }
    if (invoice.payments[0].type === 0) {
      return "withholding";
    }
    if (invoice.payments[0].type === 1) {
      return "cash";
    }
    if (invoice.payments[0].type === 2) {
      return "check";
    }
    if (invoice.payments[0].type === 3) {
      return "credit-card";
    }
    if (invoice.payments[0].type === 4) {
      return "bank-transfer";
    }
    if (invoice.payments[0].type === 5) {
      return "paypal";
    }
    if (invoice.payments[0].type === 10) {
      return "payment-app";
    }
    if (invoice.payments[0].type === 11) {
      return "other";
    }
  }

  if (invoice.user && invoice.user.pay_with) {
    return invoice.user.pay_with;
  }

  return "-";
};

export const getRelevantOtherProducts = (user, wldvps) => {
  return user.otherProducts
    .filter((op) => op.whitelabel === wldvps)
    .map((op) => op._id);
};
