import { format } from "date-fns";
import { produce } from "immer";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

const initialState = {
  unread: 0,
  data: [],
};

const createNotificationItem = (notification, id) => {
  let title;

  switch (notification.notification_type) {
    case "NOTIFICATION_TYPE_NEW_TICKET":
      title = {
        id: "notifications.new-ticket",
        values: {
          userName: `${notification.data.firstName} ${notification.data.lastName}`,
        },
      };
      break;
    case "NOTIFICATION_TYPE_NEW_REPLY_CLIENT":
      title = {
        id: "notifications.new-reply",
        values: { fromName: notification.data.fromName || "" },
      };
      break;
    case "NOTIFICATION_TYPE_NEW_REPLY_ADMIN":
      title = {
        id: "notifications.new-reply",
        values: { fromName: notification.data.fromName || "" },
      };
      break;
    case "NOTIFICATION_TYPE_ACTIVE_2FA":
      title = {
        id: "notifications.active-2fa",
      };
      break;
    default:
      return null;
  }

  return {
    _id: notification._id,
    read: notification.read,
    title,
    date: format(new Date(notification.created_at), "d/M/y HH:mm"),
    id,
    data: notification.data,
    type: notification.notification_type,
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      if (!action.payload) {
        return state;
      }

      const unread = action.payload.reduce(
        (total, item) => total + (item.read ? 0 : 1),
        0
      );

      const data = action.payload.map((n, idx) =>
        createNotificationItem(n, idx)
      );

      return { unread, data };
    }
    case READ_NOTIFICATION: {
      return produce(state, (draftState) => {
        const notification = draftState.data.find(
          (n) => n._id === action.payload._id
        );

        notification.read = true;
      });
    }
    case CLEAR_NOTIFICATIONS: {
      return initialState;
    }
    default:
      return state;
  }
}

export const readNotification = (notificationID) => {
  return { type: READ_NOTIFICATION, payload: { _id: notificationID } };
};

export const clearNotifications = () => {
  return { type: CLEAR_NOTIFICATIONS };
};
