import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";

function Spinner({ width = 22, height = 22, className = "" }) {
  return (
    <Oval
      wrapperClass={`${styles.wrapper} ${className}`}
      color="#67DA5D"
      width={width}
      height={height}
    />
  );
}

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default Spinner;
