import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getTypeOfTask } from "../../../utils/tasks";
import { useSelector } from "react-redux";
import DreamButton from "../../dream-button";
import Spinner from "../../spinner";
import { FormattedMessage } from "react-intl";
import { useAjax } from "../../../utils/hooks";

function TasksLogModal({ isOpen, onClose, taskID }) {
  const ajax = useAjax();
  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const [task, setTask] = useState(null);
  const [logs, setLogs] = useState([]);

  const getLogs = useCallback(async () => {
    const data = await ajax("/jobs/getLogs", { jobID: taskID });

    if (data.logs) {
      data.logs = data.logs.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    }

    setLogs(data.logs);
  }, [ajax, taskID]);

  useEffect(() => {
    if (isOpen) {
      getLogs();
    }
  }, [isOpen, getLogs]);

  useEffect(() => {
    if (!tasks || !tasks.data) {
      return;
    }

    const task = tasks.data.find((t) => t.id === taskID);

    setTask(task);
  }, [taskID, tasks]);

  function renderTextColor(text) {
    if (text.startsWith("step") || text.startsWith("ticket verify")) {
      return "red";
    }

    if (text.match(/[[a-z].js:\d+:\d+]/)) {
      return "#b5b5b5";
    }

    return "black";
  }

  function handleStepClicked(step) {
    document.querySelectorAll(".row-text").forEach((item) => {
      if (step === item.innerText) {
        item.scrollIntoView();
      }
    });
  }

  function renderStepsButtons() {
    const steps = [
      ...new Set(
        logs
          .filter(
            (log) => typeof log.text === "string" && log.text.startsWith("step")
          )
          .map((log) => log.text)
          .sort()
      ),
    ];

    return (
      <div className={styles.stepsWrapper}>
        {steps.map((step, key) => (
          <DreamButton
            color="text"
            key={key}
            onClick={() => handleStepClicked(step)}
          >
            {step}
          </DreamButton>
        ))}
      </div>
    );
  }

  function renderHeader() {
    if (!task) {
      return null;
    }

    return (
      <>
        {getTypeOfTask(task)} {` [${taskID}]`}
        {!task.finishedAt && task.step > -1 && <Spinner />}
      </>
    );
  }

  function renderBody() {
    if (!task) {
      return null;
    }

    return (
      <>
        <div>
          <FormattedMessage id="tasks.executed-by-user" /> -{" "}
          <b>{task.created_by}</b>{" "}
          <FormattedMessage id="tasks.executed-by-user-ip" /> -{" "}
          <b>{task.user_ip}</b>
        </div>

        {renderStepsButtons()}

        {logs.map((log, key) => (
          <div key={key} className={styles.infoRow}>
            <b>{format(new Date(log.created_at), "HH:mm:ss")}</b> -{" "}
            {typeof log.text === "string" ? (
              <span
                className="row-text"
                style={{ color: renderTextColor(log.text) }}
              >
                {log.text}
              </span>
            ) : (
              JSON.stringify(log.text)
            )}
          </div>
        ))}
      </>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>{renderHeader()}</ModalHeader>
      <ModalBody>{renderBody()}</ModalBody>
      <ModalFooter>
        <DreamButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

TasksLogModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  taskID: PropTypes.string,
};

export default TasksLogModal;
