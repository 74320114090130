import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";

function Box({
  title,
  titleOpts = {},
  expanded,
  className,
  highlight = false,
  style = {},
  children,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      className={`${className || ""} ${styles.wrapper} ${
        title ? styles.hasTitle : ""
      } ${expanded ? styles.cursor : ""} ${highlight && "highlight"}`}
      style={style}
    >
      {title && (
        <span className={titleOpts.inBox ? styles.titleInBox : styles.title}>
          {title}
        </span>
      )}

      {expanded ? children(isOpen) : children}

      {expanded && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`expanded ${isOpen ? "isOpen" : ""} ${styles.expanded} ${
            isOpen ? styles.isOpen : ""
          }`}
        >
          {expanded}
        </div>
      )}
    </div>
  );
}

Box.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titleOpts: PropTypes.object,
  expanded: PropTypes.any,
  highlight: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Box;
