import styles from "./index.module.scss";

import React, { useRef, useState } from "react";
import { encodeFileAsDataURL } from "../../utils";
import { useAjax, useAjaxValidator } from "../../utils/hooks";
import Box from "../box";
import { FormattedMessage } from "react-intl";
import DreamButton from "../dream-button";

let setFunction;

export default function VerificationRequired() {
  const ajax = useAjax();
  const ajaxValidator = useAjaxValidator();
  const inputFileRef = useRef();

  const [verificationStatus, setVerificationStatus] = useState(false);

  const [frontCreditCardFile, setFrontCreditCardFile] = useState(null);
  const [backCreditCardFile, setBackCreditCardFile] = useState(null);
  const [idCardFile, setIdCardFile] = useState(null);
  const [addressDocFile, setAddressDocFile] = useState(null);

  const [loading, setLoading] = useState(false);

  function handleSelectClicked(foo) {
    setFunction = foo;
    inputFileRef.current.click();
  }

  function handleInputFileChanged(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    file.src = URL.createObjectURL(file);

    setFunction(file);
  }

  async function handleUploadClicked() {
    setLoading(true);

    const frontCreditCard = {
      base64: await encodeFileAsDataURL(frontCreditCardFile),
      type: frontCreditCardFile.type,
    };

    const backCreditCard = {
      base64: await encodeFileAsDataURL(backCreditCardFile),
      type: backCreditCardFile.type,
    };

    const idCard = {
      base64: await encodeFileAsDataURL(idCardFile),
      type: idCardFile.type,
    };

    const addressDoc = {
      base64: await encodeFileAsDataURL(addressDocFile),
      type: addressDocFile.type,
    };

    const data = await ajax("/users/uploadVerificationDocuments", {
      frontCreditCard,
      backCreditCard,
      idCard,
      addressDoc,
    });

    ajaxValidator(data, () => {
      setVerificationStatus(true);
    });
  }

  if (verificationStatus) {
    return (
      <div className={styles.wrapper}>
        <Box>
          <div>
            <FormattedMessage
              tagName="b"
              id="verification-required.upload.title"
            />
          </div>
          <div>
            <FormattedMessage id="verification-required.upload.message" />
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Box>
        <div className={styles.title}>
          <FormattedMessage tagName="b" id="verification-required.title" />
        </div>
        <div>
          <FormattedMessage id="verification-required.message" />
        </div>
        <div className={styles.list}>
          <div>
            <div>
              <FormattedMessage id="verification-required.doc1" />
              <DreamButton
                color="text"
                onClick={() => handleSelectClicked(setFrontCreditCardFile)}
              >
                <FormattedMessage
                  id={
                    frontCreditCardFile ? "general.replace" : "general.upload"
                  }
                />
              </DreamButton>
            </div>
            {frontCreditCardFile && <img src={frontCreditCardFile.src} />}
          </div>

          <hr />

          <div>
            <div>
              <FormattedMessage id="verification-required.doc2" />
              <DreamButton
                color="text"
                onClick={() => handleSelectClicked(setBackCreditCardFile)}
              >
                <FormattedMessage
                  id={backCreditCardFile ? "general.replace" : "general.upload"}
                />
              </DreamButton>
            </div>
            {backCreditCardFile && <img src={backCreditCardFile.src} />}
          </div>

          <hr />

          <div>
            <div>
              <FormattedMessage id="verification-required.doc3" />
              <DreamButton
                color="text"
                onClick={() => handleSelectClicked(setIdCardFile)}
              >
                <FormattedMessage
                  id={idCardFile ? "general.replace" : "general.upload"}
                />
              </DreamButton>
            </div>
            {idCardFile && <img src={idCardFile.src} />}
          </div>

          <hr />

          <div>
            <div>
              <FormattedMessage id="verification-required.doc4" />
              <DreamButton
                color="text"
                onClick={() => handleSelectClicked(setAddressDocFile)}
              >
                <FormattedMessage
                  id={addressDocFile ? "general.replace" : "general.upload"}
                />
              </DreamButton>
            </div>
            {addressDocFile && <img src={addressDocFile.src} />}
          </div>
        </div>

        <div className={styles.uploadWrp}>
          <DreamButton
            onClick={handleUploadClicked}
            disabled={
              !frontCreditCardFile ||
              !backCreditCardFile ||
              !idCardFile ||
              !addressDocFile ||
              loading
            }
            loading={loading}
          >
            <FormattedMessage id="general.submit" />
          </DreamButton>
        </div>
      </Box>

      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        onChange={handleInputFileChanged}
      />
    </div>
  );
}
