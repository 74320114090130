import styles from "./costs.module.scss";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useAjax, useAlert, useUser, useWLDVPS } from "../../../../utils/hooks";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { userCurrency } from "../../../../utils/user";
import { useOutletContext } from "react-router-dom";

export default function CostsIntegrator() {
  const user = useUser();
  const ajax = useAjax();
  const wldvps = useWLDVPS();
  const alert = useAlert();
  const { server } = useOutletContext();

  const [yourMonthlyCost, setYourMonthlyCost] = useState(0);
  const [yourHourlyOnCost, setYourHourlyOnCost] = useState(0);
  const [yourHourlyOffCost, setYourHourlyOffCost] = useState(0);

  const [integratorMonthlyCost, setIntegratorMonthlyCost] = useState(0);
  const [integratorHourlyOnCost, setIntegratorHourlyOnCost] = useState(0);
  const [integratorHourlyOffCost, setIntegratorHourlyOffCost] = useState(0);

  const calculate = useCallback(async () => {
    const yourCostData = await ajax(
      "/billing-deprecated/prices-calculation/calculatePrice",
      {
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        disks_gb: server.disks_gb,
        backup: server.backup,
        image: server.image,
        // cpanelAccounts,
      }
    );

    const integratorCostData = await ajax(
      "/billing-deprecated/prices-calculation/calculatePrice",
      {
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        disks_gb: server.disks_gb,
        backup: server.backup,
        image: server.image,
        // cpanelAccounts,
        forUserID: server.user_id,
      }
    );

    setYourMonthlyCost(yourCostData.prices.monthly.price);
    setIntegratorMonthlyCost(integratorCostData.prices.monthly.price);

    setYourHourlyOnCost(yourCostData.prices.hourlyOn.price);
    setYourHourlyOffCost(yourCostData.prices.hourlyOff.price);
    setIntegratorHourlyOnCost(integratorCostData.prices.hourlyOn.price);
    setIntegratorHourlyOffCost(integratorCostData.prices.hourlyOff.price);
  }, [ajax, server]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  function renderServerPrice(forIntegrator = false) {
    if (!user) {
      return null;
    }

    if (user._id === server.user_id && forIntegrator) {
      return "-";
    }

    if (server.payment.payment_type === "hourly") {
      return (
        <>
          <span style={{ fontSize: "11px" }}>
            {userCurrency(server.payment)}
            {(forIntegrator
              ? integratorHourlyOnCost
              : yourHourlyOnCost
            ).toFixed(2)}
            /h On
            {" | "}
            {userCurrency(server.payment)}
            {(forIntegrator
              ? integratorHourlyOffCost
              : yourHourlyOffCost
            ).toFixed(2)}
            /h Off
          </span>
        </>
      );
    }

    return `${userCurrency(server.payment)}${(forIntegrator
      ? integratorMonthlyCost
      : yourMonthlyCost
    ).toFixed(2)}`;
  }

  async function handleServerPriceClicked() {
    if (!user || server.payment.payment_type === "hourly") {
      return null;
    }

    const currency = userCurrency(server.payment);

    await alert({
      title: <FormattedMessage id="overview.monthly-cost" />,
      message: (
        <>
          <FormattedMessage
            id="overview.server-cost"
            values={{
              serverCostCurrency: currency,
              serverCost: 0,
            }}
          />
          <br />
          <FormattedMessage
            id="overview.addons-cost"
            values={{
              addonsCostCurrency: currency,
              addonsCost: 0,
            }}
          />
          <br />
          <hr />
          <FormattedMessage
            id="overview.total-cost"
            values={{
              totalCostCurrency: currency,
              totalCost: 0,
            }}
          />
        </>
      ),
    });
  }

  return (
    <>
      <div className={styles.box}>
        <div className={styles.title}>
          {server.payment.payment_type === "monthly" ? (
            <FormattedMessage id="overview.your-monthly-cost" />
          ) : (
            <FormattedMessage id="overview.your-hourly-cost" />
          )}
        </div>
        <div
          className={`${styles.number} ${
            server.payment.payment_type === "monthly" ? "" : styles.cost
          }`}
        >
          <a href="#" onClick={() => handleServerPriceClicked()}>
            {renderServerPrice()}
          </a>
        </div>
        {server.addons.filter((addon) => addon.whitelabel === "dreamvps")
          .length > 0 && (
          <div className={styles.subtitle}>
            <FormattedMessage id="overview.includes-addons" />
          </div>
        )}
      </div>
      <div className={styles.box}>
        <div className={styles.title}>
          {server.payment.payment_type === "monthly" ? (
            <FormattedMessage id="overview.client-monthly-cost" />
          ) : (
            <FormattedMessage id="overview.client-hourly-cost" />
          )}
        </div>
        <div
          className={`${styles.number} ${
            server.payment.payment_type === "monthly" ? "" : styles.cost
          }`}
        >
          <a href="#" onClick={() => handleServerPriceClicked()}>
            {renderServerPrice(true)}
          </a>
        </div>
        {server.addons.filter((addon) => addon.whitelabel === wldvps).length >
          0 && (
          <div className={styles.subtitle}>
            <FormattedMessage id="overview.includes-addons" />
          </div>
        )}
      </div>
    </>
  );
}
